const imageTypeExtension = ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'tiff']
const stringUtils = {
  methods: {
    getOnlyNumbers(value) {
      return value ? value.replace(/\D/g, '') : ''
    },
    getOnlyLetters(value) {
      return value ? value.replace(/[^a-zA-Z]+/g, '') : ''
    },
    getDdd(value) {
      if (value) {
        const [ddd] = value.match(/^\([1-9]{2}\)/)
        return this.getOnlyNumbers(ddd)
      }
      return undefined
    },
    getTelephoneNumber(value) {
      if (value) {
        const arr = value.split(')')
        return arr.length > 1 ? this.getOnlyNumbers(arr[1]) : ''
      }
      return undefined
    },
    getFileName(value) {
      if (!value || typeof value === 'function') return null
      const [file] = value?.split('/').slice(-1)
      return file
    },
    getFileExtension(value) {
      if (!value) return null
      const [extension] = value?.split('.').slice(-1)
      return extension
    },
    getFileType(value) {
      if (!value) return null
      let extension = value?.split('.').slice(-1).toString()
      if (imageTypeExtension.includes(extension)) {
        extension = 'image/*'
      }
      return extension
    },
    getWithNoAccents(value) {
      if (!value) {
        return ''
      }
      return value.normalize('NFD').replace(/\p{Diacritic}/gu, '')
    },
    getStringWithFirstLetterUpper(value, delimiters = [], joinSymbol = '_') {
      if (!value || typeof value !== 'string') return ''
      let values = [value]
      if (delimiters.length) {
        values = []
        delimiters.forEach(delimiter => {
          values.push(...value.split(delimiter))
        })
      }
      return values
        .map(localValue => `${localValue[0].toUpperCase()}${localValue.substring(1)}`)
        .join(joinSymbol)
    },
    getStringOrNull(value) {
      return value?.toString() || null
    }
  },
}

export default stringUtils
