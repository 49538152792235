<template>
  <div>
    <template v-if="isDropdown">
      <b-dropdown
        boundary="window"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle mr-25"
          />
        </template>

        <b-dropdown-item
          v-if="showReadOnly"
          data-grid-action="readOnly"
          @click="$emit('read-only')"
        >
          <b-icon
            icon="search"
            variant="outline-primary"
            font-scale="1"
          />
          <span class="dropdown-text">{{ $t('Ver detalhes') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="!isActive && showActivate"
          data-grid-action="activate"
          @click="$emit('activate')"
        >
          <b-icon
            icon="check-circle"
            variant="success"
            font-scale="1"
          />
          <span class="dropdown-text">{{ $t('Ativar') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-else-if="isActive && showDeactivate"
          data-grid-action="deactivate"
          @click="$emit('deactivate')"
        >
          <b-icon
            icon="x-circle"
            variant="danger"
            font-scale="1"
          />
          <span class="dropdown-text">{{ $t('Inativar') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="showUpdate"
          data-grid-action="update"
          @click="$emit('update')"
        >
          <b-icon
            icon="pencil"
            variant="primary"
            font-scale="1"
          />
          <span class="dropdown-text">{{ $t('Alterar') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="showDelete"
          data-grid-action="delete"
          @click="$emit('delete')"
        >
          <b-icon
            icon="trash"
            variant="danger"
            font-scale="1"
          />
          <span class="dropdown-text">{{ deleteTitleValue }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-for="(button, index) in buttons"
          :key="index"
          :data-grid-action="button.event"
          @click="$emit(button.event)"
        >
          <b-icon
            :icon="button.icon"
            :variant="button.variant || 'primary'"
            font-scale="1"
          />
          <span class="dropdown-text">{{ button.title }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <div
      v-else
      class="d-flex align-items-center justify-content-center flex-wrap"
      style="gap: 5px"
    >
      <e-button
        v-if="showReadOnly"
        icon="search"
        variant="outline-primary"
        class="p-5 btn-sm-block"
        :title="$t('Ver detalhes')"
        data-grid-action="readOnly"
        @click="$emit('read-only')"
      />

      <e-button
        v-if="showUpdate"
        icon="pencil"
        variant="primary"
        class="p-5 btn-sm-block"
        :title="$t('Alterar')"
        data-grid-action="update"
        @click="$emit('update')"
      />
      <e-button
        v-if="showDelete"
        icon="trash"
        variant="danger"
        class="p-5 btn-sm-block"
        :title="deleteTitleValue"
        data-grid-action="delete"
        @click="$emit('delete')"
      />

      <e-button
        v-if="!isActive && showActivate"
        icon="check-circle"
        variant="success"
        class="p-5 btn-sm-block"
        :title="$t('Ativar')"
        data-grid-action="activate"
        @click="$emit('activate')"
      />
      <e-button
        v-else-if="isActive && showDeactivate"
        icon="x-circle"
        variant="danger"
        class="p-5 btn-sm-block"
        :title="$t('Inativar')"
        data-grid-action="deactivate"
        @click="$emit('deactivate')"
      />

      <e-button
        v-for="(button, index) in buttons"
        :key="index"
        :icon="button.icon"
        :variant="button.variant || 'primary'"
        class="p-5 btn-sm-block"
        :title="button.title"
        :data-grid-action="button.event"
        @click="$emit(button.event)"
      />
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BIcon } from 'bootstrap-vue'
import { EButton } from '@/views/components'

export default {
  components: {
    EButton,
    BDropdown,
    BDropdownItem,
    BIcon,
  },

  props: {
    showUpdate: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    deleteTitle: {
      type: String,
      default: null,
    },
    showActivate: {
      type: Boolean,
      default: false,
    },
    showDeactivate: {
      type: Boolean,
      default: false,
    },
    showReadOnly: {
      type: Boolean,
      default: false,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    isDropdown() {
      const totalShows = [
        this.showActivate || this.showDeactivate,
        this.showUpdate,
        this.showDelete,
        this.showReadOnly,
      ].filter(show => show).length
      const totalActions = totalShows + this.buttons.length

      return totalActions > 2
    },

    deleteTitleValue() {
      return this.deleteTitle || this.$t('Excluir')
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-text {
  margin-left: 5px;
}
</style>
