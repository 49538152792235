import Vue from 'vue'
import store from '@/store'
import { elapsedTime, formatTime, formatDate, formatDatetime, month } from '@/utils/dateUtils'

const formatCpfCnpj = (value, applyMask) => {
  if (!value || value === '-') return ''

  const onlyNumbers = value.replace(/[^0-9*]/g, '')

  if (onlyNumbers.length > 11) {
    return onlyNumbers.replace(
      /^(\d{2})(\d{3}|\*{3})(\d{3}|\*{3})(\d{4})(\d{2})$/g,
      applyMask ? '$1.***.***/$4-$5' : '$1.$2.$3/$4-$5'
    )
  }
  return onlyNumbers.replace(
    /^(\d{3})(\d{3}|\*{3})(\d{3}|\*{3})(\d{2})$/,
    applyMask ? '$1.$2.$3-$4' : '$1.***.***-$4'
  )
}

Vue.filter('capitalize', value => {
  if (!value) return ''
  const arr = value.toString().split(' ')
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
})

Vue.filter('uppercase', value => {
  if (!value) return ''
  return value.toString().toUpperCase()
})

Vue.filter('title', (value, replacer = '_') => {
  if (!value) return ''
  const arr = value.toString().split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
})

Vue.filter(
  'truncate',
  (value, limit) => `${value.substring(0, limit)}${value.length > limit ? '...' : ''}`
)

Vue.filter('personName', (value, hasDocument = false, hasId = false) => {
  if (!value) return ''
  if (typeof value === 'string') {
    return value
  }
  let content = `${value?.name || value?.companyName}`
  if (hasId) content = `${value?.id} - ${content}`
  if (hasDocument) content = `${content} - ${formatCpfCnpj(value?.document)}`

  return content
})

// eslint-disable-next-line import/prefer-default-export
export function idName(value, defaultValue, padStartId = 3) {
  if (!value) return defaultValue ?? ''
  if (typeof value === 'string') {
    return value
  }
  const prefixName = value.id > 0 ? `${value.id.toString().padStart(padStartId, '0')} - ` : ''
  return `${prefixName}${value.tradingName || value.name}`
}

Vue.filter('storeName', (value, defaultValue = '') => idName(value, defaultValue))
Vue.filter('idName', value => idName(value, '-', 0))

Vue.filter('storeId', (value, defaultValue) => {
  if (!value) return defaultValue ?? ''
  if (typeof value === 'string') {
    return value
  }
  const prefixName = value.id > 0 ? `${value.id.toString().padStart(3, '0')}` : ''
  return `${prefixName}`
})

Vue.filter('payboxName', value => {
  if (typeof value === 'string' || !value || !value.number) {
    return ''
  }
  const desc = value.description ? ` - ${value.description}` : ''
  return `${value.number.toString().padStart(2, '0')}${desc}`
})

Vue.filter('tailing', (value, tail) => value + tail)

Vue.filter('time', (value, defaultValue, withSecods) => formatTime(value, defaultValue, withSecods))
Vue.filter('date', (value, defaultValue, applyMask = false) =>
  formatDate(value, defaultValue, applyMask)
)
Vue.filter('dateMask', (value, defaultValue) => formatDate(value, defaultValue, true))

Vue.filter('datetime', (value, defaultValue, withSecods) =>
  formatDatetime(value, defaultValue, withSecods)
)

Vue.filter('month', (val, showYear = true) => month(val, showYear))

Vue.filter('elapsedTime', (time, emptyText = '', includeAgo = true) =>
  elapsedTime(time, emptyText, includeAgo)
)

Vue.filter('zipcode', value => {
  if (!value) return ''
  // return value.replace(/^[0-9]*$/).replace()
  return value.replace(/^(\d{5})(\d{3})$/g, '$1-$2')
})

Vue.filter('rgIeMask', value => {
  if (!value || value === '-') return ''

  const { length } = value

  if (length >= 4) {
    const firstDigits = value.substring(0, 2)
    const lastDigits = value.substring(length - 2)
    return `${firstDigits}****${lastDigits}`
  }
  return '*'.repeat(length)
})

Vue.filter('emailMask', value => {
  if (!value || value === '-') return ''

  const indexOfAt = value.indexOf('@')

  if (indexOfAt > 0) {
    const prefix = value.substring(0, Math.min(4, indexOfAt))
    const domain = value.substring(indexOfAt)
    return `${prefix}*****${domain}`
  }

  return value
})

Vue.filter('cpfCnpj', (value, applyMask = false) => formatCpfCnpj(value, applyMask))

Vue.filter('phone', value => {
  if (!value || value === '-') return ''
  const onlyNumbers = value.match(/\d+/g).join('')
  if (onlyNumbers.length === 8) {
    return value.replace(/^(\d{4})(\d{4})$/g, '$1-$2')
  }
  if (onlyNumbers.length === 10) {
    return value.replace(/^(\d{2})(\d{4})(\d{4})$/g, '($1) $2-$3')
  }
  return value.replace(/^(\d{2})(\d{5})(\d{4})$/g, '($1) $2-$3')
})

Vue.filter('currency', (value, defaultValue, decimalPlaces) => {
  if (
    // eslint-disable-next-line no-restricted-globals
    (value === null || value === undefined || isNaN(value)) &&
    defaultValue &&
    // eslint-disable-next-line no-restricted-globals
    isNaN(defaultValue)
  ) {
    return defaultValue
  }

  const localValue =
    // eslint-disable-next-line no-restricted-globals
    value === undefined || value === null || isNaN(value) ? defaultValue || 0 : value || 0
  let hasFourDecimalPlaces = false

  if (Math.floor(localValue * 1000) % 10 !== 0 || Math.floor(localValue * 10000) % 100 !== 0) {
    hasFourDecimalPlaces = true
  }

  return Intl.NumberFormat(store.state.app.locale, {
    style: 'currency',
    maximumFractionDigits: decimalPlaces || (hasFourDecimalPlaces ? 4 : 2),
    currency: store.state.app.currency || 'BRL',
  }).format(localValue)
})

Vue.filter('percentage', (value, decimals = 2, percentageMultiplier = 100) => {
  // console.log('percentageFilter', value, decimals, percentageMultiplier)
  const localValue = (Number(value) || 0) * percentageMultiplier
  return `${Intl.NumberFormat(store.state.app.locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(localValue)} %`
})

Vue.filter('number', (value, decimalPlaces = 2, checkDecimal = false) => {
  const localValue = value || 0
  let localDecimalPlaces = decimalPlaces
  if (checkDecimal) {
    localDecimalPlaces = Number.isInteger(localValue) ? 0 : decimalPlaces
  }
  return Intl.NumberFormat(store.state.app.locale, {
    minimumFractionDigits: localDecimalPlaces,
    maximumFractionDigits: localDecimalPlaces,
  }).format(localValue)
})

Vue.filter('segments2', (number, segmentSize = 3, separator = '.', padLeft = 0, padValue = '') => {
  const numStr = number.toString()
  let result = ''

  // eslint-disable-next-line no-plusplus
  for (let i = numStr.length - 1, count = 0; i >= 0; i--, count++) {
    if (count !== 0 && count % segmentSize === 0) {
      result = `${separator}${result}`
      count = 0
    }
    result = numStr[i] + result
  }

  // Calcula quantos caracteres devem ser adicionados para atingir a minLength
  const paddingLength = padLeft - result.length

  // Se a string atual for menor que a minLength, preenche com o caractere especificado
  if (paddingLength > 0) {
    result = padValue.repeat(paddingLength) + result
  }

  return result
})

Vue.filter('segments', (value, segmentSize = 3, separator = '.', padLeft = 0, padValue = '') => {
  if (!value) return ''
  const regex = new RegExp(`.{1,${segmentSize}}`, 'g')
  const segments = value.toString().padStart(padLeft, padValue).match(regex)
  return segments.join(separator)
})

Vue.filter('padLeft', (value, padSize = 0, padValue = '') => {
  if (!value) return ''
  if (value === '-') return value
  return value.toString().padStart(padSize, padValue)
})
