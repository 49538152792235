import FormulateStarRating from './FormulateStarRating.vue'

export default function VueFormulateStarRatingPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateStarRating,
    },
    library: {
      'star-rating': {
        classification: 'text',
        component: 'FormulateStarRating',
      },
    },
  })
}
