/* eslint-disable eqeqeq */
import i18n from '@/libs/i18n'
import { checkingAccountDomains, storePagination, stringUtils, uploader } from '@/mixins'
import { transformInToFormObject } from '@/utils/object-utils'
import axios from '@axios'

const getInitialProsegurXlsxData = () => ({
  line: null,
  period: null,
  companyName: null,
  deviceId: null,
  point: null,
  bankNumber: null,
  bankBranchNumber: null,
  bankAccountNumber: null,
  value: null,
})
const getInitialEchopeXlsxData = () => ({
  line: null,
  value: null,
  // TODO preencher quando implementar
})

const getInitialItemImport = () => ({
  storeId: null,
  value: null,
  depositDate: null,
  description: '',
  documentType: '',
  documentNumber: '',
  importedFileId: '',
  recordType: null,
  errorMessages: [],
  xlsxData: {},

  // layout info
  hasErrors: false,
  xlsxErrors: {},
  defaultSupplierOptions: [],
  _showDetails: false,
})

const getInitialState = () => ({
  ...storePagination.state(),
  filters: {
    value: '',
    storeId: null,
    onlyErrors: false,
  },
  importForm: {
    modelImported: checkingAccountDomains.data().bankDepositImportModelEnum.PROSEGUR,
    prosegurInfo: {
      code: null,
      date: null,
      phisicalBalance: null,
      xlsxData: {},
    }
  },
  depositImported: [],
  depositImportedFiltered: [],
  xlsxImportedFile: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getDeposits(state) {
      return state.depositImportedFiltered
    },

    getItemsSummary(state) {
      return {
        totalItems: state.depositImported?.reduce((total, i) => total + i.value, 0) || 0,
        totalErrors: state.depositImported?.filter(i => i.hasErrors)?.length || 0,
      }
    },

    isValidToSave(state) {
      return !state.depositImported.some(deposit => deposit.hasErrors)
    }
  },

  mutations: {
    ...storePagination.mutations,

    SET_XLSX_IMPORTED_FILE(state, file) {
      state.xlsxImportedFile = file
    },
    SET_DEPOSIT_IMPORTED(state, payload) {
      state.depositImported = payload
    },
    SET_DEPOSIT_IMPORTED_FILTERED(state, payload) {
      state.depositImportedFiltered = payload
    },

    SET_PROSEGUR_INFO_DATA(state, payload) {
      state.importForm.prosegurInfo = payload || {}
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    ...storePagination.actions,

    setXlsxImportedFile({ commit }, file) {
      commit('SET_XLSX_IMPORTED_FILE', file)
    },

    async sendXlsxBankDeposit({ commit, dispatch, state }, { file }) {
      const { PROSEGUR } = checkingAccountDomains.data().bankDepositImportModelEnum
      const { getStringOrNull } = stringUtils.methods

      const itemDefault = getInitialItemImport()
      let xlsxItemDefault = getInitialEchopeXlsxData()
      let url = ''

      if (state.importForm.modelImported === PROSEGUR) {
        xlsxItemDefault = getInitialProsegurXlsxData()
        url = '/api/financial/bank-deposit/prosegur/read-spreadsheet'
      }

      const result = await uploader.methods.uploadCustom({
        file,
        apiUrl: url,
        fileParamName: 'file'
      })

      const formatXlsxData = data => ({
        ...xlsxItemDefault,
        ...data,
      })

      const resultFormated = result.items.map(r => {
        const xlsxErrors = {}

        if (Array.isArray(r.errorMessages) && r.errorMessages?.length > 0) {
          r.errorMessages.forEach(errorItem => {
            xlsxErrors[errorItem?.field] = errorItem.messages?.map(msg => i18n.t(msg))
          })
        }

        return {
          ...itemDefault,
          ...r,
          storeId: getStringOrNull(r.storeId),
          xlsxData: formatXlsxData(r.xlsxData),
          hasErrors: r.errorMessages?.length > 0,
          xlsxErrors,
        }
      })

      commit('SET_DEPOSIT_IMPORTED', resultFormated)
      if (state.importForm.modelImported === PROSEGUR) {
        commit('SET_PROSEGUR_INFO_DATA', result.prosegurInfo)
      }
      dispatch('updatePaging')
    },
    // async exampleXlsxDownload() {
    //   const { data } = await axios.get(
    //     '/api/financial/bank-deposit/download/import-example',
    //     {
    //       responseType: 'blob',
    //     }
    //   )
    //   return data
    // },

    async saveBulkImport({ state }) {
      const bankDeposits = state.depositImported.map(i => ({
        storeId: i.storeId,
        value: i.value,
        depositDate: i.depositDate,
        description: i.description,
        documentType: i.documentType,
        documentNumber: i.documentNumber,
        recordType: i.recordType,
        importedFileId: i.importedFileId,
      }))

      const fileToSave = state.xlsxImportedFile ? state.xlsxImportedFile[0]?.file : null
      const formData = transformInToFormObject({
        bankDeposits,
        file: fileToSave,
        statementDate: state.importForm?.prosegurInfo?.date
      })

      await axios.post('/api/financial/bank-deposit/bulk-from-import', formData)
    },

    filterDeposit({ state, commit, dispatch }) {
      const {
        storeId,
        onlyErrors,
        value
      } = state.filters
      const filtered = state.depositImported.filter(a => {
        let result = true
        if (value != '') result &&= a.value == value
        if (onlyErrors === true) result &&= a.hasErrors === true

        if (storeId != null) result &&= a.storeId == storeId

        return result
      })
      commit('SET_DEPOSIT_IMPORTED_FILTERED', filtered)
      dispatch('updatePaging')
    },

    resetFilters({ state, commit, dispatch }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
      commit('SET_DEPOSIT_IMPORTED_FILTERED', state.depositImported)
      dispatch('updatePaging')
    },
    updatePaging({ state, commit, getters }) {
      const rowCount = getters.getDeposits.length || 0
      const isLastPage = state.paging.currentPage === Math.ceil(rowCount / state.paging.pageSize)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount,
        rowsInCurrentPage: isLastPage ? rowCount % state.paging.pageSize : state.paging.pageSize,
      })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
