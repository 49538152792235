import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  accessProfiles: [],
  allAccessProfiles: [],
  filters: { name: '' },
})

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_ACCESS_PROFILES(state, accessProfiles) {
      state.accessProfiles = accessProfiles
    },
    SET_ACCESS_ALL_PROFILES(state, allAccessProfiles) {
      state.allAccessProfiles = allAccessProfiles
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchAccessProfiles({ commit, state }) {
      const response = await axios.get('/api/accessProfiles', {
        params: {
          name: state.filters.name,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
      })
      commit('SET_ACCESS_PROFILES', response.data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: response.data.rowCount,
        rowsInCurrentPage: response.data.rowsInCurrentPage,
      })
    },
    async fetchallAccessProfiles({ commit }) {
      const response = await axios.get('/api/accessProfiles', {
        params: {
          pageSize: 0,
        },
      })
      commit('SET_ACCESS_ALL_PROFILES', response.data.results)
    },
    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },
  },
}
