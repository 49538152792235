import { getAbilityFromPermissions } from '@/auth/utils'

const acl = {
  mixins: [],
  data() {
    return {}
  },
  watch: {
    // empresaLogada() {
    //   this.configureAbility()
    // },
  },
  methods: {
    configureAbility() {
      this.$ability.update(getAbilityFromPermissions().rules)
      // this.getAbility().forEach(ability => this.$ability.can(ability.action, ability.subject))
    },
    // getAbility() {
    //   const token = useJwt.getToken()
    //   try {
    //     const decoded = jwt.decode(token)
    //     return defineAbility((can, cannot) => {
    //       debugger
    //       can('read', 'Auth')
    //       decoded.ability.forEach(ability => can(ability.action, ability.subject))
    //       cannot('manage', 'ProspectMeuCadastro')
    //     })
    //     // return [
    //     //   { resource: 'Auth', action: 'read' }, // Access for login, register, change password, etc.
    //     //   ...decoded.ability,
    //     // ]
    //   } catch {
    //     return defineAbility((can) => {
    //       can('read', 'Auth')
    //     })
    //   }
    // },
  },
}

export default acl
