import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import moment from 'moment'

export const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'CreatedDate',
    sortDesc: true,
  },
  serviceRequests: [],
  filters: {
    id: null,
    status: null,
    rangeDate: {
      startDate: moment().add(-30, 'days').startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
    serviceAreaId: null,
    serviceCategoryId: null,
    storeId: null,
    userId: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_SERVICE_REQUESTS(state, serviceRequests) {
      state.serviceRequests = serviceRequests
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchServiceRequests({ state, commit }) {
      const { id,
        status,
        rangeDate,
        serviceAreaId,
        serviceCategoryId,
        storeId,
        userId } = state.filters

      const { data } = await axios.get('/api/service/requests', {
        params: {
          id,
          status,
          startDate: rangeDate?.startDate,
          endDate: rangeDate?.endDate,
          serviceAreaId,
          serviceCategoryId,
          storeId,
          userId,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_SERVICE_REQUESTS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },
  },
}
