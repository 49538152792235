import FormulateUploader from './FormulateUploader.vue'

export default function VueFormulateUploaderPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateUploader,
    },
    library: {
      uploader: {
        classification: 'text',
        component: 'FormulateUploader',
      },
    },
  })
}
