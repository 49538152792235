import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import { getInitialState as getInitialBarTabState } from '../pdv/pay-box-bar/tabs'
import consumptions from './consumptions'

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'number',
    sortDesc: true,
  },
  barTabs: [],
  filters: {
    ...getInitialBarTabState().filters,
    storeId: null,
    status: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {
    consumptions,
  },

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_BAR_TABS(state, barTabs) {
      state.barTabs = barTabs
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, barTabs } = getInitialState()
      state.filters = filters
      state.barTabs = barTabs
      state.paging = paging
    },
    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchBarTabs({ state, commit }) {
      const { storeId, number, status, consumerName, customerId, skuId, consumptionStatus } = state.filters

      const { data } = await axios.get('/api/bar-tab', {
        params: {
          storeId,
          number,
          status,
          consumptionStatus,
          consumerName,
          customerId,
          skuId,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_BAR_TABS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },
  },
}
