export default [
  {
    path: '/relatorios/vendas-por-meio-pagamento',
    name: 'report-sale-by-payment-method',
    component: () => import('@/views/pages/reports/sale/SaleByPaymentMethodList.vue'),
    meta: {
      pageTitle: 'Vendas por Meio de Pagamento',
      resource: 'SaleReports',
      action: 'SaleByPaymentMethod',
      breadcrumb: [
        {
          text: 'Relatórios',
        },
        {
          text: 'Vendas por Meio de Pagamento',
          active: true,
        },
      ],
    },
  },
  {
    path: '/relatorios/vendas-produtos-detalhado',
    name: 'report-sku-sale-detailed',
    component: () => import('@/views/pages/reports/sale/SkuSaleDetailedList.vue'),
    meta: {
      pageTitle: 'Vendas por Produto',
      resource: 'SaleReports',
      action: 'SkuSaleDetailed',
      breadcrumb: [
        {
          text: 'Relatórios',
        },
        {
          text: 'Vendas por Produto',
          active: true,
        },
      ],
    },
  },
  {
    path: '/relatorios/contas-a-pagar',
    name: 'report-accounts-payable',
    component: () => import('@/views/pages/reports/financial/AccountsPayableList.vue'),
    meta: {
      pageTitle: 'Contas a Pagar',
      resource: 'FinancialReports',
      action: 'AccountsPayable',
      breadcrumb: [
        {
          text: 'Relatórios',
        },
        {
          text: 'Contas a Pagar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/relatorios/contas-a-receber',
    name: 'report-receivables',
    component: () => import('@/views/pages/reports/financial/ReceivablesList.vue'),
    meta: {
      pageTitle: 'Contas a Receber',
      resource: 'FinancialReports',
      action: 'Receivables',
      breadcrumb: [
        {
          text: 'Relatórios',
        },
        {
          text: 'Contas a Receber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/relatorios/conciliacoes-pendentes',
    name: 'reconciliation-peding-itens-list',
    component: () => import('@/views/pages/reports/financial/ReconciliationPedingItensList.vue'),
    meta: {
      pageTitle: 'Conciliação - Transações Ignoradas',
      navActiveLink: 'reconciliation-peding-itens-list',
      // resource: 'auth',
      // action: 'Read',
      breadcrumb: [
        {
          text: 'Lojas',
          active: true,
        },
      ],
    },
  },
]
