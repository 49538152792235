var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center flex-wrap" },
    [
      _c("FormulateInput", {
        staticClass: "input-date",
        class: {
          "margin-date": _vm.withTime,
          "date-with-time": _vm.withTime,
          "date-without-time": !_vm.withTime,
        },
        attrs: {
          id: _vm.inputsId.dateFromId,
          type: "datepicker",
          label: _vm.label,
          max: _vm.localDate.dateTo || _vm.today,
          validation: [["optional"], ["before", _vm.getValidDates.dateBefore]],
          "validation-messages": {
            before: _vm.$t("Período inválido"),
          },
          placeholder: _vm.$t(_vm.fromPlaceholder),
        },
        model: {
          value: _vm.localDate.dateFrom,
          callback: function ($$v) {
            _vm.$set(_vm.localDate, "dateFrom", $$v)
          },
          expression: "localDate.dateFrom",
        },
      }),
      _vm.withTime
        ? _c("FormulateInput", {
            staticClass: "input-time margin-time",
            attrs: {
              id: _vm.inputsId.timeFromId,
              type: "timepicker",
              label: " ",
              placeholder: _vm.$t("hh:mm"),
            },
            model: {
              value: _vm.localDate.timeFrom,
              callback: function ($$v) {
                _vm.$set(_vm.localDate, "timeFrom", $$v)
              },
              expression: "localDate.timeFrom",
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mx-1 formulate-input",
          staticStyle: { "padding-top": "18px" },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("Até")))])]
      ),
      _c("FormulateInput", {
        staticClass: "input-date",
        class: {
          "margin-date": _vm.withTime,
          "date-with-time": _vm.withTime,
          "date-without-time": !_vm.withTime,
        },
        attrs: {
          id: _vm.inputsId.dateToId,
          type: "datepicker",
          label: " ",
          validation: [["optional"], ["after", _vm.getValidDates.dateAfter]],
          "validation-messages": {
            after: _vm.$t("Período inválido"),
          },
          placeholder: _vm.$t(_vm.toPlaceholder),
        },
        model: {
          value: _vm.localDate.dateTo,
          callback: function ($$v) {
            _vm.$set(_vm.localDate, "dateTo", $$v)
          },
          expression: "localDate.dateTo",
        },
      }),
      _vm.withTime
        ? _c("FormulateInput", {
            staticClass: "input-time margin-time",
            attrs: {
              id: _vm.inputsId.timeToId,
              type: "timepicker",
              label: " ",
              placeholder: _vm.$t("hh:mm"),
            },
            model: {
              value: _vm.localDate.timeTo,
              callback: function ($$v) {
                _vm.$set(_vm.localDate, "timeTo", $$v)
              },
              expression: "localDate.timeTo",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }