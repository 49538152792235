export default {
  title: 'Estoque',
  icon: 'PackageIcon',
  // iconPack: 'bootstrap',
  children: [
    {
      title: 'Posição',
      resource: 'StockPosition',
      action: 'Read',
      route: 'stock-position-list',
    },
    {
      title: 'Movimentação',
      resource: 'StockMovement',
      action: 'Read',
      route: 'stock-movement-list',
    },
    {
      title: 'Reservas',
      resource: 'StockReservation',
      action: 'Read',
      route: 'stock-reservation-list',
    },
    {
      title: 'Inversão',
      resource: 'StockInversion',
      action: 'Read',
      route: 'stock-inversion-list',
    },
    {
      title: 'Inventário',
      resource: 'StockInventory',
      action: 'Read',
      route: 'stock-inventory-list',
    },
    {
      title: 'Estoque Retroativo',
      resource: 'StockRetroactive',
      action: 'Read',
      route: 'stock-retroactive-list',
    },
  ],
}
