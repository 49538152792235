import i18n from '@/libs/i18n'
import axios from '@axios'

const getInitialState = () => ({
  priceTables: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getAllComboPriceTables(state) {
      return state.priceTables.map(pt => ({
        ...pt,
        label: pt.name,
        value: pt.id,
      }))
    },
    getComboPriceTables(state) {
      return state.priceTables
        .filter(pt => pt.active)
        .map(pt => ({
          ...pt,
          label: pt.name,
          value: pt.id,
        }))
    },
    getComboPriceTablesWithAll(state) {
      return [
        {
          label: i18n.t('Todos'),
          value: '',
        },
        ...state.priceTables
          .filter(pt => pt.active)
          .map(pt => ({
            ...pt,
            label: pt.name,
            value: pt.id,
          })),
      ]
    },
  },

  mutations: {
    SET_PRICE_TABLES(state, priceTables) {
      state.priceTables = priceTables
    },

    CLEAN_STATE(state) {
      const { priceTables } = getInitialState()
      state.priceTables = priceTables
    },
  },

  actions: {
    async fetchPriceTables({ commit }) {
      const { data: priceTableData } = await axios.get('/api/price-tables', {
        params: { pageSize: 0, pageIndex: 0 },
      })
      commit('SET_PRICE_TABLES', priceTableData.results || [])
    },

    getPriceTableActiveOrDefaultId({ getters }, { priceTableId }) {
      const priceTableFound = getters.getAllComboPriceTables.find(
        p => p.active && String(p.id) === String(priceTableId)
      )
      if (priceTableFound) return priceTableFound

      return getters.getAllComboPriceTables.find(p => p.isDefault)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
