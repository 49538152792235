<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses, { 'atm-behavior': isPdvAtm }]"
  >
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>

    <e-overlay
      :show="loadingOverlay.show"
      :cancelable="loadingOverlay.cancelable"
      :text="loadingOverlay.text"
      :title="loadingOverlay.title"
      :image="loadingOverlay.image"
    />

    <e-modal-authorization ref="appModalAuthorization" />

    <e-simple-keyboard
      v-if="isVisible"
      ref="globalSimpleKeyboard"
      :type="layoutType"
      @input="onKeyboardInput"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import { acl } from '@/mixins'
import { isUserLoggedIn } from './auth/utils'
import EOverlay from './views/components/EOverlay.vue'
import EModalAuthorization from './views/components/EModalAuthorization.vue'
import ESimpleKeyboard from './views/components/ESimpleKeyboard.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutPayBox = () => import('@/layouts/pay-box/LayoutPayBox.vue')
const LayoutAtm = () => import('@/layouts/atm/LayoutAtm.vue')

export default {
  components: {
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutPayBox,
    LayoutAtm,
    EOverlay,
    EModalAuthorization,
    ESimpleKeyboard,
  },

  mixins: [acl],
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },

  data() {
    return {}
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapState('app', ['loadingOverlay', 'modalAuthRef']),
    ...mapState('app/simpleKeyboard', ['currentInputValue', 'isVisible', 'layoutType']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['isPdvAtm']),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (this.$route.meta.layout === 'payBox') return 'layout-pay-box'
      if (this.$route.meta.layout === 'atm') return 'layout-atm'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    routeName() {
      return this.$route.name
    },
  },
  watch: {
    currentInputValue(newValue) {
      if (this.$refs.globalSimpleKeyboard)
        this.$refs.globalSimpleKeyboard.setInput(newValue)
      else {
        // necessário pois na primeira vez que clica no input
        // ainda não tem o ref do componente de keyboard
        setTimeout(() => {
          if (this.$refs.globalSimpleKeyboard)
            this.$refs.globalSimpleKeyboard.setInput(newValue)
        }, 200)
      }
    }
  },
  mounted() {
    console.log('teste')
    this.configureAbility()
    this.setAppStyle()

    if (isUserLoggedIn()) {
      this.fetchStores()
      this.fetchAllStores()
      this.fetchPaymentMethods()
    }

    this.fetchChatApiSettings()

    this.setModalAuthRef(this.$refs.appModalAuthorization)

    this.loadAppDesktopSettings()
  },
  async beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    if (isUserLoggedIn()) {
      store.dispatch('auth/setUser')
    }
  },
  async created() {
    // if (isUserLoggedIn()) {
    //   this.fetchDynamicCrudConfig()
    // }
  },
  methods: {
    ...mapActions('app', [
      'fetchStores',
      'fetchAllStores',
      'fetchPaymentMethods',
      'setModalAuthRef',
      'fetchChatApiSettings',
      'loadAppDesktopSettings',
    ]),
    ...mapActions('app/simpleKeyboard', ['updateInputValue']),

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null
    },
    setAppStyle() {
      // Get images logos from cookie
      const favicon = this.$store.state.app.style.faviconImage
      const titulo = this.$store.state.app.style.tabTitle
      const color = this.$store.state.app.style.collor

      // Set images logos
      document.getElementById('favicon').href = favicon
      document.getElementById('pageTitle').innerText = titulo

      // Get RGB value
      const rgb = this.hexToRgb(color)
      const rgbFormatted = `${rgb.r},${rgb.g},${rgb.b}`

      // Set Operador colors
      document.documentElement.style.setProperty('--theme-color', color)
      document.documentElement.style.setProperty('--theme-color-rgb', rgbFormatted)
    },

    onKeyboardInput(input) {
      this.updateInputValue(input)
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
