import FormulateSelectSearchable from './FormulateSelectSearchable.vue'

export default function VueFormulateSelectSearchablePlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateSelectSearchable,
    },
    library: {
      'select-searchable': {
        classification: 'search',
        component: 'FormulateSelectSearchable',
      },
    },
  })
}
