import stockMovement from './stock-movement'
import stockReservation from './stock-reservation'
import stockInversion from './stock-inversion'
import stockPosition from './stock-position'
import stockRetroactive from './stock-retroactive'
import stockInventory from './stock-inventory'

export default {
  namespaced: true,
  modules: {
    stockMovement,
    stockReservation,
    stockPosition,
    stockInversion,
    stockInventory,
    stockRetroactive,
  },
  state: {},
  mutations: {},
  actions: {},
}
