import axios from '@axios'

const getInitialState = () => ({
  products: [],
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {},
  getters: {
    getComboProducts(state) {
      return state.products.map(p => ({
        ...p,
        label: `${p.name}`,
        value: p.id,
      }))
    },
  },

  mutations: {
    SET_PRODUCT(state, products) {
      state.products = products
    },

    CLEAN_STATE(state) {
      const { products } = getInitialState()
      state.products = products
    },
  },

  actions: {
    async fetchProductByNameOrBarcode({ commit }, { itemSearch }) {
      const params = { pageSize: 30 }
      if (!Number.isNaN(Number(itemSearch))) {
        params.ean = itemSearch
      } else {
        params.name = itemSearch
      }

      const { data: productsData } = await axios.get('/api/products', {
        params,
      })

      commit('SET_PRODUCT', productsData.results || [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    getProductUnit({ state }, { id }) {
      return state.products.find(p => String(p.id) === id)?.productUnit || ''
    },

    getProduct({ state }, { id }) {
      return state.products.find(p => String(p.id) === id)
    }
  },
}
