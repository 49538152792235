<template>
  <b-form-checkbox
    v-model="context.model"
    v-bind="context.attributes"
    :input-class="context.attributes.class"
    class="custom-control-primary"
    style="width: 50px"
    switch
    @change="change"
    @input="onInput"
  >
    <span class="switch-icon-left">
      <feather-icon icon="CheckIcon" />
    </span>
    <span class="switch-icon-right">
      <feather-icon icon="XIcon" />
    </span>
  </b-form-checkbox>
</template>
<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'FormulateTextMask',
  components: {
    BFormCheckbox,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    model() {
      return this.context.model
    },
  },
  watch: {
    model(val) {
      if (val === '') {
        this.context.model = false
      }
    },
  },
  methods: {
    // Diferenças dos eventos: https://bootstrap-vue.org/docs/components/form-checkbox#comp-ref-b-form-checkbox-events
    // @change ocorre nas interações do usuário
    change(event) {
      this.context.model = event
      this.context.rootEmit('change', event)
    },
    // @input ocorre nas alterações via código
    onInput(val) {
      // necessário para que o valor do v-model não se transforme em string
      this.context.model = Boolean(val)
      this.context.rootEmit('input', Boolean(val))
    },
  },
}
</script>
