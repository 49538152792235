const address = {
  data() {
    return {
      address: {
        publicPlace: null,
        number: null,
        neighborhood: null,
        zipcode: null,
        province: null,
        city: null,
        complement: null,
        lat: null,
        lng: null,
        cityCode: null,
        provinceCode: null,
      },
    }
  },

  methods: {
    formatAddress(ad) {
      if (!ad || !(ad.publicPlace || ad.number || ad.neighborhood || ad.city || ad.province)) {
        return 'Não informado'
      }

      return `${ad.publicPlace || '-'}, Nº ${ad.number || '-'}, ${ad.complement || '-'}, ${ad.neighborhood || '-'}, ${ad.city || '-'}/${ad.province || '-'}`
    }
  }
}

export default address
