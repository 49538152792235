import serviceRequest from './service-request-list'
import serviceRequestDetails from './service-request-details'
import serviceRequestCreate from './service-request-create'

export default {
  namespaced: true,
  modules: {
    serviceRequest,
    serviceRequestDetails,
    serviceRequestCreate
  },
  state: {
  },
  mutations: {

  },
  actions: {

  },
}
