import axiosInstance from '@/libs/axios'
import isElectron from 'is-electron'
import { toMiliseconds } from '@/utils/promise-util'
import tabs from "./tabs"
import consumptions from './consumptions'

const getInitialState = () => ({
  isPrintingClosingRequest: false,
  isPrintingPartialConsumption: false,
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: {
    tabs,
    consumptions,
  },

  getters: {
    canInitClosingRequestPrint(state, getters, rootState, rootGetters) {
      const { barPrintTabEvent } =
        rootGetters['pages/pdv/payBoxConfiguration/barConfig']
      const isBarStore =
        rootGetters['pages/pdv/payBoxConfiguration/isBarStore']

      return isElectron() && isBarStore && barPrintTabEvent
    },

    canInitPartialConsumptionPendingPrint(state, getters, rootState, rootGetters) {
      const { barPrintPartialConsumption } =
        rootGetters['pages/pdv/payBoxConfiguration/barConfig']
      const isBarStore =
        rootGetters['pages/pdv/payBoxConfiguration/isBarStore']

      return isElectron() && isBarStore && barPrintPartialConsumption
    },
  },

  mutations: {
    SET_IS_PRINTING_CLOSING_REQUEST(state, value) {
      state.isPrintingClosingRequest = value
    },
    SET_IS_PRINTING_PARTIAL_CONSUMPTION(state, value) {
      state.isPrintingPartialConsumption = value
    },
  },

  actions: {
    async fetchClosingRequestPendingPrint({ state, dispatch, rootGetters, commit }) {
      const { store: payBoxStore } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      if (!payBoxStore?.id) return

      const printData = async data => {
        await dispatch(
          'pages/sale/sale/printOrder',
          {
            orderPrint: data,
            template: 'SALE_BAR_TAB_CONSUMPTION'
          },
          { root: true }
        )
        return data.barTabConsumption?.consumptionEventWaitPrintId
      }

      if (!state.isPrintingClosingRequest) {
        try {
          commit('SET_IS_PRINTING_CLOSING_REQUEST', true)
          const { data } = await axiosInstance.get(`/api/bar-tab/consumption/store/${payBoxStore.id}/request-closing/pending-print`)

          if (data.length > 0) {
            const eventsIdPritConfirmed = []

            // usado o allSettled pois ele não se importa de ter alguma Promise rejeitada
            await Promise.allSettled(data.map(salePreview => printData(salePreview)))
              .then(results => {
                results.forEach(result => {
                  if (result.status === 'fulfilled') {
                    eventsIdPritConfirmed.push(result.value)
                  } else {
                    console.error('Erro impressão da solicitação de fechamento do bar', result)
                  }
                })
              })

            await axiosInstance.patch(`/api/bar-tab/consumption/store/${payBoxStore.id}/request-closing/confirm-print`, {
              eventIds: eventsIdPritConfirmed
            })
          }
        } finally {
          commit('SET_IS_PRINTING_CLOSING_REQUEST', false)
        }
      }
    },

    async fetchPartialConsumptionPendingPrint({ state, dispatch, rootGetters, commit }) {
      const { store: payBoxStore } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      if (!payBoxStore?.id) return

      const printData = async data => {
        await dispatch('printPartialConsumption', { partialConsumption: data, })
        return data.id
      }

      if (!state.isPrintingPartialConsumption) {
        try {
          commit('SET_IS_PRINTING_PARTIAL_CONSUMPTION', true)
          const { data } = await axiosInstance.get(`/api/bar-tab/partial-consumption/store/${payBoxStore.id}/pending-print`)

          if (data.length > 0) {
            const partialConsumptionIds = []

            // usado o allSettled pois ele não se importa de ter alguma Promise rejeitada
            await Promise.allSettled(data.map(pConsumption => printData(pConsumption)))
              .then(results => {
                results.forEach(result => {
                  if (result.status === 'fulfilled') {
                    partialConsumptionIds.push(result.value)
                  } else {
                    console.error('Erro impressão de consumos parciais do bar', result)
                  }
                })
              })

            await axiosInstance.patch(`/api/bar-tab/partial-consumption/store/${payBoxStore.id}/confirm-print`, {
              partialConsumptionIds,
            })
          }
        } finally {
          commit('SET_IS_PRINTING_PARTIAL_CONSUMPTION', false)
        }
      }
    },

    async printPartialConsumption({ rootGetters }, { partialConsumption }) {
      const thermalPrinterAgentConfigPayload =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      const itemsMapped = partialConsumption.items.map(i => ({
        skuId: i.sku.id,
        name: i.sku.name,
        printableName: i.sku.product?.printableName,
        quantity: i.quantity,
        unitValue: i.unitValue,
        totalValue: i.netValue,
        totalGrossValue: i.grossValue,
      }))

      const { barTabConsumption: consumption } = partialConsumption

      // TODO AVALIAR SE FAZ SENTIDO PEGAR LOCAL
      const { data: store } = await axiosInstance.get(`/api/stores/${consumption.barTab.store.id}`)

      const { data: customer } = await axiosInstance.get(`/api/customers/${consumption.customerId}`)

      const customerAddress = customer?.addresses[0] || {}
      const payload = {
        id: partialConsumption.id,
        createdDate: partialConsumption.createdDate,
        barTabNumber: partialConsumption.barTabNumber,
        barTabConsumption: {
          id: consumption?.id,
          saleId: consumption?.saleId,
          consumerName: consumption?.consumerName,
          consumerPhone: consumption?.consumerPhone,
          observation: consumption?.observation,
          createdDate: consumption?.createdDate,
          totalValue: consumption?.netValue,
          grossValue: consumption?.grossValue,
        },
        store: {
          id: store.id,
          document: store.cnpj,
          companyName: store.companyName,
          address: store?.address,
        },
        customer: {
          id: customer.id,
          cpf: customer.document,
          rg: customer.stateRegistration,
          name: customer.name,
          addressPublicPlace: customerAddress.publicPlace,
          addressNumber: customerAddress.number,
          addressNeighborhood: customerAddress.neighborhood,
          city: customerAddress.city,
          province: customerAddress.province,
        },
        totalValue: partialConsumption.total,
        items: itemsMapped,
      }

      // se deixar pouco tempo, ocorre erro de timeout qnd realiza multiplas impressões
      // possivelmente por causa da espera de inúmeras impressões ao mesmo tempo.
      await window.electronAPI.print({
        ...thermalPrinterAgentConfigPayload,
        request: {
          printTexts: [
            {
              printText: JSON.stringify(payload),
              template: 'SALE_BAR_TAB_PARTIAL_CONSUMPTION',
            },
          ],
        },
      }, toMiliseconds({ sec: 20 }))
    },
  },
}
