export default {
  title: 'Ajuda',
  icon: 'question',
  iconPack: 'bootstrap',
  children: [
    {
      title: 'Abrir ticket',
      route: 'suport-create-ticket',
      resource: 'Auth',
      action: 'read',
    },
    {
      title: 'Manual ERP',
      route: 'suport-page-erp',
      resource: 'Auth',
      action: 'read',
    },
    {
      title: 'Manual PDV',
      route: 'suport-page-pdv',
      resource: 'Auth',
      action: 'read',
    },
  ],
}
