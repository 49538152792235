var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-checkbox",
    _vm._b(
      {
        staticClass: "custom-control-primary",
        staticStyle: { width: "50px" },
        attrs: { "input-class": _vm.context.attributes.class, switch: "" },
        on: { change: _vm.change, input: _vm.onInput },
        model: {
          value: _vm.context.model,
          callback: function ($$v) {
            _vm.$set(_vm.context, "model", $$v)
          },
          expression: "context.model",
        },
      },
      "b-form-checkbox",
      _vm.context.attributes,
      false
    ),
    [
      _c(
        "span",
        { staticClass: "switch-icon-left" },
        [_c("feather-icon", { attrs: { icon: "CheckIcon" } })],
        1
      ),
      _c(
        "span",
        { staticClass: "switch-icon-right" },
        [_c("feather-icon", { attrs: { icon: "XIcon" } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }