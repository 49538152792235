import { barDomain, sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

export const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'number',
    sortDesc: true,
  },
  barTabs: [],
  filters: {
    number: '',
    status: barDomain.data().barTabStatusEnum.OPENED,
    consumptionStatus: null,
    consumerName: '',
    customerId: null,
    skuId: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_BAR_TABS(state, barTabs) {
      state.barTabs = barTabs
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, barTabs } = getInitialState()
      state.filters = filters
      state.barTabs = barTabs
      state.paging = paging
    },
    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchBarTabs({ state, commit, rootGetters }) {
      const { number, status, consumerName, customerId, skuId, consumptionStatus } = state.filters
      const { store } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const { data } = await axios.get('/api/bar-tab', {
        params: {
          storeId: store?.id || 0,
          number,
          status,
          consumptionStatus,
          consumerName,
          customerId,
          skuId,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_BAR_TABS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchBarTabConsumptionById(states, id) {
      const { data } = await axios.get(`/api/bar-tab/consumption/${id}`)
      return data
    },

    async updateComission({ rootGetters }, { tabNumber, formData }) {
      const { store } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const body = {
        comissionFee: formData.comissionFee
      }
      const { data } = await axios.patch(
        `/api/bar-tab/consumption/store/${store?.id || 0}/tab-number/${tabNumber}/change-comission`,
        body
      )
      return data
    },
    async requestCloseBarTabConsumption({ rootGetters }, { tabNumber, formData }) {
      const { store } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const body = {
        comissionFee: formData.comissionFee
      }
      const { data } = await axios.patch(
        `/api/bar-tab/consumption/store/${store?.id || 0}/tab-number/${tabNumber}/request-closing`,
        body
      )
      return data
    },
    async closeBarTabConsumption({ rootGetters }, { tabNumber, formData }) {
      const { store } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const body = {
        comissionFee: formData.comissionFee
      }
      const { data } = await axios.patch(
        `/api/bar-tab/consumption/store/${store?.id || 0}/tab-number/${tabNumber}/start-closing`,
        body
      )
      return data
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },
  },
}
