import FormulateTimepicker from './FormulateTimepicker.vue'

export default function FormulateTimepickerPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateTimepicker,
    },
    library: {
      timepicker: {
        classification: 'text',
        component: 'FormulateTimepicker',
      },
    },
  })
}
