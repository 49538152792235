import { storePagination } from '@/mixins/store'
import axios from '@axios'
import moment from 'moment'
import { sorting } from '@/mixins'

const getInitialState = () => ({
  ...storePagination.state(),
  sales: [],
  summaryFields: null,
  sorting: {
    sortBy: 'createdDate',
    sortDesc: true,
  },
  filters: {
    saleId: null,
    storeId: null,
    customerId: null,
    priceTableId: null,
    skuId: null,
    isDelivery: null,
    isConsigned: null,
    status: ['Completed'],
    origin: null,
    echopeClassification: null,
    periodType: 'completed',
    rangeDate: {
      startDate: moment().add(-30, 'days').startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_SALES(state, sales) {
      state.sales = sales
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },

    CLEAN_STATE(state) {
      const { paging, sales, filters, summaryFields } = getInitialState()
      state.sales = sales
      state.paging = paging
      state.filters = filters
      state.summaryFields = summaryFields
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchSales({ commit, state }) {
      const { saleId, storeId, customerId, priceTableId, skuId, periodType, rangeDate, status, origin, echopeClassification, } =
        state.filters

      const params = {
        id: saleId,
        storeId,
        customerId,
        priceTableId,
        skuId,
        status,
        origin,
        echopeClassification,
        periodType,
        // The range filter won't be applied when the sale id is informed
        dateFrom: saleId ? undefined : rangeDate.startDate,
        dateTo: saleId ? undefined : rangeDate.endDate,
        pageSize: state.paging.pageSize,
        pageIndex: state.paging.currentPage - 1,
        sortBy: sorting.methods.getSorting(state),
      }

      const { data } = await axios.get('/api/sales/orders', {
        params,
      })
      commit('SET_SALES', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    async fetchSaleById(states, id) {
      const { data } = await axios.get(`/api/sales/${id}`)

      const dataConverted = {
        ...data,
        total: data.netValue,
        customer: {
          ...data.customer,
          name: data.customer.name,
          document: data.customer.document || '',
          telephone: data.customer.telephone || '',
        },
      }

      return dataConverted
    },

    // TODO: possible duplicated with src/store/pages/sale/order/index.js :: printOrder
    async printOrder({ rootGetters }, { orderPrint, template }) {
      const thermalPrinterAgentConfigPayload =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      if (
        orderPrint.status === 'InProgress' ||
        !orderPrint?.invoice ||
        template === 'LENDING_CONTRACT' ||
        template === 'RENT_CONTRACT' ||
        template === 'SALE_RENT' ||
        template === 'SALE_RETURN' ||
        template === 'SALE_BAR_TAB_CONSUMPTION'
      ) {
        let saleItems

        if (template === 'LENDING_CONTRACT') {
          saleItems = orderPrint.items
            .filter(i => i.classification === 'Lending')
            .map(i => ({
              skuId: i.sku.id,
              name: i.sku.name,
              printableName: i.sku.product?.printableName,
              quantity: i.quantity,
              unitValue: i.contractualFine,
              totalValue: i.contractualFine * i.quantity,
              returnDateLimit: i.returnDateLimit,
            }))
        } else if (template === 'RENT_CONTRACT') {
          saleItems = orderPrint.items
            .filter(i => i.classification === 'Rent')
            .map(i => ({
              skuId: i.sku.id,
              name: i.sku.name,
              printableName: i.sku.product?.printableName,
              quantity: i.quantity,
              unitValue: i.contractualFine,
              totalValue: i.contractualFine * i.quantity,
              returnDateLimit: i.returnDateLimit,
            }))
        } else if (template === 'SALE_RENT') {
          saleItems = orderPrint.items
            .filter(i => i.classification === 'Rent')
            .map(i => ({
              skuId: i.sku.id,
              name: i.sku.name,
              printableName: i.sku.product?.printableName,
              quantity: i.quantity,
              unitValue: i.unitValue,
              totalValue: i.netValue,
              returnDateLimit: i.returnDateLimit,
              totalGrossValue: i.grossValue,
            }))
        } else if (template === 'SALE_RETURN') {
          saleItems = orderPrint.itemsReturned.map(i => ({
            skuId: i.sku.id,
            name: i.sku.name,
            printableName: i.sku.product?.printableName,
            quantity: i.quantity,
            unitValue: i.unitValue,
            totalValue: i.netValue,
            totalGrossValue: i.grossValue,
          }))
        } else {
          saleItems = orderPrint.items.map(i => ({
            skuId: i.sku.id,
            name: i.sku.name,
            printableName: i.sku.product?.printableName,
            quantity: i.quantity,
            unitValue: i.unitValue,
            totalValue: i.netValue,
            totalGrossValue: i.grossValue,
          }))
        }

        // TODO AVALIAR SE FAZ SENTIDO PEGAR LOCAL
        const { data: store } = await axios.get(`/api/stores/${orderPrint.store.id}`)

        const { data: customer } = await axios.get(`/api/customers/${orderPrint.customer.id}`)

        const payload = {
          id: orderPrint.id,
          issueDate: orderPrint.issueDate,
          store: {
            id: store.id,
            document: store.cnpj,
            companyName: store.companyName,
            address: store?.address,
          },
          customer: {
            id: customer.id,
            cpf: customer.document,
            rg: customer.stateRegistration,
            name: customer.name,
            addressPublicPlace: customer?.addresses[0]?.publicPlace,
            addressNumber: customer?.addresses[0]?.number,
            addressNeighborhood: customer?.addresses[0]?.neighborhood,
            city: customer?.addresses[0]?.city,
            province: customer?.addresses[0]?.province,
          },
          totalValue: orderPrint.total,
          discount: orderPrint.discount + (orderPrint?.deliveryTaxDiscountValue || 0),
          comission: orderPrint.comission,
          isDelivery: orderPrint.delivery,
          deliveryCity: orderPrint?.deliveryAddress?.city,
          deliveryComplement: orderPrint?.deliveryAddress?.complement,
          deliveryDate: orderPrint?.deliveryDate,
          deliveryNeighborhood: orderPrint?.deliveryAddress?.neighborhood,
          deliveryNumber: orderPrint?.deliveryAddress?.number,
          deliveryObservation: orderPrint?.deliveryObservation,
          deliveryProvince: orderPrint?.deliveryAddress?.province,
          deliveryPublicPlace: orderPrint?.deliveryAddress?.publicPlace,
          deliveryZipCode: orderPrint?.deliveryAddress?.zipcode,
          deliveryTax: orderPrint?.deliveryTax,
          items: saleItems,
        }

        if (template === 'SALE_RETURN') {
          payload.totalValue = orderPrint.returnSale.value
          payload.issueDate = orderPrint.completedDate
          payload.discount = 0
          payload.isDelivery = false
          payload.deliveryTax = 0
        }

        if (template === 'SALE_BAR_TAB_CONSUMPTION') {
          payload.barTabConsumption = orderPrint.barTabConsumption
          payload.barTabNumber = orderPrint?.barTabConsumption?.barTab?.number
        }

        await window.electronAPI.print({
          ...thermalPrinterAgentConfigPayload,
          request: {
            printTexts: [
              {
                printText: JSON.stringify(payload),
                template,
              },
            ],
          },
        })
      } else if (orderPrint?.invoice?.model === 'SAT') {
        const print = {
          status: orderPrint?.invoice.status,
          issueDate: orderPrint?.invoice?.issueDate,
          accessKey: orderPrint?.invoice?.satDetail?.cfeAccessKey,
          fileName: orderPrint?.invoice?.satDetail?.cfeXmlPath,
          accessKeyCancelled: orderPrint?.invoice?.satDetail?.cfeAccessKeyCancelled,
          fileNameCancelled: orderPrint?.invoice?.satDetail?.cfeXmlPathCancelled,
        }

        const printRequest = {
          ...thermalPrinterAgentConfigPayload,
          request: JSON.stringify(print),
        }

        await window.electronAPI.invoice.satPrint({ ...printRequest })
      } else if (orderPrint?.invoice?.model === 'NFCE') {
        const print = {
          fileName:
            orderPrint?.invoice?.status === 'ISSUED_IN_CONTINGENCY'
              ? orderPrint?.invoice?.nfeDetail?.xmlIssuedInContingencyPath
              : orderPrint?.invoice?.nfeDetail?.xmlFinishedSuccessPath,
          issueDate: orderPrint?.invoice?.issueDate,
          accessKey: orderPrint?.invoice?.nfeDetail?.accessKey,
          status: orderPrint?.invoice?.status,
          contingencyType: orderPrint?.invoice?.contingencyType,
        }

        const printRequest = {
          ...thermalPrinterAgentConfigPayload,
          request: JSON.stringify(print),
        }

        await window.electronAPI.invoice.nfcePrint({ ...printRequest })
      }
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
