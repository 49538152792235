import axios from '@axios'

const getInitialState = () => ({
  movementStockForm: {
    id: null,
    storeId: null,
    skuId: null,
    stockMovement: null,
    movementType: 'Output',
    comments: null,
    movementReasonId: null,
    lossesAndDamages: false,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
  },

  mutations: {
    CLEAN_STATE(state) {
      const { movementStockForm } = getInitialState()
      state.movementStockForm = movementStockForm
    }
  },

  actions: {
    async saveMovement({ state }) {
      await axios.post('/api/stock-movements', state.movementStockForm)
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
