import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  productVariations: [],
  filters: { name: '' },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_PRODUCT_VARIATIONS(state, productVariations) {
      state.productVariations = productVariations
    },
    SET_FILTER(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, productVariations } = getInitialState()
      state.filters = filters
      state.productVariations = productVariations
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchProductVariations({ commit, state }) {
      const { name } = state.filters
      const { data } = await axios.get('/api/variations', {
        params: {
          name: name || '',
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
      })

      commit('SET_PRODUCT_VARIATIONS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    setFilters({ commit }, filters) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', filters)
    },
    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
