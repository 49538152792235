import { barDomain, sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import { getInitialState as getInitialBarTabConsumptionState } from '../pdv/pay-box-bar/consumptions'

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'id',
    sortDesc: true,
  },
  summaryFields: null,
  barTabConsumptions: [],
  filters: {
    ...getInitialBarTabConsumptionState().filters,
    storeId: null,
    status: barDomain.data().barTabConsumptionStatusEnum.CLOSED,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getSummaryFields(state) {
      return {
        totalNetValue: state.summaryFields?.totalNetValue?.value || 0
      }
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_BAR_TABS(state, barTabConsumptions) {
      state.barTabConsumptions = barTabConsumptions
    },
    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, barTabConsumptions } = getInitialState()
      state.filters = filters
      state.barTabConsumptions = barTabConsumptions
      state.paging = paging
    },
    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchBarTabConsumptions({ state, commit }) {
      const { id, storeId, number, status, consumerName, customerId, skuId, consumptionStatus, rangeDate } = state.filters

      const { data } = await axios.get('/api/bar-tab/consumption', {
        params: {
          id,
          storeId,
          number,
          status,
          consumptionStatus,
          consumerName,
          customerId,
          skuId,
          createdDateFrom: rangeDate.startDate,
          createdDateTo: rangeDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_BAR_TABS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },
  },
}
