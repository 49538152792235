/* eslint-disable */
import catalog from './menus/catalog'
import sales from './menus/sales'
import purchases from './menus/purchases'
import financial from './menus/financial'
import security from './menus/security'
import reports from './menus/reports'
import pdv from './menus/pdv'
import invoice from './menus/invoice'
import kiosk from './menus/kiosk'
import i18n from '@/libs/i18n'
import support from './menus/support'
import bar from './menus/bar'
import requests from './menus/requests'

const menus = [
  {
    title: 'Home',
    route: 'home',
    icon: 'house-door',
    iconPack: 'bootstrap',
    resource: 'Auth',
    action: 'read',
  },
  store,
  catalog,
  kiosk,
  sales,
  bar,
  purchases,
  financial,
  pdv,
  invoice,
  stock,
  requests,
  reports,
  settings,
  security,
  support,
]

export default menus

import _ from 'lodash'
import store from './menus/store'
import settings from './menus/settings'
import stock from './menus/stock'

export function getSearchableMenus(children, parents) {
  const searchableMenus = []
  const source = children || menus

  source.forEach((menu) => {
    if (menu.children?.length) {
      const newParents = parents ? [...parents, menu] : [menu]
      searchableMenus.push(...getSearchableMenus(menu.children, newParents))
    } else {
      if (menu.route || menu.href) {
        const [firstParent] = parents || []
        const path = parents
          ? [...parents.map((parentMenu) => i18n.t(parentMenu.title)), i18n.t(menu.title)]
          : [i18n.t(menu.title)]
        searchableMenus.push({
          title: path.join(' > '),
          route: { name: menu.route },
          href: menu.href,
          icon: firstParent?.icon || menu.icon,
          iconPack: firstParent?.iconPack || menu.iconPack,
        })
      }
    }
  })
  return _.sortBy(searchableMenus, (item) => item.title)
}
