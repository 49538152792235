export default [
  // pedidos list
  {
    path: `/compras/pedidos`,
    name: 'purchase-order-list',
    component: () => import('@/views/pages/purchase/purchase-order/PurchaseOrderList.vue'),
    meta: {
      pageTitle: 'Pedidos',
      navActiveLink: 'purchase-order-list',
      resource: 'PurchaseOrder',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Pedidos',
          active: true,
        },
      ],
    },
  },
  // pedidos add importação
  {
    path: `/compras/pedidos/novo`,
    name: 'purchase-order-add',
    component: () => import('@/views/pages/purchase/purchase-order/PurchaseOrderMaintain.vue'),
    meta: {
      pageTitle: 'Novo Pedido',
      navActiveLink: 'purchase-order-list',
      resource: 'PurchaseOrder',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Pedidos',
          to: '/compras/pedidos',
        },
        {
          text: 'Novo Pedido',
          active: true,
        },
      ],
    },
  },
  // pedidos add manual
  {
    path: `/compras/pedidos/manual/novo`,
    name: 'purchase-order-add-manual',
    component: () => import('@/views/pages/purchase/purchase-order/PurchaseOrderManualMaintain.vue'),
    meta: {
      pageTitle: 'Novo Pedido (Manual)',
      navActiveLink: 'purchase-order-list',
      resource: 'PurchaseOrder',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Pedidos',
          to: '/compras/pedidos',
        },
        {
          text: 'Novo Pedido (Manual)',
          active: true,
        },
      ],
    },
  },
  {
    path: `/compras/pedidos/finalizar/:id`,
    name: 'purchase-order-maintain',
    component: () => import('@/views/pages/purchase/purchase-order/PurchaseOrderMaintain.vue'),
    meta: {
      pageTitle: 'Finalizar Pedido',
      navActiveLink: 'purchase-order-list',
      resource: 'PurchaseOrder',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Pedidos',
          to: '/compras/pedidos',
        },
        {
          text: 'Finalizar Pedido',
          active: true,
        },
      ],
    },
  },
  {
    path: `/compras/pedidos/detalhes/:id`,
    name: 'purchase-order-details',
    component: () => import('@/views/pages/purchase/purchase-order/PurchaseOrderMaintain.vue'),
    meta: {
      pageTitle: 'Detalhes do Pedido',
      navActiveLink: 'purchase-order-list',
      resource: 'PurchaseOrder',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Pedidos',
          to: '/compras/pedidos',
        },
        {
          text: 'Detalhes do Pedido',
          active: true,
        },
      ],
    },
  },
  // pedidos - detalhe manual
  {
    path: `/compras/pedidos/manual/detalhes/:id`,
    name: 'purchase-order-manual-details',
    component: () => import('@/views/pages/purchase/purchase-order/PurchaseOrderManualMaintain.vue'),
    meta: {
      pageTitle: 'Detalhes do Pedido (Manual)',
      navActiveLink: 'purchase-order-list',
      resource: 'PurchaseOrder',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Pedidos',
          to: '/compras/pedidos',
        },
        {
          text: 'Detalhes do Pedido (Manual)',
          active: true,
        },
      ],
    },
  },
  {
    path: `/compras/pedidos/importar-xml`,
    name: 'purchase-order-xml-import',
    component: () => import('@/views/pages/purchase/purchase-order/PurchaseOrderMaintain.vue'),
    meta: {
      pageTitle: 'Novo Pedido',
      resource: 'PurchaseOrder',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Importar Nota Fiscal',
        },
      ],
    },
  },
  {
    path: `/compras/pedidos/importar-xml/adicionar-produto`,
    name: 'purchase-order-xml-import-add-product',
    component: () => import('@/views/pages/catalog/product/ProductMaintain.vue'),
    meta: {
      pageTitle: 'Criar Produto',
      resource: 'Product',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Importar Nota Fiscal',
        },
        {
          text: 'Criar Produto',
        },
      ],
    },
  },
  {
    path: `/compras/fornecedores`,
    name: 'supplier-list',
    component: () => import('@/views/pages/purchase/supplier/SupplierList.vue'),
    meta: {
      pageTitle: 'Fornecedores',
      navActiveLink: 'supplier-list',
      resource: 'Supplier',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Fornecedores',
          active: true,
        },
      ],
    },
  },
  // fornecedor add
  {
    path: `/compras/fornecedores/adicionar`,
    name: 'supplier-add',
    component: () => import('@/views/pages/purchase/supplier/SupplierMaintain.vue'),
    meta: {
      pageTitle: 'Novo Fornecedor',
      navActiveLink: 'supplier-list',
      resource: 'Supplier',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Fornecedores',
          to: '/compras/fornecedores',
        },
        {
          text: 'Novo Fornecedor',
          active: true,
        },
      ],
    },
  },
  // fornecedor maintain
  {
    path: `/compras/fornecedores/manter/:id`,
    name: 'supplier-maintain',
    component: () => import('@/views/pages/purchase/supplier/SupplierMaintain.vue'),
    meta: {
      pageTitle: 'Manter Fornecedor',
      navActiveLink: 'supplier-list',
      resource: 'Supplier',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Fornecedores',
          to: '/compras/fornecedores'
        },
        {
          text: 'Manter Fornecedor',
          active: true,
        },
      ],
    },
  },
  // fornecedor read-only
  {
    path: `/compras/fornecedores/visualizar/:id`,
    name: 'supplier-read-only',
    component: () => import('@/views/pages/purchase/supplier/SupplierMaintain.vue'),
    props: { isReadOnly: true },
    meta: {
      pageTitle: 'Visualizar Fornecedor',
      navActiveLink: 'supplier-list',
      resource: 'Supplier',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Compras',
        },
        {
          text: 'Fornecedores',
          to: '/compras/fornecedores'
        },
        {
          text: 'Visuzalizar Fornecedor',
          active: true,
        },
      ],
    },
  },
]
