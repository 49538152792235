import FormulateECheckbox from './FormulateECheckbox.vue'

export default function VueFormulateECheckboxPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateECheckbox,
    },
    library: {
      'e-checkbox': {
        classification: 'text',
        component: 'FormulateECheckbox',
      },
    },
  })
}
