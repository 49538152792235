import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import supplierMaintain from './supplier-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  suppliers: [],
  filter: {
    name: null,
    document: null,
    email: null,
    status: null,
    personType: null,
    region: null,
    province: null,
    city: null,
  },
})

export default {
  namespaced: true,
  modules: { supplierMaintain },

  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_SUPPLIERS(state, suppliers) {
      state.suppliers = suppliers
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    CLEAN_STATE(state) {
      const { filter, paging, suppliers } = getInitialState()
      state.filter = filter
      state.suppliers = suppliers
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchSuppliers({ state, commit }) {
      const { name, document, email, status, region, province, city, personType } = state.filter
      const { data } = await axios.get('/api/suppliers', {
        params: {
          name,
          document,
          email,
          active: status,
          region,
          province,
          city,
          personType,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_SUPPLIERS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    setFilter({ commit }, filter) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', filter)
    },

    resetFilter({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', getInitialState().filter)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
