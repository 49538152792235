import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  barrels: [],
  filters: {
    id: null,
    palmBeerBarrelCode: null,
    invoiceNumber: null,
    brandId: null,
    skuId: null,
    storeId: null,
    installed: null,
  },
})

export default {
  namespaced: true,
  state: {
    ...getInitialState(),
  },
  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_BARRELS(state, barrels) {
      state.barrels = barrels
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, sorting: sortingData } = getInitialState()
      state.filters = filters
      state.sorting = sortingData
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchBarrels({ commit, state }) {
      // const store = rootState.app.stores.find(storeItem => storeItem.type === 'Kiosk')
      if (!state.filters.storeId) {
        commit('SET_BARRELS', [])
        return
      }
      const { data } = await axios.get(`/api/palmbeer/stores/${state.filters.storeId}/barrels`, {
        params: {
          id: state.filters.id,
          palmBeerBarrelCode: state.filters.palmBeerBarrelCode,
          invoiceNumber: state.filters.invoiceNumber,
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          brandId: state.filters.brandId,
          installed: state.filters.installed,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      commit('SET_BARRELS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
