import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  products: [],
  sortingProducts: {
    sortBy: 'ncm',
    sortDesc: false,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    ...storePagination.mutations,

    SET_PRODUCTS(state, products) {
      state.products = products
    },

    CLEAN_STATE(state) {
      const { products, sortingProducts } = getInitialState()
      state.products = products
      state.sortingProducts = sortingProducts
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchProducts({ commit }) {
      const response = await axios.get('/api/products/without-tax-classifications', {
        params: {}
      })
      console.log('response', response.data)
      commit('SET_PRODUCTS', response.data)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
