const telephoneTypes = {
  computed: {
    telephoneTypeEnum() {
      return {
        LANDLINE: 'Landline',
        MOBILE: 'Mobile',
      }
    },
    getTelephoneType() {
      return {
        'Landline': this.$t('Fixo'),
        'Mobile': this.$t('Móvel'),
      }
    }
  },

  methods: {
    telephoneTypes() {
      return [
        { value: 'Landline', label: this.$t('Fixo') },
        { value: 'Mobile', label: this.$t('Móvel') },
      ]
    },
  },
}

export default telephoneTypes
