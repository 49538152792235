const accountTypes = {
  methods: {
    accountTypes() {
      return [
        { value: '1', label: this.$t('Conta Poupança') },
        { value: '2', label: this.$t('Conta Corrente') },
      ]
    },
  },
}

export default accountTypes
