const postOfficeDdirectory = {
  methods: {
    postOfficeDirectories() {
      return [
        { value: '1', label: 'Correios Sede CS', sigla: 'CS' },
        { value: '3', label: 'ACRE', sigla: 'ACR' },
        { value: '4', label: 'ALAGOAS', sigla: 'AL' },
        { value: '6', label: 'AMAZONAS', sigla: 'AM' },
        { value: '5', label: 'AMAPÁ', sigla: 'AP' },
        { value: '8', label: 'BAHIA', sigla: 'BA' },
        { value: '10', label: 'BRASÍLIA', sigla: 'BSB' },
        { value: '12', label: 'CEARÁ', sigla: 'CE' },
        { value: '14', label: 'ESPIRITO SANTO', sigla: 'ES' },
        { value: '16', label: 'GOIÁS', sigla: 'GO' },
        { value: '18', label: 'MARANHÃO', sigla: 'MA' },
        { value: '20', label: 'MINAS GERAIS', sigla: 'MG' },
        { value: '22', label: 'MATO GROSSO DO SUL', sigla: 'MS' },
        { value: '24', label: 'MATO GROSSO', sigla: 'MT' },
        { value: '28', label: 'PARÁ', sigla: 'PA' },
        { value: '30', label: 'PARAÍBA', sigla: 'PB' },
        { value: '32', label: 'PERNAMBUCO', sigla: 'PE' },
        { value: '34', label: 'PIAUÍ', sigla: 'PI' },
        { value: '36', label: 'PARANÁ', sigla: 'PR' },
        { value: '50', label: 'RIO DE JANEIRO', sigla: 'RJ' },
        { value: '60', label: 'RIO GRANDE DO NORTE', sigla: 'RN' },
        { value: '64', label: 'RIO GRANDE DO SUL', sigla: 'RS' },
        { value: '26', label: 'RONDONIA', sigla: 'RO' },
        { value: '65', label: 'RORAIMA', sigla: 'RR' },
        { value: '68', label: 'SANTA CATARINA', sigla: 'SC' },
        { value: '70', label: 'SERGIPE', sigla: 'SE' },
        { value: '74', label: 'SÃO PAULO INTERIOR', sigla: 'SPI' },
        { value: '72', label: 'SÃO PAULO', sigla: 'SPM' },
        { value: '75', label: 'TOCANTINS', sigla: 'TO' },
      ]
    },
  },
}

export default postOfficeDdirectory
