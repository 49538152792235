/* eslint-disable import/prefer-default-export */

import paymentTypes from '@/mixins/dominio/payment-types'

const { paymentMethodInterfaceEnum, paymentTypeEnum } = paymentTypes.data()

const cardTypes = [paymentTypeEnum.CREDIT_CARD, paymentTypeEnum.DEBIT_CARD]

export const tefCardActive = receipts =>
  receipts
    .filter(r => !r.canceled)
    .filter(r => r.paymentMethodInterface === paymentMethodInterfaceEnum.TEF)
    .filter(r => cardTypes.includes(r.paymentMethod.method))

export const isTefPix = receipt =>
  receipt.paymentMethodInterface === paymentMethodInterfaceEnum.TEF &&
  receipt.paymentMethod.method === paymentTypeEnum.PIX
export const tefPixCanceled = receipts =>
  receipts.filter(r => r.canceled).filter(r => isTefPix(r))
export const tefPixActive = receipts =>
  receipts.filter(r => !r.canceled).filter(r => isTefPix(r))

export const hasTefCardActive = receipts => tefCardActive(receipts).length > 0

export const hasTefPixActive = receipts => tefPixActive(receipts).length > 0
export const hasTefPixCanceled = receipts => tefPixCanceled(receipts).length > 0

export const isTefCard = receipt =>
  receipt.paymentMethodInterface === paymentMethodInterfaceEnum.TEF &&
  cardTypes.includes(receipt.paymentMethod.method)
