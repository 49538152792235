<template>
  <b-card-actions
    action-collapse
    :title="title || defaultTitle"
  >
    <FormulateForm
      ref="filterForm"
      name="filter"
      @submit="search"
    >
      <div>
        <slot />
        <b-row class="mt-1">
          <b-col
            md="12"
            align="right"
          >
            <slot name="buttons">
              <e-button
                id="filter_clean_search"
                type="button"
                :class="
                  showSearchButton ? 'btn-sm-block mr-1 my-1 my-md-0' : 'btn-sm-block my-1 my-md-0'
                "
                variant="secondary"
                icon="x-circle"
                :text="$t('Limpar filtros')"
                @click="reset"
              />

              <e-button
                v-if="showSearchButton"
                id="filter_search"
                type="submit"
                class="btn-sm-block"
                variant="primary"
                icon="search"
                :busy="searching"
                :text="searchText || defaultSearchText"
              />
            </slot>
            <slot name="after_buttons" />
          </b-col>
        </b-row>
      </div>
    </FormulateForm>
  </b-card-actions>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { EButton } from '@/views/components'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BRow,
    BCol,
    EButton,
    BCardActions,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    showSearchButton: {
      type: Boolean,
      default: true,
    },
    searchText: {
      type: String,
      default: '',
    },
    searching: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localSearching: false,
    }
  },

  computed: {
    defaultTitle() {
      return this.$t('Filtros')
    },

    defaultSearchText() {
      return this.$t('Pesquisar')
    },
  },

  watch: {
    searching(val) {
      this.localSearching = val
    },
  },

  methods: {
    open() {
      this.showLocal = true
    },

    search() {
      this.$emit('search')
    },

    reset() {
      this.$emit('reset')
    },

    isValid() {
      this.$refs.filterForm.showErrors()
      return this.$refs.filterForm.isValid
    },
  },
}
</script>
