const storeDomains = {
  data() {
    return {
      storeDeliveryTypesEnum: {
        CITY: 'City',
        POLYGON: 'Polygon',
      },
    }
  },

  computed: {
    storeTypesLabel() {
      return {
        Neighborhood: this.$t('Bairro'),
        Dark: this.$t('Dark'),
        Drive: this.$t('Drive'),
        Full: this.$t('Full'),
        Takeout: this.$t('Takeout'),
        Kiosk: this.$t('Quiosque'),
        Bar: this.$t('Bar'),
      }
    },

    storeDeliveryTypesLabel() {
      return {
        City: this.$t('Cidade'),
        Polygon: this.$t('Polígono'),
      }
    },
  },

  methods: {
    storeTypes() {
      return [
        { value: 'Neighborhood', label: this.$t('Bairro') },
        { value: 'Dark', label: this.$t('Dark') },
        { value: 'Drive', label: this.$t('Drive') },
        { value: 'Full', label: this.$t('Full') },
        { value: 'Takeout', label: this.$t('Takeout') },
        { value: 'Kiosk', label: this.$t('Quiosque') },
        { value: 'Bar', label: this.$t('Bar') },
      ]
    },
    storeBusinessModels() {
      return [
        { value: 'OwnStore', label: this.$t('Loja Própria') },
        { value: 'Franchise', label: this.$t('Franquia') },
      ]
    },
    storeDeliveryTypes() {
      return [
        { value: 'City', label: this.$t('Cidade') },
        { value: 'Polygon', label: this.$t('Polígono') },
      ]
    },
    storeRegions() {
      return [
        { value: 'Centro-Oeste', label: this.$t('Centro-Oeste') },
        { value: 'Nordeste', label: this.$t('Nordeste') },
        { value: 'Norte', label: this.$t('Norte') },
        { value: 'Sudeste', label: this.$t('Sudeste') },
        { value: 'Sul', label: this.$t('Sul') },
      ]
    },
  },
}

export default storeDomains
