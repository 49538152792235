<template>
  <p class="h3">
    <b-icon
      v-if="selected"
      icon="check-circle-fill"
      variant="success"
    />
    <b-icon
      v-else
      icon="x-circle-fill"
      variant="danger"
    />
  </p>
</template>
<script>
import { BIcon } from 'bootstrap-vue'

export default {
  components: {
    BIcon,
  },
  props: {
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
}
</script>
