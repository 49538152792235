const basePath = '/vendas'

export default [
  // quotation list
  {
    path: `${basePath}/cotacoes`,
    name: 'quotation-list',
    component: () => import('@/views/pages/sale/quotation/QuotationList.vue'),
    meta: {
      pageTitle: 'Cotações',
      navActiveLink: 'quotation-list',
      resource: 'Quotation',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Cotações',
          active: true,
        },
      ],
    },
  },
  // quotation add
  {
    path: `${basePath}/cotacoes/adicionar`,
    name: 'quotation-add',
    component: () => import('@/views/pages/sale/quotation/QuotationMaintain.vue'),
    meta: {
      pageTitle: 'Nova Cotação',
      navActiveLink: 'quotation-list',
      resource: 'Quotation',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Cotações',
          to: `${basePath}/cotacoes`,
        },
        {
          text: 'Nova Cotação',
          active: true,
        },
      ],
    },
  },
  // quotation read only
  {
    path: `${basePath}/cotacoes/visualizar/:id`,
    name: 'quotation-read-only',
    component: () => import('@/views/pages/sale/quotation/QuotationMaintain.vue'),
    meta: {
      pageTitle: 'Visualizar Cotação',
      navActiveLink: 'quotation-list',
      resource: 'Quotation',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Cotações',
          to: `${basePath}/cotacoes`,
        },
        {
          text: 'Visualizar Cotação',
          active: true,
        },
      ],
    },
  },
  // order list
  {
    path: `${basePath}/pedidos`,
    name: 'order-list',
    component: () => import('@/views/pages/sale/order/OrderList.vue'),
    meta: {
      pageTitle: 'Pedidos',
      navActiveLink: 'order-list',
      resource: 'Sale',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Pedidos',
          active: true,
        },
      ],
    },
  },
  // order add
  {
    path: `${basePath}/pedidos/adicionar`,
    name: 'order-add',
    component: () => import('@/views/pages/sale/order/OrderMaintain.vue'),
    meta: {
      pageTitle: 'Novo Pedido',
      navActiveLink: 'order-list',
      resource: 'Sale',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Pedidos',
          to: `${basePath}/pedidos`,
        },
        {
          text: 'Novo Pedido',
          active: true,
        },
      ],
    },
  },
  // order maintain
  {
    path: `${basePath}/pedidos/manter/:id`,
    name: 'order-maintain',
    component: () => import('@/views/pages/sale/order/OrderMaintain.vue'),
    meta: {
      pageTitle: 'Alterar Pedido',
      navActiveLink: 'order-list',
      resource: 'Sale',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Pedidos',
          to: `${basePath}/pedidos`,
        },
        {
          text: 'Alterar Pedido',
          active: true,
        },
      ],
    },
  },
  // order read-only
  {
    path: `${basePath}/pedidos/visualizar/:id`,
    name: 'order-read-only',
    component: () => import('@/views/pages/sale/order/OrderMaintain.vue'),
    meta: {
      pageTitle: 'Visualizar Pedido',
      navActiveLink: 'order-list',
      resource: 'Sale',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Pedidos',
          to: `${basePath}/pedidos`,
        },
        {
          text: 'Visualizar Pedido',
          active: true,
        },
      ],
    },
  },
  // sale list
  {
    path: `${basePath}/vendas`,
    name: 'sale-list',
    component: () => import('@/views/pages/sale/sale/SaleList.vue'),
    meta: {
      pageTitle: 'Vendas',
      resource: 'Sale',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Vendas',
          active: true,
        },
      ],
    },
  },
  // sale maintain
  {
    path: `${basePath}/vendas/manter/:id`,
    name: 'sale-maintain',
    component: () => import('@/views/pages/sale/order/OrderMaintain.vue'),
    meta: {
      pageTitle: 'Alterar Venda',
      navActiveLink: 'sale-list',
      resource: 'Sale',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Vendas',
          to: `${basePath}/vendas`,
        },
        {
          text: 'Alterar Venda',
          active: true,
        },
      ],
    },
  },
  // sale read-only
  {
    path: `${basePath}/vendas/visualizar/:id`,
    name: 'sale-read-only',
    component: () => import('@/views/pages/sale/order/OrderMaintain.vue'),
    meta: {
      pageTitle: 'Visualizar Venda',
      navActiveLink: 'sale-list',
      resource: 'Sale',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Vendas',
          to: `${basePath}/vendas`,
        },
        {
          text: 'Visualizar Venda',
          active: true,
        },
      ],
    },
  },
  // clients list
  {
    path: `${basePath}/clientes`,
    name: 'client-list',
    component: () => import('@/views/pages/sale/clients/ClientList.vue'),
    meta: {
      pageTitle: 'Clientes',
      navActiveLink: 'client-list',
      resource: 'Customer',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Clientes',
          active: true,
        },
      ],
    },
  },
  // clients add
  {
    path: `${basePath}/clientes/adicionar`,
    name: 'client-add',
    component: () => import('@/views/pages/sale/clients/ClientMaintain.vue'),
    meta: {
      pageTitle: 'Novo Cliente',
      navActiveLink: 'client-list',
      resource: 'Customer',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Clientes',
          to: `${basePath}/clientes`,
        },
        {
          text: 'Novo Cliente',
          active: true,
        },
      ],
    },
  },
  // clients maintain
  {
    path: `${basePath}/clientes/manter/:id`,
    name: 'client-maintain',
    component: () => import('@/views/pages/sale/clients/ClientMaintain.vue'),
    meta: {
      pageTitle: 'Manter Cliente',
      navActiveLink: 'client-list',
      resource: 'Customer',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Clientes',
          to: `${basePath}/clientes`,
        },
        {
          text: 'Manter Cliente',
          active: true,
        },
      ],
    },
  },
  // clients read-only
  {
    path: `${basePath}/clientes/visualizar/:id`,
    name: 'client-read-only',
    component: () => import('@/views/pages/sale/clients/ClientMaintain.vue'),
    props: { isReadOnly: true },
    meta: {
      pageTitle: 'Visualizar Cliente',
      navActiveLink: 'client-list',
      resource: 'Customer',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Clientes',
          to: `${basePath}/clientes`,
        },
        {
          text: 'Visualizar Cliente',
          active: true,
        },
      ],
    },
  },
  // list retorno de produtos
  {
    path: `${basePath}/retorno-de-produtos`,
    name: 'reclaim-list',
    component: () => import('@/views/pages/pdv/pay-box-reclaim-products/PayBoxReclaimProducts.vue'),
    meta: {
      pageTitle: 'Retorno de produtos',
      navActiveLink: 'reclaim-list',
      resource: 'Sale',
      action: 'ReturnConsigned',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Retorno de produtos',
          active: true,
        },
      ],
    },
  },
  // priceTable list
  {
    path: `${basePath}/tabelas-de-precos`,
    name: 'price-table',
    component: () => import('@/views/pages/sale/price-table/PriceTableList.vue'),
    meta: {
      pageTitle: 'Tabelas de preços',
      resource: 'PriceTable',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Tabelas de preços',
          active: true,
        },
      ],
    },
  },
  // promotions list
  {
    path: `${basePath}/promocoes`,
    name: 'promotion-list',
    component: () => import('@/views/pages/sale/promotion/PromotionList.vue'),
    meta: {
      pageTitle: 'Promoções',
      resource: 'Promotion',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Promoções',
          active: true,
        },
      ],
    },
  },
  // promotions add
  {
    path: `${basePath}/promocoes/adicionar`,
    name: 'promotion-add',
    component: () => import('@/views/pages/sale/promotion/PromotionMaintain.vue'),
    meta: {
      pageTitle: 'Nova Promoção',
      navActiveLink: 'promotion-list',
      resource: 'Promotion',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Promoções',
          to: `${basePath}/promocoes`,
        },
        {
          text: 'Nova Promoção',
          active: true,
        },
      ],
    },
  },
  // kit de produtos maintain
  {
    path: `${basePath}/promocoes/manter/:id`,
    name: 'promotion-maintain',
    component: () => import('@/views/pages/sale/promotion/PromotionMaintain.vue'),
    meta: {
      pageTitle: 'Manter Promoções',
      navActiveLink: 'promotion-list',
      resource: 'Promotion',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Promoções',
          to: `${basePath}/promocoes`,
        },
        {
          text: 'Manter Promoção',
          active: true,
        },
      ],
    },
  },
  // delivery store config
  {
    path: `${basePath}/delivery-configuracao-loja`,
    name: 'delivery-config-store-list',
    component: () => import('@/views/pages/sale/delivery-store-config/DeliveryStoreConfigList.vue'),
    meta: {
      pageTitle: 'Config. Delivery',
      navActiveLink: 'delivery-config-store-list',
      resource: 'Auth',
      action: 'read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Config. delivery',
          active: true,
        },
      ],
    },
  },
  // delivery dashboard
  {
    path: `${basePath}/delivery-dashboard`,
    name: 'delivery-dashboard',
    component: () => import('@/views/pages/sale/delivery-dashboard/DeliveryDashboard.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Delivery',
      // resource: 'PriceTable',
      // action: 'Read',
    },
  },
  // event list
  {
    path: `${basePath}/eventos`,
    name: 'event-list',
    component: () => import('@/views/pages/sale/event/EventList.vue'),
    meta: {
      pageTitle: 'Eventos',
      navActiveLink: 'event-list',
      resource: 'Event',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Eventos',
          active: true,
        },
      ],
    },
  },
  // event add
  {
    path: `${basePath}/eventos/adicionar`,
    name: 'event-add',
    component: () => import('@/views/pages/sale/event/EventMaintain.vue'),
    meta: {
      pageTitle: 'Novo Evento',
      navActiveLink: 'event-list',
      resource: 'Event',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Eventos',
          to: `${basePath}/eventos`,
        },
        {
          text: 'Novo Evento',
          active: true,
        },
      ],
    },
  },
  // event update
  {
    path: `${basePath}/eventos/manter/:id`,
    name: 'event-maintain',
    component: () => import('@/views/pages/sale/event/EventMaintain.vue'),
    meta: {
      pageTitle: 'Alterar Evento',
      navActiveLink: 'event-list',
      resource: 'Event',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Eventos',
          to: `${basePath}/eventos`,
        },
        {
          text: 'Alterar Evento',
          active: true,
        },
      ],
    },
  },
  // event read only
  {
    path: `${basePath}/eventos/visualizar/:id`,
    name: 'event-read-only',
    component: () => import('@/views/pages/sale/event/EventMaintain.vue'),
    meta: {
      pageTitle: 'Visualizar Evento',
      navActiveLink: 'event-list',
      resource: 'Event',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Vendas',
        },
        {
          text: 'Eventos',
          to: `${basePath}/eventos`,
        },
        {
          text: 'Visualizar Evento',
          active: true,
        },
      ],
    },
  },
]
