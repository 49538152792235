import FormulateLabel from './FormulateLabel.vue'

export default function VueFormulateLabelPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateLabel,
    },
    library: {
      label: {
        classification: 'text',
        component: 'FormulateLabel',
        slotProps: {
          component: ['icons', 'title', 'isBadge']
        }
      },
    },
  })
}
