import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import purchaseOrderMaintain from './purchase-order-maintain'
import purchaseOrderManualMaintain from './purchase-order-manual-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  purchaseOrders: [],
  filters: {
    id: null,
    storeId: null,
    supplierId: null,
    skuId: null,
    status: null,
    invoiceNumber: '',
    invoiceDate: {
      startDate: null,
      endDate: null,
    },
    createdDate: {
      startDate: null,
      endDate: null,
    },
  },
  managementAccounts: [],
})

export default {
  namespaced: true,
  modules: { purchaseOrderMaintain, purchaseOrderManualMaintain },

  state: getInitialState(),

  getters: {
    managementAccountsOptions(state) {
      return state.managementAccounts?.map(item => ({
        ...item,
        value: item.id,
        label: item.name,
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_ORDER_PURCHASES(state, purchaseOrders) {
      state.purchaseOrders = purchaseOrders
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_MANAGEMENT_ACCOUNTS(state, managementAccounts) {
      state.managementAccounts = managementAccounts
    },
    CLEAN_STATE(state) {
      const { filters, paging, purchaseOrders } = getInitialState()
      state.filters = filters
      state.purchaseOrders = purchaseOrders
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchOrderPurchase({ commit, state }) {
      const { data } = await axios.get('/api/purchases/purchase-orders', {
        params: {
          id: state.filters.id,
          storeId: state.filters.storeId,
          supplierId: state.filters.supplierId,
          skuId: state.filters.skuId,
          status: state.filters.status,
          invoiceNumber: state.filters.invoiceNumber,
          invoiceDateFrom: state.filters.invoiceDate.startDate,
          invoiceDateTo: state.filters.invoiceDate.endDate,
          createdDateFrom: state.filters.createdDate.startDate,
          createdDateTo: state.filters.createdDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_ORDER_PURCHASES', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchManagementAccounts({ commit }) {
      const { data } = await axios.get('/api/accounting/management-accounts', {
        params: {
          type: 'Output',
          active: true,
          pageSize: 0,
          pageIndex: 0,
        },
      })
      commit('SET_MANAGEMENT_ACCOUNTS', data.results || [])
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
