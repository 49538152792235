var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.links
        ? _c(
            "router-link",
            {
              staticClass: "hyperlink",
              attrs: { to: _vm.to, title: _vm.title },
            },
            [
              _c("feather-icon", { attrs: { icon: "ExternalLinkIcon" } }),
              _vm._v(" " + _vm._s(_vm.text || _vm.value || "-") + " "),
            ],
            1
          )
        : _c(
            "b-dropdown",
            {
              staticClass: "hyperlink custom-dropdown-router-link",
              attrs: {
                boundary: "window",
                "split-variant": "none",
                variant: "none",
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _c(
                        "p",
                        { staticClass: "m-0 hyperlink" },
                        [
                          _c("feather-icon", {
                            staticStyle: {
                              "margin-bottom": "5px",
                              "margin-right": "5px",
                            },
                            attrs: { icon: "ExternalLinkIcon" },
                          }),
                          _c("span", {}, [
                            _vm._v(_vm._s(_vm.text || _vm.value || "-")),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.header
                ? _c("b-dropdown-header", [
                    _vm._v(" " + _vm._s(_vm.header) + " "),
                  ])
                : _vm._e(),
              _vm.header ? _c("b-dropdown-divider") : _vm._e(),
              _vm._l(_vm.links, function (link) {
                return _c(
                  "b-dropdown-item",
                  { key: link.to.name, attrs: { to: link.to, href: "#" } },
                  [_vm._v(_vm._s(link.title))]
                )
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }