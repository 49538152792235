import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  productLines: [],
  filter: { name: '' },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_PRODUCT_LINES(state, productLines) {
      state.productLines = productLines
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    CLEAN_STATE(state) {
      const { filter, paging, productLines } = getInitialState()
      state.filter = filter
      state.productLines = productLines
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchProductLines({ commit, state }) {
      const { data } = await axios.get('/api/lines', {
        params: {
          name: state.filter.name ? state.filter.name : '',
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state)
        },
      })

      commit('SET_PRODUCT_LINES', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', getInitialState().filter)
    },

    setFilter({ commit }, filter) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', filter)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
