import isElectron from 'is-electron'

const children = [
  {
    title: 'Gerenciar Caixas',
    route: 'pdv-manage-pay-box',
    resource: 'PayBox',
    action: 'Read',
  },
  {
    title: 'Extrato do Caixa',
    route: 'pdv-pay-box-statements',
    resource: 'PayBox',
    action: 'Read',
  },
]

if (isElectron()) {
  children.push({
    title: 'Caixa',
    route: 'pdv-pay-box-open',
    resource: 'Auth',
    action: 'read',
  })
}

export default {
  title: 'PDV',
  icon: 'coin',
  iconPack: 'bootstrap',
  children,
}
