import axios from '@axios'

export const getInitialInvoiceInvalidate = () => ({
  id: null,
  storeId: null,
  serialNumber: null,
  model: null,
  initialNumber: null,
  finalNumber: null,
  justification: null,
})

const getInitialState = () => ({
  invalidate: getInitialInvoiceInvalidate(),
})

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {},

  mutations: {
    SET_INVOICE_INVALIDATE(state, val) {
      state.invalidate = val
    },

    CLEAN_STATE(state) {
      const { invalidate } = getInitialState()
      state.invalidate = invalidate
    },
  },

  actions: {
    async invalidateInvoice({ state }) {
      await axios({
        url: '/api/invoices/invoice-invalidate',
        method: 'POST',
        data: {
          storeId: state.invalidate.storeId,
          model: state.invalidate.model,
          serialNumber: state.invalidate.serialNumber,
          initialNumber: state.invalidate.initialNumber,
          finalNumber: state.invalidate.finalNumber,
          justification: state.invalidate.justification,
        },
      })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
