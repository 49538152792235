import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  sales: [],
  pendingSales: [],
  filters: {
    saleId: '',
    period: {
      startDate: null,
      endDate: null,
    },
    customer: null,
    origin: null,
    deliveryStatus: null,
    isConsigned: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_SALES(state, sales) {
      state.sales = sales
    },
    SET_PENDING_SALES(state, pendingSales) {
      state.pendingSales = pendingSales
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, sales, pendingSales } = getInitialState()
      state.filters = filters
      state.sales = sales
      state.pendingSales = pendingSales
      state.paging = paging
    },
    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchSales({ state, commit }) {
      const { customer, saleId, origin, isConsigned, period } = state.filters

      const { data } = await axios.get('/api/sales/pay-box-sale', {
        params: {
          id: saleId || null,
          origin: origin || null,
          isConsigned,
          customerId: customer,
          IssueDateFrom: period.startDate,
          IssueDateTo: period.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      const dataConverted = data.results.map(s => ({
        ...s,
        total: s.netValue,
      }))

      commit('SET_SALES', dataConverted || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchPendingSales({ state, commit }) {
      const { period, customer, saleId, origin, deliveryStatus, isConsigned } = state.filters
      const { data } = await axios.get('/api/sales/in-progress', {
        params: {
          id: saleId || null,
          origin: origin || null,
          isConsigned,
          deliveryStatus: deliveryStatus || null,
          customerId: customer,
          IssueDateFrom: period.startDate,
          IssueDateTo: period.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      const dataConverted = data.results.map(s => ({
        ...s,
        total: s.netValue,
      }))

      commit('SET_PENDING_SALES', dataConverted || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchSaleById(states, id) {
      const { data } = await axios.get(`/api/sales/${id}`)

      // TODO: Duplicated with src/store/pages/sale/sale/index.js :: fetchSaleById
      const dataConverted = {
        ...data,
        total: data.netValue,
        customer: {
          ...data.customer,
          name: data.customer.name,
          document: data.customer.document || '',
          telephone: data.customer.telephone || '',
        },
        items: data.items.map(i => ({
          ...i,
          name: i.sku.name,
        })),
      }

      return dataConverted
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },
  },
}
