var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-with-button-container" },
    [
      _c(
        "v-select",
        _vm._b(
          {
            class:
              "select-with-button formulate-input-element formulate-input-element--" +
              _vm.context.type,
            attrs: {
              "data-type": _vm.context.type,
              reduce: function (x) {
                return x.value
              },
              options: _vm.context.options,
              label: "label",
            },
            on: { close: _vm.onClose },
            model: {
              value: _vm.context.model,
              callback: function ($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model",
            },
          },
          "v-select",
          _vm.context.attributes,
          false
        )
      ),
      _c(
        "b-button",
        {
          class:
            "select-with-button-btn " +
            (_vm.context.attributes.disabled ? "cursor-not-allowed" : ""),
          attrs: {
            disabled:
              !_vm.context.attributes["button-permission"] ||
              _vm.context.attributes.disabled,
            variant: "primary",
            title: !_vm.context.attributes["button-permission"]
              ? _vm.$t("Você não possui acesso a esta funcionalidade")
              : "",
          },
          on: { click: _vm.click },
        },
        [_c("b-icon", { attrs: { icon: _vm.icon, "font-scale": "2" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }