import clients from './clients'
import priceTable from './price-table'
import deliveryDashboard from './delivery-dashboard'
import deliveryStoreConfig from './delivery-store-config'
import promotion from './promotion'
import quotation from './quotation'
import order from './order'
import sale from './sale'
import event from './event'

export default {
  namespaced: true,
  modules: {
    clients,
    priceTable,
    deliveryDashboard,
    deliveryStoreConfig,
    quotation,
    order,
    promotion,
    sale,
    event,
  },
  state: {},
  mutations: {},
  actions: {},
}
