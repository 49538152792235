export default {
  title: 'Relatórios',
  icon: 'GraphUp',
  iconPack: 'bootstrap',
  children: [
    {
      title: 'Vendas por Meio de Pagamento',
      route: 'report-sale-by-payment-method',
      resource: 'SaleReports',
      action: 'SaleByPaymentMethod',
    },
    {
      title: 'Vendas por Produto',
      route: 'report-sku-sale-detailed',
      resource: 'SaleReports',
      action: 'SkuSaleDetailed',
    },

    {
      title: 'Contas a Pagar',
      route: 'report-accounts-payable',
      resource: 'FinancialReports',
      action: 'AccountsPayable',
    },
    {
      title: 'Contas a Receber',
      route: 'report-receivables',
      resource: 'FinancialReports',
      action: 'Receivables',
    },
    {
      title: 'Conciliação - Transações Ignoradas',
      route: 'reconciliation-peding-itens-list',
      // resource: 'auth',
      // action: 'Read',
    },
  ],
}
