<template>
  <FormulateInput
    v-if="isReadOnly"
    v-model="content"
    v-bind="$attrs"
    type="label"
    :label="label"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    :options="localOptions"
  />
  <FormulateInput
    v-else
    v-bind="$attrs"
    ref="select"
    v-model="content"
    type="select-searchable"
    :label="showLabel ? label : ''"
    :placeholder="$t('Digite para pesquisar')"
    :options="localOptions"
    :class="showLabel && required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    :instruction="showLabel ? $t('Pesquise pelo nome ou CPF/CNPJ') : ''"
    @fetchSearch="onSearch"
    @input="handleInput"
  />
</template>

<script>
export default {
  components: {},

  mixins: [],

  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default() {
        return this.$t('Pesquisar')
      },
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    valueIsObject: {
      type: Boolean,
      default: false,
    },
    // Opções padrões (caso o localOptions esteja vazio)
    defaultOptions: {
      type: Array,
      default() {
        return []
      },
    },
    isSupplier: {
      type: Boolean,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
    /**
     * traz somente lojas da tabela de cliente ou fornecedores
     */
    onlyStore: {
      type: Boolean,
      default: false,
    },

    onlyActive: {
      type: Boolean,
      default: true,
    },

    /**
     * trazer a loja junto com os clientes ou fornecedores
     */
    withStore: {
      type: Boolean,
      default: true,
    },

    /**
     * Lista de objetos que não deseja mostrar como opção,
     * pode ser preechido o id ou document (document deve ser sem formatação)
     * exemplos:
     * [{id: 1, document: null}]
     * [{id: null, document: '112341234'}]
     */
    hideIdOrDocumentList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      content: null,
      options: [],
    }
  },

  computed: {
    localOptions() {
      let options = this.options.length > 0 ? this.options : this.defaultOptions

      if (this.hideIdOrDocumentList?.length > 0) {
        options = options.filter(
          opt =>
            !this.hideIdOrDocumentList.some(
              hide => hide.id === opt.id || hide.document === opt.document
            )
        )
      }

      if (this.valueIsObject) {
        return options.map(c => ({
          ...c,
          label: this.formatLabel(c),
          value: c,
        }))
      }

      return options.map(c => ({
        ...c,
        label: this.formatLabel(c),
        value: c.id,
      }))
    },
  },

  watch: {
    value(val) {
      this.content = val
    },
  },

  created() {
    // necessário para preencher o input com base no valor que vem da prop "value"
    this.content = this.value
  },

  methods: {
    async onSearch(loading, searchString) {
      try {
        loading(true)
        let url = '/api/customers/search'
        if (this.isSupplier) {
          url = '/api/suppliers/search'
        }

        const params = { searchString, onlyActive: this.onlyActive }
        if (this.onlyStore) {
          params.isStore = true
        } else {
          params.isStore = this.withStore ? null : false
        }

        const { data } = await this.$http.get(url, {
          params,
        })

        this.options = data.results || []
      } catch (error) {
        this.showError({ error })
      } finally {
        loading(false)
      }
    },

    formatLabel(data) {
      let result = ''
      if (data.isStore) {
        result = `${data.id} - ${data.name || data.companyName}`
      } else if (this.isSupplier || this.isCustomer) {
        const formattedDoc = this.$options.filters.cpfCnpj(data.document)
        result = `${data.id} - ${data.name || data.companyName} - ${formattedDoc}`
      }
      if (data.active === false) {
        result += ` - ${this.$t('Inativo')}`
      }

      return result
    },

    handleInput() {
      this.$emit('input', this.content)
    },

    reset() {
      this.options = []
    },
  },
}
</script>
