<template>
  <FormulateInput
    v-if="isReadOnly"
    v-model="content"
    v-bind="$attrs"
    type="label"
    :label="showLabel ? label : ''"
    :class="showLabel && required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    :options="localOptions"
  />
  <FormulateInput
    v-else
    v-bind="$attrs"
    ref="select"
    v-model="content"
    type="select-searchable"
    :label="showLabel ? label : ''"
    :placeholder="$t('Digite para pesquisar')"
    :options="localOptions"
    :class="showLabel && required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    :instruction="showLabel ? $t('Pesquise pelo login, nome ou documento do usuário') : ''"
    @fetchSearch="onSearch"
    @input="handleInput"
  />
</template>

<script>
export default {
  components: {},

  mixins: [],

  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default() {
        return this.$t('Pesquisar')
      },
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    // Opções padrões (caso o localOptions esteja vazio)
    defaultOptions: {
      type: Array,
      default() {
        return []
      },
    },

    onlyActive: {
      type: Boolean,
      default: true,
    },

    /**
     * Lista de objetos que não deseja mostrar como opção,
     * exemplos:
     * [{ id: 1 }]
     */
    hideIdOrDocumentList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      content: null,
      options: [],
    }
  },

  computed: {
    localOptions() {
      let options = this.options.length > 0 ? this.options : this.defaultOptions

      if (this.hideIdOrDocumentList?.length > 0) {
        options = options.filter(
          opt => !this.hideIdOrDocumentList.some(hide => hide.id === opt.id)
        )
      }

      return options.map(c => ({
        ...c,
        label: this.formatLabel(c),
        value: c.id,
      }))
    },
  },

  watch: {
    value(val) {
      this.content = val
    },
  },

  created() {
    // necessário para preencher o input com base no valor que vem da prop "value"
    this.content = this.value
  },

  methods: {
    async onSearch(loading, searchString) {
      try {
        loading(true)

        const params = { searchString, onlyActive: this.onlyActive }

        const { data } = await this.$http.get('/api/users/search', { params })

        this.options = data.results || []
      } catch (error) {
        this.showError({ error })
      } finally {
        loading(false)
      }
    },

    formatLabel(data) {
      let result = data.name

      if (data.userName) result += ` (${data.userName})`

      if (data.active === false) {
        result += ` - ${this.$t('Inativo')}`
      }

      return result
    },

    handleInput() {
      const selectedOptionObject = this.localOptions.find(opt => opt.value === this.content)
      this.$emit('input', this.content, selectedOptionObject)
    },

    reset() {
      this.options = []
    },

    cleanInput() {
      this.content = null
      this.options = []
    },
  },
}
</script>
