import moment from 'moment'

const formulateValidation = {
  methods: {
    dateGreaterThan(date, biggestDate) {
      const dateValid = moment(date)
      const biggestDateValid = moment(biggestDate)

      if (!dateValid.isValid() && !biggestDateValid.isValid()) return false

      return dateValid.isSame(biggestDateValid) || dateValid.isBefore(biggestDateValid)
    },

    numberGtOrEq(numb, highNumb) {
      return Number(numb) >= Number(highNumb)
    },

    numberLtOrEq(numb, highNumb) {
      return Number(numb) <= Number(highNumb)
    },
  },
}

export default formulateValidation
