import axios from '@axios'
import { storePagination } from '@/mixins/store'
import moment from 'moment'
import { sorting } from '@/mixins'

const getInitialState = () => ({
  ...storePagination.state(),
  checkingAccountTransferences: [],
  summaryFields: null,
  sorting: {
    sortBy: 'statementDate',
    sortDesc: true,
  },
  filters: {
    storeOriginId: null,
    checkingAccountOriginId: null,
    storeDestinationId: null,
    checkingAccountDestinationId: null,
    importedFileId: null,
    period: {
      startDate: moment().format(),
      endDate: moment().format(),
    },
  },
  reportData: null,
})

export default {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_CHECKING_ACCOUNT_TRANSFERENCES(state, items) {
      state.checkingAccountTransferences = items
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },
    CLEAN_STATE(state) {
      const { checkingAccountTransferences } = getInitialState()
      state.checkingAccountTransferences = checkingAccountTransferences
    },
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchCheckingAccountTransferences({ commit, state }) {
      const { period } = state.filters
      const { data } = await axios.get('/api/financial/checking-account-transfers', {
        params: {
          ...state.filters,
          startDate: period.startDate,
          endDate: period.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_CHECKING_ACCOUNT_TRANSFERENCES', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
