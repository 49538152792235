import { storePagination } from '@/mixins/store'
import { fetchDomainData } from '@/store/utils'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  products: [],
  singleProduct: {},
  filters: {
    name: '',
    department: null,
    brand: null,
    group: null,
    subgroup: null,
  },
  productCombos: {
    departments: [],
    brands: [],
    'product-groups': [],
    'product-subgroups': [],
  },
})

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {
    getProducts(state) {
      return state.products
    },
    getProduct(state) {
      return state.singleProduct
    },
    getComboDepartments(state) {
      return state.productCombos.departments.map(department => ({
        ...department,
        label: department.name,
        value: department.id,
      }))
    },
    getComboBrands(state) {
      return state.productCombos.brands.map(brand => ({
        ...brand,
        label: brand.name,
        value: brand.id,
      }))
    },
    getComboGroups(state) {
      return state.productCombos['product-groups'].map(group => ({
        ...group,
        label: group.name,
        value: group.id,
      }))
    },
    getComboSubgroups(state) {
      return state.productCombos['product-subgroups'].map(subGroup => ({
        ...subGroup,
        label: subGroup.name,
        value: subGroup.id,
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_PRODUCTS(state, products) {
      state.products = products
    },
    SET_SINGLE_PRODUCT(state, product) {
      state.singleProduct = product
    },

    SET_FILTER(state, filters) {
      state.filters = filters
    },

    SET_PRODUCT_COMBOS(state, data) {
      state.productCombos = {
        ...state.productCombos,
        ...data,
      }
    },

    CLEAN_PRODUCT_COMBO(state, comboName) {
      state.productCombos[comboName] = []
    },

    CLEAN_STATE(state) {
      const { filters, paging, products, productCombos } = getInitialState()
      state.filters = filters
      state.products = products
      state.paging = paging
      state.productCombos = productCombos
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchProducts({ commit, state, dispatch, rootState, rootGetters }, delegateUserData) {
      await dispatch(
        'pages/pdv/payBoxConfiguration/checkCurrentPayBoxConfigAndFetch',
        {},
        {
          root: true,
        }
      )

      const customer = rootState.pages.pdv.payBox.clientForm
      const storeId =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']?.store?.id

      const { data } = await axios.get(`/api/sales/pay-box-sku/store/${storeId}/search`, {
        params: {
          name: state.filters.name,
          brandId: state.filters.brand,
          groupId: state.filters.group,
          subgroupId: state.filters.subgroup,
          departmentId: state.filters.department,
          customerId: customer.id,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
        headers: {
          'delegate-token': delegateUserData?.token,
        },
      })

      commit('SET_PRODUCTS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchProductByEan({ commit, dispatch, rootGetters }, { ean, customerId }) {
      await dispatch(
        'pages/pdv/payBoxConfiguration/checkCurrentPayBoxConfigAndFetch',
        {},
        {
          root: true,
        }
      )
      const storeId =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']?.store?.id

      const { data } = await axios.get(`/api/sales/pay-box-sku/store/${storeId}/ean/${ean}`, {
        params: {
          customerId: customerId || null,
          validateToPayBox: true,
        },
      })

      commit('SET_SINGLE_PRODUCT', data)
      return data
    },

    async fetchProductCombos({ commit, state }) {
      const [
        brands,
        // groups,
        // subgroups,
        // departments,
      ] = await Promise.all([
        fetchDomainData('/api/brands', state.productCombos.brands, { params: { active: true } }),
        // fetchDomainData('/api/product-groups', state.productCombos['product-groups']),
        // fetchDomainData('/api/departments', state.productCombos.departments, {
        //   params: { active: true },
        // })
      ])
      commit('SET_PRODUCT_COMBOS', {
        brands: brands.data?.results || [],
        // 'product-groups': groups.data?.results || [],
        // departments: departments.data?.results || [],
      })
    },

    async setComboSubgroups({ commit }, groupId) {
      let subgroups = []
      if (groupId) {
        subgroups = (
          await axios.get('/api/product-subgroups', {
            params: {
              groupId,
              pageSize: 0,
              pageIndex: 0,
            },
          })
        ).data.results
      }
      commit('SET_PRODUCT_COMBOS', { 'product-subgroups': subgroups })
    },

    resetFilters({ commit }) {
      commit('SET_FILTER', getInitialState().filters)
      commit('SET_PRODUCTS', [])
    },

    cleanProducts({ commit }) {
      commit('SET_PRODUCTS', [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
