import axios from '@axios'
import { storePagination } from '@/mixins'

const getInitialState = () => ({
  ...storePagination.state(),
  saleItems: [],
  reportData: null,
  filters: {
    storeId: null,
    createdRangeDate: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,
    SET_SALE_ITEMS(state, saleItems) {
      state.saleItems = saleItems
    },
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, saleItems } = getInitialState()
      state.filters = filters
      state.saleItems = saleItems
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,
    async fetchSaleItems({ commit, state }) {
      const { createdRangeDate } = state.filters
      const { data } = await axios.get('/api/reports/sales-by-payment-method', {
        params: {
          storeId: state.filters.storeId,
          startDate: createdRangeDate?.startDate,
          endDate: createdRangeDate?.endDate,
        },
      })

      commit('SET_SALE_ITEMS', data || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.length,
        rowsInCurrentPage:
          data.length > state.paging.pageSize ? state.paging.pageSize : data.length,
      })
    },

    async generateReport({ state, commit }) {
      const response = await axios.get('/api/reports/sales-by-payment-method', {
        params: {
          storeId: state.filters.storeId,
          startDate: state.filters?.createdRangeDate?.startDate,
          endDate: state.filters?.createdRangeDate?.endDate,
        },
        responseType: 'blob',
      })
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      commit('SET_REPORT_DATA', url)
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('CLEAN_FILTERS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    updateRowsInPage({ commit, state }) {
      commit('SET_PAGING', {
        ...state.paging,
        rowsInCurrentPage:
          state.saleItems.length > state.paging.pageSize
            ? state.paging.pageSize
            : state.saleItems.length,
      })
    },
  },
}
