var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-100 d-flex align-items-start",
      staticStyle: { gap: "10px" },
    },
    [
      _c("div", [
        _c("p", { staticClass: "h6" }, [
          _vm._v(" " + _vm._s(_vm.$t("Legenda:")) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "d-flex overflow-auto", staticStyle: { gap: "0 10px" } },
        _vm._l(_vm.colors, function (color, index) {
          return _c(
            "div",
            {
              key: index + "_" + color.title,
              staticClass:
                "mx-1 container-subtitled-item d-flex justify-content-start align-items-center flex-column flex-wrap",
              staticStyle: { gap: "5px" },
            },
            [
              _c("div", {
                class: "subtitled-item border-dark " + color.backgroundColor,
                style: {
                  "background-color": color.backgroundStyleColor,
                  border: color.border,
                },
              }),
              color.link
                ? _c(
                    "a",
                    { staticClass: "text-center", attrs: { href: color.link } },
                    [_vm._v(" " + _vm._s(color.title) + " ")]
                  )
                : _c("p", { staticClass: "text-center" }, [
                    _vm._v(" " + _vm._s(color.title) + " "),
                  ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }