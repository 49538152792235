import { storePagination } from '@/mixins/store'
import axios from '@axios'
import { sorting } from '@/mixins'
import quotationMaintain from './quotation-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  quotations: [],
  sorting: {
    sortBy: 'createdDate',
    sortDesc: true,
  },
  filters: {
    quotationId: '',
    storeId: null,
    customer: null,
    priceTableId: null,
    isDelivery: null,
    createdDate: {
      startDate: null,
      endDate: null,
    },
  },
  defaultCustomerOptions: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { quotationMaintain },

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_QUOTATIONS(state, quotations) {
      state.quotations = quotations
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    SET_DEFAULT_CUSTOMER_OPTION(state, customer) {
      if (customer) state.defaultCustomerOptions = [customer]
      else state.defaultCustomerOptions = []
    },

    CLEAN_STATE(state) {
      const { paging, quotations, filters, defaultCustomerOptions } = getInitialState()
      state.quotations = quotations
      state.paging = paging
      state.filters = filters
      state.defaultCustomerOptions = defaultCustomerOptions
    },
  },

  actions: {
    ...storePagination.actions,

    setDefaultCustomerOptions({ commit }, customer) {
      commit('SET_DEFAULT_CUSTOMER_OPTION', customer)
    },

    async fetchQuotations({ commit, state }) {
      const { quotationId, storeId, customer, priceTableId, isDelivery, createdDate } =
        state.filters

      const { data } = await axios.get('/api/sales/quotations', {
        params: {
          quotationId,
          storeId,
          customerId: customer?.id ?? null,
          priceTableId,
          isDelivery,
          createdDateFrom: createdDate.startDate,
          createdDateTo: createdDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_QUOTATIONS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async printQuotation({ rootGetters }, quotationPrint) {
      const thermalPrinterAgentConfigPayload =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      const saleItems = quotationPrint.products.map(i => ({
        SkuId: i.skuProduct.id,
        Name: i.skuProduct.name,
        Quantity: i.quantity,
        UnitValue: i.skuProduct.price,
        TotalValue: i.total,
      }))

      const { data: store } = await axios.get(`/api/stores/${quotationPrint.store.id}`)

      const { data: customer } = await axios.get(`/api/customers/${quotationPrint.customer.id}`)

      const totalQuotation =
        rootGetters['pages/sale/quotation/quotationMaintain/getQuotationTotalProducts']
      const totalDiscount =
        rootGetters['pages/sale/quotation/quotationMaintain/getQuotationTotalDiscount']

      const payload = {
        id: quotationPrint.id,
        issueDate: quotationPrint.issueDate,
        store: {
          id: store.id,
          companyName: store.companyName,
          addressPublicPlace: store?.address?.publicPlace,
          addressNumber: store?.address?.number,
          addressNeighborhood: store?.address?.neighborhood,
          city: store?.address?.city,
          province: store?.address?.province,
        },
        customer: {
          id: customer.id,
          cpf: customer.document,
          rg: customer.stateRegistration,
          name: customer.name,
          addressPublicPlace: customer?.address?.publicPlace,
          addressNumber: customer?.address?.number,
          addressNeighborhood: customer?.address?.neighborhood,
          city: customer?.address?.city,
          province: customer?.address?.province,
        },
        totalValue: totalQuotation,
        discount: totalDiscount,
        isDelivery: quotationPrint.isDelivery,
        deliveryCity: quotationPrint?.deliveryAddress?.city,
        deliveryComplement: quotationPrint?.deliveryAddress?.complement,
        deliveryDate: quotationPrint?.deliveryDate,
        deliveryNeighborhood: quotationPrint?.deliveryAddress?.neighborhood,
        deliveryNumber: quotationPrint?.deliveryAddress?.number,
        deliveryObservation: quotationPrint?.deliveryObservation,
        deliveryProvince: quotationPrint?.deliveryAddress?.province,
        deliveryPublicPlace: quotationPrint?.deliveryAddress?.publicPlace,
        deliveryZipCode: quotationPrint?.deliveryAddress?.zipcode,
        deliveryTax: quotationPrint?.deliveryTax,
        items: saleItems,
      }

      await window.electronAPI.print({
        ...thermalPrinterAgentConfigPayload,
        request: {
          printTexts: [
            {
              printText: JSON.stringify(payload),
              template: 'QUOTATION',
            },
          ],
        },
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
