import FormulateDatepicker from './FormulateDatepicker.vue'

export default function VueFormulateDatepickerPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateDatepicker,
    },
    library: {
      datepicker: {
        classification: 'text',
        component: 'FormulateDatepicker',
        slotProps: {
          component: ['inputAttr', 'hourDefault'],
        },
      },
    },
  })
}
