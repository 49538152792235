import { storePagination, sorting } from '@/mixins'
import axios from '@axios'
import { fetchDomainData } from '@/store/utils'
import productMaintain from './product-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  products: [],
  filters: {
    id: null,
    ean: '',
    name: '',
    department: null,
    line: null,
    unit: null,
    brand: null,
    group: null,
    subgroup: null,
    active: null,
    hasImage: null,
    featuredProduct: null,
    activeOnline: null,
    activeB2B: null,
  },
  productCombos: {
    departments: [],
    lines: [],
    units: [],
    brands: [],
    manufacturers: [],
    'product-groups': [],
    'product-subgroups': [],
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { productMaintain },

  getters: {
    getComboDepartments(state) {
      return state.productCombos.departments.map(department => ({
        ...department,
        label: department.name,
        value: department.id,
      }))
    },
    getComboDepartmentsActive(state) {
      return state.productCombos.departments
        .filter(department => department.active)
        .map(department => ({
          ...department,
          label: department.name,
          value: department.id,
        }))
    },
    getComboLines(state) {
      return state.productCombos.lines.map(line => ({
        ...line,
        label: line.name,
        value: line.id,
      }))
    },
    getComboLinesActive(state) {
      return state.productCombos.lines
        .filter(line => line.active)
        .map(line => ({
          ...line,
          label: line.name,
          value: line.id,
        }))
    },
    getComboUnits(state) {
      return state.productCombos.units.map(unit => ({
        ...unit,
        label: unit.name,
        value: unit.id,
      }))
    },
    getComboUnitsActive(state) {
      return state.productCombos.units
        .filter(unit => unit.active)
        .map(unit => ({
          ...unit,
          label: unit.name,
          value: unit.id,
        }))
    },
    getComboBrands(state) {
      return state.productCombos.brands.map(brand => ({
        ...brand,
        label: brand.name,
        value: brand.id,
      }))
    },
    getComboManufactures(state) {
      return state.productCombos.manufacturers.map(manufacture => ({
        ...manufacture,
        label: manufacture.name,
        value: manufacture.id,
      }))
    },
    getComboBrandsActive(state) {
      return state.productCombos.brands
        .filter(brand => brand.active)
        .map(brand => ({
          ...brand,
          label: brand.name,
          value: brand.id,
        }))
    },
    getComboGroups(state) {
      return state.productCombos['product-groups'].map(group => ({
        ...group,
        label: group.name,
        value: group.id,
      }))
    },
    getComboGroupsActive(state) {
      return state.productCombos['product-groups']
        .filter(group => group.active)
        .map(group => ({
          ...group,
          label: group.name,
          value: group.id,
        }))
    },
    getComboSubgroups(state) {
      return state.productCombos['product-subgroups'].map(subGroup => ({
        ...subGroup,
        label: subGroup.name,
        value: subGroup.id,
      }))
    },
    getComboSubgroupsActive(state) {
      return state.productCombos['product-subgroups']
        .filter(subGroup => subGroup.active)
        .map(subGroup => ({
          ...subGroup,
          label: subGroup.name,
          value: subGroup.id,
        }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_PRODUCTS(state, products) {
      state.products = products
    },

    SET_FILTER(state, filters) {
      state.filters = filters
    },

    SET_PRODUCT_COMBOS(state, data) {
      state.productCombos = {
        ...state.productCombos,
        ...data,
      }
    },

    CLEAN_PRODUCT_COMBO(state, comboName) {
      state.productCombos[comboName] = []
    },

    CLEAN_STATE(state) {
      const { filters, paging, products, productCombos } = getInitialState()
      state.filters = filters
      state.products = products
      state.paging = paging
      state.productCombos = productCombos
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchProducts({ commit, state }) {
      const { data } = await axios.get('/api/products', {
        params: {
          id: state.filters.id,
          ean: state.filters.ean,
          name: state.filters.name,
          brandId: state.filters.brand,
          groupId: state.filters.group,
          subgroupId: state.filters.subgroup,
          departmentId: state.filters.department,
          lineId: state.filters.line,
          ncm: state.filters.ncm,
          active: state.filters.active,
          hasImage: state.filters.hasImage,
          featuredProduct: state.filters.featuredProduct,
          activeOnline: state.filters.activeOnline,
          activeB2B: state.filters.activeB2B,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_PRODUCTS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchProductCombos({ commit, state }) {
      const [
        brands,
        manufacturers,
        groups,
        // subgroups,
        departments,
        lines,
        units,
      ] = await Promise.all([
        fetchDomainData('/api/brands', state.productCombos.brands),
        fetchDomainData('/api/manufacturers', state.productCombos.manufacturers),
        fetchDomainData('/api/product-groups', state.productCombos['product-groups']),
        fetchDomainData('/api/departments', state.productCombos.departments),
        fetchDomainData('/api/lines', state.productCombos.lines),
        fetchDomainData('/api/units', state.productCombos.units),
      ])
      commit('SET_PRODUCT_COMBOS', {
        brands: brands.data?.results || [],
        manufacturers: manufacturers.data?.results || [],
        'product-groups': groups.data?.results || [],
        departments: departments.data?.results || [],
        lines: lines.data?.results || [],
        units: units.data?.results || [],
      })
    },

    async fetchDomainData({ commit }, entity) {
      // debugger
      const result = await fetchDomainData(`/api/${entity}`)
      commit('SET_PRODUCT_COMBOS', {
        [entity]: result.data?.results || [],
      })
    },

    async setComboSubgroups({ commit }, groupId) {
      let subgroups = []
      if (groupId) {
        subgroups = (
          await axios.get('/api/product-subgroups', {
            params: {
              groupId,
              pageSize: 0,
              pageIndex: 0,
            },
          })
        ).data.results
      }
      commit('SET_PRODUCT_COMBOS', { 'product-subgroups': subgroups })
    },

    resetFilters({ commit }) {
      commit('SET_FILTER', getInitialState().filters)
    },

    // // comboName propertie of productCombos in this getInitialState
    // cleanCombo({ commit }, comboName) {
    //   commit('CLEAN_PRODUCT_COMBO', comboName)
    // },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
