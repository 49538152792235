import { storePagination } from '@/mixins/store'
// import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  service: {
    id: null,
    name: '',
    cost: 0,
    price: 0,
    status: true,
    tax: {
      type: '',
      ncm: '',
      origin: '',
      cest: '',
    },
  },
  services: [],
  filter: { name: '', status: 2 },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getter: {},

  mutations: {
    ...storePagination.mutations,

    SET_SERVICE(state, service) {
      state.service = service
    },

    SET_TAX(state, tax) {
      state.tax = tax
    },

    SET_SERVICES(state, services) {
      state.services = services
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    CLEAN_STATE(state) {
      const { filter, paging, services, service } = getInitialState()
      state.filter = filter
      state.services = services
      state.paging = paging
      state.service = service
    },
    CLEAN_SERVICE(state) {
      const { service } = getInitialState()
      state.service = service
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchServices({ state }) {
      console.log('fetchServices', state.filter)
      // TODO: services GET request
      // const { data } = await axios.get('/api/services', {
      //   params: {
      //     name: state.filter.name ? state.filter.name : '',
      //     pageSize: state.paging.pageSize,
      //     pageIndex: state.paging.currentPage - 1,
      //   },
      // })

      // commit('SET_SERVICES', data.results)
      // commit('SET_PAGING', {
      //   ...state.paging,
      //   rowCount: data.rowCount || 0,
      //   rowsInCurrentPage: data.rowsInCurrentPage || 0,
      // })
    },

    setService({ commit }, service) {
      commit('SET_SERVICE', service)
    },

    setTax({ commit }, tax) {
      commit('SET_TAX', tax)
    },

    setFilter({ commit }, filter) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', filter)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    cleanService({ commit }) {
      commit('CLEAN_SERVICE')
    },
  },
}
