import financial from './financial'
import periodClose from './period-close'

export default {
  namespaced: true,
  modules: {
    financial,
    periodClose,
  },
  state: {},
  mutations: {},
  actions: {},
}
