<template>
  <Slider
    v-model="context.model"
    v-bind="context.attributes"
    :input-class="context.attributes.class"
    class="custom-control-primary"
    @change="change"
  />
</template>
<script>
import Slider from '@vueform/slider/dist/slider.vue2'

export default {
  name: 'FormulateSlider',
  components: {
    Slider,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  methods: {
    change(event) {
      this.context.rootEmit('change', event)
    },
  },
}
</script>
