<template>
  <div>
    <b-input-group class="flex-nowrap">
      <b-input-group-prepend>
        <b-form-datepicker
          :id="`btn_picker-${elementId}`"
          v-model="tempValuePicker"
          v-bind="{ ...context.attributes, ...(labels[locale] || {}) }"
          :class="`${context.attributes.class || ''} custom-formulate-btn-datepicker`"
          :locale="locale"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          aria-controls="example-input"
          show-decade-nav
          button-only
          left
          @context="onDatePickerContext"
          @input="onBlur"
        />
      </b-input-group-prepend>

      <input
        :id="elementId"
        v-mask="dateMask"
        :value="tempValue"
        type="text"
        v-bind="{ disabled: context.attributes.disabled, ...inputAttr }"
        :placeholder="$t('momentFormat.datePlaceholder')"
        @input="onInput"
        @blur="onBlur"
      >
    </b-input-group>
  </div>
</template>

<script>
import { BFormDatepicker, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue'
import { locale } from '@/mixins'
import datePickerLabels from '@/libs/i18n/locales/datapicker/datepicker.json'
import moment from 'moment'
import { debounce } from 'lodash'

export default {
  components: {
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
  },
  mixins: [locale],
  props: {
    context: {
      type: Object,
      required: true,
    },
    inputAttr: {
      type: Object,
      default: () => {},
    },
    hourDefault: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      labels: datePickerLabels,
      tempValue: '',
      tempValuePicker: '', // formato obrigatório (por causa do b-form-datepicker): YYYY-MM-DD
    }
  },

  computed: {
    elementId() {
      return this.context.attributes.id || 'input-datepicker'
    },

    dateMask() {
      return [this.$t('momentFormat.dateMask')]
    },

    model() {
      return this.context.model
    },

    errors() {
      return this.context.errors
    },
  },

  watch: {
    model(val) {
      let value = val
      if (val instanceof Date) {
        value = moment(val).format()
      }

      if (value.length > 10 && value.includes('T')) {
        // eslint-disable-next-line no-param-reassign, prefer-destructuring
        value = value.split('T')[0]
      }

      const tmpFormatted = this.tempValuePicker
        ? moment(this.tempValuePicker).format('YYYY-MM-DD')
        : this.tempValuePicker
      const valueFormatted = value ? moment(value).format('YYYY-MM-DD') : value
      if (valueFormatted !== tmpFormatted) {
        if (!value) this.resetDate()
        else this.updateDate(value)
      }
    },

    errors() {},
  },

  mounted() {
    const elements = Array.from(document.querySelectorAll('.custom-formulate-btn-datepicker'))
    elements.forEach(el => {
      const childButton = el.querySelector('button')
      if (childButton) {
        childButton.setAttribute('tabindex', '-1')
      }
    })

    this.updateDate(this.model ? moment(this.model).format() : this.model)
  },

  methods: {
    // ocorre qnd altera a data (digitando ou clicando no calendário), digitando só ativa se digitar no formato YYYY-MM-DD
    onDatePickerContext(ctx) {
      this.updateDate(ctx.selectedYMD || this.tempValue)
    },

    // eslint-disable-next-line func-names
    onInput: debounce(function (event) {
      const inputValue = event.target.value
      if (inputValue.trim() === '') {
        this.resetDate(inputValue)
        return
      }
      this.updateDate(inputValue, true)
    }, 300),

    // eslint-disable-next-line func-names
    onBlur: debounce(function (event) {
      this.context.blurHandler(this.context.model)
      this.context.rootEmit('blur', this.context.model, event)
    }, 350),

    updateDate(value, isToValidate = false) {
      this.context.setErrors([])
      if (!value) return

      if (value.length > 10 && value.includes('T')) {
        // eslint-disable-next-line no-param-reassign, prefer-destructuring
        value = value.split('T')[0]
      }

      const date = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY', 'YYYY/MM/DD'], true)

      if (date.isValid()) {
        if (this.hourDefault > 0) {
          date.add(this.hourDefault, 'h')
        }

        this.tempValue = date.format(this.$t('momentFormat.date'))
        this.tempValuePicker = date.format('YYYY-MM-DD')
        this.context.model = date.format()
      } else if (isToValidate) {
        this.resetDate(value)
        this.showInvalidDateError()
      }
    },

    resetDate(tempValue) {
      this.tempValuePicker = ''
      this.context.model = null
      this.tempValue = tempValue
    },

    showInvalidDateError() {
      const dateError = 'Data inválida'
      this.context.setErrors([...this.context.errors, dateError])
    },
  },
}
</script>

<style lang="scss">
.custom-formulate-btn-datepicker {
  & > button {
    padding: 5px 10px !important;
  }
}
</style>
