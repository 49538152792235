<template>
  <div>
    <vue-numeric
      v-model="context.model"
      v-bind="context.attributes"
      :input-class="context.attributes.class"
      :data-type="context.type"
      :decimal-separator="decimalSeparator"
      :thousand-separator="thousandSeparator"
      :separator="separator"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>
<script>
import VueNumeric from 'vue-numeric'
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FormulateTextNumber',
  components: {
    VueNumeric,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
    thousandSeparator: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  data() { return {} },

  computed: {
    ...mapState('app/simpleKeyboard', ['currentInputValue']),

    model() {
      return this.context.model
    },
    decimalPlaces() {
      return this.context.attributes.decimalPlaces || 0
    },
    decimalSeparator() {
      return (
        this.context.attributes['decimal-separator'] ||
        this.context.attributes.decimalSeparator ||
        ','
      )
    },
    separator() {
      return this.context.attributes.separator || this.context.attributes.thousandSeparator || '.'
    },
    precision() {
      return this.context.attributes?.precision || 0
    }
  },

  watch: {
    model(val) {
      if (val === '' || Number.isNaN(Number(val))) {
        this.context.model = undefined
      } else {
        this.context.model = val
      }
    },

    currentInputValue(newValue) {
      if (this.$el.contains(document.activeElement)) {
        let result = Number(this.getNumber(newValue))
        if (Number.isNaN(result)) {
          result = undefined
        }

        this.$emit('oskInput', result)
        this.context.model = result
      }
    },
  },

  methods: {
    ...mapActions('app/simpleKeyboard', ['showKeyboard', 'hideKeyboard']),

    getNumber(value) {
      if (!value) return value

      const valueSplit = value.split(this.decimalSeparator)
      const integerValue = valueSplit[0].replace(/\D/g, '')
      return `${integerValue}.${valueSplit[1] || '00'}`
    },

    // eslint-disable-next-line func-names
    onBlur: _.debounce(async function (event) {
      this.context.blurHandler(event)

      this.hideKeyboard()

      this.$emit('blur', this.getNumber(event.target.value), event)
    }, 50),

    // eslint-disable-next-line func-names
    onFocus: _.debounce(async function (event) {
      document.getElementById(event.target.id).select()

      this.showKeyboard({
        el: event.target,
        layoutType: this.precision > 0 ? 'numericDecimal' : 'numeric'
      })

      this.$emit('focus', this.getNumber(event.target.value), event)
    }, 50),
  },
}
</script>
