const purchaseOrderDomains = {
  data() {
    return {
      PurchaseOrderStatusEnum: {
        IDENTIFIED: 'Identified',
        DRAFT: 'Draft',
        COMPLETED: 'Completed',
      },
      PurchaseOrderTypeEnum: {
        XML: 'Xml',
        MANUAL: 'Manual',
        SEFAZ: 'Sefaz',
      },
    }
  },
  methods: {
    mxGetAverageCostDiffAlertMsg(errorMessage) {
      const itens = JSON.parse(errorMessage || '[]')
      const formatMessage = itens
        .map(
          item =>
            `<br><br>
            <b>#${item?.skuId} - ${item?.name} </b> <br>
            Custo Atual: ${this.$options.filters.currency(item.averageCost || 0)}
            | Preço Unit: ${this.$options.filters.currency(item.unitValue || 0)}`
        )
        .join('')

      const isPlural = itens.length > 1
      const htmlMessage = `
        <b>ATENÇÃO:</b> O${isPlural ? 's' : ''} produto${isPlural ? 's' : ''} abaixo ${
        isPlural ? 'estão' : 'está'
      } com o valor unitário com diferença de mais de 5% em relação ao preço de custo atual:
        <br/> ${formatMessage}
      `
      return `<div style="text-align: left;"> ${htmlMessage} </div>`
    },
  },
}

export default purchaseOrderDomains
