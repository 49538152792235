const genderTypes = {
  methods: {
    genderTypes() {
      return [
        { value: 'Male', label: this.$t('Masculino') },
        { value: 'Female', label: this.$t('Feminino') },
      ]
    },
  },
}

export default genderTypes
