/* eslint-disable import/prefer-default-export */

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function transformInToFormObject(objectData) {
  const formData = new FormData()
  const keys = Object.keys(objectData)
  keys.forEach(key => {
    if (Array.isArray(objectData[key])) {
      objectData[key].forEach((obj, index) => {
        const keyList = Object.keys(obj)
        keyList.forEach(keyItem => {
          const keyName = [key, '[', index, ']', '.', keyItem].join('')
          formData.append(keyName, obj[keyItem])
        })
      })
    } else if (objectData[key] instanceof File) {
      formData.append(key, objectData[key])
    } else if (typeof objectData[key] === 'object') {
      const innerKeys = Object.keys(objectData[key])
      innerKeys.forEach(innerKey => {
        formData.append(`${key}.${innerKey}`, objectData[key][innerKey])
      })
    } else {
      formData.append(key, objectData[key])
    }
  })
  return formData
}

export function isJson(item) {
  let value = typeof item !== 'string' ? JSON.stringify(item) : item
  try {
    value = JSON.parse(value)
  } catch (e) {
    return false
  }

  return typeof value === 'object' && value !== null
}
