import accountsPayableCloses from './accounts-payable-closes'

export default {
  namespaced: true,
  modules: {
    accountsPayableCloses,
  },
  state: {},
  mutations: {},
  actions: {},
}
