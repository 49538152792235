<template>
  <div>
    <div
      v-if="isBadge && value != '-'"
      style="max-width: 120px"
    >
      <e-status-badge
        :id="context.attributes.id"
        :status="value"
        :options="context.options"
      />
    </div>
    <b
      v-else-if="!routerName && !routerTo"
      :id="context.attributes.id"
      :title="title"
    >
      {{ value }}
    </b>
    <e-router-link
      v-else
      :to="
        routerTo || {
          name: routerName,
          params: routerParams,
        }
      "
      :title="routerTitle"
      :value="value"
    />
    <b-icon
      v-for="iconItem of icons"
      :key="iconItem.eventName"
      :icon="iconItem.icon"
      :variant="iconItem.variant || 'primary'"
      font-scale="1"
      :class="`cursor-pointer ml-1 ${iconItem.clazz}`"
      :title="iconItem.title"
      @click="$emit(iconItem.eventName)"
    />
  </div>
</template>
<script>
import ERouterLink from '../../ERouterLink.vue'
import EStatusBadge from '../../EStatusBadge.vue'

export default {
  name: 'FormulateLabel',
  components: { ERouterLink, EStatusBadge },
  props: {
    context: {
      type: Object,
      required: true,
    },
    // options está dentro do context
    // options: {
    //   type: Array,
    //   required: false,
    //   default: () => [],
    // },
    isBadge: {
      type: Boolean,
      default: false,
    },
    /// Array of objects with the follow structure:
    /// {
    ///   eventName: 'event_name', // Required, eg: 'edit'. It will be generate an event with this name whenever this icon is clicked
    ///   icon: 'icon_name', // Required
    ///   clazz: 'icon_classes', // Optional
    ///   variant: 'icon_variant', // Optional, default is 'primary'
    ///   title: 'icon_title' // Optional
    /// }
    icons: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    value() {
      let value = this.context.value || this.context?.model || '-'

      if (
        this.isBadge &&
        (typeof this.context.value === 'boolean' || typeof this.context?.model === 'boolean')
      ) {
        value = this.context.value || this.context?.model || false
      }

      this.$emit('input', value)
      if (this.filter) {
        if (this.filterParams.length) {
          return this.$options.filters[this.filter](value, ...this.filterParams)
        }
        return this.$options.filters[this.filter](value) || '-'
      }

      if (!this.isBadge && this.context?.options?.length > 0) {
        const foundOption = this.context?.options?.find(
          opt => opt.value.toString() === value.toString()
        )

        if (foundOption) return foundOption.label || '-'
      }

      if (Array.isArray(value)) {
        return value.join(', ')
      }

      return value
    },
    filter() {
      return this.context?.attributes?.filter
    },
    filterParams() {
      return (
        this.context?.attributes?.filterParams || this.context?.attributes['filter-params'] || []
      )
    },
    routerTo() {
      return this.context?.attributes?.routerTo || this.context?.attributes['router-to']
    },
    routerName() {
      return this.context?.attributes?.routerName || this.context?.attributes['router-name']
    },
    routerParams() {
      return this.context?.attributes?.routerParams || this.context?.attributes['router-params']
    },
    routerTitle() {
      return this.context?.attributes?.routerTitle || this.context?.attributes['router-title']
    },
  },
}
</script>
