<template>
  <component
    :is="component"
    ref="main-card"
    no-actions
    :title="$t('Endereço')"
    :show-loading="loading"
  >
    <FormulateForm ref="form">
      <b-row>
        <b-col :md="readOnly ? '2' : '3'">
          <FormulateInput
            v-if="!readOnly"
            id="address-zipcode"
            v-model="formValues.zipcode"
            v-mask="['##.###-###']"
            type="text"
            :label="$t('CEP')"
            :placeholder="$t('CEP')"
            :validation="isRequired"
            :class="isRequired"
            @blur="findZipcode"
            @input="handleInput"
          />
          <FormulateInput
            v-else
            id="address-zipcode"
            v-model="formValues.zipcode"
            type="label"
            :label="$t('CEP')"
            filter="zipcode"
          />
        </b-col>
        <b-col :md="readOnly ? '4' : '6'">
          <FormulateInput
            id="address-publicPlace"
            v-model="formValues.publicPlace"
            :type="readOnly ? 'label' : 'text'"
            :label="$t('Logradouro')"
            :placeholder="$t('Logradouro')"
            :validation="[[isRequired], ['min', 2, 'length'], ['max', 60, 'length']]"
            :class="isRequired"
            @blur="findInMap"
            @input="handleInput"
          />
        </b-col>
        <b-col :md="readOnly ? '2' : '3'">
          <FormulateInput
            id="address-number"
            v-model="formValues.number"
            :type="readOnly ? 'label' : 'text'"
            :label="$t('Número')"
            :placeholder="$t('Número')"
            :validation="[[isRequired], ['min', 1, 'length'], ['max', 60, 'length']]"
            :class="isRequired"
            @blur="findInMap"
            @input="handleInput"
          />
        </b-col>
        <b-col :md="readOnly ? '3' : '3'">
          <FormulateInput
            id="address-complement"
            v-model="formValues.complement"
            :type="readOnly ? 'label' : 'text'"
            :label="$t('Complemento')"
            :placeholder="$t('Complemento')"
            :validation="[
              ['min', 0, 'length'],
              ['max', 60, 'length'],
            ]"
            @input="handleInput"
          />
        </b-col>
        <b-col :md="readOnly ? '4' : '5'">
          <FormulateInput
            id="address-neighborhood"
            v-model="formValues.neighborhood"
            :type="readOnly ? 'label' : 'text'"
            :label="$t('Bairro')"
            :placeholder="$t('Bairro')"
            :validation="[[isRequired], ['min', 2, 'length'], ['max', 60, 'length']]"
            :class="isRequired"
            @input="handleInput"
          />
        </b-col>
        <b-col :md="readOnly ? '2' : '4'">
          <FormulateInput
            id="address-province"
            v-model="formValues.province"
            :type="readOnly ? 'label' : 'vue-select'"
            :label="$t('Estado')"
            :placeholder="$t('Selecione')"
            :validation="isRequired"
            :class="isRequired"
            :options="provinces()"
            @blur="findInMap"
            @input="handleInput"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="address-city"
            v-model="formValues.city"
            :type="readOnly ? 'label' : 'text'"
            :label="$t('Cidade')"
            :placeholder="$t('Cidade')"
            :validation="[[isRequired], ['min', 2, 'length'], ['max', 60, 'length']]"
            :class="isRequired"
            @blur="findInMap"
            @input="handleInput"
          />
        </b-col>
        <b-col
          v-if="showLatLng"
          md="4"
        >
          <FormulateInput
            id="address-lat"
            v-model="formValues.lat"
            type="number"
            disabled
            :label="$t('Latitude')"
            :placeholder="$t('Latitude')"
            :validation="isRequired"
            :class="isRequired"
            @input="handleInput"
          />
        </b-col>
        <b-col
          v-if="showLatLng"
          md="4"
        >
          <FormulateInput
            id="address-lng"
            v-model="formValues.lng"
            type="number"
            disabled
            :label="$t('Longitude')"
            :placeholder="$t('Longitude')"
            :validation="isRequired"
            :class="isRequired"
            @input="handleInput"
          />
        </b-col>
      </b-row>
      <b-row v-if="showMap">
        <b-col md="12">
          <GmapMap
            ref="mapRef"
            :center="{ lat: -21.8216113, lng: -48.1721764 }"
            :zoom="15"
            map-type-id="terrain"
            style="width: 100%; height: 500px"
          >
            <GmapMarker
              ref="marker"
              :position="marker"
              :clickable="true"
              :draggable="true"
              @dragend="pointChanged"
            />
          </GmapMap>
        </b-col>
      </b-row>
    </FormulateForm>
  </component>
</template>

<script>
import axios from 'axios'
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { masks, province, stringUtils } from '@/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
  },
  mixins: [masks, province, stringUtils],
  props: {
    value: {
      type: Object,
      require: false,
      default: undefined,
    },
    showLoading: {
      type: Boolean,
      require: false,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    showMap: {
      type: Boolean,
      default: false,
    },
    showLatLng: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      component: this.readOnly ? 'div' : BCardActions,
      formValues: {
        zipcode: '',
        publicPlace: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        cityCode: 0,
        province: '',
        provinceCode: 0,
        lat: null,
        lng: null,
      },
    }
  },
  computed: {
    isValid() {
      return this.$refs.form.isValid
    },
    isRequired() {
      return this.required && !this.readOnly ? 'required' : ''
    },
    marker() {
      return {
        lat: this.formValues.lat || -21.8216113,
        lng: this.formValues.lng || -48.1721764,
      }
    },
  },
  watch: {
    value(val) {
      Object.assign(this.formValues, val)
      if (this.showMap) {
        this.setMapCenter({
          lat: this.formValues.lat || -21.8216113,
          lng: this.formValues.lng || -48.1721764,
        })
      }
    },
    formValues(val) {
      Object.assign(this.value, val)
    },
    showLoading(val) {
      this.loading = val
    },
  },
  mounted() {
    if (this.value) {
      Object.assign(this.formValues, this.value)
    }
  },
  methods: {
    print(event) {
      console.log(event)
    },
    handleInput() {
      this.$emit('input', this.formValues)
    },
    validate() {
      this.$refs.form.showErrors()
      return this.$refs.form.isValid
    },
    findZipcode() {
      if (!this.formValues.zipcode || this.formValues.zipcode.length < 10) return

      this.loading = true
      axios
        .get(`https://viacep.com.br/ws/${this.getOnlyNumbers(this.formValues.zipcode)}/json/`)
        .then(response => {
          this.formValues.publicPlace = response.data.logradouro
          this.formValues.neighborhood = response.data.bairro
          this.formValues.city = response.data.localidade
          this.formValues.cityCode = Number(response.data.ibge || '0')
          this.formValues.provinceCode = Number(response.data.ibge?.substring(0, 2) || '0')
          this.formValues.province = response.data.uf
        })
        .catch(() => {
          this.showError({ message: this.$t('Não foi possível consultar o cep') })
        })
        .finally(() => {
          this.loading = false
        })
      this.$emit('after-find-zipcode')
    },
    findInMap() {
      if (!this.showMap) return

      if (
        this.formValues.publicPlace &&
        this.formValues.number &&
        this.formValues.city &&
        this.formValues.province
      ) {
        // const searchAddress = `${this.formValues.publicPlace}, ${this.formValues.number}, ${this.formValues.city} - ${this.formValues.province}`
        const address = {
          address_line_1: `${this.formValues.publicPlace}, ${this.formValues.number}`,
          // address_line_2: '',
          city: this.formValues.city,
          state: this.formValues.province,
          zip_code: this.formValues.zipCode,
          country: 'Brazil',
        }
        const resultsMap = this.$refs.mapRef.$mapObject
        const marker = this.$refs.marker.$markerObject
        this.$geocoder.send(address, result => {
          if (result.status === 'OK') {
            const { location } = result.results[0].geometry
            this.formValues.lat = location.lat
            this.formValues.lng = location.lng
            resultsMap.setCenter(location)
            resultsMap.setZoom(15)
            marker.setPosition(location)
          } else {
            this.showError({
              message: this.$t('Desculpe, mas não foi possível encontrar este endereço no mapa'),
            })
          }
        })
      }
    },
    pointChanged(event) {
      const { latLng } = event
      this.formValues.lat = Math.round(latLng.lat() * 10000000) / 10000000
      this.formValues.lng = Math.round(latLng.lng() * 10000000) / 10000000
      this.setMapCenter()
      this.setMapCenter({
        lat: this.formValues.lat,
        lng: this.formValues.lng,
      })
    },
    setMapCenter(location) {
      if (this.$refs.mapRef.$mapObject) {
        this.$refs.mapRef.$mapObject.setCenter(location)
      }
    },
  },
}
</script>
