import managementAccount from './management-account'
import checkingAccount from './checking-account'
import checkingAccountEntries from './checking-account-entry'
import paymentType from './payment-type'
import card from './card'
import checkingAccountTransfer from './checking-account-transfer'
import checkingAccountTransferDepositBulkMaintain from './checking-account-transfer-deposit-bulk-maintain'

export default {
  namespaced: true,
  modules: {
    managementAccount,
    checkingAccount,
    checkingAccountEntries,
    checkingAccountTransfer,
    checkingAccountTransferDepositBulkMaintain,
    paymentType,
    card,
  },
  state: {},
  mutations: {},
  actions: {},
}
