import { pagination, sorting } from '@/mixins'

const storePagination = {
  state: () => ({
    paging: pagination.data().paging,
    sorting: sorting.data().sorting,
  }),

  mutations: {
    SET_PAGING(state, paging) {
      state.paging = paging
    },
    SET_CURRENT_PAGE(state, currentPage) {
      state.paging.currentPage = currentPage
    },
    SET_PAGE_SIZE(state, pageSize) {
      state.paging.pageSize = pageSize
    },
    SET_SORT_BY(state, sortBy) {
      state.sorting.sortBy = sortBy
    },
    SET_SORT_DESC(state, sortDesc) {
      state.sorting.sortDesc = sortDesc
    },

    SET_ALL_ROWS_LOADED(state, allRowsLoaded) {
      state.paging.allRowsLoaded = allRowsLoaded
    },
  },

  actions: {
    setCurrentPage({ commit }, currentPage) {
      commit('SET_CURRENT_PAGE', currentPage)
    },

    setPageSize({ commit }, pageSize) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_PAGE_SIZE', pageSize)
    },

    setSortBy({ commit }, sortBy) {
      commit('SET_SORT_BY', sortBy)
    },

    setSortDesc({ commit }, sortDesc) {
      commit('SET_SORT_DESC', sortDesc)
    },
  },
}

export default storePagination
