export default [
  {
    path: '/solicitacoes',
    name: 'service-request-list',
    component: () => import('@/views/pages/requests/ServiceRequestList.vue'),
    meta: {
      pageTitle: 'Solicitações',
      navActiveLink: 'service-request-list',
      resource: 'ServiceRequest',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Solicitações',
          active: true,
        }
      ],
    },
  },
  {
    path: '/solicitacoes/adicionar',
    name: 'service-request-create',
    component: () => import('@/views/pages/requests/ServiceRequestCreate.vue'),
    meta: {
      pageTitle: 'Nova Solicitação',
      navActiveLink: 'service-request-list',
      resource: 'ServiceRequest',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Solicitações',
        },
        {
          text: 'Nova Solicitação',
          active: true,
        },
      ],
    },
  },
  {
    path: '/solicitacoes/:id',
    name: 'service-request-details',
    component: () => import('@/views/pages/requests/ServiceRequestDetails.vue'),
    meta: {
      pageTitle: 'Detalhes da Solicitação',
      navActiveLink: 'service-request-list',
      resource: 'ServiceRequest',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Solicitações',
        },
        {
          text: 'Detalhes da Solicitação',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fluxos-aprovacao',
    name: 'approval-flow-list',
    component: () =>
      import('@/views/pages/security/approval-flow-transaction/ApprovalFlowTransactionList.vue'),
    meta: {
      pageTitle: 'Fluxos de Aprovação',
      resource: 'ApprovalFlow',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Fluxos de Aprovação',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fluxos-aprovacao/transacao/:id',
    name: 'approval-flow-details',
    component: () =>
      import('@/views/pages/security/approval-flow-transaction/ApprovalFlowTransactionDetails.vue'),
    meta: {
      pageTitle: 'Detalhe do Fluxo',
      navActiveLink: 'approval-flow-list',
      resource: 'ApprovalFlow',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Fluxos de Aprovação',
          to: '/fluxos-aprovacao',
        },
        {
          text: 'Detalhes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/grupos-aprovacao',
    name: 'approval-group-list',
    component: () => import('@/views/pages/security/approval-group/ApprovalGroupList.vue'),
    meta: {
      pageTitle: 'Grupos de aprovação',
      navActiveLink: 'approval-group-list',
      resource: 'ApprovalGroup',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Grupos de Aprovação',
          active: true,
        },
      ],
    },
  },
]
