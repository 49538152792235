const translate = {
  methods: {
    /**
     *
     * @param {string} name name of table
     * @param {string} type female | male
     * @returns empty table translated
     */
    getEmptyTableMessage(name, type = 'male') {
      return this.$t(`tables.empty_message.${type}`, { name: name.toLowerCase() })
    },

    /**
     *
     * @param {string} name name of table
     * @param {string} type female | male
     * @returns empty table translated
     */
    getEmptyTableAddMessage(name, type = 'male') {
      return this.$t(`tables.empty_message_add.${type}`, { name: name.toLowerCase() })
    },
  },
}

export default translate
