import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'

const paymentTypes = {
  data() {
    return {
      payBoxActionsEnum: {
        TEF: 'TEF',
        CASH_UP: 'cashUp',
        REINFORCEMENT: 'reinforcement',
      },
      paymentTypeEnum: {
        CASH: 'Cash',
        CHECK: 'Check',
        CREDIT_CARD: 'CreditCard',
        DEBIT_CARD: 'DebitCard',
        STORE_CREDIT: 'StoreCredit',
        FOOD_TICKET: 'FoodTicket',
        MEAL_TICKET: 'MealTicket',
        GIFT_TICKET: 'GiftTicket',
        FUEL_TICKET: 'FuelTicket',
        BANK_SLIP: 'BankSlip',
        BANK_DEPOSIT: 'BankDeposit',
        PIX: 'Pix',
        BANK_TRANSFERENCE: 'BankTransference',
        PRODUCT_BONUS: 'ProductBonus',
        WITHOUT_PAYMENT: 'WithoutPayment',
        OTHER: 'Other',
      },
      paymentMethodInterfaceEnum: {
        POS: 'Pos',
        TEF: 'Tef',
        MANUAL: 'Manual',
        ONLINE: 'Online',
      },
    }
  },

  computed: {
    ...mapState('app', { mxStPaymentMethods: 'paymentMethods' }),
    ...mapGetters('app', ['paymentMethodsOptions']),

    payBoxActionsDesc() {
      return {
        [this.payBoxActionsEnum.CASH_UP]: this.$t('Sangria'),
        [this.payBoxActionsEnum.REINFORCEMENT]: this.$t('Reforço'),
      }
    },
    paymentTypeLabel() {
      return {
        Cash: this.$t('Dinheiro'),
        Check: this.$t('Cheque'),
        CreditCard: this.$t('Cartão de Crédito'),
        DebitCard: this.$t('Cartão de Débito'),
        StoreCredit: this.$t('Crédito Loja'),
        FoodTicket: this.$t('Vale Alimentação'),
        MealTicket: this.$t('Vale Refeição'),
        GiftTicket: this.$t('Vale Presente'),
        FuelTicket: this.$t('Vale Combustível'),
        BankSlip: this.$t('Boleto Bancário'),
        BankDeposit: this.$t('Depósito Bancário'),
        Pix: this.$t('Pix'),
        BankTransference: this.$t('Transferência Bancária'),
        ProductBonus: this.$t('Programa de fidelidade, Cashback, Crédito Virtual'),
        WithoutPayment: this.$t('Sem Pagamento'),
        Other: this.$t('Outros'),
      }
    },

    paymentMethodInterfaceLabel() {
      return {
        [this.paymentMethodInterfaceEnum.POS]: this.$t('POS'),
        [this.paymentMethodInterfaceEnum.TEF]: this.$t('TEF'),
        [this.paymentMethodInterfaceEnum.MANUAL]: this.$t('Manual'),
        [this.paymentMethodInterfaceEnum.ONLINE]: this.$t('Online'),
      }
    },
  },

  methods: {
    paymentTypes() {
      return [
        { value: 'bank_slip', label: this.$t('Boleto') },
        { value: 'debit', label: this.$t('Débito') },
        { value: 'credit', label: this.$t('Crédito') },
        { value: 'cash', label: this.$t('Dinheiro') },
        { value: 'pix', label: this.$t('Pix') },
      ]
    },
    paymentTypeOptions() {
      return [
        { value: 'BankSlip', label: this.paymentTypeLabel.BankSlip },
        { value: 'Cash', label: this.paymentTypeLabel.Cash },
        { value: 'CreditCard', label: this.paymentTypeLabel.CreditCard },
        { value: 'DebitCard', label: this.paymentTypeLabel.DebitCard },
        { value: 'Check', label: this.paymentTypeLabel.Check },
        { value: 'StoreCredit', label: this.paymentTypeLabel.StoreCredit },
        { value: 'BankDeposit', label: this.paymentTypeLabel.BankDeposit },
        { value: 'Other', label: this.paymentTypeLabel.Other },
        { value: 'Pix', label: this.paymentTypeLabel.Pix },
        { value: 'FoodTicket', label: this.paymentTypeLabel.FoodTicket },
        { value: 'MealTicket', label: this.paymentTypeLabel.MealTicket },
        { value: 'FuelTicket', label: this.paymentTypeLabel.FuelTicket },
        { value: 'WithoutPayment', label: this.paymentTypeLabel.WithoutPayment },
      ]
    },

    isCashPaymentMethod(paymentMethodId) {
      const paymentFound = this.paymentMethodsOptions.find(opt => paymentMethodId === opt.value)
      if (!paymentFound) return false
      return paymentFound.method === this.paymentTypeEnum.CASH
    },

    /**
     * O enum 'other' sempre irá retornar o paymentMethod "Outros"
     * @param {string} method is paymentTypeEnum
     */
    getPaymentMethodByMethod(method) {
      const payment = _.sortBy(this.mxStPaymentMethods || [], ['id'])
        .reverse()
        .find(opt => opt.method === method)

      if (!payment) return null

      return payment
    },
  },
}

export default paymentTypes
