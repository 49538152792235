<template>
  <div>
    <b-form-checkbox
      v-model="context.model"
      v-bind="context.attributes"
      :input-class="context.attributes.class"
      :data-type="context.type"
      @blur="context.blurHandler"
      @input="onInput"
      @change="change"
    />
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'FormulateECheckbox',
  components: {
    BFormCheckbox,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    // Diferenças dos eventos: https://bootstrap-vue.org/docs/components/form-checkbox#comp-ref-b-form-checkbox-events
    // @change ocorre nas interações do usuário
    change(event) {
      this.context.model = event
      this.context.rootEmit('change', event)
    },
    // @input ocorre nas alterações via código
    onInput(val) {
      // necessário para que o valor do v-model não se transforme em string
      this.context.model = Boolean(val)
      this.context.rootEmit('input', Boolean(val))
    },
  },
}
</script>

<style lang="scss" scoped>
.container-checkbox {
  display: inline-block;
  cursor: pointer;

  .wrapper-checkbox {
    display: flex;
    background-color: var(--bg-color);
    padding: 2px 15px;
    border-radius: 5px;
    align-items: center;

    label {
      margin-bottom: 0px;
      cursor: pointer;
      font-weight: 900;
      color: var(--text-color);
    }

    input {
      margin-right: 5px;
      padding: 0.5em;
    }
  }
}
</style>
