var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-badge",
    {
      staticClass: "w-100",
      attrs: { variant: _vm.statusLabel[_vm.status].classStyle },
    },
    [_vm._v(" " + _vm._s(_vm.statusLabel[_vm.status].text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }