import axiosInstance from '@/libs/axios'
import { v4 as uuidV4 } from 'uuid'

const getInitialState = () => ({
  person: {
    id: null,
    personType: 'Juridical',
    telephones: [{ id: null, type: 'Mobile', number: '' }],
    email: null,
    emailInvoice: null,
    siteUrl: null,
    observation: '',
    document: '', // represent CNPJ or CPF
    name: '', // represent name or TradingName

    rg: {
      document: '',
      issuer: '',
      issuerState: '',
    },
    gender: '',
    birthdate: null,

    companyName: '',
    stateRegistrationType: '',
    stateRegistration: '',
    municipalInscription: '',
  },
  attributes: {
    addresses: [],
    contacts: [],
    bankAccounts: [],
    pixes: [],
    telephonesApi: [],
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getAddresses(state) {
      return state.attributes.addresses.filter(ad => !ad.isDeleted)
    },
    getContacts(state) {
      return state.attributes.contacts.filter(ct => !ct.isDeleted)
    },
    getBankAccounts(state) {
      return state.attributes.bankAccounts.filter(ba => !ba.isDeleted)
    },
    getPixes(state) {
      return state.attributes.pixes.filter(pix => !pix.isDeleted)
    },
  },

  mutations: {
    SET_PERSON(state, payload) {
      state.person = { ...payload }
    },
    SET_ATTRIBUTE_ITEM(state, { itemName, payload }) {
      state.attributes[itemName] = payload || []
    },

    CLEAN_STATE(state) {
      const { person, attributes } = getInitialState()
      state.person = person
      state.attributes = attributes
    },

    CLEAN_PERSON_TYPE_DATA(state) {
      const {
        name,
        document,
        rg,
        gender,
        birthdate,
        companyName,
        stateRegistrationType,
        stateRegistration,
        municipalInscription,
      } = getInitialState().person
      state.person = {
        ...state.person,
        name,
        document,
        rg,
        gender,
        birthdate,
        companyName,
        stateRegistrationType,
        stateRegistration,
        municipalInscription,
      }
    },
  },

  actions: {
    async fetchSupplierById({ commit }, { supplierId, isReadOnly }) {
      const { data } = isReadOnly
        ? await axiosInstance.get(`/api/suppliers/${supplierId}/read-only`, {
            params: {
              onlyActiveAddress: false,
            },
          })
        : await axiosInstance.get(`/api/suppliers/${supplierId}`, {
            params: {
              onlyActiveAddress: false,
            },
          })
      const {
        addresses,
        bankAccounts,
        contacts,
        pixes,
        id,
        personType,
        telephones,
        email,
        emailInvoice,
        siteUrl,
        observation,
        document,
        name,
        rg,
        gender,
        birthdate,
        companyName,
        stateRegistrationType,
        stateRegistration,
        municipalInscription,
      } = data

      commit('SET_ATTRIBUTE_ITEM', { itemName: 'addresses', payload: addresses })
      commit('SET_ATTRIBUTE_ITEM', { itemName: 'contacts', payload: contacts })
      commit('SET_ATTRIBUTE_ITEM', { itemName: 'bankAccounts', payload: bankAccounts })
      commit('SET_ATTRIBUTE_ITEM', { itemName: 'pixes', payload: pixes })
      commit('SET_ATTRIBUTE_ITEM', { itemName: 'telephonesApi', payload: telephones })

      commit('SET_PERSON', {
        id,
        personType,
        telephones,
        email,
        emailInvoice,
        siteUrl,
        observation,
        document,
        name,
        rg: rg ?? getInitialState().person.rg,
        gender,
        birthdate,
        companyName,
        stateRegistrationType,
        stateRegistration,
        municipalInscription,
      })
    },

    addAttributeItem({ commit, state }, { itemName, formData }) {
      const item = state.attributes[itemName]
      item.push({ ...formData, localId: uuidV4() })
      commit('SET_ATTRIBUTE_ITEM', { itemName, payload: item })
    },
    updateAttributeItem({ commit, state }, { itemName, formData }) {
      const indexFound = state.attributes[itemName].findIndex(
        v =>
          (formData.id && v.id === formData.id) ||
          (formData.localId && v.localId === formData.localId)
      )

      const item = state.attributes[itemName]
      item.splice(indexFound, 1, formData)
      commit('SET_ATTRIBUTE_ITEM', { itemName, payload: item })
    },
    removeAttributeItem({ commit, state }, { itemName, id }) {
      if (!id) throw new Error('Item sem id')
      const indexFound = state.attributes[itemName].findIndex(
        v => v.id === id || v.localId === id
      )

      if (indexFound < 0) throw new Error('Item não encontrado')

      const item = state.attributes[itemName]
      if (item[indexFound]?.id) {
        item[indexFound].isDeleted = true
      } else if (item[indexFound]?.localId) {
        item.splice(indexFound, 1)
      }
      commit('SET_ATTRIBUTE_ITEM', { itemName, payload: item })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanPersonTypeData({ commit }) {
      commit('CLEAN_PERSON_TYPE_DATA')
    },
  },
}
