var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cc-selector-2" },
    [
      _vm._l(_vm.comboOptions, function (option) {
        return [
          _c(
            "div",
            {
              key: _vm.elementId + "-" + option.value,
              staticClass: "radio-input-container",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.context.model,
                    expression: "context.model",
                  },
                ],
                attrs: {
                  id: _vm.elementId + "-" + option.value,
                  type: "radio",
                  name: _vm.elementId,
                },
                domProps: {
                  value: option.value,
                  checked: _vm._q(_vm.context.model, option.value),
                },
                on: {
                  change: [
                    function ($event) {
                      return _vm.$set(_vm.context, "model", option.value)
                    },
                    function (event) {
                      return _vm.onInput(event, option.value)
                    },
                  ],
                },
              }),
              _c(
                "label",
                {
                  staticClass: "drinkcard-cc",
                  attrs: { for: _vm.elementId + "-" + option.value },
                },
                [
                  _c("img", {
                    staticClass: "w-75",
                    attrs: { src: option.icon, alt: option.label },
                  }),
                ]
              ),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }