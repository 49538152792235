<template>
  <e-status-badge
    :status="context.model === true"
    :type="badgeType"
    :true-label="trueLabel"
    :false-label="falseLabel"
  />
</template>
<script>
import { EStatusBadge } from '@/views/components'

export default {
  components: { EStatusBadge },
  props: {
    context: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    badgeType() {
      return (
        this.context?.attributes['badge-type'] ||
        this.context?.attributes?.badgeType ||
        'activeInactive'
      )
    },
    trueLabel() {
      return (
        this.context?.attributes['true-label'] || this.context?.attributes?.trueLabel || undefined
      )
    },
    falseLabel() {
      return (
        this.context?.attributes['false-label'] || this.context?.attributes?.falseLabel || undefined
      )
    },
  },
}
</script>
