const TIMEOUT_DEFAULT = 1000

const toMiliseconds = ({ hour = 0, min = 0, sec = 0 }) => (hour * 60 * 60 + min * 60 + sec) * 1000

function promiseOrTimeout(promise, milliseconds = TIMEOUT_DEFAULT) {
  // Esta promise fará a rejeição da Promise dentro do tempo definido em milliseconds
  let timer

  // eslint-disable-next-line no-param-reassign
  milliseconds = milliseconds || TIMEOUT_DEFAULT

  const timeout = new Promise((resolve, reject) => {
    timer = setTimeout(() => {
      console.error(`Promise timeout reached (limit: ${milliseconds} ms)`)
      reject(new Error('Tempo limite foi atingido, por favor tente reiniciar o PDV (app).'))
    }, milliseconds)
  })

  // Faz uma corrida entre a Promise que desejamos resolver com a promise com o timeout programado, iremos capturar a que acontecer primeiro
  return Promise.race([timeout, promise]).finally(() => clearTimeout(timer))
}

export { promiseOrTimeout as default, promiseOrTimeout, toMiliseconds }
