export default {
  title: 'Compras',
  icon: 'bucket',
  iconPack: 'bootstrap',
  children: [
    // {
    //   title: 'Orçamentos',
    //   route: 'purchase-estimate-list',
    //   resource: 'Auth', // 'PurchaseEstimate'
    //   action: 'read', // 'Read'
    // },
    {
      title: 'Importar Nota Fiscal',
      route: 'purchase-order-xml-import',
      resource: 'PurchaseOrder',
      action: 'Create', // 'Read'
    },
    {
      title: 'Pedidos',
      route: 'purchase-order-list',
      resource: 'PurchaseOrder', // 'PurchaseOrder'
      action: 'Read', // 'Read'
    },
    // {
    //   title: 'Compras',
    //   route: 'purchase-list',
    //   resource: 'Auth', // 'Purchase'
    //   action: 'read', // 'Read'
    // },
    {
      title: 'Fornecedores',
      route: 'supplier-list',
      resource: 'Supplier', // 'Supplier'
      action: 'Read', // 'Read'
    },
    // {
    //   title: 'Devoluções',
    //   route: 'purchase-returned-list',
    //   resource: 'Auth', // 'PurchaseReturn'
    //   action: 'read', // 'Read'
    // },
    // {
    //   title: 'Compras em Aberto',
    //   route: 'purchase-opened-list',
    //   resource: 'Auth', // 'Purchase'
    //   action: 'read', // 'Read'
    // },
  ],
}
