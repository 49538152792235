<template>
  <e-router-link
    :value="productName"
    :header="`Cód.: ${skuId} | Ean: ${ean}`"
    :links="links"
  />
</template>

<script>
import { ERouterLink } from '@/views/components'

export default {
  components: {
    ERouterLink,
  },
  props: {
    skuId: {
      type: Number,
      default: null,
    },
    productId: {
      type: Number,
      default: null,
    },
    productName: {
      type: String,
      default: null,
    },
    ean: {
      type: String,
      default: null,
    },
    isKit: {
      type: Boolean,
      default: false,
    },
    showStockPositionLink: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    links() {
      return [
        {
          to: {
            name: 'product-view',
            params: { id: this.productId },
          },
          title: this.$t('Detalhes do Produto'),
          hide: this.isKit,
        },
        {
          to: {
            name: 'stock-position-list',
            query: { skuId: this.skuId },
          },
          title: this.$t('Posição do Estoque'),
          hide: this.isKit || !this.showStockPositionLink,
        },
        {
          to: {
            name: 'product-kit-maintain',
            params: { id: this.skuId },
          },
          title: this.$t('Detalhes do Kit'),
          hide: !this.isKit,
        },
      ].filter(l => !l.hide)
    },
  },
}
</script>

<style></style>
