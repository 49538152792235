const discountTypes = {
  computed: {
    discountTypesEnum() {
      return {
        PERCENTAGE: 'Percentage',
        VALUE: 'Value',
      }
    },

    discountTypesLabel() {
      return {
        Value: 'R$',
        Percentage: '%',
      }
    }
  },

  methods: {
    discountTypes() {
      return [
        { value: 'Percentage', label: this.$t('%') },
        { value: 'Value', label: this.$t('R$') },
      ]
    },
  },
}

export default discountTypes
