const orderDates = {
  methods: {
    orderDates() {
      return [
        { value: 'created', label: this.$t('Data de Criação') },
        { value: 'completed', label: this.$t('Data de Finalização') },
        { value: 'delivery', label: this.$t('Data de Entrega') },
        { value: 'canceled', label: this.$t('Data de Cancelamento') },
      ]
    },
  },
}

export default orderDates
