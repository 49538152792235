import FormulateSelectWithButton from './FormulateSelectWithButton.vue'

export default function VueFormulateSelectWithButtonPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateSelectWithButton,
    },
    library: {
      'select-with-button': {
        classification: 'search',
        component: 'FormulateSelectWithButton',
      },
    },
  })
}
