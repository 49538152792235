const bankList = [
  { value: '001', label: '(001) Banco do Brasil S.A.' },
  { value: '070', label: '(070) BRB - BANCO DE BRASILIA S.A.' },
  { value: '272', label: '(272) AGK CORRETORA DE CAMBIO S.A.' },
  {
    value: '136',
    label:
      '(136) CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASIl',
  },
  {
    value: '407',
    label: '(407) ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
  },
  { value: '104', label: '(104) CAIXA ECONOMICA FEDERAL' },
  { value: '077', label: '(077) Banco Inter S.A.' },
  { value: '741', label: '(741) BANCO RIBEIRAO PRETO S.A.' },
  { value: '330', label: '(330) BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.' },
  { value: '739', label: '(739) Banco Cetelem S.A.' },
  { value: '743', label: '(743) Banco Semear S.A.' },
  { value: '100', label: '(100) Planner Corretora de Valores S.A.' },
  { value: '096', label: '(096) Banco B3 S.A.' },
  { value: '747', label: '(747) Banco Rabobank International Brasil S.A.' },
  { value: '362', label: '(362) CIELO S.A.' },
  {
    value: '322',
    label: '(322) Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
  },
  { value: '748', label: '(748) BANCO COOPERATIVO SICREDI S.A.' },
  {
    value: '350',
    label:
      '(350) COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE',
  },
  { value: '752', label: '(752) Banco BNP Paribas Brasil S.A.' },
  {
    value: '091',
    label:
      '(091) CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S',
  },
  {
    value: '379',
    label:
      '(379) COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU',
  },
  { value: '399', label: '(399) Kirton Bank S.A. - Banco Múltiplo' },
  { value: '108', label: '(108) PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO' },
  { value: '378', label: '(378) BBC LEASING S.A. - ARRENDAMENTO MERCANTIL' },
  { value: '756', label: '(756) BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB' },
  {
    value: '360',
    label: '(360) TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  { value: '757', label: '(757) BANCO KEB HANA DO BRASIL S.A.' },
  {
    value: '102',
    label: '(102) XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
  },
  { value: '084', label: '(084) UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA' },
  {
    value: '180',
    label: '(180) CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  { value: '066', label: '(066) BANCO MORGAN STANLEY S.A.' },
  {
    value: '015',
    label: '(015) UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  },
  { value: '143', label: '(143) Treviso Corretora de Câmbio S.A.' },
  { value: '062', label: '(062) Hipercard Banco Múltiplo S.A.' },
  { value: '074', label: '(074) Banco J. Safra S.A.' },
  {
    value: '099',
    label: '(099) UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.',
  },
  { value: '387', label: '(387) Banco Toyota do Brasil S.A.' },
  { value: '326', label: '(326) PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.' },
  { value: '025', label: '(025) Banco Alfa S.A.' },
  { value: '315', label: '(315) PI Distribuidora de Títulos e Valores Mobiliários S.A.' },
  { value: '075', label: '(075) Banco ABN Amro S.A.' },
  { value: '040', label: '(040) Banco Cargill S.A.' },
  {
    value: '307',
    label: '(307) Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '190',
    label:
      '(190) SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN',
  },
  { value: '296', label: '(296) VISION S.A. CORRETORA DE CAMBIO' },
  { value: '063', label: '(063) Banco Bradescard S.A.' },
  {
    value: '191',
    label: '(191) Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '382',
    label:
      '(382) FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L',
  },
  { value: '064', label: '(064) GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.' },
  { value: '097', label: '(097) Credisis - Central de Cooperativas de Crédito Ltda.' },
  {
    value: '016',
    label:
      '(016) COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
  },
  { value: '299', label: '(299) BANCO SOROCRED S.A. - BANCO MÚLTIPLO' },
  { value: '012', label: '(012) Banco Inbursa S.A.' },
  { value: '003', label: '(003) BANCO DA AMAZONIA S.A.' },
  { value: '060', label: '(060) Confidence Corretora de Câmbio S.A.' },
  { value: '037', label: '(037) Banco do Estado do Pará S.A.' },
  {
    value: '411',
    label: '(411) Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos',
  },
  { value: '359', label: '(359) ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A' },
  {
    value: '159',
    label: '(159) Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
  },
  { value: '085', label: '(085) Cooperativa Central de Crédito - Ailos' },
  {
    value: '400',
    label: '(400) COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE',
  },
  { value: '410', label: '(410) PLANNER SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR S.A.' },
  {
    value: '114',
    label: '(114) Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
  },
  {
    value: '328',
    label:
      '(328) COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA',
  },
  { value: '036', label: '(036) Banco Bradesco BBI S.A.' },
  { value: '394', label: '(394) Banco Bradesco Financiamentos S.A.' },
  { value: '004', label: '(004) Banco do Nordeste do Brasil S.A.' },
  { value: '320', label: '(320) China Construction Bank (Brasil) Banco Múltiplo S/A' },
  { value: '189', label: '(189) HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS' },
  { value: '105', label: '(105) Lecca Crédito, Financiamento e Investimento S/A' },
  { value: '076', label: '(076) Banco KDB do Brasil S.A.' },
  { value: '082', label: '(082) BANCO TOPÁZIO S.A.' },
  {
    value: '312',
    label:
      '(312) HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT',
  },
  { value: '286', label: '(286) COOPERATIVA DE CRÉDITO RURAL DE OURO SULCREDI/OURO' },
  {
    value: '093',
    label:
      '(093) PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
  },
  { value: '391', label: '(391) COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM' },
  {
    value: '273',
    label: '(273) Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
  },
  { value: '368', label: '(368) Banco CSF S.A.' },
  { value: '290', label: '(290) Pagseguro Internet S.A.' },
  { value: '259', label: '(259) MONEYCORP BANCO DE CÂMBIO S.A.' },
  {
    value: '395',
    label: "(395) F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  { value: '364', label: '(364) GERENCIANET S.A.' },
  {
    value: '157',
    label: '(157) ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '183',
    label:
      '(183) SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
  },
  { value: '014', label: '(014) STATE STREET BRASIL S.A. ? BANCO COMERCIAL' },
  {
    value: '130',
    label: '(130) CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  { value: '358', label: '(358) MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
  { value: '127', label: '(127) Codepe Corretora de Valores e Câmbio S.A.' },
  { value: '079', label: '(079) Banco Original do Agronegócio S.A.' },
  { value: '340', label: '(340) Super Pagamentos e Administração de Meios Eletrônicos S.A.' },
  { value: '081', label: '(081) BancoSeguro S.A.' },
  {
    value: '133',
    label:
      '(133) CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
  },
  { value: '323', label: '(323) MERCADOPAGO.COM REPRESENTACOES LTDA.' },
  { value: '121', label: '(121) Banco Agibank S.A.' },
  { value: '083', label: '(083) Banco da China Brasil S.A.' },
  { value: '138', label: '(138) Get Money Corretora de Câmbio S.A.' },
  { value: '024', label: '(024) Banco Bandepe S.A.' },
  {
    value: '384',
    label:
      '(384) GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO(UEN)',
  },
  { value: '088', label: '(088) BANCO RANDON S.A.' },
  { value: '319', label: '(319) OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
  {
    value: '274',
    label:
      '(274) MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
  },
  { value: '095', label: '(095) Travelex Banco de Câmbio S.A.' },
  { value: '094', label: '(094) Banco Finaxis S.A.' },
  { value: '276', label: '(276) BANCO SENFF S.A.' },
  { value: '092', label: '(092) BRK S.A. Crédito, Financiamento e Investimento' },
  { value: '047', label: '(047) Banco do Estado de Sergipe S.A.' },
  { value: '144', label: '(144) BEXS BANCO DE CÂMBIO S/A' },
  { value: '332', label: '(332) Acesso Soluções de Pagamento S.A.' },
  { value: '126', label: '(126) BR Partners Banco de Investimento S.A.' },
  { value: '325', label: '(325) Órama Distribuidora de Títulos e Valores Mobiliários S.A.' },
  { value: '301', label: '(301) BPP Instituição de Pagamento S.A.' },
  {
    value: '173',
    label: '(173) BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    value: '331',
    label: '(331) Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  { value: '119', label: '(119) Banco Western Union do Brasil S.A.' },
  { value: '396', label: '(396) HUB PAGAMENTOS S.A' },
  { value: '309', label: '(309) CAMBIONET CORRETORA DE CÂMBIO LTDA.' },
  { value: '254', label: '(254) PARANÁ BANCO S.A.' },
  { value: '268', label: '(268) BARI COMPANHIA HIPOTECÁRIA' },
  { value: '107', label: '(107) Banco Bocom BBM S.A.' },
  { value: '412', label: '(412) BANCO CAPITAL S.A.' },
  { value: '124', label: '(124) Banco Woori Bank do Brasil S.A.' },
  {
    value: '149',
    label: '(149) Facta Financeira S.A. - Crédito Financiamento e Investimento',
  },
  { value: '197', label: '(197) Stone Pagamentos S.A.' },
  { value: '313', label: '(313) AMAZÔNIA CORRETORA DE CÂMBIO LTDA.' },
  { value: '142', label: '(142) Broker Brasil Corretora de Câmbio Ltda.' },
  { value: '389', label: '(389) Banco Mercantil do Brasil S.A.' },
  { value: '184', label: '(184) Banco Itaú BBA S.A.' },
  { value: '634', label: '(634) BANCO TRIANGULO S.A.' },
  { value: '545', label: '(545) SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A' },
  { value: '132', label: '(132) ICBC do Brasil Banco Múltiplo S.A.' },
  { value: '298', label: "(298) Vip's Corretora de Câmbio Ltda." },
  { value: '377', label: '(377) BMS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  {
    value: '321',
    label:
      '(321) CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
  },
  { value: '260', label: '(260) Nu Pagamentos S.A.' },
  { value: '129', label: '(129) UBS Brasil Banco de Investimento S.A.' },
  { value: '128', label: '(128) MS Bank S.A. Banco de Câmbio' },
  { value: '416', label: '(416) LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  {
    value: '194',
    label: '(194) PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  { value: '383', label: '(383) BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.' },
  { value: '324', label: '(324) CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { value: '310', label: '(310) VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.' },
  { value: '380', label: '(380) PICPAY SERVICOS S.A.' },
  { value: '163', label: '(163) Commerzbank Brasil S.A. - Banco Múltiplo' },
  { value: '280', label: '(280) WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
  { value: '146', label: '(146) GUITTA CORRETORA DE CAMBIO LTDA.' },
  {
    value: '343',
    label:
      '(343) FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
  },
  { value: '279', label: '(279) COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE' },
  { value: '335', label: '(335) Banco Digio S.A.' },
  { value: '349', label: '(349) AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
  { value: '374', label: '(374) REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.' },
  { value: '278', label: '(278) Genial Investimentos Corretora de Valores Mobiliários S.A.' },
  { value: '271', label: '(271) IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.' },
  { value: '021', label: '(021) BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO' },
  { value: '246', label: '(246) Banco ABC Brasil S.A.' },
  { value: '292', label: '(292) BS2 Distribuidora de Títulos e Valores Mobiliários S.A.' },
  { value: '751', label: '(751) Scotiabank Brasil S.A. Banco Múltiplo' },
  { value: '352', label: '(352) TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
  { value: '208', label: '(208) Banco BTG Pactual S.A.' },
  {
    value: '386',
    label: '(386) NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento',
  },
  { value: '746', label: '(746) Banco Modal S.A.' },
  { value: '241', label: '(241) BANCO CLASSICO S.A.' },
  { value: '398', label: '(398) IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
  { value: '336', label: '(336) Banco C6 S.A.' },
  { value: '612', label: '(612) Banco Guanabara S.A.' },
  { value: '604', label: '(604) Banco Industrial do Brasil S.A.' },
  { value: '505', label: '(505) Banco Credit Suisse (Brasil) S.A.' },
  { value: '329', label: '(329) QI Sociedade de Crédito Direto S.A.' },
  { value: '196', label: '(196) FAIR CORRETORA DE CAMBIO S.A.' },
  { value: '342', label: '(342) Creditas Sociedade de Crédito Direto S.A.' },
  { value: '300', label: '(300) Banco de la Nacion Argentina' },
  { value: '477', label: '(477) Citibank N.A.' },
  { value: '266', label: '(266) BANCO CEDULA S.A.' },
  { value: '122', label: '(122) Banco Bradesco BERJ S.A.' },
  { value: '376', label: '(376) BANCO J.P. MORGAN S.A.' },
  { value: '348', label: '(348) Banco XP S.A.' },
  { value: '473', label: '(473) Banco Caixa Geral - Brasil S.A.' },
  { value: '745', label: '(745) Banco Citibank S.A.' },
  { value: '120', label: '(120) BANCO RODOBENS S.A.' },
  { value: '265', label: '(265) Banco Fator S.A.' },
  { value: '007', label: '(007) BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL' },
  {
    value: '188',
    label: '(188) ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES',
  },
  {
    value: '134',
    label: '(134) BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  { value: '029', label: '(029) Banco Itaú Consignado S.A.' },
  { value: '243', label: '(243) Banco Máxima S.A.' },
  { value: '397', label: '(397) LISTO SOCIEDADE DE CREDITO DIRETO S.A.' },
  { value: '078', label: '(078) Haitong Banco de Investimento do Brasil S.A.' },
  { value: '355', label: '(355) ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { value: '367', label: '(367) VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
  { value: '373', label: '(373) UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.' },
  {
    value: '111',
    label: '(111) OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
  },
  { value: '408', label: '(408) BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { value: '402', label: '(402) COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { value: '404', label: '(404) SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { value: '414', label: '(414) WORK SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { value: '406', label: '(406) ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { value: '403', label: '(403) CORA SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { value: '419', label: '(419) NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  {
    value: '306',
    label: '(306) PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  },
  { value: '017', label: '(017) BNY Mellon Banco S.A.' },
  { value: '174', label: '(174) PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO' },
  { value: '495', label: '(495) Banco de La Provincia de Buenos Aires' },
  { value: '125', label: '(125) BANCO GENIAL S.A.' },
  { value: '488', label: '(488) JPMorgan Chase Bank, National Association' },
  { value: '065', label: '(065) Banco AndBank (Brasil) S.A.' },
  { value: '492', label: '(492) ING Bank N.V.' },
  { value: '145', label: '(145) LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.' },
  { value: '250', label: '(250) BCV - BANCO DE CRÉDITO E VAREJO S.A.' },
  {
    value: '354',
    label: '(354) NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS ECOMMODITIES',
  },
  { value: '253', label: '(253) Bexs Corretora de Câmbio S/A' },
  { value: '269', label: '(269) BANCO HSBC S.A.' },
  { value: '213', label: '(213) Banco Arbi S.A.' },
  { value: '139', label: '(139) Intesa Sanpaolo Brasil S.A. - Banco Múltiplo' },
  { value: '018', label: '(018) Banco Tricury S.A.' },
  { value: '422', label: '(422) Banco Safra S.A.' },
  { value: '630', label: '(630) Banco Smartbank S.A.' },
  { value: '224', label: '(224) Banco Fibra S.A.' },
  { value: '393', label: '(393) Banco Volkswagen S.A.' },
  { value: '600', label: '(600) Banco Luso Brasileiro S.A.' },
  { value: '390', label: '(390) BANCO GM S.A.' },
  { value: '623', label: '(623) Banco Pan S.A.' },
  { value: '655', label: '(655) Banco Votorantim S.A.' },
  { value: '479', label: '(479) Banco ItauBank S.A.' },
  { value: '456', label: '(456) Banco MUFG Brasil S.A.' },
  { value: '464', label: '(464) Banco Sumitomo Mitsui Brasileiro S.A.' },
  { value: '341', label: '(341) ITAÚ UNIBANCO S.A.' },
  { value: '237', label: '(237) Banco Bradesco S.A.' },
  { value: '381', label: '(381) BANCO MERCEDES-BENZ DO BRASIL S.A.' },
  { value: '613', label: '(613) Omni Banco S.A.' },
  { value: '652', label: '(652) Itaú Unibanco Holding S.A.' },
  { value: '637', label: '(637) BANCO SOFISA S.A.' },
  { value: '653', label: '(653) BANCO INDUSVAL S.A.' },
  { value: '069', label: '(069) Banco Crefisa S.A.' },
  { value: '370', label: '(370) Banco Mizuho do Brasil S.A.' },
  { value: '249', label: '(249) Banco Investcred Unibanco S.A.' },
  { value: '318', label: '(318) Banco BMG S.A.' },
  { value: '626', label: '(626) BANCO C6 CONSIGNADO S.A.' },
  { value: '270', label: '(270) Sagitur Corretora de Câmbio Ltda.' },
  { value: '366', label: '(366) BANCO SOCIETE GENERALE BRASIL S.A.' },
  { value: '113', label: '(113) Magliano S.A. Corretora de Cambio e Valores Mobiliarios' },
  { value: '131', label: '(131) TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA' },
  { value: '011', label: '(011) CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A' },
  { value: '611', label: '(611) Banco Paulista S.A.' },
  { value: '755', label: '(755) Bank of America Merrill Lynch Banco Múltiplo S.A.' },
  { value: '089', label: '(089) CREDISAN COOPERATIVA DE CRÉDITO' },
  { value: '643', label: '(643) Banco Pine S.A.' },
  { value: '140', label: '(140) Easynvest - Título Corretora de Valores SA' },
  { value: '707', label: '(707) Banco Daycoval S.A.' },
  { value: '288', label: '(288) CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.' },
  { value: '363', label: '(363) SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
  {
    value: '101',
    label: '(101) RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  { value: '487', label: '(487) DEUTSCHE BANK S.A. - BANCO ALEMAO' },
  { value: '233', label: '(233) Banco Cifra S.A.' },
  { value: '177', label: '(177) Guide Investimentos S.A. Corretora de Valores' },
  { value: '365', label: '(365) SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS' },
  { value: '633', label: '(633) Banco Rendimento S.A.' },
  { value: '218', label: '(218) Banco BS2 S.A.' },
  { value: '169', label: '(169) BANCO OLÉ CONSIGNADO S.A.' },
  {
    value: '293',
    label: '(293) Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  { value: '285', label: '(285) Frente Corretora de Câmbio Ltda.' },
  { value: '080', label: '(080) B&T CORRETORA DE CAMBIO LTDA.' },
  { value: '753', label: '(753) Novo Banco Continental S.A. - Banco Múltiplo' },
  { value: '222', label: '(222) BANCO CRÉDIT AGRICOLE BRASIL S.A.' },
  { value: '281', label: '(281) Cooperativa de Crédito Rural Coopavel' },
  { value: '754', label: '(754) Banco Sistema S.A.' },
  { value: '311', label: '(311) DOURADA CORRETORA DE CÂMBIO LTDA.' },
  { value: '098', label: '(098) Credialiança Cooperativa de Crédito Rural' },
  { value: '610', label: '(610) Banco VR S.A.' },
  { value: '712', label: '(712) Banco Ourinvest S.A.' },
  { value: '720', label: '(720) BANCO RNX S.A.' },
  { value: '010', label: '(010) CREDICOAMO CREDITO RURAL COOPERATIVA' },
  {
    value: '283',
    label: '(283) RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA',
  },
  { value: '033', label: '(033) BANCO SANTANDER (BRASIL) S.A.' },
  { value: '217', label: '(217) Banco John Deere S.A.' },
  { value: '041', label: '(041) Banco do Estado do Rio Grande do Sul S.A.' },
  { value: '117', label: '(117) ADVANCED CORRETORA DE CÂMBIO LTDA' },
  { value: '654', label: '(654) BANCO DIGIMAIS S.A.' },
  { value: '371', label: '(371) WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.' },
  { value: '212', label: '(212) Banco Original S.A.' },
  { value: '289', label: '(289) DECYSEO CORRETORA DE CAMBIO LTDA.' },
]

const banks = {
  methods: {
    banks() {
      return bankList
    },
    getBank(bankNumber) {
      return bankList.find(bank => bank.value === String(bankNumber).padStart(3, '0'))
    }
  },
}

export default banks
