import axios from '@axios'

const getInitialState = () => ({
  reportData: null,
  filters: {
    storeId: null,
    skuId: null,
    brandId: null,
    groupId: null,
    period: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, reportData } = getInitialState()
      state.filters = filters
      state.paging = paging
      state.reportData = reportData
    },
  },

  actions: {
    async generateReport({ state, commit }) {
      const { period } = state.filters
      const response = await axios.get('/api/reports/sku-sales-detailed/download', {
        params: {
          storeId: state.filters?.storeId,
          skuId: state.filters?.skuId,
          brandId: state.filters?.brandId,
          groupId: state.filters?.groupId,
          startDate: period?.startDate,
          endDate: period?.endDate,
        },
        responseType: 'blob'
      })
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      commit('SET_REPORT_DATA', url)
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('CLEAN_FILTERS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
