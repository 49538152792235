<template>
  <vue-numeric
    v-model="context.model"
    v-bind="context.attributes"
    :input-class="context.attributes.class"
    :data-type="context.type"
    :decimal-separator="decimalSeparator"
    :thousand-separator="thousandSeparator"
    :separator="separator"
    @blur="onBlur"
    @focus="onFocus"
  />
</template>
<script>
import VueNumeric from 'vue-numeric'
import _ from 'lodash'

export default {
  name: 'FormulateTextNumber',
  components: {
    VueNumeric,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    thousandSeparator: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    model() {
      return this.context.model
    },
    decimalPlaces() {
      return this.context.attributes.decimalPlaces || 0
    },
    decimalSeparator() {
      return (
        this.context.attributes['decimal-separator'] ||
        this.context.attributes.decimalSeparator ||
        ','
      )
    },
    separator() {
      return this.context.attributes.separator || this.context.attributes.thousandSeparator || '.'
    },
  },
  watch: {
    model(val) {
      if (val === '' || Number.isNaN(Number(val))) {
        this.context.model = undefined
      }
    },
  },
  methods: {
    // getMask() {
    //   console.log(`${'#'.repeat(this.context.attributes.maxlength || 999)}${this.decimalPlaces ? `.${'#'.repeat(this.decimalPlaces)}` : ''}`)
    //   return [`${'#'.repeat(this.context.attributes.maxlength || 999)}${this.decimalPlaces ? `.${'#'.repeat(this.decimalPlaces)}` : ''}`]
    // },

    getNumber(value) {
      if (!value) return value

      const valueSplit = value.split(this.decimalSeparator)
      const integerValue = valueSplit[0].replace(/\D/g, '')
      return `${integerValue}.${valueSplit[1] || '00'}`
    },

    // eslint-disable-next-line func-names
    onBlur: _.debounce(async function (event) {
      this.context.blurHandler(event)
      this.$emit('blur', this.getNumber(event.target.value), event)
    }, 50),

    // eslint-disable-next-line func-names
    onFocus: _.debounce(async function (event) {
      this.$emit('focus', this.getNumber(event.target.value), event)
      document.getElementById(event.target.id).select()
    }, 50),
  },
}
</script>
