import isElectron from 'is-electron'
import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueBrazil from 'vue-brazil'
import VueTheMask from 'vue-the-mask'
import VueCookie from 'vue-cookie'
import * as VueGoogleMaps from 'vue2-google-maps'
import Geocoder from '@pderas/vue2-geocoder'
import i18n from '@/libs/i18n'
import VTooltip from 'v-tooltip'
import VueFileAgent from 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'
import VueSelect from 'vue-select'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// Filters
import '@/filters/filters'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/acl'
import '@/libs/vue-select'
import '@/libs/formulate'
import '@/libs/dexie'

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import '@sum.cumo/vue-datepicker/dist/Datepicker.css'
import { alerts, translate } from './mixins'
import simpleKeyboardDirective from './directives/simpleKeyboardDirective'

// override logs to use electron-log on browser
if (isElectron()) {
  const originalConsole = {
    log: console.log,
    info: console.info,
    debug: console.debug,
    error: console.error,
  }
  const electronLog = window.electronAPP.logger

  console.log = (...args) => {
    originalConsole.log(...args)
    electronLog.log('[browser]', ...args)
  }
  console.info = (...args) => {
    originalConsole.info(...args)
    electronLog.info('[browser]', ...args)
  }
  console.debug = (...args) => {
    originalConsole.debug(...args)
    electronLog.debug('[browser]', ...args)
  }
  console.error = (...args) => {
    originalConsole.error(...args)
    electronLog.error('[browser]', ...args)
  }
}

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VueFileAgent)
Vue.use(VueCookie)
Vue.use(VTooltip)

const GOOGLE_MAPS_API_KEY = 'AIzaSyAgzBHInSIRdZKXeuGJJUCgde5r1WfJRRA'

Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAPS_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    // If you want to set the version, you can do so:
    // v: '3.26',
  },

  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  // installComponents: true,
})
Vue.use(Geocoder, {
  defaultCountryCode: 'BR', // e.g. 'CA'
  defaultLanguage: 'pt', // e.g. 'en'
  defaultMode: 'address', // or 'lat-lng'
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
})

// Composition API
Vue.use(VueCompositionAPI)

// Máscaras para informações do Brasil (documentos, telefone, cep, etc)
Vue.use(VueBrazil)
Vue.use(VueTheMask)

// components
Vue.component('VSelect', VueSelect)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.mixin({
  components: {
    ...alerts.components,
  },
  computed: {},
  methods: {
    ...alerts.methods,
    ...translate.methods,
  },
})

// diretiva não funciona para inputs do formulate, mas funciona em inputs do bootstrap e nativos
Vue.directive('keyboard', simpleKeyboardDirective)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
