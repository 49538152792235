export default [
  {
    path: '/suport/ticket',
    name: 'suport-create-ticket',
    component: () => import('@/views/pages/faq/SuportCreateTicket.vue'),
    meta: {
      pageTitle: 'Abrir ticket (Suporte)',
      navActiveLink: 'suport-create-ticket',
      resource: 'Auth',
      action: 'read',
      breadcrumb: [
        {
          text: 'Ajuda',
        },
        {
          text: 'Abrir ticket (Suporte)',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suport/manual-erp',
    name: 'suport-page-erp',
    component: () => import('@/views/pages/faq/SuportPageERP.vue'),
    meta: {
      pageTitle: 'Manual ERP',
      navActiveLink: 'suport-page-erp',
      resource: 'Auth',
      action: 'read',
      breadcrumb: [
        {
          text: 'Ajuda',
        },
        {
          text: 'Manual ERP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suport/manual-pdv',
    name: 'suport-page-pdv',
    component: () => import('@/views/pages/faq/SuportPagePDV.vue'),
    meta: {
      pageTitle: 'Manual PDV',
      navActiveLink: 'suport-page-pdv',
      resource: 'Auth',
      action: 'read',
      breadcrumb: [
        {
          text: 'Ajuda',
        },
        {
          text: 'Manual PDV',
          active: true,
        },
      ],
    },
  },
]
