import { payBoxSale, payBoxTypes } from '@/mixins'
import { getInitialPayBoxItem } from '../pay-box'
import catalog from './atm-catalog'
import status from './atm-status'

export const atmScreenEnum = {
  CATALOG: 'catalog',
  PAYMENT: 'payment',
}

const getInitialState = () => ({})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {
    catalog,
    status,
  },

  getters: {},

  mutations: {
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    async initAtm({ dispatch }) {
      await dispatch('pages/pdv/atm/status/loadAtmStatus', {}, { root: true })
      await dispatch('pages/pdv/atm/catalog/loadAtmCatalog', {}, { root: true })
      await dispatch('pages/pdv/loadAtmPaymentMethods', {}, { root: true })
    },

    async addItemFromAtm({ dispatch }, { amount, ean, skuId }) {
      const cartItem = getInitialPayBoxItem()
      cartItem.amount = amount || 1

      const product = await dispatch(
        'pages/pdv/atm/catalog/fetchProductToCart',
        { ean, skuId },
        { root: true }
      )
      const productToItem = payBoxSale.methods.prepareProductToSaleItem(cartItem.amount, product)
      cartItem.product = productToItem

      await dispatch(
        'pages/pdv/payBox/addItem',
        {
          item: cartItem,
          inputMethod: payBoxTypes.data().productInputMethodEnum.TYPED,
        },
        { root: true }
      )
    },
    async removeItemFromAtm({ dispatch }, item) {
      await dispatch('pages/pdv/payBox/removeItem', item, { root: true })
    },
    async updateItemFromAtm({ dispatch }, { item, amount }) {
      await dispatch(
        'pages/pdv/payBox/updateItem',
        { payload: { ...item, amount } },
        { root: true }
      )
    },

    async cleanState({ commit, dispatch }) {
      await dispatch('pages/pdv/payBox/cleanState', {}, { root: true })
      commit('CLEAN_STATE')
      await dispatch('pages/pdv/atm/status/cleanState', {}, { root: true })
    },
  },
}
