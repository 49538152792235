import { financialDomain, sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  filters: {
    description: '',
    accountsPayableId: null,
    managementAccountId: null,
    paymentMethodId: null,
    supplierId: null,
    customerId: null,
    purchaseOrderId: null,
    saleId: null,
    value: '',
    periodType: financialDomain.data().payablePeriodTypeEnum.DUE_DATE,
    period: {
      startDate: null,
      endDate: null,
    },
    paymentStatus: null,
  },
  accountsPayable: [],
  selectedInstallments: [],
  summaryFields: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_ACCOUNTS_PAYABLE(state, accountsPayable) {
      state.accountsPayable = accountsPayable
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },
    SET_SELECTED_INSTALLMENTS(state, selectedInstallments) {
      state.selectedInstallments = selectedInstallments
    },

    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchPayBoxAccountsPayable({ commit, state }) {
      const { description, value, periodType, period, paymentStatus } = state.filters

      const { startDate, endDate } = period

      const { CREATED_DATE, DUE_DATE, ISSUE_DATE, PAYMENT_DATE } =
        financialDomain.data().payablePeriodTypeEnum
      const periodParams = {
        [CREATED_DATE]: {
          createdDateFrom: startDate,
          createdDateTo: endDate,
        },
        [DUE_DATE]: {
          dueDateFrom: startDate,
          dueDateTo: endDate,
        },
        [ISSUE_DATE]: {
          issueDateFrom: startDate,
          issueDateTo: endDate,
        },
        [PAYMENT_DATE]: {
          paymentDateFrom: startDate,
          paymentDateTo: endDate,
        },
      }
      const periodFilter = periodParams[periodType] || {}

      const params = {
        ...state.filters,
        ...periodFilter,
        description: description || null,
        value: value || null,
        sortBy: sorting.methods.getSorting(state),
        pageSize: state.paging.pageSize,
        pageIndex: state.paging.currentPage - 1,
      }

      if (paymentStatus) {
        params[paymentStatus] = true
      }

      const { data } = await axios.get('/api/accounting/accounts-payable-installments/pay-box', {
        params,
      })

      const installmentsFormated = data.results.map(installment => ({
        ...installment,
        checkedToPay: state.selectedInstallments.some(selected => selected.id === installment.id),
      }))

      commit('SET_ACCOUNTS_PAYABLE', installmentsFormated || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    addInstallmentsToPay({ commit, state }, item) {
      const { accountsPayable } = item
      if (!accountsPayable || !accountsPayable.store) throw new Error('Dados no item faltando')

      if (state.selectedInstallments.length > 0) {
        const existingStoreId = state.selectedInstallments[0]?.storeId
        if (accountsPayable.store.id !== existingStoreId)
          throw new Error('Não é possível realizar pagamento em massa para lojas diferentes.')
      }

      const newSelectedInstallments = [
        ...state.selectedInstallments,
        { ...item, storeId: accountsPayable.store.id },
      ]
      commit('SET_SELECTED_INSTALLMENTS', newSelectedInstallments)
    },
    removeInstallmentsToPay({ commit, state }, item) {
      const newList = [...state.selectedInstallments]
      const indexFound = newList.findIndex(inst => inst.id === item.id)
      newList.splice(indexFound, 1)
      commit('SET_SELECTED_INSTALLMENTS', newList)
    },
    async deleteInstallment({ dispatch }, id) {
      await axios.delete(`/api/accounting/accounts-payable-installments/${id}`)
      await dispatch('fetchPayBoxAccountsPayable')
    },
    async printAccountPayableReceipt(
      { rootGetters },
      { accountPayableId, openCashDrawer = false }
    ) {
      const printerConfig =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      const payboxConfig = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const { data } = await axios.get(`/api/accounting/accounts-payable/${accountPayableId}`)
      const installment = { ...data.accountsPayableInstallments[0] }
      installment.accountsPayable = { ...data }

      try {
        await window.electronAPI.print({
          ...printerConfig,
          request: {
            openCashDrawer,
            printTexts: [
              {
                printText: JSON.stringify({
                  payBox: payboxConfig,
                  ...installment,
                }),
                template: 'ACCOUNT_PAYABLE_INSTALLMENT',
              },
            ],
          },
        })
      } catch (error) {
        console.error('error on print account payable installment', error)
      }
    },

    setFilters({ commit }, filters) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanInstallmentsToPay({ commit }) {
      commit('SET_SELECTED_INSTALLMENTS', [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
