<template>
  <div>
    <div
      v-show="showAsLabel"
      class="mt-25"
    >
      <e-button
        icon="trash"
        variant="danger"
        class="p-5 btn-sm-block"
        :title="$t('Excluir')"
        @click="onBeforeDelete(firstFile)"
      />
      <b class="ml-1">{{ fileName }}</b>
    </div>
    <VueFileAgent
      v-show="!showAsLabel"
      ref="documentos"
      v-model="context.model"
      v-bind="context.attributes"
      :input-class="context.attributes.class"
      :data-type="context.type"
      :help-text="context.attributes['help-text'] || $t('Arraste e solte os arquivos aqui...')"
      :error-text="helpText"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      @blur="context.blurHandler"
    />
  </div>
</template>
<script>
import { stringUtils, uploader } from '@/mixins'
import EButton from '../../EButton.vue'

export default {
  name: 'FormulateUploader',
  components: { EButton },
  mixins: [uploader],
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileRecordsForUpload: [],
    }
  },
  computed: {
    firstFile() {
      const [file] = Array.isArray(this.context.model) ? this.context.model : [this.context.model]
      return file
    },
    fileName() {
      if (Array.isArray(this.context.model)) {
        const [file] = this.context.model
        if (file && file.url) {
          return stringUtils.methods.getFileName(file.url)
        }
      }
      return null
    },
    showAsLabel() {
      return this.context.model?.length && this.context.attributes['show-as-label']
    },
    helpText() {
      return {
        ...this.context.attributes['error-text'],
        size: this.$t('Você selecionou um arquivo muito grande'),
      }
    },
    deleteConfirmMessage() {
      return (
        this.context.attributes['delete-confirm-message'] ||
        this.context.attributes?.deleteConfirmMessage
      )
    },
  },
  methods: {
    async upload({ fileType, destinationPath }) {
      const uploadResult = await this.upload({
        files: this.context.model,
        fileInput: 'file',
        fileType,
        destinationPath,
        isFormOnline: false,
      })
      uploadResult.forEach((uploadItem, index) => {
        this.context.model[index].url = uploadItem.url
      })
      return uploadResult
    },
    fileDeleted(fileRecord) {
      this.fileRecordsForUpload = this.context.model
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      }
      this.context.model = this.fileRecordsForUpload
      this.$emit('delete', i)
    },
    async onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) {
          this.fileRecords.splice(k, 1)
        }
      } else {
        const resposta = await this.confirm({
          text: this.deleteConfirmMessage || this.$t('Tem certeza que deseja apagar este arquivo?'),
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
        })
        if (resposta) {
          this.$refs.documentos.deleteFileRecord(fileRecord)
        }
      }
    },
  },
}
</script>
