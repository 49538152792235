import statusTypes from './status-types'

const echopeClassificationEnum = {
  E1: 'E1',
  E2: 'E2',
  E3: 'E3',
}

const onlinePaymentModelEnum = {
  PAY_ON_PICKUP: 'PayOnPickup',
  PAY_ONLINE: 'PayOnline',
}

const coldOptionEnum = {
  COLD_ON_ICE: 'ColdOnIce',
  COLD: 'Cold',
  NATURAL: 'Natural',
}

const saleDomain = {
  data() {
    return {
      echopeClassificationEnum,
      onlinePaymentModelEnum,
      coldOptionEnum,
    }
  },

  computed: {
    saleOriginEnum() {
      return {
        PAY_BOX: 'PayBox',
        STORE_SELLER: 'StoreSeller',
        APP: 'App',
        WEBSITE: 'Website',
        SELF_SERVICE: 'SelfService',
        BAR_CONSUMPTION: 'BarConsumption',
        INVOICE: 'Invoice',
      }
    },
    saleOriginLabel() {
      return {
        PayBox: this.$t('Caixa'),
        StoreSeller: this.$t('Vendedor'),
        App: this.$t('Aplicativo'),
        Website: this.$t('Site'),
        SelfService: this.$t('Auto atendimento'),
        Invoice: this.$t('Nota fiscal'),
        BarConsumption: this.$t('Bar'),
      }
    },
    echopeClassificationLabel() {
      return {
        [echopeClassificationEnum.E1]: this.$t('Normal (E1)'),
        [echopeClassificationEnum.E2]: this.$t('Eventos (E2)'),
        [echopeClassificationEnum.E3]: this.$t('Outros (E3)'),
      }
    },
  },

  methods: {
    saleOriginOptions() {
      return [
        { value: 'PayBox', label: this.$t('Caixa') },
        { value: 'StoreSeller', label: this.$t('Vendedor') },
        { value: 'App', label: this.$t('App') },
        { value: 'Website', label: this.$t('Site') },
        { value: 'SelfService', label: this.$t('Auto atendimento') },
        { value: 'BarConsumption', label: this.$t('Bar') },
      ]
    },
    saleOriginToOrderOptions() {
      return [
        { value: 'PayBox', label: this.$t('PDV') },
        { value: 'StoreSeller', label: this.$t('Vendedor') },
        { value: 'App', label: this.$t('App') },
        { value: 'Website', label: this.$t('Site') },
        { value: 'SelfService', label: this.$t('Auto atendimento') },
        { value: 'BarConsumption', label: this.$t('Bar') },
      ]
    },
    echopeClassificationOptions() {
      return [
        { value: echopeClassificationEnum.E1, label: this.$t('Normal (E1)') },
        { value: echopeClassificationEnum.E2, label: this.$t('Eventos (E2)') },
        { value: echopeClassificationEnum.E3, label: this.$t('Outros (E3)') },
      ]
    },

    mxValidatePrintForOnlineSale(sale) {
      const result = { valid: true, message: '' }

      const { PAY_ONLINE } = onlinePaymentModelEnum
      const { COMPLETED } = statusTypes.computed.saleStatusEnum()
      if (sale?.onlinePayment?.paymentModel === PAY_ONLINE && sale.status !== COMPLETED) {
        result.message = this.$t('SALE.UI.ONLINE_PAYMENT_PRINT_BEFORE_COMPLETE')
        result.valid = false
      }

      return result
    },
    coldOptionOptions() {
      return [
        { value: coldOptionEnum.COLD_ON_ICE, label: this.$t('Embalado no gelo') },
        { value: coldOptionEnum.COLD, label: this.$t('Gelado') },
        { value: coldOptionEnum.NATURAL, label: this.$t('Natural') },
      ]
    },
  },
}

export default saleDomain
