const requireIcon = require.context('@/assets/images/icons', false, /\.(png|jpe?g|svg)$/)

const documentIcons = {
  methods: {
    getIconDocument(file) {
      const extension = file.slice(-4)
      switch (extension) {
        case '.pdf':
          return requireIcon('./pdf.png')
        case '.doc':
          return requireIcon('./doc.png')
        case '.jpg':
        case '.jpeg':
          return requireIcon('./jpg.png')
        case '.xls':
          return requireIcon('./xls.png')
        case '.txt':
          return requireIcon('./txt.png')
        default:
          return requireIcon('./unknown.png')
      }
    },
  },
}
export default documentIcons
