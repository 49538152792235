import i18n from '@/libs/i18n'
import alerts from '../alerts'
import statusTypes from './status-types'

const deliveryDomain = {
  mixins: [alerts],

  data() {
    return {
      deliveryFeeEnum: {
        SINGLE_FEE: 'SingleFee',
        FEE_PER_KILOMETER: 'FeePerKilometer',
      },
    }
  },

  computed: {
    deliveryFeeLabel() {
      return {
        SingleFee: i18n.t('Única'),
        FeePerKilometer: i18n.t('Por quilômetro'),
      }
    },
  },

  methods: {
    async mxChangeDeliveryStatus(sale) {
      const { PENDING, IN_PROGRESS, PREPARING } = statusTypes.computed.deliveryStatusEnum()
      const types = {
        [PENDING]: {
          endpoint: 'prepare',
          dialogMessage: this.$t('Pendente -> Preparando'),
        },
        [PREPARING]: {
          endpoint: 'start',
          dialogMessage: this.$t('Preparando -> Entregando'),
        },
        [IN_PROGRESS]: {
          endpoint: 'finish',
          dialogMessage: this.$t('Entregando -> Entregue'),
        },
      }

      const { id, deliveryStatus } = sale
      if (types[deliveryStatus]) {
        const { endpoint, dialogMessage } = types[deliveryStatus]

        const confirmed = await this.confirm({
          title: this.$t('Alterar o status da entrega?'),
          text: dialogMessage,
        })
        if (!confirmed) {
          return null
        }

        const { data } = await this.$http.patch(
          `/api/sales/pay-box-sale/delivery/${endpoint}/${id}`
        )
        return data
      }

      return null
    },
  },
}

export default deliveryDomain
