export default {
  title: 'Segurança',
  icon: 'key',
  iconPack: 'bootstrap',
  children: [
    {
      title: 'Usuários',
      route: 'user-maintain',
      resource: 'User',
      action: 'Read',
    },
    {
      title: 'Perfis de Acesso',
      route: 'access-profile-list',
      resource: 'AccessProfile',
      action: 'Read',
    },
  ],
}
