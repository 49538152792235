import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import priceTableMaintain from './price-table-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  priceTables: [],
  filters: { name: undefined, active: undefined },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { priceTableMaintain },

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_PRICE_TABLES(state, priceTables) {
      state.priceTables = priceTables
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    CLEAN_STATE(state) {
      const { paging, priceTables, filters } = getInitialState()
      state.priceTables = priceTables
      state.paging = paging
      state.filters = filters
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchPriceTables({ commit, state }) {
      const { data } = await axios.get('/api/price-tables', {
        params: {
          name: state.filters.name,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_PRICE_TABLES', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
