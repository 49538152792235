var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.context.attributes.type === "checkbox"
      ? _c(
          "input",
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.context.model,
                  expression: "context.model",
                },
              ],
              attrs: {
                "input-class": _vm.context.attributes.class,
                "data-type": _vm.context.type,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.context.model)
                  ? _vm._i(_vm.context.model, null) > -1
                  : _vm.context.model,
              },
              on: {
                blur: _vm.onBlur,
                focus: _vm.onFocus,
                change: function ($event) {
                  var $$a = _vm.context.model,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.context, "model", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.context,
                          "model",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.context, "model", $$c)
                  }
                },
              },
            },
            "input",
            _vm.context.attributes,
            false
          )
        )
      : _vm.context.attributes.type === "radio"
      ? _c(
          "input",
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.context.model,
                  expression: "context.model",
                },
              ],
              attrs: {
                "input-class": _vm.context.attributes.class,
                "data-type": _vm.context.type,
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.context.model, null) },
              on: {
                blur: _vm.onBlur,
                focus: _vm.onFocus,
                change: function ($event) {
                  return _vm.$set(_vm.context, "model", null)
                },
              },
            },
            "input",
            _vm.context.attributes,
            false
          )
        )
      : _c(
          "input",
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.context.model,
                  expression: "context.model",
                },
              ],
              attrs: {
                "input-class": _vm.context.attributes.class,
                "data-type": _vm.context.type,
                type: _vm.context.attributes.type,
              },
              domProps: { value: _vm.context.model },
              on: {
                blur: _vm.onBlur,
                focus: _vm.onFocus,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.context, "model", $event.target.value)
                },
              },
            },
            "input",
            _vm.context.attributes,
            false
          )
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }