import axios from '@axios'
import beerTaps from './beer-taps'
import barrels from './barrels'
import consumptions from './consumptions'

export default {
  namespaced: true,
  modules: {
    beerTaps,
    barrels,
    consumptions,
  },
  getters: {
    storeOptions(state) {
      return state.stores.map(store => ({
        ...store,
        value: store.id,
        label: store.tradingName,
      }))
    },
  },
  state: {
    stores: [],
  },
  actions: {
    async fetchStores({ commit }) {
      const response = await axios.get('/api/stores', {
        params: {
          type: 'Kiosk',
          sortBy: 'TradingName',
        },
      })
      commit('SET_STORES', response.data.results)
    },
  },
  mutations: {
    SET_STORES(state, stores) {
      state.stores = stores
    },
  },
}
