import axios from '@axios'
import { sorting, storePagination } from '@/mixins'
import invoiceMaintain from './invoice-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  invoices: [],
  filters: {
    storeId: null,
    customer: null,
    supplier: null,
    model: 'NFE',
    saleId: null,
    movementType: null,
    invoiceNumber: '',
    serialNumber: '',
    nfeAccessKey: '',
    status: null,
    stockLossesReason: null,
    issueDate: {
      startDate: null,
      endDate: null,
    },
    movementDate: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  modules: {
    invoiceMaintain,
  },
  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_INVOICES(state, invoices) {
      state.invoices = invoices
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, invoices } = getInitialState()
      state.filters = filters
      state.invoices = invoices
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchInvoices({ commit, state }) {
      const {
        storeId,
        customer,
        supplier,
        model,
        saleId,
        cfop,
        nfeAccessKey,
        movementType,
        invoiceNumber,
        serialNumber,
        issueDate,
        movementDate,
        status,
        stockLossesReason,
      } = state.filters

      const { data } = await axios.get('/api/invoices', {
        params: {
          storeId,
          customerId: customer ? customer.id : null,
          supplierId: supplier ? supplier.id : null,
          model,
          saleId,
          cfop: cfop ? cfop.split('-')[0].trim() : null,
          movementType,
          invoiceNumber,
          nfeAccessKey,
          serialNumber,
          status,
          stockLossesReason,
          issueDateFrom: issueDate.startDate,
          issueDateTo: issueDate.endDate,
          movementDateFrom: movementDate.startDate,
          movementDateTo: movementDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_INVOICES', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async downloadReport({ state }) {
      const {
        storeId,
        customer,
        supplier,
        model,
        saleId,
        cfop,
        movementType,
        invoiceNumber,
        serialNumber,
        issueDate,
        movementDate,
        status,
        stockLossesReason,
      } = state.filters

      const { data } = await axios.get('/api/invoices/reports/download', {
        responseType: 'blob',
        params: {
          storeId,
          customerId: customer ? customer.id : null,
          supplierId: supplier ? supplier.id : null,
          model,
          saleId,
          cfop: cfop ? cfop.split('-')[0].trim() : null,
          movementType,
          invoiceNumber,
          serialNumber,
          status,
          stockLossesReason,
          issueDateFrom: issueDate.startDate,
          issueDateTo: issueDate.endDate,
          movementDateFrom: movementDate.startDate,
          movementDateTo: movementDate.endDate,
          pageSize: 0,
          pageIndex: 0,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      return data
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
