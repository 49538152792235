<template>
  <div class="content-center">
    <b-spinner
      :variant="variant"
      label="Text Centered"
    />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'ESpinner',
  components: { BSpinner },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
