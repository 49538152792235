import FormulateOskText from './FormulateOskText.vue'

export default function VueFormulateOskTextPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateOskText,
    },
    library: {
      'osk-text': {
        classification: 'text',
        component: 'FormulateOskText',
        slotProps: {
          component: ['oskType']
        }
      },
    },
  })
}
