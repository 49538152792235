import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

export const getInitialSecurityUserForm = () => ({
  name: '',
  active: true,
  email: '',
  document: '',
  userName: '',
  mainStore: '',
  allowAllStores: false,
  stores: [],
  storesSelected: [],
  accessProfilesSelected: [],
  password: '',
  confirmPassword: '',
})

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: '',
    sortDesc: false,
  },
  user: getInitialSecurityUserForm(),
  users: [],
  filters: {
    name: '',
    userName: '',
    storeId: '',
    email: '',
    active: 'true',
    accessProfile: '',
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_USERS(state, users) {
      state.users = users
    },
    SET_USER(state, user) {
      state.user = user
    },

    SET_FILTER(state, filters) {
      state.filters = filters
    },

    CLEAN_USER_STATE(state) {
      const { user } = getInitialState()
      state.user = user
    },
    CLEAN_STATE(state) {
      const { filters, user, users } = getInitialState()
      state.filters = filters
      state.user = user
      state.users = users
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchUsers({ commit, state }) {
      // const querys = this.getQueryString({
      //   filter: {
      //     tenantSeller: this.filter.seller,
      //     nomePerfil: this.filter.profileName,
      //     nomeUsuario: this.filter.name,
      //     emailUsuario: this.filter.email,
      //   },
      //   pageSize: context.perPage,
      //   pageIndex: context.currentPage - 1,
      // })

      const { data } = await axios.get('/api/users', {
        params: {
          ...state.filters,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_USERS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    setUser({ commit }, user) {
      commit('SET_USER', user)
    },

    resetFilters({ commit }) {
      commit('SET_FILTER', getInitialState().filters)
    },

    cleanUserState({ commit }) {
      commit('CLEAN_USER_STATE')
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
