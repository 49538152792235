<template>
  <b-form-tags
    v-model="context.model"
    v-bind="context.attributes"
    :input-class="context.attributes.class"
    :data-type="context.type"
    @blur="context.blurHandler"
  />
</template>
<script>
import { BFormTags } from 'bootstrap-vue'

export default {
  name: 'FormulateTags',
  components: {
    BFormTags,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
}
</script>
