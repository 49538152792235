var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Ações"),
          "main-icon": "keyboard_command_key",
          actions: _vm.actions,
          "fixed-tooltip": true,
          busy: _vm.busy,
        },
        on: { allevents: _vm.allevents },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }