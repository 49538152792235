const getInitialState = () => ({
  priceTable: {
    description: '',
    retailDiscount: 0,
    products: [],
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ADD_PRODUCT(state, product) {
      if (typeof product === 'object') state.priceTable.products.push(product)
    },

    UPDATE_PRODUCT(state, { product, index }) {
      if (typeof product === 'object' && index > -1) {
        state.priceTable.products[index] = product
      }
    },

    REMOVE_PRODUCT(state, index) {
      if (index !== undefined && index > -1) state.priceTable.products.splice(index, 1)
    },

    CLEAN_PRODUCTS(state) {
      const { priceTable } = getInitialState()
      state.priceTable.products = priceTable.products
    },

    CLEAN_STATE(state) {
      const { priceTable } = getInitialState()
      state.priceTable = priceTable
    },
  },

  actions: {
    addProduct({ commit }, product) {
      commit('ADD_PRODUCT', product)
    },

    updateProduct({ commit }, { product, index }) {
      commit('UPDATE_PRODUCT', { product, index })
    },

    removeProduct({ commit }, index) {
      commit('REMOVE_PRODUCT', index)
    },

    cleanProducts({ commit }) {
      commit('CLEAN_PRODUCTS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
