<template>
  <FormulateInput
    :id="id"
    v-model="content"
    type="select-searchable"
    v-bind="$attrs"
    :label="label"
    :placeholder="$t('Digite para pesquisar (min 3 caracteres)')"
    :options="skusOptions"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    :min-search-length="0"
    :instruction="$t('Busque pelo código EAN (GTIN), código do produto ou nome')"
    :start-search="startSearch"
    :autofocus="autofocus"
    @fetchSearch="fetchSearchSku"
    @input="handleInput"
  />
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default() {
        return this.$t('Produto')
      },
    },
    valueIsObject: {
      type: Boolean,
      default: false,
    },
    storeId: {
      type: [String, Number],
      default: '',
    },
    priceTableId: {
      type: [String, Number],
      default: '',
    },
    /// The string that will be pre-searched.
    startSearch: {
      type: String,
      default: null,
    },
    // to return product options active
    onlyActive: {
      type: Boolean,
      default: false,
    },

    defaultOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      skus: [],
      content: null,
    }
  },
  computed: {
    skusOptions() {
      const formatLabel = sku => {
        let result = sku.name
        if (sku.active === false) result += ` - ${this.$t('Inativo')}`
        return result
      }
      const options = this.skus.length > 0 ? this.skus : this.defaultOptions

      if (this.valueIsObject) {
        return options.map(sku => ({
          id: sku.id,
          value: { ...sku, label: formatLabel(sku) },
          label: formatLabel(sku),
        }))
      }

      return options.map(sku => ({
        ...sku,
        value: sku.id,
        label: formatLabel(sku),
      }))
    },
  },
  watch: {
    value(val) {
      this.content = val
    },
  },
  mounted() {},
  created() {
    // necessário para preencher o input com base no valor que vem da prop "value"
    this.content = this.value
  },
  methods: {
    handleInput() {
      this.$emit('input', this.content, this.skus)
    },
    async fetchSearchSku(loading, search) {
      if (!search || search.length < 3) {
        return
      }

      try {
        loading(true)
        let url = 'skus/search'
        let params = {
          search,
          name: search,
          active: this.onlyActive || null,
        }

        if (this.storeId) url = `sales/pay-box-sku/store/${this.storeId}/search`

        if (this.priceTableId) params = { ...params, priceTableId: this.priceTableId }

        this.skus = (await this.$http.get(`/api/${url}`, { params })).data.results
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      } finally {
        loading(false)
      }
    },
  },
}
</script>
