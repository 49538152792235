const basePath = '/bar'

export default [
  // barTabList
  {
    path: `${basePath}/gerenciar-comandas`,
    name: 'bar-tab-list',
    component: () => import('@/views/pages/pdv/pay-box-bar/BarTabList.vue'),
    props: { isErp: true },
    meta: {
      pageTitle: 'Gerenciar Comandas',
      resource: 'BarTabConsumption',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Bar',
        },
        {
          text: 'Gerenciar Comandas',
          active: true,
        },
      ],
    },
  },
  // histórico de consumos
  {
    path: `${basePath}/historico-consumo`,
    name: 'bar-tab-consumption-list',
    component: () => import('@/views/pages/pdv/pay-box-bar/BarTabConsumptionList.vue'),
    props: { isErp: true },
    meta: {
      pageTitle: 'Histórico de consumos',
      resource: 'BarTabConsumption',
      action: 'ReadHistory',
      breadcrumb: [
        {
          text: 'Bar',
        },
        {
          text: 'Histórico de consumos',
          active: true,
        },
      ],
    },
  },
]
