var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "star-rating" },
    [
      _vm._l(_vm.stars, function (star, index) {
        return _c(
          "span",
          {
            key: index,
            staticClass: "star",
            on: {
              mousemove: function ($event) {
                return _vm.highlightStar(index, $event)
              },
              mouseleave: _vm.resetHighlight,
              click: function ($event) {
                return _vm.setRating(index + 1)
              },
            },
          },
          [
            _c("b-icon", {
              attrs: { icon: _vm.getStarIcon(index), variant: "warning" },
            }),
          ],
          1
        )
      }),
      _vm.showNumber
        ? _c("span", { staticClass: "ml-250" }, [
            _vm._v("(" + _vm._s(_vm.rating) + ")"),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }