import axios from 'axios'

const downloader = {
  methods: {
    /**
     * Realiza o download de documentos.
     * @returns
     */
    async download(url, title, fileType, secure = false) {
      // https://s3.us-west-2.amazonaws.com/it.echope.hub.private/MINHA_IMAGEM.png
      if (secure) {
        const urlParts = url.split('/').filter(part => !!part)
        const [bucket, ...objectKey] = urlParts.slice(2)
        this.$http
          .get(
            `/backend/v1/s3?objectKey=${objectKey.join('/')}&bucket=${bucket}&fileType=${fileType || ''
            }`,
            {
              responseType: 'blob',
            }
          )
          .then(response => {
            this.forceFileDownload(response, title)
          })
          .catch(() =>
            this.showError({
              message: 'Não foi possível realizar o download deste arquivo',
            })
          )
      } else {
        axios({
          method: 'get',
          url,
          responseType: 'blob',
        })
          .then(response => {
            this.forceFileDownload(response, title)
          })
          .catch(error => {
            if (error.response?.status === 403 || error.response?.status === 401) {
              this.download(url, title, true)
            } else {
              this.showError({
                message: 'Não foi possível realizar o download deste arquivo',
              })
            }
          })
      }
    },

    forceFileDownloadXlsx(blobData, title) {
      this.forceFileDownload(blobData, `${title}.xlsx`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', })
    },

    /**
     *
     * @param {Blob} blobData respostas do axios precisam da opção "responseType: 'blob'"
     * @param {String} title nome do arquivo (necessário colocar a extensão)
     * @param {BlobPropertyBag} blobOptions opções na criação do tipo Blob
     */
    forceFileDownload(blobData, title, blobOptions = {}) {
      const url = window.URL.createObjectURL(new Blob([blobData], blobOptions))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
      link.remove()
    },

    forceFileDownloadFromBase64(base64, title, fileType) {
      const linkSource = `data:application/${fileType};base64,${base64}`
      const downloadLink = document.createElement('a')
      const fileName = `${title}.${fileType}`

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  },
}

export default downloader
