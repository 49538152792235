import store from '@/store'
// import { mapGetters } from 'vuex'

const locale = {
  computed: {
    locale() {
      return store.state.app.locale
    },
  },
}

export default locale
