import FormulateStatusBadgeMultiple from './FormulateStatusBadgeMultiple.vue'

export default function VueFormulateStatusBadgePlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateStatusBadgeMultiple,
    },
    library: {
      'status-badge-multiple': {
        classification: 'text',
        component: 'FormulateStatusBadgeMultiple',
      },
    },
  })
}
