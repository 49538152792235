import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import productKitMaintain from './product-kit-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  productKits: [],
  filter: {
    ean: '',
    name: '',
    active: null,
    activeOnline: null,
    period: {
      startDate: null,
      endDate: null,
    },
    storeId: null,
    region: null,
    province: null,
    city: '',
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: {
    productKitMaintain,
  },

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_PRODUCT_KITS(state, productKits) {
      state.productKits = productKits
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    CLEAN_STATE(state) {
      const { filter, paging, productKits } = getInitialState()
      state.filter = filter
      state.productKits = productKits
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchProductKits({ state, commit }) {
      const { name, active, ean, activeOnline, period, storeId, region, province, city } =
        state.filter

      const { data } = await axios.get('/api/product-kits', {
        params: {
          storeId,
          region,
          province,
          city,
          ean: ean || null,
          name: name || null,
          active,
          activeOnline,
          startDate: period.startDate,
          endDate: period.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state)
        },
      })
      commit('SET_PRODUCT_KITS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    setFilter({ commit }, filter) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', filter)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', getInitialState().filter)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
