import FormulateTextPercentage from './FormulateTextPercentage.vue'

export default function VueFormulateTextPercentagePlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateTextPercentage,
    },
    library: {
      'text-percetage': {
        classification: 'text',
        component: 'FormulateTextPercentage',
      },
    },
  })
}
