export default [
  {
    path: '/seguranca/usuarios',
    name: 'user-maintain',
    component: () => import('@/views/pages/security/user/UserMaintain.vue'),
    meta: {
      pageTitle: 'Usuários',
      navActiveLink: 'user-maintain',
      resource: 'User',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Segurança',
        },
        {
          text: 'Usuários',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seguranca/perfis-acesso',
    name: 'access-profile-list',
    component: () => import('@/views/pages/security/access-profile/AccessProfileList.vue'),
    meta: {
      pageTitle: 'Perfis de Acesso',
      navActiveLink: 'access-profile-list',
      resource: 'AccessProfile',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Segurança',
        },
        {
          text: 'Perfis de Acesso',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seguranca/perfis-acesso/adicionar',
    name: 'role-add',
    component: () => import('@/views/pages/security/access-profile/AccessProfileMaintain.vue'),
    meta: {
      pageTitle: 'Novo Perfil de Acesso',
      navActiveLink: 'access-profile-list',
      resource: 'AccessProfile',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Segurança',
        },
        {
          text: 'Perfis de Acesso',
        },
        {
          text: 'Novo Perfil de Acesso',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seguranca/perfis-acesso/manter/:id',
    name: 'role-maintain',
    component: () => import('@/views/pages/security/access-profile/AccessProfileMaintain.vue'),
    meta: {
      pageTitle: 'Manter Perfil de Acesso',
      navActiveLink: 'access-profile-list',
      resource: 'AccessProfile',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Segurança',
        },
        {
          text: 'Perfis de Acesso',
          to: '/seguranca/perfis-acesso',
        },
        {
          text: 'Manter Perfil de Acesso',
          active: true,
        },
      ],
    },
  }
]
