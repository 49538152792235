import { render, staticRenderFns } from "./FormulateOskNumberWithButtons.vue?vue&type=template&id=56671746&"
import script from "./FormulateOskNumberWithButtons.vue?vue&type=script&lang=js&"
export * from "./FormulateOskNumberWithButtons.vue?vue&type=script&lang=js&"
import style0 from "./FormulateOskNumberWithButtons.vue?vue&type=style&index=0&id=56671746&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56671746')) {
      api.createRecord('56671746', component.options)
    } else {
      api.reload('56671746', component.options)
    }
    module.hot.accept("./FormulateOskNumberWithButtons.vue?vue&type=template&id=56671746&", function () {
      api.rerender('56671746', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/formulate/on-screen-keyboard/osk-number-with-buttons/FormulateOskNumberWithButtons.vue"
export default component.exports