var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-input-group",
        { staticClass: "flex-nowrap" },
        [
          _c(
            "b-input-group-prepend",
            [
              _c(
                "b-form-datepicker",
                _vm._b(
                  {
                    class:
                      (_vm.context.attributes.class || "") +
                      " custom-formulate-btn-datepicker",
                    attrs: {
                      id: "btn_picker-" + _vm.elementId,
                      locale: _vm.locale,
                      "date-format-options": {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      },
                      "aria-controls": "example-input",
                      "show-decade-nav": "",
                      "button-only": "",
                      left: "",
                    },
                    on: { context: _vm.onDatePickerContext, input: _vm.onBlur },
                    model: {
                      value: _vm.tempValuePicker,
                      callback: function ($$v) {
                        _vm.tempValuePicker = $$v
                      },
                      expression: "tempValuePicker",
                    },
                  },
                  "b-form-datepicker",
                  Object.assign(
                    {},
                    _vm.context.attributes,
                    _vm.labels[_vm.locale] || {}
                  ),
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.dateMask,
                    expression: "dateMask",
                  },
                ],
                attrs: {
                  id: _vm.elementId,
                  type: "text",
                  placeholder: _vm.$t("momentFormat.datePlaceholder"),
                },
                domProps: { value: _vm.tempValue },
                on: { input: _vm.onInput, blur: _vm.onBlur },
              },
              "input",
              Object.assign(
                {},
                { disabled: _vm.context.attributes.disabled },
                _vm.inputAttr
              ),
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }