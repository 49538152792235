import axios from 'axios'
import isElectron from 'is-electron'
import i18n from '@/libs/i18n'
import { isJson } from './object-utils'

const exceptionTypeEnum = {
  BUSINESS_RULE: 'BusinessRule',
  NOT_FOUND: 'NotFound',
  PERMISSION_DENIED: 'PermissionDenied',
}

function getApiErrorData(apiError) {
  const result = {
    message: '',
    exceptionType: '',
    args: { text: '', text_list: '' },
  }

  if (typeof apiError?.response?.data === 'string' || apiError?.response.data?.status === 500) {
    return result
  }

  const keys = Object.keys(apiError.response.data)
  const firstMessage = apiError.response.data[keys[0]] ? apiError.response.data[keys[0]][0] : ''
  if (keys.length === 1 && typeof firstMessage === 'string' && firstMessage.includes('|||[')) {
    const [mainError, strItems] = firstMessage.split('|||')
    const itemsList = JSON.parse(strItems)
    result.message = mainError
    result.args = { ...result.args, text_list: itemsList }
  } else if (keys.length === 1 && typeof firstMessage === 'string' && firstMessage.includes('|')) {
    const [mainError, strValue] = firstMessage.split('|')
    result.message = mainError
    result.args = { ...result.args, text: strValue }
  } else if (isJson(firstMessage)) {
    const parsed = JSON.parse(firstMessage)
    const { args, message, exeptionType } = parsed

    result.message = message
    result.exceptionType = exeptionType
    result.args = args
  }

  return result
}

function getApiErrorDataMessage(errorData) {
  const { message, args } = errorData

  let listLength = 1
  Object.entries(args).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const item = value.map(i => `- ${i18n.t(i)} <br>`).join('')
      listLength = value.length
      args[key] = `<br> ${item}`
    }
  })

  return i18n.tc(message, listLength, args)
}

// We format error message so it shows properly in electron logs, currently some objects it fails to send to electron process
function parseErrorObject(error) {
  try {
    if (!error) return null

    if (!axios.isAxiosError(error)) {
      return isElectron() ? JSON.stringify(error) : error
    }
    const echopeHeaders = {}
    if (error.config?.headers) {
      const { headers } = error.config
      Object.keys(headers).forEach(key => {
        if (key.toLowerCase().startsWith('echope')) {
          echopeHeaders[key] = headers[key]
        }
      })
    }

    const errorFormatted = {
      name: error.name,
      code: error.code,
      message: error.message,
      method: error.config?.method,
      url: error.config?.url,
      echopeHeaders,
      response: error.response?.response || error?.response?.responseText,
    }

    return isElectron() ? JSON.stringify(errorFormatted) : errorFormatted
  } catch (err) {
    /* eslint-disable no-console */
    console.error(err)
    return isElectron() ? JSON.stringify(error) : error
  }
}

export { getApiErrorData, parseErrorObject, getApiErrorDataMessage, exceptionTypeEnum }
