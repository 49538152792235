import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
/* eslint-disable import/no-cycle */
import router from '@/router'

//  Modules
import useJwt from '@/auth/jwt/useJwt'
import { vuexPlugin } from '@/frontend-monitoring'
import { initSendToS3BackupsAndLogs } from '@/electron-app/frontend-send-to-s3'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import pages from './pages'
import common from './common'

const plugins = []
const vuexPluginApp = vuexPlugin()
if (vuexPluginApp) vuexPluginApp.forEach(p => plugins.push(p))

Vue.use(Vuex)

try {
  initSendToS3BackupsAndLogs()
} catch (_) {
  // ignored
}

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    pages,
    common,
  },
  strict: process.env.DEV,
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
  actions: {
    logout() {
      useJwt.logout()
      router.push({
        name: 'auth-login',
        params: { sessionExpired: true, redirect: router.currentRoute },
      })
    },
  },
  plugins,
})
