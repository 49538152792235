import { discountTypes } from '@/mixins'
import axios from '@axios'
import { localListAddItem, localListDeleteItem, localListUpdateItem } from '@/store/utils'

export const getInitialKitItemForm = () => ({
  id: null,
  skuProduct: null,
  quantity: 0,
  quantityIsEditable: false,
  unitDiscount: 0,
  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  isDeleted: false,
})

const getInitialState = () => ({
  kit: {
    id: null,
    ean: '',
    name: '',
    description: '',
    activeOnline: false,
    isBeerCoolerKit: false,
    isCredit: false,
    isAvailableForChoppGiftCard: false,
    creditType: null,
    period: {
      startDate: null,
      endDate: null,
    },
    allowAllStores: false,
    productKitStores: [],
    storesSelected: [],
    kitItems: [],
    imageWide: [],
    imageSquare: [],
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getDiscountType(state) {
      return state.kit.discountType
    },

    getKitItems(state) {
      return state.kit.kitItems.filter(k => !k.isDeleted)
    },
  },

  mutations: {
    SET_PRODUCT_OPTIONS(state, options) {
      state.productOptions = options
    },

    SET_KIT(state, kit) {
      state.kit = kit
    },

    ADD_KIT_ITEM(state, kitItem) {
      state.kit.kitItems = localListAddItem(state.kit.kitItems, kitItem)
    },

    UPDATE_KIT_ITEM(state, { kitItem }) {
      state.kit.kitItems = localListUpdateItem(state.kit.kitItems, kitItem)
    },

    REMOVE_KIT_ITEM(state, id) {
      state.kit.kitItems = localListDeleteItem(state.kit.kitItems, id)
    },

    CLEAN_STATE(state) {
      const { kit } = getInitialState()
      state.kit = kit
    },
  },

  actions: {
    async loadKitById({ commit }, id) {
      const { data } = await axios.get(`/api/product-kits/${id}`)

      const kitItems = data.products.map(p => ({
        ...p,
        skuProduct: { ...p.sku, label: p.sku.name },
      }))

      commit('SET_KIT', {
        ...data,
        period: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
        productKitStores: data.stores || [],
        storesSelected: data.stores
          ? data.stores.map(productKitStore => productKitStore.store.id.toString())
          : [],
        kitItems,
      })
    },

    addKitItem({ commit }, kitItem) {
      commit('ADD_KIT_ITEM', kitItem)
    },

    updateKitItem({ commit }, { kitItem, index }) {
      commit('UPDATE_KIT_ITEM', { kitItem, index })
    },

    removeKitItem({ commit }, index) {
      commit('REMOVE_KIT_ITEM', index)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
