<template>
  <div class="star-rating">
    <span
      v-for="(star, index) in stars"
      :key="index"
      class="star"
      @mousemove="highlightStar(index, $event)"
      @mouseleave="resetHighlight"
      @click="setRating(index + 1)"
    >
      <b-icon
        :icon="getStarIcon(index)"
        variant="warning"
      />
    </span>
    <span
      v-if="showNumber"
      class="ml-250"
    >({{ rating }})</span>
  </div>
</template>
<script>
import { BIcon } from 'bootstrap-vue'

export default {
  name: 'StarRating',
  components: {
    BIcon,
    // BIconStarFill,
    // BIconStarHalf,
    // BIconStar,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // rating: this.value,
      hoverRating: 0,
    }
  },
  computed: {
    stars() {
      return Array(5).fill(0)
    },
    rating() {
      return this.context.value || this.context?.model
    },
    showNumber() {
      return this.context?.attributes?.showNumber || this.context?.attributes['show-number']
    },
  },
  watch: {
    value(newVal) {
      this.rating = newVal
    },
  },
  methods: {
    setRating(rating) {
      const preciseRating = this.hoverRating ? this.hoverRating : rating
      this.rating = preciseRating
      this.$emit('input', this.rating)
    },
    highlightStar(index, event) {
      const starWidth = event.currentTarget.offsetWidth
      const hoverPosition = event.offsetX
      this.hoverRating = index + (hoverPosition >= starWidth / 2 ? 1 : 0.5)
    },
    resetHighlight() {
      this.hoverRating = 0
    },
    getStarIcon(index) {
      const fullRating = Math.floor(this.rating)
      const halfRating = this.rating - fullRating
      if (index < fullRating) {
        return 'star-fill'
      }
      if (index === fullRating && halfRating >= 0.5) {
        return 'star-half'
      }
      return 'star'
    },
  },
}
</script>

<style scoped>
.star-rating {
  display: flex;
}
.star {
  cursor: pointer;
  margin-right: 5px;
}
</style>
