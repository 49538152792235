import delegablePermissions from '@/utils/delegable-permissions'
import { mapState } from 'vuex'
import { payBoxSale, productDomains } from '.'

const authorizationRules = {
  computed: {
    ...mapState('app', ['modalAuthRef']),
  },

  methods: {
    /**
     * @param {*} quantityToAdd valor positivo acréscimo, valor negativo decréscimo
     * @returns
     */
    async authAddProductWithNegativeStock(
      product,
      quantityToAdd,
      itemsAdded,
      tempDelegations = [],
      storeId = null
    ) {
      const result = {
        delegateUserData: null,
        productStock: product.stock,
        kitItemName: '',
      }

      if (product.kitItems?.length > 0) {
        const hasItemWithoutStock = product.kitItems.some(ki => {
          const kiProduct = ki.product || ki
          const singleItem = itemsAdded.find(
            i => i.product?.skuId != null && i.product?.skuId === kiProduct.skuId
          )

          if (singleItem) {
            const quantityInSale = payBoxSale.methods.getProductQuantityInSale(
              itemsAdded,
              kiProduct.skuId
            )
            const quantityReserved = payBoxSale.methods.getProductQuantityInSale(
              itemsAdded,
              kiProduct.skuId,
              { originalOrder: true }
            )

            const stockOnSale =
              quantityInSale - quantityReserved + (quantityToAdd * kiProduct.quantity || 0)

            if (stockOnSale > singleItem.product?.stock) {
              result.kitItemName = kiProduct.name
              return true
            }
            return false
          }

          return false
        })

        if (hasItemWithoutStock) {
          // -1 pois algum dos itens dentro do kit excedeu o estoque local
          result.productStock = -1
        } else {
          const qttKitInSale = payBoxSale.methods.getKitQuantityInSale(itemsAdded, product.id)
          const qttKitReserved = payBoxSale.methods.getKitQuantityInSale(itemsAdded, product.id, {
            originalOrder: true,
          })
          const quantityAdded = qttKitInSale - qttKitReserved
          result.productStock =
            Math.floor(product.stock) - ((quantityAdded || 0) + (quantityToAdd || 0))
        }
      } else {
        const quantityInSale = payBoxSale.methods.getProductQuantityInSale(
          itemsAdded,
          product.skuId
        )
        const quantityReserved = payBoxSale.methods.getProductQuantityInSale(
          itemsAdded,
          product.skuId,
          { originalOrder: true }
        )

        const quantityAdded = quantityInSale - quantityReserved
        result.productStock = product.stock - ((quantityAdded || 0) + (quantityToAdd || 0))
      }

      const alreadyHasPermission = tempDelegations.some(delegation =>
        delegation?.permissions.some(
          tp => tp === delegablePermissions.PDV_PRODUCT_ADD_PRODUCT_NEGATIVE_STOCK.permission
        )
      )
      if (alreadyHasPermission) return result

      const { CREDIT } = productDomains.computed.productClassificationsEnum()
      if (
        result.productStock < 0 &&
        !product.kitItems?.some(kitItem => kitItem.classification === CREDIT)
      ) {
        result.delegateUserData = await this.modalAuthRef.auth(
          delegablePermissions.PDV_PRODUCT_ADD_PRODUCT_NEGATIVE_STOCK,
          storeId
        )
      }

      return result
    },
  },
}

export default authorizationRules
