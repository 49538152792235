var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    { staticClass: "h3" },
    [
      _vm.selected
        ? _c("b-icon", {
            attrs: { icon: "check-circle-fill", variant: "success" },
          })
        : _c("b-icon", { attrs: { icon: "x-circle-fill", variant: "danger" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }