var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _vm._t("before"),
      _c(
        "v-select",
        _vm._b(
          {
            ref: "select",
            class:
              "formulate-input-element formulate-input-element--" +
              _vm.context.type,
            staticStyle: { width: "100%" },
            attrs: {
              "data-type": _vm.context.type,
              reduce: function (x) {
                return x.value
              },
              options: _vm.context.options,
              label: "label",
              filterable: false,
            },
            on: { close: _vm.onClose, search: _vm.onSearch },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function () {
                  return [_vm._v(" " + _vm._s(_vm.noOptionsMessage) + " ")]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.context.model,
              callback: function ($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model",
            },
          },
          "v-select",
          _vm.context.attributes,
          false
        )
      ),
      _vm._t("after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }