<template>
  <div
    class="w-100 d-flex align-items-start"
    style="gap: 10px"
  >
    <div>
      <p class="h6">
        {{ $t('Legenda:') }}
      </p>
    </div>

    <div
      class="d-flex overflow-auto"
      style="gap: 0 10px"
    >
      <div
        v-for="(color, index) in colors"
        :key="`${index}_${color.title}`"
        class="mx-1 container-subtitled-item d-flex justify-content-start align-items-center flex-column flex-wrap"
        style="gap: 5px"
      >
        <div
          :class="`subtitled-item border-dark ${color.backgroundColor}`"
          :style="{ 'background-color': color.backgroundStyleColor, border: color.border }"
        />
        <a
          v-if="color.link"
          :href="color.link"
          class="text-center"
        >
          {{ color.title }}
        </a>
        <p
          v-else
          class="text-center"
        >
          {{ color.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EColorSubtitled',
  components: {},
  props: {
    /**
     * lista de objetos
     * { backgroundColor, title }
     */
    colors: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.container-subtitled-item {
  max-width: 120px;
}

.container-subtitled-item a {
  color: inherit;
}

.subtitled-item {
  box-sizing: border-box;
  width: 50px;
  height: 20px;
}
</style>
