import axios from '@axios'

const getInitialState = () => ({
  reasons: [],
})

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getComboReasons(state) {
      return state.reasons.map(r => ({
        ...r,
        label: r.name,
        value: r.id,
      }))
    },
  },

  mutations: {
    SET_REASONS(state, reasons) {
      state.reasons = reasons
    },

    CLEAN_STATE(state) {
      const { reasons } = getInitialState()
      state.reasons = reasons
    },
  },

  actions: {
    async fetchReasons({ commit }) {
      const { data } = await axios.get('/api/stock-movement-reasons', {
        params: { pageSize: 0, pageIndex: 0 }
      })

      commit('SET_REASONS', data.results || [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
