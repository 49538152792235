import store from '@/store'

/**
 * use em inputs do bootstrap ou nativos, no formulate a diretiva não mantém reativo, ele só preenche o input no próprio elemento.
 * uso da diretiva: v-keyboard="{layout: 'mobile'}"
 */
export default {
  inserted(el, binding) {
    const isVirtualKeyboardEnabled = store.getters['app/isVirtualKeyboardEnabled']

    if (!isVirtualKeyboardEnabled) return

    let element = el
    const isFormulateInput = el.classList.contains('formulate-input')

    if (isFormulateInput) {
      element = el.querySelector('input')
    }

    element.addEventListener('focus', () => {
      store.dispatch('app/simpleKeyboard/showKeyboard', {
        el: element,
        layoutType: binding?.value?.layout,
      })
    })

    element.addEventListener('blur', () => {
      store.dispatch('app/simpleKeyboard/hideKeyboard')
    })
  },
}
