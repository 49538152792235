const basePath = '/catalogo'

export default [
  // marcas
  {
    path: `${basePath}/marcas`,
    name: 'brand-list',
    component: () => import('@/views/pages/catalog/brand/Brand.vue'),
    meta: {
      pageTitle: 'Marcas',
      navActiveLink: 'brand-list',
      resource: 'Brand',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Marcas',
          active: true,
        },
      ],
    },
  },
  // produtos list
  {
    path: `${basePath}/produtos`,
    name: 'product-list',
    component: () => import('@/views/pages/catalog/product/ProductList.vue'),
    meta: {
      pageTitle: 'Produtos',
      navActiveLink: 'product-list',
      // resource: 'Brand',
      // action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Produtos',
          active: true,
        },
      ],
    },
  },
  // produtos add
  {
    path: `${basePath}/produtos/adicionar`,
    name: 'product-add',
    component: () => import('@/views/pages/catalog/product/ProductMaintain.vue'),
    meta: {
      pageTitle: 'Novo Produto',
      navActiveLink: 'product-list',
      resource: 'Product',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Produtos',
          to: `${basePath}/produtos`,
        },
        {
          text: 'Novo Produto',
          active: true,
        },
      ],
    },
  },
  // produtos manter
  {
    path: `${basePath}/produtos/manter/:id`,
    name: 'product-maintain',
    component: () => import('@/views/pages/catalog/product/ProductMaintain.vue'),
    meta: {
      pageTitle: 'Manter Produto',
      navActiveLink: 'product-list',
      resource: 'Product',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Produtos',
          to: `${basePath}/produtos`,
        },
        {
          text: 'Manter Produto',
          active: true,
        },
      ],
    },
  },
  // produtos visualizar
  {
    path: `${basePath}/produtos/visualizar/:id`,
    name: 'product-view',
    component: () => import('@/views/pages/catalog/product/ProductMaintain.vue'),
    meta: {
      pageTitle: 'Detalhes do Produto',
      navActiveLink: 'product-list',
      // resource: 'Product',
      // action: 'Create',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Produtos',
          to: `${basePath}/produtos`,
        },
        {
          text: 'Detalhes do Produto',
          active: true,
        },
      ],
    },
  },
  // custo médio de produtos
  {
    path: `${basePath}/produtos/custo-medio`,
    name: 'product-average-cost-list',
    component: () => import('@/views/pages/catalog/product-average-cost/ProductAverageCostList.vue'),
    meta: {
      pageTitle: 'Custo médio de produtos',
      navActiveLink: 'product-average-cost-list',
      resource: 'Product',
      action: 'UpdateAveragePrice',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Custo médio de produtos',
          active: true,
        },
      ],
    },
  },
  // Serviços list
  {
    path: `${basePath}/servicos`,
    name: 'service-list',
    component: () => import('@/views/pages/catalog/service/ServiceList.vue'),
    meta: {
      pageTitle: 'Serviços',
      navActiveLink: 'service-list',
      // resource: 'Service',
      // action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Serviços',
          active: true,
        },
      ],
    },
  },
  // grupos
  {
    path: `${basePath}/grupos-de-produtos`,
    name: 'product-group-list',
    component: () => import('@/views/pages/catalog/product-group/ProductGroup.vue'),
    meta: {
      pageTitle: 'Grupos',
      navActiveLink: 'product-group-list',
      resource: 'ProductGroup',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Grupos',
          active: true,
        },
      ],
    },
  },
  // Subgrupos
  {
    path: `${basePath}/subgrupos-de-produtos`,
    name: 'product-subgroup-list',
    component: () => import('@/views/pages/catalog/product-subgroup/ProductSubgroup.vue'),
    meta: {
      pageTitle: 'Subgrupos',
      resource: 'ProductSubgroup',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Subgrupos',
          active: true,
        },
      ],
    },
  },
  // departamentos
  {
    path: `${basePath}/departamentos`,
    name: 'product-departament-list',
    component: () => import('@/views/pages/catalog/product-department/ProductDepartment.vue'),
    meta: {
      pageTitle: 'Departamentos',
      navActiveLink: 'product-departament-list',
      resource: 'ProductDepartment',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Departamentos',
          active: true,
        },
      ],
    },
  },
  // variantes
  {
    path: `${basePath}/variantes`,
    name: 'product-variant-list',
    component: () => import('@/views/pages/catalog/variation/Variation.vue'),
    meta: {
      pageTitle: 'Variantes',
      resource: 'Variation',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Variantes',
          active: true,
        },
      ],
    },
  },
  // linhas
  {
    path: `${basePath}/linhas`,
    name: 'product-line-list',
    component: () => import('@/views/pages/catalog/product-line/ProductLine.vue'),
    meta: {
      pageTitle: 'Linhas',
      navActiveLink: 'product-line-list',
      resource: 'ProductLine',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Linhas',
          active: true,
        },
      ],
    },
  },
  // unidade de medida
  {
    path: `${basePath}/unidades-de-medida`,
    name: 'unit-of-measurement-list',
    component: () => import('@/views/pages/catalog/product-unit/ProductUnit.vue'),
    meta: {
      pageTitle: 'Unidades de Medida',
      navActiveLink: 'unit-of-measurement-list',
      resource: 'UnitOfMeasurement',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Unidades de Medida',
          active: true,
        },
      ],
    },
  },
  // kit de produtos list
  {
    path: `${basePath}/kit-produtos`,
    name: 'product-kit-list',
    component: () => import('@/views/pages/catalog/product-kit/ProductKitList.vue'),
    meta: {
      pageTitle: 'Kit de Produtos',
      navActiveLink: 'product-kit-list',
      resource: 'ProductKit',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Kits de Produtos',
          active: true,
        },
      ],
    },
  },
  // kit de produtos add
  {
    path: `${basePath}/kit-produtos/adicionar`,
    name: 'product-kit-add',
    component: () => import('@/views/pages/catalog/product-kit/ProductKitMaintain.vue'),
    meta: {
      pageTitle: 'Novo Kit de Produtos',
      navActiveLink: 'product-kit-list',
      resource: 'ProductKit',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Kits de Produtos',
          to: `${basePath}/kit-produtos`,
        },
        {
          text: 'Novo Kit de produto',
          active: true,
        },
      ],
    },
  },
  // kit de produtos maintain
  {
    path: `${basePath}/kit-produtos/manter/:id`,
    name: 'product-kit-maintain',
    component: () => import('@/views/pages/catalog/product-kit/ProductKitMaintain.vue'),
    meta: {
      pageTitle: 'Manter Kit de Produtos',
      navActiveLink: 'product-kit-list',
      resource: 'ProductKit',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Catálogo',
        },
        {
          text: 'Kits de Produtos',
          to: `${basePath}/kit-produtos`,
        },
        {
          text: 'Manter Kit de produtos',
          active: true,
        },
      ],
    },
  },
]
