import { financialDomain } from '@/mixins'
import moment from 'moment'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const defaultFilter = () => ({
  period: {
    startDate: moment().add(-30, 'days').startOf('day').format(),
    endDate: moment().add(30, 'days').endOf('day').format(),
  },
  storeId: null,
})

export const getInitialState = () => ({
  ...storePagination.state(),
  filtersToReport: {
    ...defaultFilter(),
    paymentStatus: null,
  },
  sorting: {
    sortBy: 'id',
    sortDesc: true,
  },
  reconciliationPedingItenss: [],
  filters: defaultFilter(),
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    CLEAN_STATE(state) {
      const { filters, paging, reportData } = getInitialState()
      state.filters = filters
      state.paging = paging
      state.reportData = reportData
    },

    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    async generateReport({ state }) {
      const { storeId, periodType, period, valueFrom, valueTo } = state.filtersToReport

      const { startDate, endDate } = period
      const { CREATED_DATE, DUE_DATE, ISSUE_DATE, PAYMENT_DATE } =
        financialDomain.data().receivablePeriodTypeEnum
      const periodParams = {
        [CREATED_DATE]: {
          createdDateFrom: startDate,
          createdDateTo: endDate,
        },
        [DUE_DATE]: {
          dueDateFrom: startDate,
          dueDateTo: endDate,
        },
        [ISSUE_DATE]: {
          issueDateFrom: startDate,
          issueDateTo: endDate,
        },
        [PAYMENT_DATE]: {
          paymentDateFrom: startDate,
          paymentDateTo: endDate,
        },
      }
      const periodFilter = periodParams[periodType] || {}

      const params = {
        storeId,
        valueFrom,
        valueTo,
        startDate: period?.startDate,
        endDate: period?.endDate,
        sortBy: 'CreatedDate ASC',
        pageSize: 0,
        pageIndex: 0,
        ...periodFilter,
      }

      const { data } = await axios.get(
        '/api/reports/financial/reconciliation/GetnetReconciliationPedingItems/download',
        {
          params,
          responseType: 'blob',
        }
      )

      return data
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },
  },
}
