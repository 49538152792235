var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "date-range-picker",
    _vm._b(
      {
        ref: "picker",
        attrs: {
          "locale-data": _vm.localeData,
          "time-picker": _vm.timePicker,
          "time-picker24-hour": true,
          "show-week-numbers": false,
          "show-dropdowns": true,
          "auto-apply": true,
          "linked-calendars": true,
          separator: _vm.$t("até"),
          ranges: _vm.dateRanges,
          "control-container-class": "form-control reportrange-text",
          opens: _vm.popupOpens(_vm.$el),
        },
        on: {
          update: _vm.context.blurHandler,
          blur: _vm.context.blurHandler,
          "start-selection": _vm.onSelectStartDate,
          "finish-selection": _vm.onSelectFinishDate,
        },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                  },
                  [
                    _c("span", { attrs: { id: _vm.context.attributes.id } }, [
                      _vm._v(" " + _vm._s(_vm.periodLabel) + " "),
                    ]),
                    _vm.clearable && _vm.showClearButton
                      ? _c(
                          "b-button",
                          {
                            staticClass: "p-0 m-0",
                            staticStyle: { padding: "5px" },
                            attrs: {
                              id: "btn_clear-" + _vm.context.attributes.id,
                              variant: "outline-light",
                              pressed: "",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticStyle: { cursor: "pointer" },
                              attrs: { icon: "x", scale: "1.1" },
                              on: {
                                click: function ($event) {
                                  return _vm.cleanInput($event)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.context.model,
          callback: function ($$v) {
            _vm.$set(_vm.context, "model", $$v)
          },
          expression: "context.model",
        },
      },
      "date-range-picker",
      _vm.context.attributes,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }