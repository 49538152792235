import axios from '@axios'
import { sorting, storePagination } from '@/mixins'
import moment from 'moment'
import invoiceInvalidateMaintain from './invoice-invalidate-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  gaps: [],
  filters: {
    storeIds: [],
    model: null,
    startDate: moment().add(-3, 'months').startOf('day').format(),
  },
})

export default {
  namespaced: true,
  modules: {
    invoiceInvalidateMaintain,
  },
  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_GAPS(state, gaps) {
      state.gaps = gaps
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, gaps } = getInitialState()
      state.filters = filters
      state.gaps = gaps
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchInvoicesInvalidatesGaps({ commit, state }) {
      const { data } = await axios.get('/api/invoices/invalidates/gap', {
        params: {
          ...state.filters,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_GAPS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
