import FormulateImage from './FormulateImage.vue'

export default function VueFormulateImagePlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateImage,
    },
    library: {
      image: {
        classification: 'text',
        component: 'FormulateImage',
      },
    },
  })
}
