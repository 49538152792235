import axios from '@axios'
import { sorting, storePagination } from '@/mixins'

const getInitialState = () => ({
  ...storePagination.state(),
  invoices: [],
  invoiceSelected: null,
  invoiceXml: null,
  invoicePdf: null,
  sorting: {
    sortBy: 'issueDate',
    sortDesc: true,
  },
  filters: {
    accessKey: null,
    storeId: null,
    supplierDocument: null,
    model: null,
    invoiceNumber: null,
    serialNumber: null,
    reason: null,
    status: 'Pending',
    issueDate: {
      startDate: null,
      endDate: null,
    },
    dueDate: null,
    cfop: null,
  },
})

export default {
  namespaced: true,
  modules: {},
  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_INVOICES(state, invoices) {
      state.invoices = invoices
    },

    SET_INVOICE(state, invoice) {
      state.invoiceSelected = invoice
    },

    SET_INVOICE_XML(state, invoiceXml) {
      state.invoiceXml = invoiceXml
    },

    SET_INVOICE_PDF(state, invoicePdf) {
      state.invoicePdf = invoicePdf
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, invoices } = getInitialState()
      state.filters = filters
      state.invoices = invoices
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchInvoices({ commit, state }) {
      const {
        storeId,
        supplierDocument,
        model,
        cfop,
        reason,
        invoiceNumber,
        serialNumber,
        issueDate,
        status,
        dueDate,
      } = state.filters

      const { data } = await axios.get('/api/invoices-received', {
        params: {
          storeId,
          supplierDocument,
          model,
          cfop: cfop ? cfop.split('-')[0].trim() : null,
          reason,
          invoiceNumber,
          serialNumber,
          status,
          issueDateFrom: issueDate.startDate,
          issueDateTo: issueDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
          dueDate,
        },
      })

      commit('SET_INVOICES', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchInvoiceXml({ state, commit }, invoiceId) {
      const { data } = await axios.get(`/api/invoices-received/${invoiceId}/xml`)
      const invoiceSelected = state.invoices.find(invoice => invoice.id === invoiceId)

      commit('SET_INVOICE_XML', data)
      commit('SET_INVOICE', invoiceSelected)
    },

    async fetchInvoicePdf({ state, commit }, invoiceId) {
      const { data } = await axios.get(`/api/invoices-received/${invoiceId}/pdf`)
      const invoiceSelected = state.invoices.find(invoice => invoice.id === invoiceId)

      commit('SET_INVOICE_PDF', data)
      commit('SET_INVOICE', invoiceSelected)
    },

    async acknowledgeInvoice(
      { dispatch },
      { invoiceId, reason, managementAccountId, observation }
    ) {
      await axios.put(`/api/invoices-received/${invoiceId}/acknowledge`, {
        reason,
        managementAccountId,
        observation,
      })

      dispatch('fetchInvoices')
    },

    async downloadReport({ state }) {
      const {
        storeId,
        supplierDocument,
        model,
        cfop,
        reason,
        invoiceNumber,
        serialNumber,
        issueDate,
        status,
        dueDate,
      } = state.filters

      const { data } = await axios.get('/api/invoices-received/reports/download', {
        responseType: 'blob',
        params: {
          storeId,
          supplierDocument,
          model,
          cfop: cfop ? cfop.split('-')[0].trim() : null,
          reason,
          invoiceNumber,
          serialNumber,
          status,
          issueDateFrom: issueDate.startDate,
          issueDateTo: issueDate.endDate,
          pageSize: 0,
          pageIndex: 0,
          sortBy: sorting.methods.getSorting(state),
          dueDate,
        },
      })

      return data
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
