<template>
  <FormulateInput
    v-if="isReadOnly"
    v-model="content"
    v-bind="$attrs"
    type="label"
    :label="label"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    :options="getLocalOptions"
  />
  <FormulateInput
    v-else
    v-model="content"
    v-bind="$attrs"
    type="vue-select"
    :multiple="multiple"
    :label="showLabel ? label : ''"
    :placeholder="placeholder"
    :options="getLocalOptions"
    :class="showLabel && required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    @input="handleInput"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},

  mixins: [],

  props: {
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default() {
        return this.$t('Forma de pagamento')
      },
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('Selecione')
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    valueIsObject: {
      type: Boolean,
      default: false,
    },

    flag: {
      type: String,
      default: 'all',
      validation(value) {
        return [
          'all',
          'payBoxAccountsPayable',
          'pdv',
          'payBoxReturn',
          'invoiceReturn',
          'invoiceSale',
          'invoiceTransference',
          'invoiceStockLosses',
          'invoiceProductBonus',
          'invoiceStoreLending',
        ].includes(value)
      },
    },
  },

  data() {
    return {
      content: null,
    }
  },

  computed: {
    ...mapGetters('app', ['paymentMethodsOptions', 'paymentMethodsObjectOptions']),

    getLocalOptions() {
      const options =
        this.valueIsObject && !this.isReadOnly
          ? this.paymentMethodsObjectOptions
          : this.paymentMethodsOptions
      switch (this.flag) {
        case 'payBoxAccountsPayable':
          return options.filter(opt => opt.activeOnPayBoxAccountsPayable)
        case 'pdv':
          return options.filter(opt => opt.activeOnPayBox)
        case 'payBoxReturn':
          return options.filter(opt => opt.activeOnPayBoxReturn)
        case 'invoiceReturn':
          return options.filter(opt => opt.activeOnInvoiceCategoryReturn)
        case 'invoiceSale':
          return options.filter(opt => opt.activeOnInvoiceCategorySale)
        case 'invoiceTransference':
          return options.filter(opt => opt.activeOnInvoiceCategoryTransference)
        case 'invoiceStockLosses':
          return options.filter(opt => opt.activeOnInvoiceCategoryStockLosses)
        case 'invoiceProductBonus':
          return options.filter(opt => opt.activeOnInvoiceCategoryProductBonus)
        case 'invoiceStoreLending':
          return options.filter(opt => opt.activeOnInvoiceCategoryStoreLending)
        default:
          return options
      }
    },
  },

  watch: {
    value(val) {
      if (this.valueIsObject) {
        this.content = this.isReadOnly ? String(val.id) : val
      } else {
        this.content = val
      }
    },

    isReadOnly(val) {
      // Se valor do input é um objeto, quando altera o isReadOnly ele não alterava o content pra objeto
      if (this.valueIsObject) {
        this.content = val ? String(this.value.id) : this.value
      }
    },
  },

  created() {
    if (this.value) {
      if (this.valueIsObject) {
        this.content = this.isReadOnly ? String(this.value.id) : this.value
      } else {
        this.content = String(this.value)
      }
    }
  },

  methods: {
    handleInput() {
      this.$emit('input', this.content)
    },
  },
}
</script>
