<template>
  <div
    :style="cssProps"
    class="container-checkbox"
    @click="handle"
  >
    <div class="wrapper-checkbox">
      <input
        ref="input"
        v-model="model"
        type="checkbox"
        :value="inputValue"
      >
      <label>{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormulateCheckboxButton',
  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    inputValue: {
      type: String,
      required: false,
      default: '',
    },
    bgColor: {
      type: String,
      required: false,
      default: '',
    },
    textColor: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    cssProps() {
      return {
        '--bg-color': this.bgColor ? this.bgColor : '#333',
        '--text-color': this.textColor ? this.textColor : '#fff',
      }
    },
  },
  methods: {
    handle() {
      const value = [...this.value]

      const index = value.indexOf(this.inputValue)
      if (index > -1) {
        value.splice(index, 1)
      } else {
        value.push(this.inputValue)
      }

      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.container-checkbox {
  display: inline-block;
  cursor: pointer;

  .wrapper-checkbox {
    display: flex;
    background-color: var(--bg-color);
    padding: 2px 15px;
    border-radius: 5px;
    align-items: center;

    label {
      margin-bottom: 0px;
      cursor: pointer;
      font-weight: 900;
      color: var(--text-color);
    }

    input {
      margin-right: 5px;
      padding: 0.5em;
    }
  }
}
</style>
