import sales from './sales'
import reconciliation from './reconciliation'

export default {
  namespaced: true,
  modules: {
    sales,
    reconciliation,
  },
  state: {},
  mutations: {},
  actions: {},
}
