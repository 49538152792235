import axiosInstance from '@/libs/axios'
import i18n from '@/libs/i18n'
import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import cardMaintain from './card-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  cards: [],
  filters: { name: '', status: '', type: '', onlineActive: '', imageLocal: '' },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { cardMaintain },

  getters: {
    getCardsType() {
      return [
        { value: 'CREDIT', label: i18n.t('Crédito') },
        { value: 'DEBIT', label: i18n.t('Débito') },
      ]
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_CARDS(state, cards) {
      state.cards = cards
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, cards } = getInitialState()
      state.filters = filters
      state.cards = cards
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchCards({ commit, state }) {
      const { data } = await axiosInstance.get('/api/settings/financial/cards', {
        params: {
          name: state.filters.name,
          onlineActive: state.filters.onlineActive,
          imageLocal: state.filters.imageLocal,
          type: state.filters.type,
          active: state.filters.status,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_CARDS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
    },

    setFilters({ commit }, filters) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
