const formulateHelper = {
  methods: {
    /* eslint-disable consistent-return */
    getField(name, schema) {
      /* eslint-disable no-restricted-syntax,consistent-return */
      for (const element of schema) {
        if (element.name === name) {
          return element
        }
        if (element.children?.length) {
          const found = this.getField(name, element.children)
          if (found) {
            return found
          }
        }
      }
    },

    filterSuggestions(data, text, field) {
      return data
        .filter(item => item[field].toLowerCase().indexOf(text?.toLowerCase()) > -1)
        .sort()
    },

    /**
     * To focus and select the input
     * @param {String} selector
     * @param {Boolean} isSelect
     */
    focusInput(selector, isSelect = true) {
      this.$nextTick(() => {
        const el = document.querySelector(selector)
        if (el) {
          el.focus()
          if (isSelect) {
            setTimeout(() => {
              el.select()
            }, 650)
          }
        }
      })
    },

    /**
     * To focus and select the input
     * @param {String} selector
     * @param {Boolean} isSelect
     */
    focusInputWithTimeout(selector, timeout = 100) {
      this.$nextTick(() => {
        const el = document.querySelector(selector)
        if (el) {
          setTimeout(() => {
            el.select()
          }, timeout)
        }
      })
    },

    /**
     * To focus and select the input
     * @param {object} ref Object ref ($$refs) of the element that will be focused
     */
    focusInputRef(ref) {
      this.$nextTick(() => {
        ref.focus()
      })
    },
  },
}

export default formulateHelper
