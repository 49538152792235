// import { setCookie } from 'tiny-cookie'
/* eslint-disable import/no-cycle */
// import useJwt from '@/@core/auth/jwt/useJwt'
import jwt from 'jsonwebtoken'

const getUserData = () => {
  if (localStorage.getItem('userData') && localStorage.getItem('userData') !== 'undefined') {
    return JSON.parse(localStorage.getItem('userData'))
  }
  const token = localStorage.getItem('token')
  try {
    const decoded = jwt.decode(token)
    return {
      email: decoded.emailUsuario,
      username: decoded.emailUsuario,
      fullName: decoded.nomeUsuario,
      role: 'admin', // TODO: verificar mecanismo de role
      tenant: decoded.tenant,
    }
  } catch {
    /* eslint-disable no-console */
    console.log('Token inválido')
  }
  return {}
}

const getPermissions = () => {
  if (localStorage.getItem('permissions') && localStorage.getItem('permissions') !== 'undefined') {
    return JSON.parse(localStorage.getItem('permissions'))
  }
  return undefined
}

export default {
  namespaced: true,
  state: {
    userData: getUserData(),
    permissions: getPermissions(),
  },
  getters: {},
  mutations: {
    SET_USER(state, userData) {
      state.userData = userData
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
  },
  actions: {
    setUser({ commit }, payload) {
      let userData
      if (payload) {
        userData = payload
        localStorage.setItem('userData', JSON.stringify(userData))
      } else {
        userData = getUserData()
        localStorage.setItem('userData', JSON.stringify(userData))
      }

      commit('SET_USER', userData)
    },
    setPermissions({ commit }, payload) {
      let permissions
      if (payload) {
        permissions = payload
        localStorage.setItem('permissions', JSON.stringify(permissions))
      } else {
        permissions = getPermissions()
        localStorage.setItem('permissions', JSON.stringify(permissions))
      }
      commit('SET_PERMISSIONS', permissions)
    },
  },
}
