const province = {
  methods: {
    provinces() {
      return [
        { ibge: '12', value: 'AC', label: 'Acre' },
        { ibge: '27', value: 'AL', label: 'Alagoas' },
        { ibge: '16', value: 'AP', label: 'Amapá' },
        { ibge: '13', value: 'AM', label: 'Amazonas' },
        { ibge: '29', value: 'BA', label: 'Bahia' },
        { ibge: '23', value: 'CE', label: 'Ceará' },
        { ibge: '53', value: 'DF', label: 'Distrito Federal' },
        { ibge: '32', value: 'ES', label: 'Espírito Santo' },
        { ibge: '52', value: 'GO', label: 'Goiás' },
        { ibge: '21', value: 'MA', label: 'Maranhão' },
        { ibge: '51', value: 'MT', label: 'Mato Grosso' },
        { ibge: '50', value: 'MS', label: 'Mato Grosso do Sul' },
        { ibge: '31', value: 'MG', label: 'Minas Gerais' },
        { ibge: '15', value: 'PA', label: 'Pará' },
        { ibge: '25', value: 'PB', label: 'Paraíba' },
        { ibge: '41', value: 'PR', label: 'Paraná' },
        { ibge: '26', value: 'PE', label: 'Pernambuco' },
        { ibge: '22', value: 'PI', label: 'Piauí' },
        { ibge: '33', value: 'RJ', label: 'Rio de Janeiro' },
        { ibge: '24', value: 'RN', label: 'Rio Grande do Norte' },
        { ibge: '43', value: 'RS', label: 'Rio Grande do Sul' },
        { ibge: '11', value: 'RO', label: 'Rondônia' },
        { ibge: '14', value: 'RR', label: 'Roraíma' },
        { ibge: '42', value: 'SC', label: 'Santa Catarina' },
        { ibge: '35', value: 'SP', label: 'São Paulo' },
        { ibge: '28', value: 'SE', label: 'Sergipe' },
        { ibge: '17', value: 'TO', label: 'Tocantins' },
      ]
    },
    provincesWithStores() {
      return this.provinces().filter(
        provinceItem => this.$store.getters['app/statesWithStore'].indexOf(provinceItem.value) >= 0
      )
    },
  },
}

export default province
