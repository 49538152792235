/* eslint-disable max-classes-per-file */
import axios from '@axios'
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import { isPdvRoute } from '@/router/utils'
import lodash from 'lodash'
import isElectron from 'is-electron'
import { getAppVersions } from '@/utils/app-utils'

const environment = window.env || 'local'

const appVersions = JSON.stringify(getAppVersions())

export class PayBoxMissingSetupError extends Error {}
export class PayBoxInativeError extends Error {}
export class PayBoxUnknownError extends Error {}

function auttarDevAuthentication() {
  if (environment === 'production')
    return {
      authUsername: '572700001',
      authPassword: 'hoje2023',
    }

  return {
    authUsername: 'prodata',
    authPassword: 'pro123',
    authDocument: '41.602.316/0001-30',
  }
}

async function identifyPaybox(payBoxData) {
  if (isElectron()) {
    try {
      const userData = getUserData()
      // we save the config in a file, so we can refer to it when creating an incident on agent
      await window.electronAPI.system.identifyPaybox({
        ...userData,
        ...payBoxData,
      })
    } catch (err) {
      console.error('error to identifyPaybox', err)
    }
  }
}

const getInitialState = () => ({
  options: {
    availablePayBoxes: [],
    satModels: [
      'BEMATECH',
      'BEMATECH_RB',
      'CONTROLID',
      'DIMEP',
      'ELGIN',
      'EPSON',
      'GERTEC',
      'TANCA',
      'MFE',
    ],
  },
  pdvGlobalFilters: {
    payBox: {
      id: null,
      store: null,
    },
  },
  payBox: {
    id: null,
    macAddress: null,
    storeId: null,
    eventId: null,
    finishedSetup: false,
    preferredInvoiceType: null,
    expeditionThermalPrinter: false,
    thermalPrinterCustomerCopy: false,

    isAtm: null,

    barPrintTabEvent: false,
    barPrintPartialConsumption: false,

    availableInvoiceTypes: [],
    sat: {
      activateCode: null,
      serialNumber: null,
      signatureCommercialApp: null,
      satModel: null,
      marginRight: null,
      marginLeft: null,
      marginTop: null,
      marginBottom: null,
      useUtf8: null,
      pageCode: null,
      skipped: null,
      layoutVersion: null,
    },
    thermalPrinter: {
      port: null,
      model: null,
      pageCode: null,
      cashDrawerEnabled: true,
      cashDrawerInvertedSign: false,
      verifyPrinter: true,
      controlPort: false,
      cashDrawerTimeOn: null,
      cashDrawerTimeOff: null,
      linesBetweenCoupons: null,
      spaceBetweenLines: null,
      columnsNormalFont: null,
      skipped: null,
      autoIdentifyConf: true,
    },
    thermalPrinterExpedition: {
      port: null,
      model: null,
      pageCode: null,
      verifyPrinter: true,
      controlPort: false,
      linesBetweenCoupons: null,
      spaceBetweenLines: null,
      columnsNormalFont: null,
      skipped: null,
      autoIdentifyConf: true,
      cashDrawerEnabled: false,
      cashDrawerInvertedSign: false,
      cashDrawerTimeOn: null,
      cashDrawerTimeOff: null,
    },
    tef: {
      businessCode: null,
      storeCode: null,
      terminalCode: null,
      pinpadModel: null,

      systemName: 'Admin Echope',
      systemVersion: '1',

      connectionHttps: true,
      connectionHost: null,
      connectionPort: null,
      connectionProtocol: null,

      authUsername: null,
      authPassword: null,
      authDocument: null,

      log: true,
      cryptography: false,
      interactive: true,
      skipped: null,
      enabled: false,
      restartClientMode: 'OnError',
      warmClientMode: 'PdvStart',
      restartDevice: false,
      ...auttarDevAuthentication(),
    },
    nfce: {
      idSecurityCode: null,
      tokenSecurityCode: null,
      serialNumber: null,
      lastInvoiceNumber: null,
      submitMethod: null,
      skipped: null,
    },
  },
  invoiceEnvironment: environment === 'production' ? 'PRODUCTION' : 'HOMOLOGATION',
})

// In case we received and error, but we already have the paybox configuration, we evict updating it with default values
//  this case can happen with internet failure, and we don't want to loose PayBox configs
function skipPayboxUpdateConfig(status, currentPayboxData) {
  return status !== 200 && currentPayboxData.number && currentPayboxData.storeId
}

function formatPayboxFilterData(data) {
  if (!isPdvRoute()) {
    const { pdvGlobalFilters } = getInitialState()
    return pdvGlobalFilters
  }

  return {
    payBox: {
      id: data?.id,
      store: {
        id: data?.store?.id,
      },
    },
  }
}

function formatPayboxData(data, currentPayboxData) {
  const removeNulls = obj => lodash.omitBy(obj, lodash.isNil)

  const { payBox: defaultProperties } = getInitialState()

  const payBoxData = {
    ...defaultProperties,
    ...removeNulls({
      ...data,
    }),
    nfce: {
      ...defaultProperties.nfce,
      ...removeNulls(data?.nfce),
    },
    sat: {
      ...defaultProperties.sat,
      ...removeNulls(data?.sat),
    },
    tef: {
      ...defaultProperties.tef,
      ...removeNulls(data?.tef),
    },
    thermalPrinter: {
      ...defaultProperties.thermalPrinter,
      ...removeNulls(data?.thermalPrinter),
    },
    thermalPrinterExpedition: {
      ...defaultProperties.thermalPrinterExpedition,
      ...removeNulls(data?.thermalPrinterExpedition),
    },
  }

  if (!data.finishedSetup && data.store) {
    payBoxData.tef.authDocument = data.store?.document
  }

  if (currentPayboxData?.macAddress) {
    payBoxData.macAddress = currentPayboxData?.macAddress
  }

  if (environment !== 'production') {
    payBoxData.tef = {
      ...payBoxData.tef,
      ...auttarDevAuthentication(),
    }
  }

  return payBoxData
}

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    pdvGlobalFilters(state) {
      return state.pdvGlobalFilters
    },
    currentPayboxConfiguration(state) {
      const {
        id,
        number,
        description,
        preferredInvoiceType,
        store,
        expeditionThermalPrinter,
        thermalPrinterCustomerCopy,
        eventId,
      } = state.payBox
      return {
        id,
        number,
        description,
        store,
        preferredInvoiceType,
        maxCashAmountPayBox: store?.maxCashAmountPayBox,
        expeditionThermalPrinter,
        thermalPrinterCustomerCopy,
        eventId,
      }
    },
    getAvailablePayBoxesOptions(state) {
      return state.options.availablePayBoxes.map(p => {
        const descriptionSufix = p.description ? ` - ${p.description}` : ''
        return {
          ...p,
          label: `${p.number}${descriptionSufix}`,
          value: p.id,
        }
      })
    },
    getAvailableSatOptions(state) {
      return state.options.satModels.map(p => ({
        ...p,
        label: p,
        value: p,
      }))
    },
    invoiceAgentConfigPayload(state, getters) {
      return {
        ...getters.thermalPrinterAgentConfigPayload,
        sat_activate_code: state.payBox.sat.activateCode,
        sat_use_utf8: state.payBox.sat.useUtf8,
        sat_page_code: Number(state.payBox.sat.pageCode),
        sat_signature_commercial_app: state.payBox.sat.signatureCommercialApp,
        sat_model: state.payBox.sat.satModel,
        sat_layout_version: Number(state.payBox.sat.layoutVersion),
        margin_right: Number(state.payBox.sat.marginRight),
        margin_left: Number(state.payBox.sat.marginLeft),
        margin_top: Number(state.payBox.sat.marginTop),
        margin_bottom: Number(state.payBox.sat.marginBottom),
        nfce_serial_number: Number(state.payBox.nfce.serialNumber),
        nfce_id_security_code: Number(state.payBox.nfce.idSecurityCode),
        nfce_token_security_code: state.payBox.nfce.tokenSecurityCode,
        nfce_submit_method: state.payBox.nfce.submitMethod,
      }
    },
    thermalPrinterAgentConfigPayload(state) {
      return {
        printer_port: state.payBox.thermalPrinter.port,
        printer_model: state.payBox.thermalPrinter.model,
        printer_page_code: state.payBox.thermalPrinter.pageCode,
        printer_verify: state.payBox.thermalPrinter.verifyPrinter,
        printer_control_port: state.payBox.thermalPrinter.controlPort,
        cash_drawer_enabled: state.payBox.thermalPrinter.cashDrawerEnabled,
        cash_drawer_inverted_sign: state.payBox.thermalPrinter.cashDrawerInvertedSign,
        cash_drawer_time_on: Number(state.payBox.thermalPrinter.cashDrawerTimeOn),
        cash_drawer_time_off: Number(state.payBox.thermalPrinter.cashDrawerTimeOff),
        lines_between_coupons: Number(state.payBox.thermalPrinter.linesBetweenCoupons),
        space_between_lines: Number(state.payBox.thermalPrinter.spaceBetweenLines),
        columns_normal_font: Number(state.payBox.thermalPrinter.columnsNormalFont),
        printer_auto_identify_conf: state.payBox.thermalPrinter.autoIdentifyConf,
      }
    },
    thermalPrinterExpeditionAgentConfigPayload(state) {
      const { thermalPrinterExpedition } = state.payBox
      return {
        printer_port: thermalPrinterExpedition.port,
        printer_model: thermalPrinterExpedition.model,
        printer_page_code: thermalPrinterExpedition.pageCode,
        printer_verify: thermalPrinterExpedition.verifyPrinter,
        printer_control_port: thermalPrinterExpedition.controlPort,
        cash_drawer_enabled: thermalPrinterExpedition.cashDrawerEnabled,
        cash_drawer_inverted_sign: thermalPrinterExpedition.cashDrawerInvertedSign,
        cash_drawer_time_on: Number(thermalPrinterExpedition.cashDrawerTimeOn),
        cash_drawer_time_off: Number(thermalPrinterExpedition.cashDrawerTimeOff),
        lines_between_coupons: Number(thermalPrinterExpedition.linesBetweenCoupons),
        space_between_lines: Number(thermalPrinterExpedition.spaceBetweenLines),
        columns_normal_font: Number(thermalPrinterExpedition.columnsNormalFont),
        printer_auto_identify_conf: thermalPrinterExpedition.autoIdentifyConf,
      }
    },

    tefAgentConfigPayload(state) {
      return {
        user: state.payBox.tef.authUsername,
        password: state.payBox.tef.authPassword,
        document: state.payBox.tef.authDocument,
        businessCode: state.payBox.tef.businessCode,
        storeCode: state.payBox.tef.storeCode,
        terminalCode: state.payBox.tef.terminalCode,
        pinpadModel: state.payBox.tef.pinpadModel,
        systemName: state.payBox.tef.systemName,
        systemVersion: state.payBox.tef.systemVersion,
        log: state.payBox.tef.log,
        cryptography: state.payBox.tef.cryptography,
        interactive: state.payBox.tef.interactive,
        enabled: state.payBox.tef.enabled,
        restartClientMode: state.payBox.tef.restartClientMode,
        warmClientMode: state.payBox.tef.warmClientMode,
        restartDevice: state.payBox.tef.restartDevice,
        ipList: [
          {
            https: state.payBox.tef.connectionHttps,
            host: state.payBox.tef.connectionHost,
            port: Number(state.payBox.tef.connectionPort),
            protocol: state.payBox.tef.connectionProtocol,
          },
        ],
      }
    },

    barConfig(state) {
      return {
        barPrintTabEvent: state.payBox?.barPrintTabEvent,
        barPrintPartialConsumption: state.payBox?.barPrintPartialConsumption,
        storeComissions: state.payBox?.store?.storeWaiterComissions || [],
        hasWaiterComission: !!state.payBox?.store?.waiterComission,
      }
    },

    isPdvAtm(state) {
      return state.payBox.isAtm
    },

    isKioskStore(state) {
      return state.payBox?.store?.type === 'Kiosk'
    },

    isBarStore(state) {
      return state.payBox?.store?.type === 'Bar'
    },

    isTefEnabled(state) {
      return state.payBox?.tef?.enabled
    },
  },

  mutations: {
    SET_OPTIONS_OF(state, { option, value }) {
      state.options[option] = value
    },

    SET_PAY_BOX_THERMAL_PRINTER_PORT(state, portValue) {
      state.payBox.thermalPrinter.port = portValue
    },
    SET_PAY_BOX_THERMAL_PRINTER_EXPEDITION_PORT(state, portValue) {
      state.payBox.thermalPrinterExpedition.port = portValue
    },

    SET_PAY_BOX_ID(state, id) {
      state.payBox.id = id
    },

    SET_PAY_BOX(state, value) {
      state.payBox = value
    },

    SET_PDV_GLOBAL_FILTERS_STATE(state, value) {
      state.pdvGlobalFilters = value
    },
    SET_PAY_BOX_DETAIL_DATA(state, payBox) {
      state.payBox.id = payBox.id
      state.payBox.number = payBox.number
      state.payBox.description = payBox.description
      state.payBox.store = payBox.store
    },
    SET_NFCE_LAST_INVOICE_NUMBER(state, number) {
      state.payBox.nfce.lastInvoiceNumber = Number(number)
    },

    CLEAN_PDV_GLOBAL_FILTERS_STATE(state) {
      const { pdvGlobalFilters } = getInitialState()
      state.pdvGlobalFilters = pdvGlobalFilters
    },

    CLEAN_STATE(state) {
      const initial = getInitialState()
      Object.keys(initial).forEach(key => {
        state[key] = initial[key]
      })
    },
  },

  actions: {
    async updateLastInvoiceNumber({ commit }, { invoiceNumber }) {
      commit('SET_NFCE_LAST_INVOICE_NUMBER', invoiceNumber)
    },
    async fetchAvailablePayBoxes({ state, commit }) {
      const { storeId } = state.payBox

      if (!storeId) {
        commit('SET_OPTIONS_OF', { option: 'availablePayBoxes', value: [] })
        const { payBox } = getInitialState()
        commit('SET_PAY_BOX', payBox)
        return
      }

      const { data } = await axios.get('/api/sales/pay-box/available', {
        params: {
          storeId,
          pageSize: 0,
          pageIndex: 0,
        },
      })

      commit('SET_OPTIONS_OF', { option: 'availablePayBoxes', value: data.results || [] })
    },

    async finishPayBoxSetup({ state }) {
      const tef = {
        ...state.payBox.tef,
        connectionPort: Number(state.payBox.tef.connectionPort),
      }
      const thermalPrinter = {
        ...state.payBox.thermalPrinter,
        linesBetweenCoupons: Number(state.payBox.thermalPrinter.linesBetweenCoupons),
        spaceBetweenLines: Number(state.payBox.thermalPrinter.spaceBetweenLines),
        columnsNormalFont: Number(state.payBox.thermalPrinter.columnsNormalFont),
        cashDrawerTimeOn: Number(state.payBox.thermalPrinter.cashDrawerTimeOn),
        cashDrawerTimeOff: Number(state.payBox.thermalPrinter.cashDrawerTimeOff),
      }

      const thermalPrinterExpedition = {
        ...state.payBox.thermalPrinterExpedition,
        linesBetweenCoupons: Number(state.payBox.thermalPrinterExpedition.linesBetweenCoupons),
        spaceBetweenLines: Number(state.payBox.thermalPrinterExpedition.spaceBetweenLines),
        columnsNormalFont: Number(state.payBox.thermalPrinterExpedition.columnsNormalFont),
        cashDrawerTimeOn: Number(state.payBox.thermalPrinterExpedition.cashDrawerTimeOn),
        cashDrawerTimeOff: Number(state.payBox.thermalPrinterExpedition.cashDrawerTimeOff),
      }
      const sat = {
        ...state.payBox.sat,
        pageCode: state.payBox.sat.pageCode ? Number(state.payBox.sat.pageCode) : null,
        marginRight: Number(state.payBox.sat.marginRight),
        marginLeft: Number(state.payBox.sat.marginLeft),
        marginTop: Number(state.payBox.sat.marginTop),
        marginBottom: Number(state.payBox.sat.marginBottom),
      }
      const nfce = {
        ...state.payBox.nfce,
        serialNumber: Number(state.payBox.nfce.serialNumber),
      }

      const payboxData = await window.electronAPI.system.identifyPayboxRead()
      const systemInfo = await window.electronAPI.system.systemInfo()

      const payload = {
        ...state.payBox,
        tef,
        thermalPrinter,
        thermalPrinterExpedition,
        sat,
        nfce,
        guid: payboxData?.guid,
        macAddress: systemInfo.macAddress,
        hostname: systemInfo.hostname,
        username: systemInfo.username,
        platform: systemInfo.platform,
        applicationVersion: appVersions,
      }
      await axios.put(`/api/sales/pay-box/setup`, payload)
    },

    async desktopAppDownload() {
      if (!isUserLoggedIn()) {
        return
      }

      let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
      if (platform.indexOf(' ') > 0) {
        platform = platform.substring(0, platform.indexOf(' ')).trim()
      }

      const isProduction = environment === 'production'
      const { data } = await axios.get(`/api/sales/pay-box/files/get-latest-desktop-app-version`, {
        params: {
          channel: isProduction ? 'main' : 'staging',
          system: platform,
        },
      })
      data.forEach(file => {
        window.open(file.signedUrl, '_blank')
      })
    },

    async fetchPayBox({ state, commit }) {
      const { id } = state.payBox

      if (!id) {
        const { payBox } = getInitialState()
        commit('SET_PAY_BOX', payBox)
        return
      }

      const { data, status } = await axios.get(`/api/sales/pay-box/${id}`)
      if (skipPayboxUpdateConfig(status, state.payBox)) return

      const payBoxData = formatPayboxData(data, state.payBox)
      const payBoxFilterData = formatPayboxFilterData(payBoxData)

      await identifyPaybox(payBoxData)

      commit('SET_PAY_BOX', payBoxData)
      commit('SET_PDV_GLOBAL_FILTERS_STATE', payBoxFilterData)
    },

    async checkCurrentPayBoxConfigAndFetch({ dispatch, getters }) {
      const { id: payBoxId } = getters.currentPayboxConfiguration
      if (!payBoxId || typeof payBoxId === 'string') {
        if (isElectron()) {
          console.debug('currentPayboxConfiguration was wrong, force loading')
          const payboxData = await window.electronAPI.system.identifyPayboxRead()
          const systemInfo = await window.electronAPI.system.systemInfo()

          await dispatch('fetchPayBoxByMacAddress', {
            guid: payboxData.guid,
            macAddress: systemInfo.macAddress,
          })
        }
      }
    },
    async fetchPayBoxByMacAddress({ state, commit }, { guid, macAddress }) {
      if (!macAddress) {
        throw new Error('Mac Address is required')
      }
      if (!isUserLoggedIn()) {
        return
      }

      const { data, status } = await axios.get(
        `/api/sales/pay-box/mac-address/${guid || macAddress}`
      )
      if (skipPayboxUpdateConfig(status, state.payBox)) return

      const payBoxData = formatPayboxData(data, state.payBox)
      const payBoxFilterData = formatPayboxFilterData(payBoxData)

      await identifyPaybox(payBoxData)

      commit('SET_PAY_BOX', payBoxData)
      commit('SET_PDV_GLOBAL_FILTERS_STATE', payBoxFilterData)
    },

    async checkPayBoxIsActiveByMacAddress() {
      const payboxData = await window.electronAPI.system.identifyPayboxRead()
      const systemInfo = await window.electronAPI.system.systemInfo()
      if (!systemInfo.macAddress) {
        throw new Error('Mac Address is required')
      }

      if (!isUserLoggedIn()) {
        return
      }

      try {
        await axios.get(
          `/api/sales/pay-box/active/mac-address/${payboxData?.guid || systemInfo.macAddress}`,
          {
            params: {
              hostname: systemInfo.hostname,
              username: systemInfo.username,
              platform: systemInfo.platform,
              applicationVersion: appVersions,
            },
          }
        )
      } catch (error) {
        const status = error?.response?.status
        console.error('status:', status, 'error:', error)
        if (status === 404) {
          throw new PayBoxMissingSetupError('Este PDV necessita ser configurado.')
        } else if (status === 403) {
          throw new PayBoxInativeError(
            'Este PDV está desabilitado. Entre em contato com o suporte.'
          )
        } else {
          throw new PayBoxUnknownError(
            'Ocorreu um erro inesperado ao buscar configurações do PDV. Tente novamente.'
          )
        }
      }
    },

    setPayBoxDetail({ commit }, payload) {
      commit('SET_PAY_BOX_DETAIL_DATA', payload)
    },

    setPayBoxThermalPrinterPort({ commit }, portValue) {
      commit('SET_PAY_BOX_THERMAL_PRINTER_PORT', portValue)
    },
    setPayBoxThermalPrinterExpeditionPort({ commit }, portValue) {
      commit('SET_PAY_BOX_THERMAL_PRINTER_EXPEDITION_PORT', portValue)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    cleanPdvGlobalFiltersState({ commit }) {
      commit('CLEAN_PDV_GLOBAL_FILTERS_STATE')
    },
  },
}
