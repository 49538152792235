var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-autosuggest",
    _vm._b(
      {
        attrs: {
          "get-suggestion-value": _vm.getSuggestionValue,
          "input-props": Object.assign({}, _vm.inputProps, {
            class: "formulate-input-element--" + _vm.context.type,
            focus: function (e) {
              return e.target.select()
            },
          }),
        },
        on: {
          input: _vm.onInputChange,
          click: _vm.onClick,
          selected: _vm.onSelected,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var suggestion = ref.suggestion
                return [_c("div", [_vm._v(_vm._s(suggestion.item.textLabel))])]
              },
            },
            _vm.context.attributes.suggestions.length === 0
              ? {
                  key: "after-suggestions",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-center py-1" },
                        [
                          _vm.context.attributes.loading
                            ? _c("b-spinner", { attrs: { variant: "primary" } })
                            : _c("p", [_vm._v(" Nenhum dado encontrado ")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.context.model,
          callback: function ($$v) {
            _vm.$set(_vm.context, "model", $$v)
          },
          expression: "context.model",
        },
      },
      "vue-autosuggest",
      _vm.context.attributes,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }