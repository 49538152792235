var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-tags",
    _vm._b(
      {
        attrs: {
          "input-class": _vm.context.attributes.class,
          "data-type": _vm.context.type,
        },
        on: { blur: _vm.context.blurHandler },
        model: {
          value: _vm.context.model,
          callback: function ($$v) {
            _vm.$set(_vm.context, "model", $$v)
          },
          expression: "context.model",
        },
      },
      "b-form-tags",
      _vm.context.attributes,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }