import Vue from 'vue'
import Dexie from 'dexie'

const idbInstance = new Dexie('echope')

// https://dexie.org/docs/API-Reference#quick-reference
idbInstance.version(5).stores({
  payBoxCurrentSale: 'uuid',
  payBoxTefLastMessages: 'id++',
  payBoxInvoiceNumberControl: 'storekey',
})

Vue.prototype.$idb = idbInstance

export default idbInstance
