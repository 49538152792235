var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline", staticStyle: { "margin-top": "5px" } },
    [
      _c(
        "b-badge",
        _vm._b({ staticClass: "text-wrap" }, "b-badge", _vm.badgeAttr, false),
        _vm._l(_vm.shortcuts, function (text, index) {
          return _c("span", { key: index }, [
            index !== 0 ? _c("span", [_vm._v("+")]) : _vm._e(),
            _vm._v(" " + _vm._s(text) + " "),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }