var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    [
      _c("validation-provider", {
        attrs: { name: _vm.context.attributes.label, rules: "required" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "b-input-group",
                  {
                    staticClass: "input-group-merge",
                    class: errors.length > 0 ? "is-invalid" : null,
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control-merge",
                      attrs: {
                        id: "password",
                        type: _vm.passwordFieldType,
                        state: errors.length > 0 ? false : null,
                        name: "password",
                        placeholder: _vm.context.attributes.placeholder,
                      },
                      on: { blur: _vm.context.blurHandler },
                      model: {
                        value: _vm.context.model,
                        callback: function ($$v) {
                          _vm.$set(_vm.context, "model", $$v)
                        },
                        expression: "context.model",
                      },
                    }),
                    _c(
                      "b-input-group-append",
                      { attrs: { "is-text": "" } },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer",
                          attrs: { icon: _vm.passwordToggleIcon },
                          on: { click: _vm.togglePasswordVisibility },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("small", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(errors[0])),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }