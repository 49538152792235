import axios from '@axios'
import { sorting, storePagination } from '@/mixins'
import invoiceInvalidateMaintain from './invoice-invalidate-maintain'
import invalidateGap from './invalidate-gap'

const getInitialState = () => ({
  ...storePagination.state(),
  invalidates: [],
  filters: {
    storeId: null,
    model: 'NFE',
    serialNumber: '',
    initialNumber: null,
    finalNumber: null,
    issueDate: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  modules: {
    invoiceInvalidateMaintain,
    invalidateGap,
  },
  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_INVALIDATES(state, invalidates) {
      state.invalidates = invalidates
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, invalidates } = getInitialState()
      state.filters = filters
      state.invalidates = invalidates
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchInvoicesInvalidates({ commit, state }) {
      const {
        storeId,
        model,
        serialNumber,
        initialNumber,
        finalNumber,
        issueDate,
      } = state.filters

      const { data } = await axios.get('/api/invoices/invalidates', {
        params: {
          storeId,
          model,
          serialNumber,
          initialNumber,
          finalNumber,
          issueDateFrom: issueDate.startDate,
          issueDateTo: issueDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_INVALIDATES', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
