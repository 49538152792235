import axios from '@axios'

const getInitialState = () => ({
  formManagementAccount: {
    name: null,
    dreClassificationId: null,
    description: null,
    type: null,
    expenseType: null,
    active: undefined,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {

  },

  mutations: {
    SET_MANAGEMENT_ACCOUNT(state, managementAccount) {
      state.formManagementAccount = {
        ...managementAccount,
        dreClassificationId: managementAccount.dreClassification?.id.toString(),
      }
    },
    CLEAN_STATE(state) {
      const { formManagementAccount } = getInitialState()
      state.formManagementAccount = formManagementAccount
    },
  },

  actions: {
    async fetchManagementAccount({ commit }, id) {
      const { data } = await axios.get(`/api/accounting/management-accounts/${id}`)
      commit('SET_MANAGEMENT_ACCOUNT', data)
    },
    async saveManagementAccount({ state }) {
      await axios({
        method: state.formManagementAccount.id ? 'PUT' : 'POST',
        url: '/api/accounting/management-accounts',
        data: state.formManagementAccount
      })
    },
    async switchManagementAccountActivation(context, managementAccount) {
      await axios({
        method: 'PUT',
        url: `/api/accounting/management-accounts/${managementAccount.active ? 'deactivate' : 'activate'}/${managementAccount.id}`,
      })
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
