const payablePeriodTypeEnum = {
  DUE_DATE: 'DUE_DATE',
  ISSUE_DATE: 'ISSUE_DATE',
  CREATED_DATE: 'CREATED_DATE',
  PAYMENT_DATE: 'PAYMENT_DATE',
}

const receivablePeriodTypeEnum = {
  DUE_DATE: 'DUE_DATE',
  ISSUE_DATE: 'ISSUE_DATE',
  CREATED_DATE: 'CREATED_DATE',
  PAYMENT_DATE: 'PAYMENT_DATE',
}

const financialOperationTypeEnum = {
  CREDIT: 'Credit',
  DEBIT: 'Debit',
}

const writeOffTypeEnum = {
  RECEIVABLE: 'Receivable',
  ACCOUNTS_PAYABLE: 'AccountsPayable',
}

const checkingAccountTransferEnum = {
  DEPOSIT: 'Deposit',
  TRANSFERENCE: 'Transference',
}

const recordtypeEnum = {
  AUTO: 'Auto',
  MANUAL: 'Manual',
  SPREADSHEET: 'Spreadsheet',
}

const financialDomain = {
  data() {
    return {
      payablePeriodTypeEnum,
      receivablePeriodTypeEnum,
      financialOperationTypeEnum,
      writeOffTypeEnum,
      checkingAccountTransferEnum,
      recordtypeEnum,
    }
  },

  computed: {
    financialOperationTypeLabel() {
      return {
        [financialOperationTypeEnum.CREDIT]: this.$t('Crédito'),
        [financialOperationTypeEnum.DEBIT]: this.$t('Débito'),
      }
    },

    writeOffTypeLabel() {
      return {
        [writeOffTypeEnum.ACCOUNTS_PAYABLE]: this.$t('Contas a pagar'),
        [writeOffTypeEnum.RECEIVABLE]: this.$t('Contas a receber'),
      }
    },

    checkingAccountTransferLabel() {
      return {
        [checkingAccountTransferEnum.DEPOSIT]: this.$t('Depósito'),
        [checkingAccountTransferEnum.TRANSFERENCE]: this.$t('Transferência'),
      }
    },
  },

  methods: {
    payablePeriodTypeOptions() {
      return [
        { value: payablePeriodTypeEnum.DUE_DATE, label: this.$t('Vencimento') },
        { value: payablePeriodTypeEnum.ISSUE_DATE, label: this.$t('Emissão') },
        { value: payablePeriodTypeEnum.CREATED_DATE, label: this.$t('Lançamento') },
        { value: payablePeriodTypeEnum.PAYMENT_DATE, label: this.$t('Pagamento') },
      ]
    },

    receivablePeriodTypeOptions() {
      return [
        { value: receivablePeriodTypeEnum.DUE_DATE, label: this.$t('Vencimento') },
        { value: receivablePeriodTypeEnum.ISSUE_DATE, label: this.$t('Emissão') },
        { value: receivablePeriodTypeEnum.CREATED_DATE, label: this.$t('Lançamento') },
        { value: receivablePeriodTypeEnum.PAYMENT_DATE, label: this.$t('Pagamento') },
      ]
    },

    writeOffTypeOptions() {
      return [
        { value: writeOffTypeEnum.ACCOUNTS_PAYABLE, label: this.$t('Contas a pagar') },
        { value: writeOffTypeEnum.RECEIVABLE, label: this.$t('Contas a receber') },
      ]
    },

    checkingAccountTransferOptions() {
      return [
        { value: checkingAccountTransferEnum.DEPOSIT, label: this.$t('Depósito') },
        { value: checkingAccountTransferEnum.TRANSFERENCE, label: this.$t('Transferência') },
      ]
    },

    documentTypeOptions() {
      return [
        { value: 'ADIANTAMENTO', label: 'ADIANTAMENTO' },
        { value: 'AVISO FERIAS', label: 'AVISO FERIAS' },
        { value: 'BOLETO', label: 'BOLETO' },
        { value: 'BORDERO', label: 'BORDERO' },
        { value: 'COFINS', label: 'COFINS' },
        { value: 'CONTA/FATURA', label: 'CONTA/FATURA' },
        { value: 'CONTRATO', label: 'CONTRATO' },
        { value: 'CRF', label: 'CRF' },
        { value: 'CSLL', label: 'CSLL' },
        { value: 'DARE-SP', label: 'DARE-SP' },
        { value: 'E-MAIL', label: 'E-MAIL' },
        { value: 'EXTRATO', label: 'EXTRATO' },
        { value: 'FGTS', label: 'FGTS' },
        { value: 'HOLERITE', label: 'HOLERITE' },
        { value: 'ICMS', label: 'ICMS' },
        { value: 'INSS', label: 'INSS' },
        { value: 'IPTU', label: 'IPTU' },
        { value: 'IR', label: 'IR' },
        { value: 'IRPJ', label: 'IRPJ' },
        { value: 'IRRF', label: 'IRRF' },
        { value: 'IRRF-APF (ALUGUEL)', label: 'IRRF-APF (ALUGUEL)' },
        { value: 'ISSQN', label: 'ISSQN' },
        { value: 'NFE', label: 'NFE' },
        { value: 'NFSE', label: 'NFSE' },
        { value: 'NOTA DEBITO', label: 'NOTA DEBITO' },
        { value: 'PIS', label: 'PIS' },
        { value: 'PLANILHA', label: 'PLANILHA' },
        { value: 'PREVISAO', label: 'PREVISAO' },
        { value: 'PROCESSO', label: 'PROCESSO' },
        { value: 'RECIBO', label: 'RECIBO' },
        { value: 'REEMBOLSO', label: 'REEMBOLSO' },
        { value: 'RESCISAO', label: 'RESCISAO' },
        { value: 'ROYALTIES', label: 'ROYALTIES' },
        { value: 'TAXAS', label: 'TAXAS' },
        { value: 'VENDA', label: 'VENDA' },
      ]
    },
  },
}

export default financialDomain
