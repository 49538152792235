import i18n from '@/libs/i18n'
import accountsPayable from './accounts-payable'
import receivables from './receivables'
import reconciliation from './reconciliation'

export default {
  namespaced: true,
  modules: {
    accountsPayable,
    receivables,
    reconciliation,
  },
  getters: {
    documentTypeOptions() {
      return [
        { value: 'NFe', label: i18n.t('NFe') },
        { value: 'NFSe', label: i18n.t('NFSe') },
        { value: 'Receipt', label: i18n.t('Recibo') },
        { value: 'Advance', label: i18n.t('Adiantamento') },
      ]
    },
  },
  state: {},
  mutations: {},
  actions: {},
}
