import * as onScan from 'onscan.js'
import { mapGetters } from 'vuex'

// lib doc: https://github.com/axenox/onscan.js

const scanBarcode = {
  data() {
    return {}
  },

  computed: {
    ...mapGetters('app', ['loadingOverlayShowActive']),
  },

  methods: {
    onScanAttachDefault() {
      if (!onScan.isAttachedTo(document)) {
        this.onScanAttach(() => {})
      }
    },
    onScanDetach() {
      if (onScan.isAttachedTo(document)) {
        onScan.detachFrom(document)
      }
    },

    /**
     * Quando criado, apenas os inputs que possuem o atributo "data-enable-scan" irão digitar o valor scaneado no input.
     * @param {Function} onScanCallback ação que será executada quando for escaneado um código de barras.
     * @returns
     */
    onScanAttach(onScanCallback) {
      this.onScanDetach()

      // input com atributo data-enable-scan não reseterá ele.
      const onScanIngore = value => {
        const activeEl = document.activeElement
        const canScan = document.activeElement.dataset.enableScan !== undefined
        const isInput = activeEl.matches('input') || activeEl.matches('textarea')

        if (value && !canScan && isInput && !this.loadingOverlayShowActive) {
          activeEl.value = '' // texto vazio pois se usar undefined, ele fica escrito em inputs de texto
          activeEl.dispatchEvent(new Event('input', { bubbles: true })) // evento pra manter reatividade do componente.
          return true
        }

        return false
      }

      onScan.attachTo(document, {
        onScan(sScanned, iQty) {
          // console.log('onScan', `${iQty}x ${sScanned}`)
          if (onScanIngore(sScanned)) return

          onScanCallback(sScanned, iQty)
        },
        // onScanError(oDebug) {
        //   console.debug('onScanError', oDebug)
        // },
        // onScanButtonLongPress() {
        //   console.debug('onScanButtonLongPress')
        // },
        // onKeyDetect(iKeyCode, oEvent) {
        //   // executa antes do onScan
        //   console.log('onKeyDetect', iKeyCode, oEvent)
        // },
        // onKeyProcess(sChar, oEvent) {
        //   // executa antes do onScan, com exceção da tecla enter (ele é o único que executa após o onScan)
        //   console.log('onKeyProcess', sChar, oEvent)
        // },
        // onPaste(sPasted) {
        //   console.debug('onPaste', sPasted)
        // },
      })
    },

    simulateScan(val) {
      if (!onScan.isAttachedTo(document)) return
      onScan.simulate(document, val || '78936683')
    },
  },
}

export default scanBarcode
