const getInitialState = () => ({
  receiveForm: {
    id: null,
    description: '',
    documentNumber: '',
    incomeType: null,
    checkingAccount: null,
    value: 0,
    client: null,
    releaseDate: null,
    dueDate: null,
    paymentMethod: '',
    isPaidOff: false,
    recurrence: null,
    intallments: '',
    observation: '',
    documentsUrls: [],
  },
  documents: [],
})

export default {

  namespaced: true,
  state: getInitialState(),
  mutations: {
    SET_DOCUMENTS(state, documents) {
      state.documents = documents
    },

    CLEAN_STATE(state) {
      const { documents, receiveForm } = getInitialState()
      state.documents = documents
      state.receiveForm = receiveForm
    }
  },
  actions: {
    setDocuments({ commit }, documents) {
      commit('SET_DOCUMENTS', documents)
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
