import axios from '@axios'

const getInitialState = () => ({
  managementAccounts: [],
})

const prepareOption = m => ({
  ...m,
  label: `${m.name}`,
  value: m.id,
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {},
  getters: {
    getComboManagementAccounts(state) {
      return state.managementAccounts.map(s => prepareOption(s))
    },
    getComboActiveManagementAccounts(state) {
      return state.managementAccounts.filter(m => m.active).map(m => prepareOption(m))
    },
    getComboInputManagementAccounts(state) {
      return state.managementAccounts.filter(m => m.type === 'Input').map(s => prepareOption(s))
    },
    getComboOutputManagementAccounts(state) {
      return state.managementAccounts.filter(m => m.type === 'Output').map(s => prepareOption(s))
    },
  },

  mutations: {
    SET_MANAGEMENT_ACCOUNTS(state, managementAccounts) {
      state.managementAccounts = managementAccounts
    },

    CLEAN_STATE(state) {
      const { managementAccounts } = getInitialState()
      state.managementAccounts = managementAccounts
    },
  },

  actions: {
    async fetchManagementAccounts({ commit }) {
      const { data } = await axios.get('/api/accounting/management-accounts', {
        params: { pageSize: 0, pageIndex: 0 },
      })
      commit('SET_MANAGEMENT_ACCOUNTS', data.results || [])
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
