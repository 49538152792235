import { financialDomain, sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import moment from 'moment'
import expenseMaintain from './expense-maintain'
import accountsPayableBulkMaintain from './accounts-payable-bulk-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  filters: {
    description: null,
    storeId: null,
    accountsPayableId: null,
    managementAccountId: null,
    paymentMethodId: null,
    supplierId: null,
    customerId: null,
    purchaseOrderOrSaleId: null,
    documentType: null,
    document: null,
    value: '',
    periodType: financialDomain.data().payablePeriodTypeEnum.CREATED_DATE,
    period: {
      startDate: moment().add(-30, 'days').startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
    paymentStatus: null,
  },
  accountsPayable: [],
  reportData: null,
  selectedInstallments: [],
  options: {
    managementAccounts: [],
  },
  summaryFields: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { expenseMaintain, accountsPayableBulkMaintain },

  mutations: {
    ...storePagination.mutations,

    SET_ACCOUNTS_PAYABLE(state, accountsPayable) {
      state.accountsPayable = accountsPayable
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    SET_SELECTED_INSTALLMENTS(state, selectedInstallments) {
      state.selectedInstallments = selectedInstallments
    },

    CLEAN_STATE(state) {
      const { filters, paging, accountsPayable, selectedInstallments, summaryFields } =
        getInitialState()
      state.filters = filters
      state.accountsPayable = accountsPayable
      state.selectedInstallments = selectedInstallments
      state.paging = paging
      state.summaryFields = summaryFields
    },

    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchAccountsPayable({ commit, state }) {
      const {
        description,
        storeId,
        accountsPayableId,
        managementAccountId,
        supplierId,
        customerId,
        value,
        periodType,
        period,
        paymentStatus,
        purchaseOrderOrSaleId,
        documentType,
        document,
        paymentMethodId,
      } = state.filters

      const { startDate, endDate } = period

      const { CREATED_DATE, DUE_DATE, ISSUE_DATE, PAYMENT_DATE } =
        financialDomain.data().payablePeriodTypeEnum
      const periodParams = {
        [CREATED_DATE]: {
          createdDateFrom: startDate,
          createdDateTo: endDate,
        },
        [DUE_DATE]: {
          dueDateFrom: startDate,
          dueDateTo: endDate,
        },
        [ISSUE_DATE]: {
          issueDateFrom: startDate,
          issueDateTo: endDate,
        },
        [PAYMENT_DATE]: {
          paymentDateFrom: startDate,
          paymentDateTo: endDate,
        },
      }
      const periodFilter = periodParams[periodType] || {}

      const params = {
        description: description || null,
        storeId,
        accountsPayableId,
        managementAccountId,
        paymentMethodId,
        purchaseOrderOrSaleId,
        documentType,
        document,
        supplierId,
        customerId,
        value: value || null,
        sortBy: sorting.methods.getSorting(state),
        pageSize: state.paging.pageSize,
        pageIndex: state.paging.currentPage - 1,
        ...periodFilter,
        // ...statusProps,
      }

      if (paymentStatus) {
        params[paymentStatus] = true
      }

      const { data } = await axios.get('/api/accounting/accounts-payable-installments', {
        params,
      })

      const installmentsFormated = data.results.map(installment => ({
        ...installment,
        checkedToPay: state.selectedInstallments.some(selected => selected.id === installment.id),
      }))

      commit('SET_ACCOUNTS_PAYABLE', installmentsFormated || [])

      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    addInstallmentsToPay({ commit, state }, item) {
      const { accountsPayable } = item
      if (!accountsPayable || !accountsPayable.store) throw new Error('Dados no item faltando')

      if (state.selectedInstallments.length > 0) {
        const existingStoreId = state.selectedInstallments[0]?.storeId
        if (accountsPayable.store.id !== existingStoreId)
          throw new Error('Não é possível realizar pagamento em massa para lojas diferentes.')
      }

      const newSelectedInstallments = [
        ...state.selectedInstallments,
        { ...item, storeId: accountsPayable.store.id },
      ]
      commit('SET_SELECTED_INSTALLMENTS', newSelectedInstallments)
    },
    removeInstallmentsToPay({ commit, state }, item) {
      const newList = [...state.selectedInstallments]
      const indexFound = newList.findIndex(inst => inst.id === item.id)
      newList.splice(indexFound, 1)
      commit('SET_SELECTED_INSTALLMENTS', newList)
    },

    async reversePayment({ dispatch }, financialWriteOffId) {
      await axios.patch(`/api/financial/write-offs/${financialWriteOffId}/reverse-payment`)
      dispatch('fetchAccountsPayable')
    },

    async deleteInstallment({ dispatch }, id) {
      await axios.delete(`/api/accounting/accounts-payable-installments/${id}`)
      await dispatch('fetchAccountsPayable')
    },

    setFilters({ commit }, filters) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanInstallmentsToPay({ commit }) {
      commit('SET_SELECTED_INSTALLMENTS', [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    async generateReport({ state, commit }) {
      const {
        description,
        storeId,
        accountsPayableId,
        managementAccountId,
        supplierId,
        customerId,
        value,
        periodType,
        period,
        paymentStatus,
        purchaseOrderOrSaleId,
        document,
        paymentMethodId,
      } = state.filters

      const { startDate, endDate } = period

      const { CREATED_DATE, DUE_DATE, ISSUE_DATE, PAYMENT_DATE } =
        financialDomain.data().payablePeriodTypeEnum
      const periodParams = {
        [CREATED_DATE]: {
          createdDateFrom: startDate,
          createdDateTo: endDate,
        },
        [DUE_DATE]: {
          dueDateFrom: startDate,
          dueDateTo: endDate,
        },
        [ISSUE_DATE]: {
          issueDateFrom: startDate,
          issueDateTo: endDate,
        },
        [PAYMENT_DATE]: {
          paymentDateFrom: startDate,
          paymentDateTo: endDate,
        },
      }
      const periodFilter = periodParams[periodType] || {}

      const params = {
        description: description || null,
        storeId,
        accountsPayableId,
        managementAccountId,
        paymentMethodId,
        purchaseOrderOrSaleId,
        document,
        supplierId,
        customerId,
        value: value || null,
        sortBy: 'CreatedDate ASC',
        pageSize: 999999999,
        pageIndex: 0,
        ...periodFilter,
      }

      if (paymentStatus) {
        params[paymentStatus] = true
      }

      const { data } = await axios.get('/api/accounting/accounts-payable-installments/download', {
        params,
        responseType: 'blob',
      })
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      commit('SET_REPORT_DATA', url)
    },
  },
}
