import axiosInstance from '@/libs/axios'
import { cardDomain } from '@/mixins'
import { localListAddItem, localListDeleteItem, localListUpdateItem } from '@/store/utils'

const getInitialState = () => ({
  formCard: {
    id: null,
    name: '',
    acquirer: '',
    daysToReceive: 0,
    roundInstallment: cardDomain.data().roundInstallmentEnum.LAST,
    type: cardDomain.data().cardTypeEnum.CREDIT,
    installmentForms: [],
    feePerProduct: false,
    active: true,
    onlineActive: false,
  },
  installmentForms: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  mutations: {
    SET_FORM_CARD(state, formCard) {
      state.formCard = formCard
    },

    CLEAN_INSTALLMENTS(state) {
      const { formCard } = getInitialState()
      state.formCard.installmentForms = formCard.installmentForms
    },

    CLEAN_STATE(state) {
      const { formCard } = getInitialState()
      state.formCard = formCard
    },
  },

  actions: {
    async fetchById({ commit, state }, id) {
      const { data } = await axiosInstance.get(`/api/settings/financial/cards/${id}`)
      state.installmentForms = data.installmentForms
      data.feePerProduct = false
      commit('SET_FORM_CARD', data)
    },

    async saveForm({ state }, imageLocal) {
      const {
        id,
        name,
        acquirer,
        daysToReceive,
        roundInstallment,
        type,
        installmentForms,
        active,
        onlineActive,
      } = state.formCard

      const formSubmit = {
        id,
        name,
        acquirer,
        daysToReceive,
        image: imageLocal,
        type,
        roundInstallment,
        active,
        installmentForms,
        onlineActive,
      }

      await axiosInstance({
        url: '/api/settings/financial/cards',
        method: id ? 'PUT' : 'POST',
        data: formSubmit,
      })
    },

    setFormCard({ commit }, formCard) {
      commit('SET_FORM_CARD', formCard)
    },

    addInstallment({ state }, data) {
      state.formCard.installmentForms = localListAddItem(state.formCard.installmentForms, data)
    },
    updateInstallment({ state }, { data }) {
      state.formCard.installmentForms = localListUpdateItem(state.formCard.installmentForms, data)
    },
    removeInstallment({ state }, { idOrLocalId }) {
      state.formCard.installmentForms = localListDeleteItem(
        state.formCard.installmentForms,
        idOrLocalId
      )
    },

    cleanInstallments({ commit }) {
      commit('CLEAN_INSTALLMENTS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
