export default {
  title: 'Configurações',
  icon: 'SettingsIcon',
  // resource: 'Auth', // 'ChartOfAccount'
  // action: 'read', // 'Read'
  // iconPack: 'bootstrap',
  children: [
    {
      title: 'Fiscal',
      icon: 'FileTextIcon',
      resource: 'Auth', // 'ChartOfAccount'
      action: 'read', // 'Read'
      children: [
        {
          title: 'Cfop',
          route: 'cfop-list',
          resource: 'Cfop',
          action: 'Read',
        },
        {
          title: 'Classificação Fiscal',
          route: 'tax-classification-list',
          resource: 'TaxClassification',
          action: 'Read',
        },
        {
          title: 'Inconsistências Fiscais',
          route: 'tax-classification-list-inconsistencies',
          resource: 'TaxClassification',
          action: 'Read',
        },
      ],
    },
    {
      title: 'Financeiro',
      icon: 'DollarSignIcon',
      children: [
        {
          title: 'Contas Gerenciais',
          route: 'management-account-list',
          resource: 'ManagementAccount',
          action: 'Read',
        },
        {
          title: 'Forma de Pagamento',
          route: 'payment-type-list',
          resource: 'PaymentMethod',
          action: 'Read',
        },
        {
          title: 'Cartão de Crédito/Débito',
          route: 'card-list',
          resource: 'SettingsFinancialCards', // 'Card'
          action: 'Read', // 'Read'
        },
      ],
    },
  ],
}
