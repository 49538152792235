import i18n from '@/libs/i18n'
import idbInstance from '@/libs/dexie'
import moment from 'moment'
import router from '@/router'
import _ from 'lodash'
import isElectron from 'is-electron'

const payBoxTefLastMessagesIdb = idbInstance.table('payBoxTefLastMessages')

const getInitialState = () => ({
  lastMessagesToKeep: 100,
  lastMessages: [],
})

const isConfigurationRoute = () => router.currentRoute.name === 'pdv-pay-box-configuration'

const setLoadingOverlay = (commit, payload) => {
  if (isConfigurationRoute()) {
    return
  }

  commit('app/SET_LOADING_OVERLAY', payload, { root: true })
}
export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    SET_MESSAGES(state, lastMessages) {
      state.lastMessages = lastMessages
    },
    ADD_MESSAGE(state, payload) {
      state.lastMessages.unshift(payload) // we add at the begening, so modal show new messages in reverse
      state.lastMessages = state.lastMessages.slice(0, state.lastMessagesToKeep)
    },
  },
  getters: {
    async lastMessages() {
      const messages = await payBoxTefLastMessagesIdb.toArray()
      return _.sortBy(messages, ['dateTime']).reverse() || []
    },
  },

  actions: {
    async restoreLastMessages({ commit, getters }) {
      const messages = await getters.lastMessages
      commit('SET_MESSAGES', messages)
    },
    writeLocalFileLastMessages({ getters }) {
      // we save the messages in a file, so we can refer to it when creating an incident on agent
      if (isElectron()) {
        getters.lastMessages.then(messages => {
          window.electronAPI.system.tefLastMessagesPaybox(messages).catch(err => {
            console.error('error to tefLastMessages', err)
          })
        })
      }
    },
    async handleMessage({ commit, state, dispatch, rootGetters }, payload) {
      const isOverlayActive = rootGetters['app/loadingOverlayShowActive']
      const { messages } = payload
      const msgElement = messages.map(m => i18n.t(m)).join('\n')

      if (isOverlayActive) {
        setLoadingOverlay(commit, {
          show: true,
          text: msgElement,
        })
      }

      // eslint-disable-next-line no-param-reassign
      delete payload.messages

      const messagesPayload = {
        ...payload,
        isOverlayActive,
        message: msgElement,
        eventType: 'MESSAGE',
        dateTime: moment().format(),
      }

      const itemAdded = await payBoxTefLastMessagesIdb.add(messagesPayload)
      commit('ADD_MESSAGE', messagesPayload)

      dispatch('writeLocalFileLastMessages')

      const currentCount = await payBoxTefLastMessagesIdb.count()
      if (currentCount > state.lastMessagesToKeep) {
        const minId = itemAdded - state.lastMessagesToKeep
        await payBoxTefLastMessagesIdb.where(':id').below(minId).delete()
      }
    },
    async initialize({ commit, rootGetters }) {
      const configPayload = rootGetters['pages/pdv/payBoxConfiguration/tefAgentConfigPayload']

      setLoadingOverlay(commit, {
        show: true,
        text: i18n.t('PAY_BOX_SALE.TEF.START_TEF_INTEGRATION_GENERIC'),
      })

      try {
        return await window.electronAPI.tef.initialize(configPayload)
      } finally {
        commit('app/SET_LOADING_OVERLAY', { show: false }, { root: true })
      }
    },
    async authenticate({ commit, rootGetters }) {
      const configPayload = rootGetters['pages/pdv/payBoxConfiguration/tefAgentConfigPayload']
      setLoadingOverlay(commit, {
        show: true,
        text: i18n.t('PAY_BOX_SALE.TEF.START_TEF_INTEGRATION_GENERIC'),
      })

      try {
        return await window.electronAPI.tef.authenticate(configPayload)
      } finally {
        commit('app/SET_LOADING_OVERLAY', { show: false }, { root: true })
      }
    },
    async printLastReceipt({ commit, rootGetters }) {
      const configPayload = rootGetters['pages/pdv/payBoxConfiguration/tefAgentConfigPayload']
      const configPayloadPrinter =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      setLoadingOverlay(commit, {
        show: true,
        text: i18n.t('PAY_BOX_SALE.TEF.START_TEF_INTEGRATION_GENERIC'),
      })
      try {
        const result = await window.electronAPI.tef.printLastReceipt(configPayload)

        commit(
          'app/SET_LOADING_OVERLAY',
          {
            show: true,
            text: i18n.t('PAY_BOX_SALE.TEF.PRINT_TEF'),
          },
          { root: true }
        )
        try {
          await window.electronAPI.print({
            ...configPayloadPrinter,
            request: {
              openCashDrawer: false,
              printTexts: [
                {
                  printText: result.couponStore,
                  template: 'PAYMENT_GETNET',
                },
                {
                  printText: result.couponCustomer,
                  template: 'PAYMENT_GETNET',
                },
              ],
            },
          })
        } catch (error) {
          console.error('error on print tef', error)
        }

        return result
      } finally {
        commit('app/SET_LOADING_OVERLAY', { show: false }, { root: true })
      }
    },
    async refreshTables({ commit, rootGetters }) {
      const configPayload = rootGetters['pages/pdv/payBoxConfiguration/tefAgentConfigPayload']
      setLoadingOverlay(commit, {
        show: true,
        text: i18n.t('PAY_BOX_SALE.TEF.START_TEF_INTEGRATION_GENERIC'),
      })

      try {
        return await window.electronAPI.tef.refreshTables(configPayload)
      } finally {
        commit('app/SET_LOADING_OVERLAY', { show: false }, { root: true })
      }
    },
    async autoConfigurationPinpad({ commit, rootGetters }) {
      const configPayload = rootGetters['pages/pdv/payBoxConfiguration/tefAgentConfigPayload']

      setLoadingOverlay(commit, {
        show: true,
        text: i18n.t('PAY_BOX_SALE.TEF.START_TEF_INTEGRATION_GENERIC'),
      })
      try {
        return await window.electronAPI.tef.autoConfigurationPinpad(configPayload)
      } finally {
        commit('app/SET_LOADING_OVERLAY', { show: false }, { root: true })
      }
    },
    async restartService({ commit }) {
      setLoadingOverlay(commit, {
        show: true,
        text: i18n.t('Reiniciando serviço de integração com PINPAD'),
      })
      try {
        await window.electronAPI.tef.restartService()
      } finally {
        commit('app/SET_LOADING_OVERLAY', { show: false }, { root: true })
      }
    },
  },
}
