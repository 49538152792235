import FormulateSwitch from './FormulateSwitch.vue'

export default function VueFormulateSwitchPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateSwitch,
    },
    library: {
      switch: {
        classification: 'text',
        component: 'FormulateSwitch',
      },
    },
  })
}
