<template>
  <vue-numeric
    v-model="valueTemp"
    v-bind="context.attributes"
    :input-class="context.attributes.class"
    :data-type="context.type"
    :decimal-separator="decimalSeparator"
    :separator="separator"
    @blur="onChanceValue"
    @focus="onFocus"
  />
</template>
<script>
import VueNumeric from 'vue-numeric'

export default {
  name: 'FormulateTextNumber',
  components: {
    VueNumeric,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valueTemp: undefined,
    }
  },
  computed: {
    decimalPlaces() {
      return this.context.attributes.decimalPlaces || 0
    },
    decimalSeparator() {
      return (
        this.context.attributes['decimal-separator'] ||
        this.context.attributes.decimalSeparator ||
        ','
      )
    },
    separator() {
      return this.context.attributes.separator || this.context.attributes.thousandSeparator || '.'
    },
    model() {
      return this.context.model
    },
  },
  watch: {
    model(val) {
      this.valueTemp = val * 100
    },
  },
  mounted() {
    this.valueTemp = this.model * 100
  },
  methods: {
    onChanceValue() {
      this.context.model = this.valueTemp / 100

      this.context.blurHandler(this.valueTemp)
    },

    async onFocus(event) {
      // necessário o "sleep" por causa do delay do componente para formatar o texto.
      await new Promise(resolve => setTimeout(() => resolve(), 50))
      document.getElementById(event.target.id).select()
      this.$emit('focus', this.valueTemp, event)
    },
  },
}
</script>
