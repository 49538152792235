<template>
  <div>
    <fab
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :actions="actions"
      :fixed-tooltip="true"
      :busy="busy"
      @allevents="allevents"
    />
  </div>
</template>

<script>
import fab from '@/views/components/FAB.vue'

export default {
  components: {
    fab,
  },

  props: {
    busy: {
      type: Boolean,
      default: false,
    },

    showSave: {
      type: Boolean,
      default: true,
    },
    showActive: {
      type: Boolean,
      default: false,
    },
    showInactive: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    additionalActions: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {}
  },

  computed: {
    actions() {
      const actions = [
        {
          name: 'cancel',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
        },
      ]
      if (this.showActive) {
        actions.push({
          name: 'active',
          icon: 'toggle_on',
          color: 'green',
          tooltip: this.$t('Ativar'),
        })
      }
      if (this.showInactive) {
        actions.push({
          name: 'inactive',
          icon: 'toggle_off',
          color: 'red',
          tooltip: this.$t('Inativar'),
        })
      }
      if (this.showSave) {
        actions.push({
          name: 'save',
          icon: 'save',
          tooltip: this.$t('Salvar'),
        })
      }
      if (this.showEdit) {
        actions.push({
          name: 'edit',
          icon: 'edit',
          tooltip: this.$t('Editar'),
        })
      }
      if (this.additionalActions?.length) {
        actions.push(...this.additionalActions)
      }
      return actions
    },
  },

  methods: {
    allevents(evt) {
      this.$emit(evt)
      // console.log(evt)
    },
  },
}
</script>
