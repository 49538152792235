<template>
  <div class="align-items-center justify-content-start mb-1 mb-md-0">
    <v-select
      :id="`${$attrs.id || 'table'}-select-page-size`"
      :value="perPage"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="perPageOptions"
      :clearable="false"
      style="display: inline-block; min-width: 80px"
      class="mr-1"
      @input="(val) => $emit('change', val)"
    />
    <label>{{ $t('registros por página') }}</label>
  </div>
</template>

<script>
import vSelect from 'vue-select'
// import {
//   BCol,
// } from 'bootstrap-vue'

export default {
  components: {
    // BCol,
    vSelect,
  },
  props: {
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      perPageOptions: [10, 20, 50, 100],
    }
  },
}
</script>
