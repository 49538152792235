<template>
  <div class="osk-with-button-container">
    <b-button
      :disabled="context.attributes.disabled"
      variant="primary"
      :class="`osk-with-button-btn osk-with-button-btn-left ${context.attributes.disabled ? 'cursor-not-allowed' : ''}`"
      @click="onLeftClick"
    >
      <b-icon
        :icon="iconLeft"
        font-scale="2"
      />
    </b-button>

    <vue-numeric
      v-model="context.model"
      v-bind="context.attributes"
      :input-class="context.attributes.class"
      :data-type="context.type"
      :decimal-separator="decimalSeparator"
      :thousand-separator="thousandSeparator"
      :separator="separator"
      :class="`formulate-input-element formulate-input-element--${context.type} osk-with-button-input`"
      :disabled="inputDisabled"
      @blur="onBlur"
      @focus="onFocus"
    />

    <b-button
      :disabled="context.attributes.disabled"
      variant="primary"
      :class="`osk-with-button-btn osk-with-button-btn-right ${context.attributes.disabled ? 'cursor-not-allowed' : ''}`"
      @click="onRightClick"
    >
      <b-icon
        :icon="iconRight"
        font-scale="2"
      />
    </b-button>
  </div>
</template>
<script>
import VueNumeric from 'vue-numeric'
import { BIcon, BButton } from 'bootstrap-vue'
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FormulateTextNumber',
  components: {
    VueNumeric,
    BIcon,
    BButton,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
    thousandSeparator: {
      type: String,
      required: false,
      default: undefined,
    },
    iconLeft: {
      type: String,
      default: 'dash',
    },
    iconRight: {
      type: String,
      default: 'plus',
    },
    inputDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() { return {} },

  computed: {
    ...mapState('app/simpleKeyboard', ['currentInputValue']),

    model() {
      return this.context.model
    },
    decimalPlaces() {
      return this.context.attributes.decimalPlaces || 0
    },
    decimalSeparator() {
      return (
        this.context.attributes['decimal-separator'] ||
        this.context.attributes.decimalSeparator ||
        ','
      )
    },
    separator() {
      return this.context.attributes.separator || this.context.attributes.thousandSeparator || '.'
    },
  },

  watch: {
    model(val) {
      if (val === '' || Number.isNaN(Number(val))) {
        this.context.model = undefined
      } else {
        this.context.model = val
      }
    },

    currentInputValue(newValue) {
      if (this.$el.contains(document.activeElement)) {
        let result = Number(this.getNumber(newValue))
        if (Number.isNaN(result)) {
          result = undefined
        }

        this.$emit('oskInput', result)
        this.context.model = result
      }
    }
  },

  methods: {
    ...mapActions('app/simpleKeyboard', ['showKeyboard', 'hideKeyboard']),

    getNumber(value) {
      if (!value) return value

      const valueSplit = value.split(this.decimalSeparator)
      const integerValue = valueSplit[0].replace(/\D/g, '')
      return `${integerValue}.${valueSplit[1] || '00'}`
    },

    onLeftClick(event) {
      this.$emit('leftClick', event)
    },

    onRightClick(event) {
      this.$emit('rightClick', event)
    },

    // eslint-disable-next-line func-names
    onBlur: _.debounce(async function (event) {
      this.context.blurHandler(event)
      this.$emit('blur', this.getNumber(event.target.value), event)

      this.hideKeyboard()
    }, 50),

    // eslint-disable-next-line func-names
    onFocus: _.debounce(async function (event) {
      document.getElementById(event.target.id).select()

      this.showKeyboard({
        el: event.target,
        layoutType: 'numeric'
      })

      this.$emit('focus', this.getNumber(event.target.value), event)
    }, 50),
  },
}
</script>


<style lang="scss">
.osk-with-button-container {
  display: flex;

  .osk-with-button-input {
    border-radius: 0 !important;
    text-align: center;

    &[disabled] {
      background-color: white !important;
    }
  }

  .osk-with-button-btn {
    height: 37px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .osk-with-button-btn-right {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .osk-with-button-btn-left {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }
}
</style>
