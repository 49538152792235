const purchaseOrderDomains = {
  data() {
    return {}
  },
  methods: {
    mxAlertNotFoundAverageCost(errorMessage) {
      const errorMessageList = errorMessage.split('|||')
      if (errorMessageList.length < 2) return ''

      const itens = JSON.parse(errorMessageList[1] || '[]')
      const formatMessage = itens
        .map(
          item =>
            `<br>
            <b>#${item?.skuId} - ${item?.name} </b> <br>`
        )
        .join('')

      const htmlMessage = `
        ${this.$tc(errorMessageList[0], itens.length)}:
        <br/> ${formatMessage}
      `
      const htmlText = `<div style="text-align: left;"> ${htmlMessage} </div>`

      return this.confirmHtml({
        title: this.$t('Produto sem custo médio'),
        html: htmlText,
        confirmButtonText: this.$t('Ok'),
        showCancelButton: false,
      })
    }
  },
}

export default purchaseOrderDomains
