import axios from '@axios'
import { storePagination } from '@/mixins/store'
import { sorting } from '@/mixins'

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'modifiedDate',
    sortDesc: true,
  },
  stockItems: [],
  filters: {
    storeId: null,
    skuId: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_STOCK_POSITIONS(state, stockItems) {
      state.stockItems = stockItems
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, stockItems } = getInitialState()
      state.filters = filters
      state.stockItems = stockItems
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchStockItems({ commit, state }) {
      const { data } = await axios.get('/api/stock-positions', {
        params: {
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_STOCK_POSITIONS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('CLEAN_FILTERS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
