import axios from '@axios'
import { storePagination } from '@/mixins/store'
import { sorting } from '@/mixins'
import maintain from './reconciliation-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  filters: {
    storeId: null,
    checkingAccountId: null,
    status: undefined,
    movementDate: {
      startDate: null,
      endDate: null,
    },
  },
  reconciliations: [],
  getnetAcquirerProducts: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { maintain },

  getters: {
    getnetAcquirerProductsOptions(state) {
      return (
        state.getnetAcquirerProducts?.map(p => ({
          ...p,
          value: p.id,
          label: p.name,
        })) || []
      )
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_RECONCILIATIONS(state, reconciliations) {
      state.reconciliations = reconciliations
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    SET_ACQUIRER_PRODUCTS(state, getnetAcquirerProducts) {
      state.getnetAcquirerProducts = getnetAcquirerProducts
    },

    CLEAN_STATE(state) {
      const { filters, paging, reconciliations, selectedInstallments } = getInitialState()
      state.filters = filters
      state.reconciliations = reconciliations
      state.selectedInstallments = selectedInstallments
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchReconciliations({ commit, state }) {
      const { storeId, checkingAccountId, status, movementDate } = state.filters

      const { startDate, endDate } = movementDate

      const params = {
        storeId,
        checkingAccountId,
        status,
        movementDateFrom: startDate,
        movementDateTo: endDate,
        sortBy: sorting.methods.getSorting(state),
        pageSize: state.paging.pageSize,
        pageIndex: state.paging.currentPage - 1,
      }

      if (!status) delete params.status

      const { data } = await axios.get('/api/financial/reconciliation/getnet', {
        params,
      })

      commit('SET_RECONCILIATIONS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
    },

    async fetchGetnetAcquirerProducts({ commit, state }) {
      if (state.getnetAcquirerProducts.length > 0) return

      const { data } = await axios.get(`/api/financial/acquirer/products`, {
        params: {
          pageIndex: 0,
          pageSize: 0,
          types: ['Credit', 'Debit'],
          acquirer: 'GETNET',
        },
      })

      commit('SET_ACQUIRER_PRODUCTS', data.results || [])
    },

    setFilters({ commit }, filters) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
