<template>
  <div style="display: inline">
    <b-button
      :variant="buttonVariant"
      :disabled="disabled"
      @click="showModal = true"
    >
      {{ $t(buttonTitle) }}
    </b-button>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      v-model="showModal"
      centered
      :title="$t(title)"
      :ok-title="$t('Exportar')"
      :cancel-title="$t('Cancelar')"
      @cancel="showModal = false"
    >
      <FormulateForm
        ref="formExport"
        name="formExport"
      >
        <b-row>
          <b-col md="12">
            <FormulateInput
              v-model="localFileName"
              type="text"
              class="required"
              :validation="['required']"
              :label="$t('Nome do arquivo')"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              v-model="fileExtension"
              type="vue-select"
              class="required"
              :validation="['required']"
              :label="$t('Formato')"
              :options="fileExtensions"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              v-model="cellAutoWidth"
              type="switch"
              :label="$t('Ajustar largura das colunas automaticamente')"
            />
          </b-col>
        </b-row>
      </FormulateForm>
      <template #modal-footer>
        <e-button
          variant="outline-secondary"
          :text="$t('Cancelar')"
          @click="showModal = false"
        />
        <e-button
          variant="primary"
          :busy="localBusy"
          :text="$t('Exportar')"
          @click="exportData"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'
import { export_json_to_excel } from '@/libs/Export2Excel'
import EButton from './EButton.vue'

const resolvePath = require('object-resolve-path')

export default {
  components: {
    BRow,
    BCol,
    BButton,
    EButton,
  },
  mixins: [],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Exportar',
    },
    buttonTitle: {
      type: String,
      default: 'Exportar',
    },
    buttonVariant: {
      type: String,
      default: 'primary',
    },
    fileName: {
      type: String,
      default: 'export',
    },
    /**
     * Array com as colunas que serão incluidas na exportação. O objeto segue a mesma estrutura do objeto Field do componente BTable:
     * Referência: https://bootstrap-vue.org/docs/components/table#field-definition-reference
     */
    fields: {
      type: Array,
      default: () => [],
    },
    /**
     * Dados que serão exportados.
     */
    data: {
      type: Array,
      default: null,
    },
    /**
     * Função assíncrona que será utilizada para buscar os dados para exportação (usado somente se a prop data não for utilizada).
     */
    dataFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      localBusy: false,
      localData: [],
      fileExtensions: [
        { value: 'xlsx', label: this.$t('xlsx') },
        { value: 'csv', label: this.$t('csv') },
        { value: 'txt', label: this.$t('txt') },
      ],
      fileExtension: 'xlsx',
      localFileName: this.fileName,
      cellAutoWidth: false,
    }
  },
  watch: {
    data() {
      if (this.data?.length) {
        this.localData = [...this.data]
      }
    },
    fileName(val) {
      this.localFileName = val
    },
  },

  methods: {
    async exportData() {
      this.$refs.formExport.showErrors()
      if (this.$refs.formExport.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      let { data } = this
      if (this.dataFunction) {
        try {
          // debugger
          this.localBusy = true
          data = await this.dataFunction()
        } catch (error) {
          this.showError({ message: this.$t('Não foi possível obter os dados para exportar') })
          return
        } finally {
          this.localBusy = false
        }
      }
      export_json_to_excel({
        header: this.fields.map(item => item.label),
        data: this.formatJson(data),
        filename: this.localFileName,
        autoWidth: this.cellAutoWidth,
        bookType: this.fileExtension || 'xlsx',
      })
      this.clearFields()
      this.showModal = false
      this.$emit('done')
    },
    formatJson(jsonData) {
      return jsonData.map(item => {
        const formatedItem = []
        this.fields.forEach(column => {
          const { key, formatter } = column
          let value = resolvePath(item, key)
          if (formatter) {
            value = formatter(value)
          }
          formatedItem.push(value)
        })
        return formatedItem
      })
    },
    formatData(data) {
      return data.map()
    },
    clearFields() {
      this.localFileName = this.fileName
      this.fileExtension = 'xlsx'
      this.cellAutoWidth = true
    },
  },
}
</script>
