// import store from '@/store'
import ability from './ability'

// eslint-disable-next-line arrow-body-style
export const canNavigate = async to => {
  // debugger
  // if (store.state.hub.empresaLogada) {
  const abilityRef = ability
  const can = to.matched.some(
    route =>
      !route.meta.resource || abilityRef.can(route.meta.action || 'read', route.meta.resource)
  )
  return can
  // }
  // console.log('canNavigate')
  // return setTimeout(() => canNavigate(to), 200)
}

export const _ = undefined
