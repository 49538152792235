var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "align-items-center justify-content-start mb-1 mb-md-0" },
    [
      _c("v-select", {
        staticClass: "mr-1",
        staticStyle: { display: "inline-block", "min-width": "80px" },
        attrs: {
          id: (_vm.$attrs.id || "table") + "-select-page-size",
          value: _vm.perPage,
          dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
          options: _vm.perPageOptions,
          clearable: false,
        },
        on: {
          input: function (val) {
            return _vm.$emit("change", val)
          },
        },
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("registros por página")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }