import axiosInstance from '@/libs/axios'
import { storePagination } from '@/mixins/store'

export const getInitialAtmCatalogFilters = () => ({
  name: '',
  groupId: null,
})

const getInitialState = () => ({
  ...storePagination.state(),
  filters: getInitialAtmCatalogFilters(),
  catalog: [],
  groups: [],

  catalogLoading: false,
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {},

  getters: {
    getGroups(state) {
      return state.groups || []
    },

    isLoadingCatalog(state) {
      return state.catalogLoading
    },

    isLoadedAllCatalog(state) {
      return state.paging.allRowsLoaded
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_CATALOG(state, catalog) {
      state.catalog = catalog || []
    },
    SET_GROUPS(state, groups) {
      state.groups = groups || []
    },

    SET_CATALOG_LOADING(state, loading) {
      state.catalogLoading = loading
    },

    SET_FILTERS(state, filters) {
      state.filters = filters || getInitialAtmCatalogFilters()
    },

    CLEAN_INFINITE_SCROLL(state) {
      const { catalog, paging } = getInitialState()
      state.catalog = catalog
      state.paging.allRowsLoaded = paging.allRowsLoaded
      state.paging.currentPage = paging.currentPage
    },

    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    async loadAtmCatalog({ dispatch }) {
      await dispatch('fetchCatalogGroup')
      await dispatch('fetchAtmProductCatalog')
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },
    async setFiltersAndSearch({ state, commit, dispatch }, filters) {
      commit('SET_FILTERS', { ...state.filters, ...filters })
      commit('CLEAN_INFINITE_SCROLL')

      await dispatch('fetchAtmProductCatalog')
    },

    async fetchAtmProductCatalog({ state, commit, rootState, rootGetters, dispatch }) {
      if (state.catalogLoading || state.paging.allRowsLoaded) return

      commit('SET_CATALOG_LOADING', true)

      const customer = rootState.pages.pdv.payBox.clientForm
      const payBoxConfig = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']
      const storeId = payBoxConfig?.store?.id
      const eventId = payBoxConfig?.eventId

      const params = {
        // brandId: state.filters.brand,
        // subgroupId: state.filters.subgroup,
        // departmentId: state.filters.department,
        name: state.filters.name,
        groupId: state.filters.groupId,
        eventId,
        customerId: customer.id,
        pageSize: 8,
        pageIndex: state.paging.currentPage - 1,
      }

      try {
        const { data } = await axiosInstance.get(`/api/sales/pay-box-sku/store/${storeId}/atm`, {
          params,
        })

        if (data.results.length === 0) {
          commit('SET_ALL_ROWS_LOADED', true)
        } else {
          const catalogItems = state.catalog
          catalogItems.push(...data?.results)

          commit('SET_CATALOG', catalogItems)
          commit('SET_CURRENT_PAGE', state.paging.currentPage + 1)
        }
      } catch (error) {
        console.error('[atm catalog] load items', error)
      } finally {
        commit('SET_CATALOG_LOADING', false)
      }

      dispatch('reloadUntilScrollShow')
    },

    async fetchCatalogGroup({ commit, rootGetters }) {
      const payBoxConfig = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']
      const storeId = payBoxConfig?.store?.id
      const eventId = payBoxConfig?.eventId

      const { data } = await axiosInstance.get(
        `/api/sales/pay-box-catalog-group/store/${storeId}/atm`,
        {
          params: { eventId },
        }
      )

      commit('SET_GROUPS', data)
    },

    async fetchProductToCart({ dispatch }, { ean, skuId }) {
      // TODO considerar promotion, kit e skuId para key
      const productFound = {}[skuId]
      if (productFound) {
        return productFound
      }

      const productFetched = await dispatch(
        'pages/pdv/saleProducts/fetchProductByEan',
        { ean },
        { root: true }
      )

      // TODO salvar no dicionário para utilizar na próxima vez que buscar o produto.

      return productFetched
    },

    async reloadUntilScrollShow({ dispatch }) {
      const catalogContainer = document.querySelector('#atm-catalog-main-container')

      const hasVerticalScroll = catalogContainer.scrollHeight > catalogContainer.clientHeight

      if (catalogContainer && !hasVerticalScroll) {
        await dispatch('fetchAtmProductCatalog')
      }
    },
  },
}
