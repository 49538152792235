import axios from '@axios'
import { storePagination, sorting } from '@/mixins'
import i18n from '@/libs/i18n'
// import moment from 'moment'
import moment from 'moment'
import stockMovementMaintain from './stock-movement-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'movementDate',
    sortDesc: true,
  },
  stockMovements: [],
  filters: {
    storeId: null,
    skuId: null,
    movementType: null,
    movementOrigin: null,
    invoiceNumber: '',
    saleOrPurchaseOrderId: null,
    period: {
      startDate: moment().startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { stockMovementMaintain },

  getters: {
    stockMovementTypes() {
      return [
        { value: 'Input', label: i18n.t('Entrada') },
        { value: 'Output', label: i18n.t('Saída') },
        // { value: 'transference', label: i18n.t('Transferência') },
      ]
    },
    stockMovementOrigins() {
      return [
        { value: 'Manual', label: i18n.t('Manual') },
        { value: 'Inversion', label: i18n.t('Inversão de estoque') },
        { value: 'BarrelConsumption', label: i18n.t('Consumo de chope') },
        { value: 'PurchaseOrder', label: i18n.t('Pedido de compra') },
        { value: 'Sale', label: i18n.t('Venda') },
        { value: 'SaleReclaim', label: i18n.t('Retorno venda') },
        { value: 'SaleItemReturn', label: i18n.t('Devolução consignado') },
        { value: 'Inventory', label: i18n.t('Inventário') },
        { value: 'Invoice', label: i18n.t('Nota fiscal') },
      ]
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_STOCK_MOVEMENTS(state, stockMovements) {
      state.stockMovements = stockMovements
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, stockMovements } = getInitialState()
      state.filters = filters
      state.stockMovements = stockMovements
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchStockMovements({ commit, state }) {
      const { startDate, endDate } = state.filters.period
      const { data } = await axios.get('/api/stock-movements', {
        params: {
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          invoiceNumber: state.filters.invoiceNumber,
          saleOrPurchaseOrderId: state.filters.saleOrPurchaseOrderId,
          movementType: state.filters.movementType,
          movementOrigin: state.filters.movementOrigin,
          from: startDate,
          to: endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_STOCK_MOVEMENTS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('CLEAN_FILTERS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
