var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isDropdown
        ? [
            _c(
              "b-dropdown",
              {
                attrs: {
                  boundary: "window",
                  variant: "link",
                  "toggle-class": "text-decoration-none",
                  "no-caret": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "text-body align-middle mr-25",
                            attrs: { icon: "MoreVerticalIcon", size: "16" },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2290300402
                ),
              },
              [
                _vm.showReadOnly
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { "data-grid-action": "readOnly" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("read-only")
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "search",
                            variant: "outline-primary",
                            "font-scale": "1",
                          },
                        }),
                        _c("span", { staticClass: "dropdown-text" }, [
                          _vm._v(_vm._s(_vm.$t("Ver detalhes"))),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isActive && _vm.showActivate
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { "data-grid-action": "activate" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("activate")
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "check-circle",
                            variant: "success",
                            "font-scale": "1",
                          },
                        }),
                        _c("span", { staticClass: "dropdown-text" }, [
                          _vm._v(_vm._s(_vm.$t("Ativar"))),
                        ]),
                      ],
                      1
                    )
                  : _vm.isActive && _vm.showDeactivate
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { "data-grid-action": "deactivate" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("deactivate")
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "x-circle",
                            variant: "danger",
                            "font-scale": "1",
                          },
                        }),
                        _c("span", { staticClass: "dropdown-text" }, [
                          _vm._v(_vm._s(_vm.$t("Inativar"))),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showUpdate
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { "data-grid-action": "update" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("update")
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "pencil",
                            variant: "primary",
                            "font-scale": "1",
                          },
                        }),
                        _c("span", { staticClass: "dropdown-text" }, [
                          _vm._v(_vm._s(_vm.$t("Alterar"))),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showDelete
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { "data-grid-action": "delete" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("delete")
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "trash",
                            variant: "danger",
                            "font-scale": "1",
                          },
                        }),
                        _c("span", { staticClass: "dropdown-text" }, [
                          _vm._v(_vm._s(_vm.deleteTitleValue)),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.buttons, function (button, index) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: index,
                      attrs: { "data-grid-action": button.event },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(button.event)
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: button.icon,
                          variant: button.variant || "primary",
                          "font-scale": "1",
                        },
                      }),
                      _c("span", { staticClass: "dropdown-text" }, [
                        _vm._v(_vm._s(button.title)),
                      ]),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]
        : _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center flex-wrap",
              staticStyle: { gap: "5px" },
            },
            [
              _vm.showReadOnly
                ? _c("e-button", {
                    staticClass: "p-5 btn-sm-block",
                    attrs: {
                      icon: "search",
                      variant: "outline-primary",
                      title: _vm.$t("Ver detalhes"),
                      "data-grid-action": "readOnly",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("read-only")
                      },
                    },
                  })
                : _vm._e(),
              _vm.showUpdate
                ? _c("e-button", {
                    staticClass: "p-5 btn-sm-block",
                    attrs: {
                      icon: "pencil",
                      variant: "primary",
                      title: _vm.$t("Alterar"),
                      "data-grid-action": "update",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("update")
                      },
                    },
                  })
                : _vm._e(),
              _vm.showDelete
                ? _c("e-button", {
                    staticClass: "p-5 btn-sm-block",
                    attrs: {
                      icon: "trash",
                      variant: "danger",
                      title: _vm.deleteTitleValue,
                      "data-grid-action": "delete",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("delete")
                      },
                    },
                  })
                : _vm._e(),
              !_vm.isActive && _vm.showActivate
                ? _c("e-button", {
                    staticClass: "p-5 btn-sm-block",
                    attrs: {
                      icon: "check-circle",
                      variant: "success",
                      title: _vm.$t("Ativar"),
                      "data-grid-action": "activate",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("activate")
                      },
                    },
                  })
                : _vm.isActive && _vm.showDeactivate
                ? _c("e-button", {
                    staticClass: "p-5 btn-sm-block",
                    attrs: {
                      icon: "x-circle",
                      variant: "danger",
                      title: _vm.$t("Inativar"),
                      "data-grid-action": "deactivate",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("deactivate")
                      },
                    },
                  })
                : _vm._e(),
              _vm._l(_vm.buttons, function (button, index) {
                return _c("e-button", {
                  key: index,
                  staticClass: "p-5 btn-sm-block",
                  attrs: {
                    icon: button.icon,
                    variant: button.variant || "primary",
                    title: button.title,
                    "data-grid-action": button.event,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(button.event)
                    },
                  },
                })
              }),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }