import cfops from './cfops'
import taxClassifications from './taxClassification'
import invoice from './invoice'
import invoiceReceived from './invoice-received'
import fiscalFiles from './fiscal-files'
import invalidate from './invalidate'

export default {
  namespaced: true,
  modules: {
    cfops,
    taxClassifications,
    invoice,
    invoiceReceived,
    fiscalFiles,
    invalidate,
  },
  state: {},
  mutations: {},
  actions: {},
}
