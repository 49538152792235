import pagination from '@/mixins/pagination'

const helperSidebarTypes = {
  NONE: {
    key: 'NONE',
    description: '',
    comboName: '',
    apiPath: '',
  },
}

const getInitialState = () => ({
  taxClassification: {
    ncm: '',
    originState: '',
    operationType: 'INTERNAL',
    destinationState: 'Todos',
    ipiLegalFrameworkCode: '999',
  },
  attributesForm: {},
  attributes: {},
  helperSidebarTypes,
  helperSidebarState: helperSidebarTypes.NONE,
  paging: pagination.data().paging,
})

export default {
  namespaced: true,
  state: getInitialState(),

  getter: {},

  mutations: {
    SET_SIDEBAR_STATE(state, sidebarState) {
      state.helperSidebarState = sidebarState
    },
    SET_TAX_CLASSIFICATION(state, taxClassification) {
      state.taxClassification = taxClassification
    },
    CLEAN_ATTRIBUTE_FORM(state, formName) {
      const { attributesForm } = getInitialState()
      state.attributesForm[formName] = attributesForm[formName]
    },
    CLEAN_STATE(state) {
      const { taxClassification, attributes, attributesForm, helperSidebarState, paging } =
        getInitialState()

      state.taxClassification = taxClassification
      state.attributes = attributes
      state.attributesForm = attributesForm
      state.helperSidebarState = helperSidebarState
      state.paging = paging
    },
  },

  actions: {
    setHelperSidebarState({ commit }, sidebarState) {
      commit('SET_SIDEBAR_STATE', sidebarState)
    },

    setTaxClassification({ commit }, taxClassification) {
      commit('SET_TAX_CLASSIFICATION', taxClassification)
    },

    addAttributeItem({ commit, state }, { itemName, formName }) {
      const item = state.attributes[itemName]
      item.push(state.attributesForm[formName])
      commit('SET_ATTRIBUTE_ITEM', { itemName, payload: item })
    },

    removeAttributeItem({ commit, state }, { itemName, index }) {
      const item = state.attributes[itemName]
      item.splice(index, 1)
      commit('SET_ATTRIBUTE_ITEM', { itemName, payload: item })
    },

    cleanAttributeForm({ commit }, formName) {
      commit('CLEAN_ATTRIBUTE_FORM', formName)
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
