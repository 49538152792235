<template>
  <FormulateInput
    v-model="content"
    v-bind="$attrs"
    :type="isReadOnly ? 'label' : 'vue-select'"
    :multiple="multiple"
    :label="showLabel ? label : ''"
    :placeholder="placeholder"
    :options="localStoreOptions"
    :class="showLabel && required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    @input="handleInput"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  mixins: [],
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default() {
        return this.$t('Loja')
      },
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('Selecione')
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    onlyActiveOptions: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Caso o combo tenha apenas 1 opção, seleciona essa opção automáticamente
     */
    selectSingleOption: {
      type: Boolean,
      default: true,
    },
    showAllStores: {
      type: Boolean,
      default: false,
    },
    /**
     * Filtro que será aplicado na lista de lojas. Deve ser uma função que recebe o objeto da loja como parâmetro, retornando um boleano.
     * Exemplo, quero exibir apenas as lojas do tipo 'Kiosk'
     * <e-store-combo :filter="(store) => store.type === 'Kiosk'" />
     */
    filter: {
      type: Function,
      default: null,
    },

    /**
     * Lista de objetos que não deseja mostrar como opção,
     * pode ser preechido o id ou document (document deve ser sem formatação)
     * exemplos:
     * [{id: 1, document: null}]
     * [{id: null, document: '112341234'}]
     */
    hideIdOrDocumentList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      content: null,
    }
  },
  computed: {
    ...mapGetters('app', ['storeOptions', 'allStoreOptions', 'activeStoreOptions']),

    localStoreOptions() {
      let optList = this.storeOptions

      if (this.onlyActiveOptions) optList = this.activeStoreOptions

      if (this.showAllStores) optList = this.allStoreOptions

      if (this.hideIdOrDocumentList?.length > 0) {
        return optList.filter(
          opt =>
            !this.hideIdOrDocumentList.some(
              hide => hide.id === opt.id || hide.document === opt.cnpj
            )
        )
      }

      if (this.filter) {
        return optList.filter(store => this.filter(store))
      }

      return optList
    },
  },
  watch: {
    value(val) {
      this.content = val
    },
  },
  created() {
    if (Array.isArray(this.value)) this.content = this.value
    else if (this.value) this.content = String(this.value)
  },

  mounted() {
    this.onSelectSingleOption()
  },

  methods: {
    handleInput() {
      this.$emit('input', this.content)
      const storeSelected = this.localStoreOptions.find(
        store => store.id === parseInt(this.content, 10)
      )
      if (storeSelected) {
        this.$emit('input-object', storeSelected)
      }
    },

    onSelectSingleOption() {
      if (!this.selectSingleOption) return

      if (this.localStoreOptions?.length === 1) {
        const defaultValue = this.localStoreOptions[0].id.toString()

        if (Array.isArray(this.content)) this.content = [defaultValue]
        else this.content = defaultValue

        this.$emit('input', this.content)
      }
    },
  },
}
</script>
