import FormulateOskNumberWithButtons from './FormulateOskNumberWithButtons.vue'

export default function VueFormulateOskNumberWithButtonsPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateOskNumberWithButtons,
    },
    library: {
      'osk-number-with-buttons': {
        classification: 'text',
        component: 'FormulateOskNumberWithButtons',
        slotProps: {
          component: ['iconLeft', 'iconRight', 'inputDisabled'],
        },
      },
    },
  })
}
