import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import VueFormulateTextMaskPlugin from '@/views/components/formulate/text-mask'
import VueFormulateDatepickerPlugin from '@/views/components/formulate/datepicker'
import VueFormulateSwitchPlugin from '@/views/components/formulate/switch'
import VueFormulateSliderPlugin from '@/views/components/formulate/slider'
import VueFormulateTagsPlugin from '@/views/components/formulate/tags'
import VueFormulateTextNumberPlugin from '@/views/components/formulate/text-number'
import VueFormulateTextPasswordPlugin from '@/views/components/formulate/text-password'
import VueFormulateTextPercentagePlugin from '@/views/components/formulate/text-percetage'
import VueFormulateUploaderPlugin from '@/views/components/formulate/uploader'
import VueFormulateCheckboxButtonPlugin from '@/views/components/formulate/checkbox-button'
import VueLabelPlugin from '@/views/components/formulate/label'
import FormulateVSelectPlugin from '@cone2875/vue-formulate-select'
import { en, pt, es } from '@braid/vue-formulate-i18n'
import OLabelWithInstructions from '@/views/components/ELabelWithInstructions.vue'
import { localesErrorMessage, rules } from '@/libs/validations'
import FormulateTimepickerPlugin from '@/views/components/formulate/timepicker'
import '@vueform/slider/themes/default.css'
import VueFormulateSelectWithButtonPlugin from '@/views/components/formulate/select-with-button'
import VueFormulateAutoSuggestPlugin from '@/views/components/formulate/auto-suggest'
import VueFormulateSelectSearchablePlugin from '@/views/components/formulate/select-searchable'
import VueFormulateDateRangePickerPlugin from '@/views/components/formulate/date-range-picker'
import VueFormulateECheckboxPlugin from '@/views/components/formulate/e-checkbox'
import VueFormulateStatusBadgePlugin from '@/views/components/formulate/status-badge'
import VueFormulateStarRatingPlugin from '@/views/components/formulate/star-rating'
import VueFormulateImagePlugin from '@/views/components/formulate/image'
import VueFormulateERadioIconPlugin from '@/views/components/formulate/e-radio-icon'
import VueFormulateEStatusBadgeMultiplePlugin from '@/views/components/formulate/status-badge-multiple'

import VueFormulateOskTextPlugin from '@/views/components/formulate/on-screen-keyboard/osk-text'
import VueFormulateOskTextNumberPlugin from '@/views/components/formulate/on-screen-keyboard/osk-text-number'
import VueFormulateOskNumberWithButtonsPlugin from '@/views/components/formulate/on-screen-keyboard/osk-number-with-buttons'
import VueFormulateOskTextMaskPlugin from '@/views/components/formulate/on-screen-keyboard/osk-text-mask'

Vue.component('OLabelWithInstructions', OLabelWithInstructions)
Vue.use(VueFormulate, {
  slotComponents: {
    // Use the string name of the globally registered component.
    label: 'OLabelWithInstructions',
  },
  plugins: [
    en,
    pt,
    es,
    VueFormulateDatepickerPlugin,
    FormulateVSelectPlugin,
    VueFormulateTextMaskPlugin,
    VueFormulateSwitchPlugin,
    VueFormulateSliderPlugin,
    VueFormulateTagsPlugin,
    VueFormulateTextNumberPlugin,
    VueFormulateTextPasswordPlugin,
    VueFormulateTextPercentagePlugin,
    VueFormulateUploaderPlugin,
    FormulateTimepickerPlugin,
    VueFormulateCheckboxButtonPlugin,
    VueFormulateSelectWithButtonPlugin,
    VueFormulateAutoSuggestPlugin,
    VueFormulateSelectSearchablePlugin,
    VueLabelPlugin,
    VueFormulateDateRangePickerPlugin,
    VueFormulateECheckboxPlugin,
    VueFormulateStatusBadgePlugin,
    VueFormulateStarRatingPlugin,
    VueFormulateEStatusBadgeMultiplePlugin,
    VueFormulateImagePlugin,
    VueFormulateERadioIconPlugin,

    VueFormulateOskTextPlugin,
    VueFormulateOskTextNumberPlugin,
    VueFormulateOskNumberWithButtonsPlugin,
    VueFormulateOskTextMaskPlugin,
  ],
  // uploader: axiosInstance,
  uploader: async (file, progress, error, options) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const result = await fetch(options.uploadUrl, {
        method: 'POST',
        body: formData,
      })
      progress(100) // (native fetch doesn’t support progress updates)
      return await result.json()
    } catch (err) {
      error('Unable to upload file')
    }
    return []
  },
  // locale: 'pt',
  locales: localesErrorMessage,
  rules,
  validationNameStrategy: ['label', 'validationName', 'name', 'type'],
})
