export default {
  title: 'Catálogo',
  icon: 'box-seam',
  iconPack: 'bootstrap',
  children: [
    {
      title: 'Produtos',
      route: 'product-list',
      resource: 'Product', // 'Product'
      action: 'Read', // 'Read'
    },
    {
      title: 'Kit de Produtos',
      route: 'product-kit-list',
      resource: 'ProductKit', // 'ProductKit'
      action: 'Read', // 'Read'
    },
    {
      title: 'Marcas',
      route: 'brand-list',
      resource: 'Brand', // 'Brand'
      action: 'Read', // 'Read'
    },
    {
      title: 'Grupos',
      route: 'product-group-list',
      resource: 'ProductGroup', // 'ProductGroup'
      action: 'Read', // 'Read'
    },
    {
      title: 'Subgrupos',
      route: 'product-subgroup-list',
      resource: 'ProductSubgroup', // 'ProductSubgroup'
      action: 'Read', // 'Read'
    },
    {
      title: 'Departamentos',
      route: 'product-departament-list',
      resource: 'ProductDepartment', // 'ProductDepartament'
      action: 'Read', // 'Read'
    },
    {
      title: 'Linhas',
      route: 'product-line-list',
      resource: 'ProductLine', // 'ProductLine'
      action: 'Read', // 'Read'
    },
    {
      title: 'Unidades de Medida',
      route: 'unit-of-measurement-list',
      resource: 'UnitOfMeasurement', // 'UnitOfMeasurement'
      action: 'Read', // 'Read'
    },
    {
      title: 'Variantes',
      route: 'product-variant-list',
      resource: 'ProductVariant', // 'ProductVariant'
      action: 'Read', // 'Read'
    },
    {
      title: 'Custo médio de produtos',
      route: 'product-average-cost-list',
      resource: 'Product',
      action: 'UpdateAveragePrice',
    },
    // {
    //   title: 'Características',
    //   route: 'product-attributes-list',
    //   resource: 'Auth', // 'ProductAttribute'
    //   action: 'read', // 'Read'
    // },
  ],
}
