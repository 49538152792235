import FormulateTextPassword from './FormulateTextPassword.vue'

export default function VueFormulateTextPasswordPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateTextPassword,
    },
    library: {
      'text-password': {
        classification: 'text',
        component: 'FormulateTextPassword',
      },
    },
  })
}
