import axios from '@axios'
import { $themeBreakpoints, $themeConfig } from '@themeConfig'
import _ from 'lodash'
import moment from 'moment'
import EventBus from '@/utils/EventBus'
import { idName } from '@/filters/filters'
import isElectron from 'is-electron'
import simpleKeyboard from './simple-keyboard'

const currencies = {
  pt: 'BRL',
  en: 'USD',
  es: 'EUR',
  'pt-PT': 'EUR',
}

const loadingOverlayDefault = {
  show: false,
  cancelable: false,
  text: '',
  image: null,
  title: null,
  id: null,
}

const setAppTimezone = () => {
  // TODO não foi possível setar dentro do export default usando a store do locale
  moment.locale('pt-br')
}
setAppTimezone()

function formatPayboxLabel(item) {
  const paybox = `${item.number} - ${item.description}`
  return `${idName(item.store)} - ${paybox}`
}

export default {
  namespaced: true,
  modules: { simpleKeyboard },
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loadingOverlay: loadingOverlayDefault,
    modalAuthRef: null,
    style: {
      tabTitle: 'Echope Admin',
      collor: '#008200',
      // eslint-disable-next-line import/no-unresolved,global-require,import/no-absolute-path
      faviconImage: require('/public/echope.png'),
      loginImage: $themeConfig.app.appLogoImageLogin,
      headerImage: $themeConfig.app.appLogoImageBigger,
      reducedHeaderImage: $themeConfig.app.appLogoImage,
    },
    // estiloCarregado: false,
    locale: 'pt-br',
    currency: 'BRL',
    stores: [],
    payboxes: [],
    allStores: [],
    paymentMethods: [],
    checkingAccounts: [],
    chatApiSettings: {
      webSocketEndpoint: null,
      webApiEndpoint: null,
    },
    virtualKeyboardEnabled: false,
  },
  getters: {
    loadingOverlayShowActive: state => state.loadingOverlay.show === true,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },

    statesWithStore: state => _.uniq(state.stores.map(store => store.address?.province)),
    payboxOptions: state =>
      _.sortBy(
        state.payboxes.map(item => ({
          ...item,
          value: item.id,
          label: formatPayboxLabel(item),
        })),
        item => item.label
      ),
    storeOptions: state =>
      _.sortBy(
        state.stores.map(store => ({
          ...store,
          value: store.id,
          label: `${store.id.toString().padStart(3, '0')} - ${store.tradingName}`,
        })),
        item => item.label
      ),
    allStoreOptions: state =>
      _.sortBy(
        state.allStores.map(store => ({
          ...store,
          value: store.id,
          label: `${store.id.toString().padStart(3, '0')} - ${store.tradingName}`,
        })),
        item => item.label
      ),
    activeStoreOptions: state =>
      _.sortBy(
        state.stores
          .filter(s => s.active)
          .map(store => ({
            ...store,
            value: store.id,
            label: `${store.id.toString().padStart(3, '0')} - ${store.tradingName}`,
          })),
        item => item.label
      ),
    paymentMethodsOptions: state =>
      state.paymentMethods.map(paymentMethod => ({
        ...paymentMethod,
        value: paymentMethod.id,
        label: paymentMethod.name,
      })),
    paymentMethodsObjectOptions: state =>
      state.paymentMethods.map(paymentMethod => ({
        ...paymentMethod,
        value: paymentMethod,
        label: paymentMethod.name,
      })),
    checkingAccountsOptions: state =>
      state.checkingAccounts.map(checkingAccount => ({
        ...checkingAccount,
        value: checkingAccount.id,
        label: checkingAccount.name,
      })),
    storeById: state => storeId =>
      // eslint-disable-next-line eqeqeq
      state.stores.find(item => item.id == storeId),
    // estiloCarregado: state => {
    //   const { style } = state
    //   return !!style?.faviconImage
    // },
    isVirtualKeyboardEnabled: state => state.virtualKeyboardEnabled,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_LOADING_OVERLAY(state, { show, cancelable, text, image, title, id }) {
      if (!show) {
        EventBus.$emit('overlay-hidden', state.loadingOverlay)
        state.loadingOverlay = { ...loadingOverlayDefault }
        return
      }

      state.loadingOverlay = {
        show: show ?? state.loadingOverlay.show,
        cancelable: cancelable ?? state.loadingOverlay.cancelable,
        image: image ?? state.loadingOverlay.image,
        title: title ?? state.loadingOverlay.title,
        text: text ?? state.loadingOverlay.text,
        id,
      }
    },
    SET_LOCALE(state, val) {
      state.locale = val
    },
    SET_CURRENCY(state, val) {
      state.currency = val
    },
    SET_PAYBOXES(state, payboxes) {
      state.payboxes = payboxes
    },
    SET_STORES(state, stores) {
      state.stores = stores
    },
    SET_ALL_STORES(state, stores) {
      state.allStores = stores
    },
    SET_CHECKING_ACCOUNTS(state, checkingAccounts) {
      state.checkingAccounts = checkingAccounts
    },
    SET_PAYMENT_METHODS(state, paymentMethods) {
      state.paymentMethods = _.orderBy(paymentMethods, item => item.name)
    },
    SET_MODAL_AUTH_REF(state, ref) {
      state.modalAuthRef = ref
    },
    CLEAN_MODAL_AUTH_REF(state) {
      state.modalAuthRef = null
    },
    SET_CHAT_API_SETTINGS(state, chatApiSettings) {
      state.chatApiSettings = chatApiSettings
    },
    SET_VIRTUAL_KEYBOARD_ENABLED(state, virtualKeyboardEnabled) {
      state.virtualKeyboardEnabled = virtualKeyboardEnabled
    },
  },
  actions: {
    setLocale({ commit }, payload) {
      commit('SET_LOCALE', payload)
      commit('SET_CURRENCY', currencies[payload])
    },

    async loadAppDesktopSettings({ commit }) {
      if (!isElectron()) return

      const payboxData = await window.electronAPI.system.identifyPayboxRead()
      if (payboxData.isAtm) {
        commit('SET_VIRTUAL_KEYBOARD_ENABLED', true)
      }
    },

    async fetchPayboxes({ commit, state }) {
      if (state.payboxes.length > 0) return

      const { results } = (
        await axios.get('/api/sales/pay-box', { params: { pageSize: 0, pageIndex: 0 } })
      ).data

      commit('SET_PAYBOXES', results)
    },
    async fetchStores({ commit }) {
      const { results } = (
        await axios.get('/api/stores', { params: { pageSize: 0, pageIndex: 0 } })
      ).data
      commit('SET_STORES', results)
    },
    async fetchAllStores({ commit }) {
      const results = (await axios.get('/api/stores/all')).data
      commit('SET_ALL_STORES', results)
    },
    async fetchPaymentMethods({ commit }) {
      const { data } = await axios.get('/api/settings/financial/payment-methods/active', {
        params: { pageSize: 0, pageIndex: 0 },
      })
      commit('SET_PAYMENT_METHODS', data.results || [])
    },
    async fetchCheckingAccounts({ commit }) {
      const { data } = await axios.get('/api/financial/checking-accounts', {
        params: { pageSize: 0, pageIndex: 0 },
      })
      commit('SET_CHECKING_ACCOUNTS', data.results || [])
    },
    async fetchChatApiSettings({ commit }) {
      const { data } = await axios.get('/api/settings/chat')
      commit('SET_CHAT_API_SETTINGS', data)
    },

    async fetchCombos({ dispatch }) {
      dispatch('fetchStores')
      dispatch('fetchAllStores')
      dispatch('fetchPaymentMethods')
      dispatch('fetchCheckingAccounts')
    },

    setModalAuthRef({ commit }, ref) {
      commit('SET_MODAL_AUTH_REF', ref)
    },
    cleanModalAuthRef({ commit }) {
      commit('CLEAN_MODAL_AUTH_REF')
    },
  },
}
