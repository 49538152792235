export default [
  // contaGerencial list
  {
    path: '/configuracao/financeiro/plano-de-contas-gerencial',
    name: 'management-account-list',
    component: () =>
      import('@/views/pages/settings/financial/management-account/ManagementAccountList.vue'),
    meta: {
      pageTitle: 'Contas Gerenciais',
      navActiveLink: 'management-account-list',
      resource: 'ManagementAccount',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas Gerenciais',
          active: true,
        },
      ],
    },
  },
  // contaCorrente list
  {
    path: '/financeiro/conta-corrente',
    name: 'checking-account-list',
    component: () => import('@/views/pages/financial/checking-account/CheckingAccountList.vue'),
    meta: {
      pageTitle: 'Cadastro',
      navActiveLink: 'checking-account-list',
      resource: 'CheckingAccount',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Conta corrente',
        },
        {
          text: 'Cadastro',
          active: true,
        },
      ],
    },
  },
  // ContaCorrente extrato list
  {
    path: '/financeiro/conta-corrente/extrato',
    name: 'checking-account-entry-list',
    component: () =>
      import('@/views/pages/financial/checking-account-entry/CheckingAccountEntryList.vue'),
    meta: {
      pageTitle: 'Extrato',
      navActiveLink: 'checking-account-entry-list',
      resource: 'CheckingAccountEntry',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Conta corrente',
        },
        {
          text: 'Extrato',
          active: true,
        },
      ],
    },
  },
  // contaCorrente transferencias list
  {
    path: '/financeiro/conta-corrente/transferencias',
    name: 'checking-account-transference-list',
    component: () =>
      import('@/views/pages/financial/checking-account-transfer/CheckingAccountTransferList.vue'),
    meta: {
      pageTitle: 'Transferências',
      navActiveLink: 'checking-account-transference-list',
      resource: 'CheckingAccountTransfer',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Conta corrente',
        },
        {
          text: 'Transferências',
          active: true,
        },
      ],
    },
  },
  // contaCorrente transferencias bulk maintain
  {
    path: '/financeiro/conta-corrente/transferencias/deposito/importacao',
    name: 'checking-account-bank-deposit-bulk',
    component: () =>
      import('@/views/pages/financial/checking-account-transfer/BankDepositBulkMaintain.vue'),
    meta: {
      pageTitle: 'Importação de depósitos',
      navActiveLink: 'checking-account-transference-list',
      resource: 'BankDeposit',
      action: 'BulkCreateFromImport',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Conta corrente',
        },
        {
          text: 'Transferências',
        },
        {
          text: 'Importação de depósitos',
          active: true,
        },
      ],
    },
  },
  // formasDePagamento list
  {
    path: '/configuracao/financeiro/formas-de-pagamento',
    name: 'payment-type-list',
    component: () => import('@/views/pages/settings/financial/payment-type/PaymentTypeList.vue'),
    meta: {
      pageTitle: 'Formas de pagamento',
      navActiveLink: 'payment-type-list',
      resource: 'PaymentMethod',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Financeiro',
        },
        {
          text: 'Formas de pagamento',
          active: true,
        },
      ],
    },
  },
  // Cartao list
  {
    path: '/configuracao/financeiro/cartao',
    name: 'card-list',
    component: () => import('@/views/pages/settings/financial/card/CardList.vue'),
    meta: {
      pageTitle: 'Cartão de Crédito/Débito',
      navActiveLink: 'card-list',
      resource: 'SettingsFinancialCards',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Financeiro',
        },
        {
          text: 'Cartão de Crédito/Débito',
          active: true,
        },
      ],
    },
  },
  // Cartao add
  {
    path: '/configuracao/financeiro/cartao/adicionar',
    name: 'card-add',
    component: () => import('@/views/pages/settings/financial/card/CardMaintain.vue'),
    meta: {
      pageTitle: 'Novo Cartão de Crédito/Débito',
      navActiveLink: 'card-list',
      resource: 'SettingsFinancialCards',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Financeiro',
        },
        {
          text: 'Cartão de Crédito/Débito',
        },
        {
          text: 'Novo Cartão de Crédito/Débito',
          active: true,
        },
      ],
    },
  },
  // Cartao maintain
  {
    path: '/configuracao/financeiro/cartao/manter/:id',
    name: 'card-maintain',
    component: () => import('@/views/pages/settings/financial/card/CardMaintain.vue'),
    meta: {
      pageTitle: 'Editar Cartão de Crédito/Débito',
      navActiveLink: 'card-list',
      resource: 'SettingsFinancialCards',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Financeiro',
        },
        {
          text: 'Cartão de Crédito/Débito',
        },
        {
          text: 'Editar Cartão de Crédito/Débito',
          active: true,
        },
      ],
    },
  },
]
