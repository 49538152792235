import axios from '@axios'
import { storePagination, sorting } from '@/mixins'

const getInitialState = () => ({
  ...storePagination.state(),
  filters: {
    id: '',
    type: null,
    region: null,
    province: null,
    city: null,
  },
  stores: [],
  selectedStoreId: null,
  selectedStores: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_STORES(state, stores) {
      state.stores = stores
    },

    RESET_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },

    SET_SELECTED_STORES(state, selectedStores) {
      state.selectedStores = selectedStores
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchStores({ commit, state }) {
      const { id, province, city, type, region } = state.filters
      const response = await axios.get('/api/stores/delivery', {
        params: {
          id,
          province,
          city,
          type,
          region,
          sortBy: sorting.methods.getSorting(state),
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
      })

      const installmentsFormated = response.data.results.map(installment => ({
        ...installment,
        storeSelected: state.selectedStores.some(selected => selected.id === installment.id),
      }))

      commit('SET_STORES', installmentsFormated)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: response.data.rowCount,
        rowsInCurrentPage: response.data.rowsInCurrentPage,
      })
    },

    addStoresThatWillBeConfigured({ commit, state }, item) {
      const newSelectedStores = [...state.selectedStores, { ...item }]
      commit('SET_SELECTED_STORES', newSelectedStores)
    },

    removeStoresThatWillBeConfigured({ commit, state }, item) {
      const newList = [...state.selectedStores]
      const indexFound = newList.findIndex(inst => inst.id === item.id)
      newList.splice(indexFound, 1)
      commit('SET_SELECTED_STORES', newList)
    },

    async fetchStore(states, storeId) {
      const response = await axios.get(`/api/stores/${storeId}`)
      return response.data
    },

    resetFilters({ commit }) {
      commit('RESET_FILTERS')
    },

    cleanSelectedStores({ commit }) {
      commit('SET_SELECTED_STORES', [])
    },
  },
}
