export default [
  {
    path: '/lojas/listar',
    name: 'store-list',
    component: () => import('@/views/pages/store/StoreList.vue'),
    meta: {
      pageTitle: 'Lojas',
      navActiveLink: 'store-list',
      resource: 'Store',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Lojas',
        },
        {
          text: 'Gestão de Lojas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lojas/adicionar',
    name: 'store-add',
    component: () => import('@/views/pages/store/StoreMaintain.vue'),
    meta: {
      pageTitle: 'Nova Loja',
      navActiveLink: 'store-list',
      resource: 'Store',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Lojas',
        },
        {
          text: 'Gestão de Lojas',
        },
        {
          text: 'Nova Loja',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lojas/manter/:id',
    name: 'store-maintain',
    component: () => import('@/views/pages/store/StoreMaintain.vue'),
    meta: {
      pageTitle: 'Manter Loja',
      navActiveLink: 'store-list',
      resource: 'Store',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Lojas',
        },
        {
          text: 'Gestão de Lojas',
        },
        {
          text: 'Manter Loja',
          active: true,
        },
      ],
    },
  },
]
