import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import isElectron from 'is-electron'

const app = {
  computed: {
    style() {
      return store.state.app.style
    },
  },

  methods: {
    async doAppLogout(electronCloseWindow) {
      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      // Setando o estilo do operador para vazio, a aplicação não é mais exibida, e o spinning vai ficar no lugar.
      await this.$store.dispatch('app/setEstiloOperador', {})
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block'
      }
      useJwt.logout()

      // Reset ability
      this.$ability.update(initialAbility)

      if (isElectron()) {
        if (electronCloseWindow) {
          window.electronAPP.quit()
        } else {
          window.location.href = window.electronAPP.loadURL
        }
      } else {
        // Usando $router.go para resetar todo o estado da aplicação (Vuex)
        this.$router.go()
      }
    },
  }
}

export default app
