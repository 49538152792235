<template>
  <div>
    <formulate-text-mask
      :context="context"
      :native-type="nativeType"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import FormulateTextMask from '../../text-mask/FormulateTextMask.vue'

export default {
  name: 'FormulateOskTextMask',

  components: {
    FormulateTextMask,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
    oskType: {
      type: String,
      default: 'numeric',
    },
    nativeType: {
      type: String,
      default: undefined
    }
  },

  computed: {
    ...mapState('app/simpleKeyboard', ['currentInputValue']),
  },

  watch: {
    currentInputValue(newValue) {
      if (this.$el.contains(document.activeElement)) {
        this.context.model = newValue
      }
    }
  },

  methods: {
    ...mapActions('app/simpleKeyboard', ['showKeyboard', 'hideKeyboard']),

    // eslint-disable-next-line func-names
    onFocus: _.debounce(async function (event) {
      this.showKeyboard({
        el: event.target,
        layoutType: this.oskType
      })
      this.$emit('focus', event.target.value, event)
    }, 50),

    onBlur() {
      this.hideKeyboard()
    },
  },
}
</script>
