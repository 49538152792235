import { mapMutations, mapState } from 'vuex'

const loadingOverlay = {
  computed: {
    ...mapState('app', ['loadingOverlay']),
  },

  methods: {
    ...mapMutations('app', ['SET_LOADING_OVERLAY']),

    showLoadingOverlayV2({ text, title, image, cancelable, id }) {
      this.SET_LOADING_OVERLAY({ show: true, text, image, title, cancelable, id })
    },

    showLoadingOverlay(text = null, title = null, image = null, cancelable = false, id = null) {
      this.SET_LOADING_OVERLAY({ show: true, text, image, title, cancelable, id })
    },

    hideLoadingOverlay() {
      this.SET_LOADING_OVERLAY({ show: false })
    },
  },
}

export default loadingOverlay
