<template>
  <b-img
    :src="image"
    fluid
  />
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: { BImg },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      noImage: require('@/assets/images/noimage.png'),
    }
  },
  computed: {
    image() {
      const [image] = Array.isArray(this.context.model) ? this.context.model : []
      return image?.url || image || this.noImage
    },
  },
}
</script>
