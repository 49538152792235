import FormulateAutoSuggest from './FormulateAutoSuggest.vue'

export default function VueFormulateAutoSuggestPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateAutoSuggest,
    },
    library: {
      'auto-suggest': {
        classification: 'text',
        component: 'FormulateAutoSuggest',
      },
    },
  })
}
