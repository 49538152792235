const keyboardCode = {
  computed: {
    // values from event 'keydown' or 'keyup' of property event.code
    // reference https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes/
    // all keys https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
    keyCode() {
      return {
        backspace: 'Backspace',
        tab: 'Tab',
        enter: 'Enter',
        esc: 'Escape',
        ctrl: 'Ctrl',
        alt: 'Alt',

        0: 'Digit0',
        1: 'Digit1',
        2: 'Digit2',
        3: 'Digit3',
        4: 'Digit4',
        5: 'Digit5',
        6: 'Digit6',
        7: 'Digit7',
        8: 'Digit8',
        9: 'Digit9',
        num0: 'Numpad0',
        num1: 'Numpad1',
        num2: 'Numpad2',
        num3: 'Numpad3',
        num4: 'Numpad4',
        num5: 'Numpad5',
        num6: 'Numpad6',
        num7: 'Numpad7',
        num8: 'Numpad8',
        num9: 'Numpad9',
        numMul: 'NumpadMultiply',
        numAdd: 'NumpadAdd',
        numSub: 'NumpadSubtract',
        numDec: 'NumpadDecimal',
        numDiv: 'NumpadDivide',
        numEnter: 'NumpadEnter',
        f1: 'F1',
        f2: 'F2',
        f3: 'F3',
        f4: 'F4',
        f5: 'F5',
        f6: 'F6',
        f7: 'F7',
        f8: 'F8',
        f9: 'F9',
        f10: 'F10',
        f11: 'F11',
        f12: 'F12',
      }
    },
  },

  methods: {
    /**
     *
     * @param {KeyboardEvent} event
     * @returns {Boolean}
     */
    isOnlyCtrl(event) {
      if (event.altKey || event.shiftKey) return false
      return event.ctrlKey
    },

    addKeyEvent(callback) {
      window.addEventListener('keydown', callback, false)
    },

    removeKeyEvent(callback) {
      window.removeEventListener('keydown', callback, false)
    },

    /**
     *
     * @param {string} keyCode valor do `event.code` do evento de keyup
     * @param {Object} othersKeyCode adicione apenas propriedades que deseja do `event.code`. Ex: se quer que tenha enter e esc `{'enter': true, 'esc': true}`
     * @returns
     */
    waitKeyUp(keyCode, othersKeyCode = {}) {
      return new Promise(resolve => {
        function onKeyHandler(event) {
          if (keyCode === event.code || othersKeyCode[event.code]) {
            window.removeEventListener('keyup', onKeyHandler)
            resolve(event)
          }
        }
        window.addEventListener('keyup', onKeyHandler)
      })
    }
  },
}

export default keyboardCode
