<template>
  <b-badge
    :variant="statusLabel[status].classStyle"
    class="w-100"
  >
    {{ statusLabel[status].text }}
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'EStatusBadge',
  components: { BBadge },
  props: {
    status: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      statusLabel: {
        true: { text: this.$t('Sim'), classStyle: 'success' },
        false: { text: this.$t('Não'), classStyle: 'danger' },
      },
    }
  },
}
</script>

<style></style>
