import axios from '@axios'
import { storePagination, sorting } from '@/mixins'
import moment from 'moment'

const getInitialState = () => ({
  ...storePagination.state(),
  stockInventoryForm: {
    id: null,
    storeId: null,
    groupId: null,
    subgroupId: null,
    brandId: null,
    departmentId: null,
    description: null,
    cancelationUser: null,
    cancelationDate: null,
    cancelationReason: null,
    productClassifications: [],
  },
  filters: {
    skuId: '',
    ean: null,
    name: null,
    department: null,
    brand: null,
    group: null,
    subgroup: null,
  },
  stockInventorySkus: [],
  invoiceId: null,
  invoiceNumber: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_STOCK_INVENTORY(state, stockInventory) {
      state.stockInventoryForm = stockInventory
    },
    SET_STOCK_INVENTORY_SKUS(state, stockInventorySkus) {
      state.stockInventorySkus = stockInventorySkus
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_INVOICE_ID(state, invoiceId) {
      state.invoiceId = invoiceId
    },
    SET_INVOICE_NUMBER(state, invoiceNumber) {
      state.invoiceNumber = invoiceNumber
    },
    CLEAN_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const initialState = getInitialState()
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
  },

  actions: {
    ...storePagination.actions,

    async finishInventory({ state }, { delegation }) {
      const { data } = await axios.post(
        `/api/stock-inventories/finish/${state.stockInventoryForm.id}`,
        {},
        {
          headers: {
            'delegate-token': delegation?.token,
          },
        }
      )
      return data
    },

    async stockInventoryToInvoice({ state, commit }) {
      const payload = {
        idStockInventory: state.stockInventoryForm.id,
      }
      const { data } = await axios.post(`/api/invoices/invoice-create-by-stock-inventory`, payload)
      commit('SET_INVOICE_ID', data.id)
    },

    async fetchInventory({ commit }, id) {
      const { data } = await axios.get(`/api/stock-inventories/${id}`)

      const formatted = {
        ...data,
        productClassifications: data?.productClassifications?.map(c => c.classification),
      }

      commit('SET_STOCK_INVENTORY', formatted)
      commit('SET_INVOICE_ID', data.invoiceId)
      commit('SET_INVOICE_NUMBER', data.invoice?.invoiceNumber)
    },

    async fetchInventorySkus({ commit, state }, stockInventoryId) {
      const { data } = await axios.get(`/api/stock-inventory-skus/${stockInventoryId}`, {
        params: {
          skuId: state.filters.skuId,
          ean: state.filters.ean,
          name: state.filters.name,
          brandId: state.filters.brand,
          groupId: state.filters.group,
          subgroupId: state.filters.subgroup,
          departmentId: state.filters.department,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      const resultFormated =
        data.results.map(item => {
          const stockInSystem =
            item.stockInSystem == null
              ? item.stockInSystem
              : item.stockInSystem + (item.stockLendingOrRent || 0)

          return {
            ...item,
            timeCounted: item.stockCountedDate
              ? moment(item.stockCountedDate).format('HH:mm')
              : null,
            busy: false,
            stockMovemented:
              item.stockCounted != null ? item.stockInSystem - item.stockInSystemAtCounting : 0,
            stockInSystem,
          }
        }) || []

      commit('SET_STOCK_INVENTORY_SKUS', resultFormated)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },
    async generateReport({ state }, stockInventoryId) {
      const { data } = await axios.get(`/api/stock-inventory-skus/${stockInventoryId}/download`, {
        params: {
          skuId: state.filters.skuId,
          ean: state.filters.ean,
          name: state.filters.name,
          brandId: state.filters.brand,
          groupId: state.filters.group,
          subgroupId: state.filters.subgroup,
          departmentId: state.filters.department,
          pageSize: 0,
          pageIndex: 0,
          sortBy: sorting.methods.getSorting(state),
        },
        responseType: 'blob',
      })
      return data
    },
    async saveInventory({ state }) {
      const productClassifications = state.stockInventoryForm?.productClassifications?.map(
        classification => ({
          classification,
        })
      )

      const payload = {
        ...state.stockInventoryForm,
        productClassifications,
      }
      const { data: result } = await axios.post('/api/stock-inventories', payload)
      return result
    },
    async saveSkuStockCounted({ state, commit }, { skuId, stockCounted }) {
      const { data: result } = await axios.patch(
        `/api/stock-inventories/${state.stockInventoryForm.id}/${skuId}/stock-counted`,
        { stockCounted }
      )
      commit('SET_STOCK_INVENTORY', {
        ...state.stockInventoryForm,
        percentCompleted: result.inventoryPercentCompleted,
        differenceValue: result.inventoryDifferenceValue,
      })

      return result
    },
    async saveSkuStockTimeCounted({ state, commit }, { skuId, timeCounted }) {
      const momentDate = moment().format('YYYY-MM-DD')
      const time = timeCounted || moment().format('HH:mm')
      const deliveryDateTimeFormatted = moment(`${momentDate} ${time}`, 'YYYY-MM-DD HH:mm').format()

      const { data: result } = await axios.patch(
        `/api/stock-inventories/${state.stockInventoryForm.id}/${skuId}/stock-time-counted`,
        { timeCounted: deliveryDateTimeFormatted }
      )
      commit('SET_STOCK_INVENTORY', {
        ...state.stockInventoryForm,
        percentCompleted: result.inventoryPercentCompleted,
        differenceValue: result.inventoryDifferenceValue,
      })

      return result
    },
    async resetSkuStockCounted({ state, commit }, skuId) {
      const { data: result } = await axios.patch(
        `/api/stock-inventories/${state.stockInventoryForm.id}/${skuId}/stock-counted-reset`
      )
      commit('SET_STOCK_INVENTORY', {
        ...state.stockInventoryForm,
        percentCompleted: result.inventoryPercentCompleted,
        differenceValue: result.inventoryDifferenceValue,
      })

      return result
    },
    async saveSkuComments({ state }, { skuId, comments }) {
      await axios.patch(`/api/stock-inventories/${state.stockInventoryForm.id}/${skuId}/comments`, {
        comments,
      })
    },
    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },
    resetFilters({ commit }) {
      commit('CLEAN_FILTERS')
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
