export default [
  {
    path: '/estoque/posicao-estoque',
    props: route => ({ storeId: route.params.storeId, skuId: route.params.skuId }),
    name: 'stock-position-list',
    component: () => import('@/views/pages/stock/stock-position/StockPositionList.vue'),
    meta: {
      pageTitle: 'Posição de Estoque',
      resource: 'StockPosition',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Posição de Estoque',
          active: true,
        },
      ],
    },
  },
  {
    path: '/estoque/estoque-retroativo',
    name: 'stock-retroactive-list',
    component: () => import('@/views/pages/stock/stock-retroactive/StockRetroactiveList.vue'),
    meta: {
      pageTitle: 'Estoque Retroativo',
      resource: 'StockRetroactive',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Estoque Retroativo',
          active: true,
        },
      ],
    },
  },
  // MovimentacaoEstoque list
  {
    path: '/estoque/movimentacao-estoque',
    props: route => ({ storeId: route.params.storeId, skuId: route.params.skuId }),
    name: 'stock-movement-list',
    component: () => import('@/views/pages/stock/stock-movement/StockMovementList.vue'),
    meta: {
      pageTitle: 'Movimentação de Estoque',
      navActiveLink: 'stock-movement-list',
      resource: 'StockMovement',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Movimentação de Estoque',
          active: true,
        },
      ],
    },
  },
  // MovimentacaoEstoque add
  {
    path: '/estoque/movimentacao-estoque/adicionar',
    name: 'stock-movement-add',
    component: () => import('@/views/pages/stock/stock-movement/StockMovementMaintain.vue'),
    meta: {
      pageTitle: 'Nova Movimentação de Estoque',
      navActiveLink: 'stock-movement-list',
      resource: 'StockMovement',
      action: 'ManualUpdate',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Movimentação de Estoque',
        },
        {
          text: 'Nova Movimentação de Estoque',
          active: true,
        },
      ],
    },
  },
  // MovimentacaoEstoque maintain
  {
    path: '/estoque/movimentacao-estoque/manter/:id',
    name: 'stock-movement-maintain',
    component: () => import('@/views/pages/stock/stock-movement/StockMovementMaintain.vue'),
    meta: {
      pageTitle: 'Editar Movimentação de Estoque',
      navActiveLink: 'stock-movement-list',
      resource: 'StockMovement',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Movimentação de Estoque',
        },
        {
          text: 'Editar Movimentação de Estoque',
          active: true,
        },
      ],
    },
  },
  // ReservaEstoque list
  {
    path: '/estoque/reserva-estoque',
    props: route => ({ storeId: route.params.storeId, skuId: route.params.skuId }),
    name: 'stock-reservation-list',
    component: () => import('@/views/pages/stock/stock-reservation/StoctReservationList.vue'),
    meta: {
      pageTitle: 'Reserva de Estoque',
      navActiveLink: 'stock-reservation-list',
      resource: 'StockReservation',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Reserva de Estoque',
          active: true,
        },
      ],
    },
  },
  {
    path: '/estoque/inversao-de-estoque',
    name: 'stock-inversion-list',
    component: () => import('@/views/pages/stock/stock-inversion/StoctInversionList.vue'),
    meta: {
      pageTitle: 'Inversão de Estoque',
      resource: 'StockInversion',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Inversão de Estoque',
          active: true,
        },
      ],
    },
  },
  {
    path: '/estoque/inventario-de-estoque',
    name: 'stock-inventory-list',
    component: () => import('@/views/pages/stock/stock-inventory/StockInventoryList.vue'),
    meta: {
      pageTitle: 'Inventário de Estoque',
      resource: 'StockInventory',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Inventário de Estoque',
          active: true,
        },
      ],
    },
  },
  {
    path: '/estoque/inventario-de-estoque/manter/:id',
    name: 'stock-inventory-maintain',
    component: () => import('@/views/pages/stock/stock-inventory/StockInventoryMaintain.vue'),
    meta: {
      pageTitle: 'Manter Inventário de Estoque',
      navActiveLink: 'stock-inventory-list',
      resource: 'StockInventory',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Inventário de Estoque',
          to: { name: 'stock-inventory-list' },
        },
        {
          text: 'Manter Inventário',
          active: true,
        },
      ],
    },
  },
  {
    path: '/estoque/inventario-de-estoque/visualizar/:id',
    props: { readOnlyPage: true },
    name: 'stock-inventory-read-only',
    component: () => import('@/views/pages/stock/stock-inventory/StockInventoryMaintain.vue'),
    meta: {
      pageTitle: 'Visualizar Inventário de Estoque',
      navActiveLink: 'stock-inventory-list',
      resource: 'StockInventory',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Estoque',
        },
        {
          text: 'Inventário de Estoque',
          to: { name: 'stock-inventory-list' },
        },
        {
          text: 'Visualizar Inventário',
          active: true,
        },
      ],
    },
  },
]
