var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "mr-1 " + _vm.labelContainerClass,
      staticStyle: { display: "inline" },
    },
    [
      _vm.localInstruction || _vm.localInstructionList.length
        ? _c("feather-icon", {
            staticClass: "cursor-pointer",
            staticStyle: { "margin-rigth": "8px", "margin-bottom": "3px" },
            attrs: {
              id: _vm.target,
              icon: "InfoIcon",
              size: "15",
              tabindex: _vm.tabIndex,
            },
          })
        : _vm._e(),
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.localLabel,
              expression: "localLabel",
            },
          ],
          staticClass: "formulate-input-label",
          staticStyle: { display: "inline" },
          attrs: { id: _vm.localFor + "_label", for: _vm.localFor },
        },
        [_vm._v(" " + _vm._s(_vm.localLabel) + " ")]
      ),
      _vm.localInstruction || _vm.localInstructionList.length > 0
        ? _c(
            "b-popover",
            {
              attrs: {
                target: _vm.target,
                triggers: "focus",
                placement: "top",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [_c("span", [_vm._v(_vm._s(_vm.localTitle))])]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3888591078
              ),
            },
            [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.localInstruction) + " "),
                _c(
                  "ul",
                  _vm._l(_vm.localInstructionList, function (item) {
                    return _c("li", { key: item.text }, [
                      item.strong
                        ? _c("strong", [_vm._v(" " + _vm._s(item.text) + " ")])
                        : _c("span", [_vm._v(" " + _vm._s(item.text) + " ")]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }