<template>
  <div>
    <div
      v-if="showBadgeList"
      class="d-flex"
      style="gap: 10px"
    >
      <div
        v-for="(item, index) in context.model"
        :key="`${item}_${index}`"
      >
        <e-status-badge
          :status="item"
          :options="options"
        />
      </div>
    </div>
    <template v-else>
      <span>-</span>
    </template>
  </div>
</template>
<script>
import { EStatusBadge } from '@/views/components'

export default {
  components: { EStatusBadge },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return this.context.options
    },
    showBadgeList() {
      return (
        Array.isArray(this.context.model) &&
        this.context.model.length > 0 &&
        this.options?.length > 0
      )
    },
  },
}
</script>
