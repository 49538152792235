var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showBadgeList
        ? _c(
            "div",
            { staticClass: "d-flex", staticStyle: { gap: "10px" } },
            _vm._l(_vm.context.model, function (item, index) {
              return _c(
                "div",
                { key: item + "_" + index },
                [
                  _c("e-status-badge", {
                    attrs: { status: item, options: _vm.options },
                  }),
                ],
                1
              )
            }),
            0
          )
        : [_c("span", [_vm._v("-")])],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }