import axios from '@axios'

const getInitialState = () => ({
  ncms: [],
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {},
  getters: {
    getComboNcms(state) {
      return state.ncms.map(s => ({
        ...s,
        label: `${s.ncm} - ${s.description}`,
        value: s.ncm,
      }))
    },
  },

  mutations: {
    SET_NCMS(state, ncms) {
      state.ncms = ncms
    },

    CLEAN_STATE(state) {
      const { ncms } = getInitialState()
      state.ncms = ncms
    },
  },

  actions: {
    async fetchNcms({ commit }, { itemSearch }) {
      if (itemSearch.length >= 4) {
        const { data: ncmsData } = await axios.get('/api/tax/ncms', {
          params: { ncm: itemSearch },
        })
        commit('SET_NCMS', ncmsData || [])
      }
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
