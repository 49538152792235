<template>
  <div class="d-flex align-items-center flex-wrap">
    <!-- necessário utilizar v-model para que não ocorra problemas quando manipular os valores internamente. -->
    <!-- Ex: usar um método que reseta a data no componente pai, não limparia a data no layout mas limparia no JS -->
    <FormulateInput
      :id="inputsId.dateFromId"
      v-model="localDate.dateFrom"
      type="datepicker"
      :label="label"
      :max="localDate.dateTo || today"
      :validation="[['optional'], ['before', getValidDates.dateBefore]]"
      :validation-messages="{
        before: $t('Período inválido'),
      }"
      class="input-date"
      :class="{
        'margin-date': withTime,
        'date-with-time': withTime,
        'date-without-time': !withTime,
      }"
      :placeholder="$t(fromPlaceholder)"
    />
    <FormulateInput
      v-if="withTime"
      :id="inputsId.timeFromId"
      v-model="localDate.timeFrom"
      type="timepicker"
      label=" "
      class="input-time margin-time"
      :placeholder="$t('hh:mm')"
    />

    <div
      class="mx-1 formulate-input"
      style="padding-top: 18px"
    >
      <span>{{ $t('Até') }}</span>
    </div>

    <FormulateInput
      :id="inputsId.dateToId"
      v-model="localDate.dateTo"
      type="datepicker"
      label=" "
      class="input-date"
      :class="{
        'margin-date': withTime,
        'date-with-time': withTime,
        'date-without-time': !withTime,
      }"
      :validation="[['optional'], ['after', getValidDates.dateAfter]]"
      :validation-messages="{
        after: $t('Período inválido'),
      }"
      :placeholder="$t(toPlaceholder)"
    />
    <FormulateInput
      v-if="withTime"
      :id="inputsId.timeToId"
      v-model="localDate.timeTo"
      type="timepicker"
      label=" "
      class="input-time margin-time"
      :placeholder="$t('hh:mm')"
    />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  components: {},

  props: {
    /**
     * {
     *    dateFrom: null | Date | String,
     *    timeFrom: null | String
     *    dateTo: null | Date | String,
     *    timeTo: null | String
     * }
     */
    value: {
      type: Object,
      default: () => ({}),
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    inputsId: {
      type: Object,
      default: () => ({
        dateFromId: 'input-date-from',
        dateToId: 'input-date-to',
        timeFromId: 'input-time-from',
        timeToId: 'input-time-to',
      }),
    },
    label: {
      type: String,
      default: '',
    },
    fromPlaceholder: {
      type: String,
      default: 'De',
    },
    toPlaceholder: {
      type: String,
      default: 'Até',
    },
  },

  data() {
    return {
      localDate: {
        dateFrom: null,
        timeFrom: null,
        dateTo: null,
        timeTo: null,
      },
    }
  },

  computed: {
    today() {
      return new Date()
    },

    getValidDates() {
      return {
        dateBefore: moment(this.localDate.dateTo).add(1, 'days').format('YYYY-MM-DD'),
        dateAfter: moment(this.localDate.dateFrom).subtract(1, 'days').format('YYYY-MM-DD'),
      }
    },

    getValidTime() {
      const isSameDate = moment(this.localDate.dateTo) === moment(this.localDate.dateFrom)
      if (!isSameDate)
        return {
          dateBefore: true,
          dateAfter: true,
        }
      return {
        dateBefore: moment(this.localDate.dateTo).add(1, 'days').format('YYYY-MM-DD'),
        dateAfter: moment(this.localDate.dateFrom).subtract(1, 'days').format('YYYY-MM-DD'),
      }
    },
  },

  watch: {
    value(val) {
      this.localDate = val
    },

    // eslint-disable-next-line func-names
    'localDate.dateFrom': function (val) {
      this.$emit('input', { ...this.localDate, dateFrom: val })
    },

    // eslint-disable-next-line func-names
    'localDate.dateTo': function (val) {
      this.$emit('input', { ...this.localDate, dateTo: val })
    },

    // eslint-disable-next-line func-names
    'localDate.timeFrom': function (val) {
      this.$emit('input', { ...this.localDate, timeFrom: val })
    },

    // eslint-disable-next-line func-names
    'localDate.timeTo': function (val) {
      this.$emit('input', { ...this.localDate, timeTo: val })
    },
  },

  created() {
    this.localDate = this.value
  },
}
</script>

<style lang="scss" scoped>
.input-date {
  width: 200px;
}

.date-with-time {
  max-width: 24.75%;
}

.date-without-time {
  max-width: 44.75%;
}

.input-time {
  width: 105px;
}

.margin-date {
  margin-right: 5px;
}

.margin-time {
  padding-left: 0px;
}
</style>
