<template>
  <div
    class="e-sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
  >
    <h4 class="mb-0">
      {{ title }}
    </h4>
    <feather-icon
      class="ml-1 cursor-pointer"
      icon="XIcon"
      size="16"
      @click="hide"
    />
  </div>
</template>
<script>
export default {
  props: {
    hide: {
      type: Function,
      require: true,
      default: () => {},
    },
    title: {
      type: String,
      require: true,
      default: '',
    },
  },
}
</script>
