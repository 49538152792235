export default {
  title: 'Solicitações',
  icon: 'inboxes',
  iconPack: 'bootstrap',
  children: [
    {
      title: 'Solicitações',
      route: 'service-request-list',
      resource: 'Auth',
      action: 'read',
    },
    {
      title: 'Grupos de Aprovação',
      route: 'approval-group-list',
      resource: 'ApprovalGroup',
      action: 'Read',
    },
    {
      title: 'Fluxos de Aprovação',
      route: 'approval-flow-list',
      resource: 'ApprovalFlow',
      action: 'Read',
    }
  ],
}
