/* eslint-disable no-param-reassign */
import { personTypes, stringUtils } from '@/mixins'
import { localListAddItem, localListDeleteItem, localListUpdateItem } from '@/store/utils'
import { roundDecimal } from '@/utils/number-utils'
import axios from '@axios'
import moment from 'moment'
import { v4 as uuidV4 } from 'uuid'

export const getInitialInstallmentExpense = () => ({
  id: null,
  localId: null,
  paymentMethod: null,
  installment: null,
  dueDate: null,
  paymentDate: null,
  value: 0,
  paymentValue: 0,
  interest: 0,
  discount: 0,
  comments: '',
  isDeleted: false,
})

const getInitialState = () => ({
  expenseForm: {
    id: null,
    store: { name: '' },
    storeId: null,
    personCategory: personTypes.data().personCategoryEnum.SUPPLIER,
    supplierId: null,
    defaultSupplier: null,
    customerId: null,
    defaultCustomer: null,
    managementAccountId: null,
    purchaseOrderId: null,
    saleId: null,
    documentType: null,
    document: null,
    description: null,
    comments: null,
    value: 0,
    statementDate: null,
    firstDueDate: null,
    installmentQuantity: null,
    paymentMethodToInstallments: null,
    accountsPayableInstallments: [],
    documentsUrls: [],
    recordType: null,
  },
  documents: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getInstallments(state) {
      return state.expenseForm.accountsPayableInstallments.filter(inst => !inst.isDeleted)
    },

    hasInstallmentPaid(state, getters) {
      return getters.getInstallments.some(inst => inst.paymentValue > 0)
    },

    canEditFieldsToUpdateInstallments(state, getters) {
      const { saleId } = state.expenseForm
      return !(saleId || getters.hasInstallmentPaid)
    },

    getTitleWhenCantUpdateValues(state, getters) {
      const { saleId, purchaseOrderId } = state.expenseForm
      const pathI18n = 'ACCOUNTS_PAYABLE.UI.INPUT_TITLE'
      let text = ''
      if (saleId) text = `${pathI18n}.CAN_NOT_CHANGE_PAYABLE_VALUE_BECAUSE_SALE`
      if (purchaseOrderId)
        text = `${pathI18n}.CAN_NOT_CHANGE_PAYABLE_VALUE_BECAUSE_PURCHASE_ORDER`
      if (getters.hasInstallmentPaid)
        text = `${pathI18n}.CAN_NOT_CHANGE_PAYABLE_VALUE_BECAUSE_INSTALLMENT_PAID`

      return text
    },
  },

  mutations: {
    SET_EXPENSE_FORM(state, payload) {
      state.expenseForm = payload
    },
    SET_EXPENSE_VALUE(state, value) {
      state.expenseForm.value = value
    },
    SET_INSTALLMENT_QUANTITY(state, quantity) {
      state.expenseForm.installmentQuantity = quantity
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents
    },
    SET_PAYMENT_INSTALLMENTS(state, { payload }) {
      state.expenseForm.accountsPayableInstallments = payload
    },
    CLEAN_STATE_TO_CLONE(state) {
      state.expenseForm.id = null
      state.expenseForm.localId = null
      state.expenseForm.purchaseOrderId = null
      state.expenseForm.saleId = null
      state.expenseForm.accountsPayableInstallments.forEach(inst => {
        inst.paymentValue = null
        inst.paymentDate = null
        inst.id = null
        inst.localId = uuidV4()
      })
    },
    CLEAN_STATE(state) {
      const { documents, expenseForm } = getInitialState()
      state.documents = documents
      state.expenseForm = expenseForm
    },
  },

  actions: {
    setDocuments({ commit }, documents) {
      commit('SET_DOCUMENTS', documents)
    },

    async fetchAccountPaymentById({ commit }, id) {
      const { data } = await axios.get(`/api/accounting/accounts-payable/${id}`)

      const accountsPayableInstallments = data.accountsPayableInstallments.map(i => {
        const paymentDate = i.paymentDate || getInitialInstallmentExpense().paymentDate

        return {
          ...i,
          paymentMethod: {
            ...i.paymentMethod,
            label: i.paymentMethod.name,
            value: i.paymentMethod.id,
          },
          paymentDate,
        }
      })

      const person = {}
      const { CUSTOMER, SUPPLIER } = personTypes.data().personCategoryEnum
      if (data.supplier?.id) {
        person.defaultSupplier = data.supplier
        person.supplierId = stringUtils.methods.getStringOrNull(data.supplier.id)
        person.personCategory = SUPPLIER
      } else if (data.customer?.id) {
        person.defaultCustomer = data.customer
        person.customerId = stringUtils.methods.getStringOrNull(data.customer.id)
        person.personCategory = CUSTOMER
      }

      const formatedData = {
        ...data,
        ...person,
        storeId: String(data.store.id),
        managementAccountId: String(data.managementAccount.id),
        purchaseOrderId: data.purchaseOrderId,
        accountsPayableInstallments,
        firstDueDate: moment(accountsPayableInstallments[0].dueDate).format(),
        paymentMethodToInstallments: accountsPayableInstallments[0].paymentMethod,
        installmentQuantity: accountsPayableInstallments.length,
      }

      commit('SET_EXPENSE_FORM', formatedData)
    },

    async saveExpense({ state }) {
      const {
        id,
        supplierId,
        customerId,
        storeId,
        managementAccountId,
        documentType,
        document,
        description,
        comments,
        value,
        statementDate,
        personCategory,
        accountsPayableInstallments: installments,
      } = state.expenseForm

      const accountsPayableInstallments = installments.map(i => ({
        id: i.id,
        paymentMethodId: i.paymentMethod.id,
        installment: i.installment,
        dueDate: i.dueDate,
        paymentDate: i.paymentDate,
        value: i.value,
        paymentValue: i.paymentValue,
        comments: i.comments,
        interest: i.interest,
        discount: i.discount,
        isDeleted: i.isDeleted,
      }))

      const { CUSTOMER, SUPPLIER } = personTypes.data().personCategoryEnum
      const auxBody = {
        supplierId,
        customerId,
      }
      if (personCategory === CUSTOMER) {
        auxBody.supplierId = null
      } else if (personCategory === SUPPLIER) {
        auxBody.customerId = null
      }

      const body = {
        id,
        storeId,
        managementAccountId,
        documentType,
        document,
        description,
        comments,
        value,
        statementDate,
        accountsPayableInstallments,
        ...auxBody,
      }

      await axios({
        url: '/api/accounting/accounts-payable',
        method: id ? 'PUT' : 'POST',
        data: body,
      })
    },

    addInstallment({ commit, state, getters }, { formData }) {
      const list = state.expenseForm.accountsPayableInstallments
      const newList = localListAddItem(list, {
        ...formData,
        installment: list.filter(i => !i.isDeleted).length + 1,
      })
      commit('SET_PAYMENT_INSTALLMENTS', { payload: newList })
      commit('SET_INSTALLMENT_QUANTITY', getters.getInstallments.length)
      // const expenseValue = newList.reduce((prev, curr) => prev + curr.value, 0)
      // commit('SET_EXPENSE_VALUE', expenseValue)
    },
    updateInstallment({ commit, state }, { formData }) {
      const newList = localListUpdateItem(state.expenseForm.accountsPayableInstallments, formData)
      commit('SET_PAYMENT_INSTALLMENTS', { payload: newList })
      // const expenseValue = newList.reduce((prev, curr) => prev + curr.value, 0)
      // commit('SET_EXPENSE_VALUE', expenseValue)
    },
    removeInstallment({ commit, state, getters }, { id }) {
      const newList = localListDeleteItem(state.expenseForm.accountsPayableInstallments, id)

      let numberAux = 1
      const listFormated = newList.map(p => {
        if (p.isDeleted) {
          return p
        }
        const updatedItem = { ...p, installment: numberAux }
        numberAux += 1
        return updatedItem
      })

      commit('SET_PAYMENT_INSTALLMENTS', { payload: listFormated })
      commit('SET_INSTALLMENT_QUANTITY', getters.getInstallments.length)
      // const expenseValue = newList
      //   .filter(item => !item.isDeleted)
      //   .reduce((prev, curr) => prev + curr.value, 0)
      // commit('SET_EXPENSE_VALUE', expenseValue)
    },

    async updateInstallmentsValue({ state, dispatch, getters }) {
      const installmentDefault = getInitialInstallmentExpense()
      const { value, firstDueDate, installmentQuantity, paymentMethodToInstallments: paymentMethod } = state.expenseForm
      const installmentsNotDeleted = getters.getInstallments
      if (!(value && firstDueDate && installmentQuantity && paymentMethod)) return

      if (installmentsNotDeleted.length === 0) {
        const instQuantity = (installmentQuantity || 0)
        const instValue = roundDecimal((value || 0) / instQuantity)
        let auxDueDateInstallment = firstDueDate
        const instToAddPromise = []

        for (let i = 0; i < instQuantity; i += 1) {
          const formData = {
            ...installmentDefault,
            installment: i + 1,
            paymentMethod,
            dueDate: auxDueDateInstallment,
            value: instValue,
          }
          auxDueDateInstallment = moment(auxDueDateInstallment).add(1, 'month').format()
          instToAddPromise.push(dispatch('addInstallment', { formData }))
        }
        await Promise.all(instToAddPromise)
      } else {
        const diffInstQuantity = installmentsNotDeleted.length - installmentQuantity
        const instValueUpdated = roundDecimal((value || 0) / ((installmentsNotDeleted.length || 0) - diffInstQuantity))
        const instPromise = []

        if (diffInstQuantity < 0) {
          // ao aumentar quantidade de parcelas
          const lastItem = installmentsNotDeleted[installmentsNotDeleted.length - 1]
          if (lastItem) {
            let lastInstDueDate = lastItem.dueDate
            for (let i = 0; i < Math.abs(diffInstQuantity); i += 1) {
              lastInstDueDate = moment(lastInstDueDate).add(1, 'month').format()
              const formData = {
                ...installmentDefault,
                installment: i + 1,
                paymentMethod,
                dueDate: lastInstDueDate,
                value: instValueUpdated,
              }

              instPromise.push(dispatch('addInstallment', { formData }))
            }
          }
        } else if (diffInstQuantity > 0) {
          // ao diminuir quantidade de parcelas
          for (let i = 1; i <= diffInstQuantity; i += 1) {
            const instToDelete = installmentsNotDeleted[installmentsNotDeleted.length - i]
            if (instToDelete) {
              instPromise.push(dispatch('removeInstallment', { id: instToDelete.id || instToDelete.localId }))
            }
          }
        }
        await Promise.all(instPromise)

        // atualizar parcelas
        const [firstInstallment] = getters.getInstallments
        const isDiffFirstDueDate = firstDueDate !== firstInstallment?.dueDate
        const isDiffPaymentMethod = paymentMethod?.id !== firstInstallment?.paymentMethod?.id
        await Promise.all(getters.getInstallments.map((inst, index) => {
          const installmentData = {
            installment: index + 1
          }
          if (isDiffFirstDueDate) installmentData.dueDate = moment(firstDueDate).add(index, 'month').format()
          if (isDiffPaymentMethod) installmentData.paymentMethod = paymentMethod

          return dispatch('updateInstallment', { formData: { ...inst, value: instValueUpdated, ...installmentData } })
        }))
      }

      const totalInst = getters.getInstallments.reduce((total, inst) => total + inst.value, 0)
      const valueDiff = roundDecimal(totalInst - value)
      const lastItem = getters.getInstallments[getters.getInstallments.length - 1]
      if (lastItem) {
        await dispatch('updateInstallment', {
          formData: {
            ...lastItem,
            value: roundDecimal(lastItem.value - valueDiff)
          }
        })
      }
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
