import axios from '@axios'
import accessProfiles from './access-profiles'
import userMaintain from './user-maintain'
import approvalFlowTransaction from './approval-flow-transaction'
import approvalGroup from './approval-group'

export default {
  namespaced: true,
  modules: {
    accessProfiles,
    userMaintain,
    approvalFlowTransaction,
    approvalGroup,
  },
  state: {
    functionalities: [],
  },
  mutations: {
    SET_FUNCTIONALITIES(state, functionalities) {
      // state.functionalities.loaded = !!checkingAccountLabels
      state.functionalities = functionalities
    },
  },
  actions: {
    fetchFunctionalities({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/functionalities')
          .then(response => {
            commit('SET_FUNCTIONALITIES', response.data.results)
            resolve(response.data.results)
          })
          .catch(error => reject(error))
      })
    },
  },
}
