<template>
  <input
    v-model="context.model"
    type="time"
    no-close-button
    v-bind="{ ...context.attributes, ...(labels[locate] || {}) }"
    :class="context.attributes.class"
    locale="pt-br"
    :locate="locate"
  >
</template>
<script>
// import { BFormTimepicker } from 'bootstrap-vue'
import { locale } from '@/mixins'

export default {
  name: 'FormulateTextMask',
  components: {
    // BFormTimepicker,
  },
  mixins: [locale],
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      labels: require('@/libs/i18n/locales/timepicker/timepicker.json'),
      locate: 'pt',
    }
  },
}
</script>
