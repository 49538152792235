const basePath = '/fiscal'

export default [
  // invoice list
  {
    path: '/fiscal/notas-fiscais',
    name: 'invoice-list',
    component: () => import('@/views/pages/invoice/invoice/InvoiceList.vue'),
    meta: {
      pageTitle: 'Notas Fiscais',
      navActiveLink: 'invoice-list',
      resource: 'Invoice',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Notas Fiscais',
          active: true,
        },
      ],
    },
  },
  // invoice create
  {
    path: '/fiscal/notas-fiscais/criar',
    name: 'invoice-create',
    component: () => import('@/views/pages/invoice/invoice/InvoiceMaintain.vue'),
    meta: {
      pageTitle: 'Nova Nota Fiscal',
      navActiveLink: 'invoice-list',
      resource: 'Invoice',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Notas Fiscais',
          to: `${basePath}/notas-fiscais`,
        },
        {
          text: 'Nova Nota Fiscal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/notas-fiscais/criar-de-cupom-fiscal',
    name: 'invoice-create-from-coupon',
    component: () => import('@/views/pages/invoice/invoice/InvoiceFromSales.vue'),
    meta: {
      pageTitle: 'Nota Fiscal de Cupom Fiscal',
      navActiveLink: 'invoice-list',
      resource: 'Invoice',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Notas Fiscais',
          to: `${basePath}/notas-fiscais`,
        },
        {
          text: 'Nova Nota Fiscal',
          active: true,
        },
      ],
    },
  },
  // invoice maintain
  {
    path: '/fiscal/notas-fiscais/manter/:id',
    name: 'invoice-maintain',
    component: () => import('@/views/pages/invoice/invoice/InvoiceMaintain.vue'),
    meta: {
      pageTitle: 'Manter Nota Fiscal',
      navActiveLink: 'invoice-list',
      resource: 'Invoice',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Notas Fiscais',
          to: `${basePath}/notas-fiscais`,
        },
        {
          text: 'Manter Nota Fiscal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/notas-fiscais/inutilizacoes',
    name: 'invoice-invalidate-list',
    component: () => import('@/views/pages/invoice/invoice/InvoiceInvalidateList.vue'),
    meta: {
      pageTitle: 'Notas Fiscais Inutilizadas',
      navActiveLink: 'invoice-invalidate-list',
      resource: 'Invoice',
      action: 'Invalidate',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Notas Fiscais',
          to: `${basePath}/notas-fiscais/inutilizacoes`,
        },
      ],
    },
  },
  // fiscal files list
  {
    path: '/fiscal/arquivos-fiscais',
    name: 'invoice-fiscal-files',
    component: () => import('@/views/pages/invoice/fiscal-files/FiscalFilesList.vue'),
    meta: {
      pageTitle: 'Arquivos Fiscais',
      navActiveLink: 'invoice-fiscal-files',
      resource: 'Invoice',
      action: 'ReadCompetenceFile',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Arquivos Fiscais',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/parametrizacao/cfop',
    name: 'cfop-list',
    component: () => import('@/views/pages/invoice/parameters/CfopList.vue'),
    meta: {
      pageTitle: 'Cfop',
      navActiveLink: 'cfop-list',
      resource: 'Cfop',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Cfops',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/parametrizacao/cfop/adicionar',
    name: 'cfop-add',
    component: () => import('@/views/pages/invoice/parameters/CfopMaintain.vue'),
    meta: {
      pageTitle: 'Adicionar Cfop',
      navActiveLink: 'cfop-list',
      resource: 'Cfop',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Cfops',
        },
        {
          text: 'Novo Cfop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/parametrizacao/cfop/atualizar/:id',
    name: 'cfop-maintain',
    component: () => import('@/views/pages/invoice/parameters/CfopMaintain.vue'),
    meta: {
      pageTitle: 'Adicionar Cfop',
      navActiveLink: 'cfop-list',
      resource: 'Cfop',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Cfops',
        },
        {
          text: 'Atualizar Cfop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/parametrizacao/tax-classification',
    name: 'tax-classification-list',
    component: () => import('@/views/pages/invoice/parameters/TaxClassificationList.vue'),
    meta: {
      pageTitle: 'Classificação Fiscal',
      navActiveLink: 'tax-classification-list',
      resource: 'TaxClassification',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Classificação Fiscal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/parametrizacao/tax-classification/adicionar',
    name: 'tax-classification-add',
    component: () => import('@/views/pages/invoice/parameters/TaxClassificationMaintain.vue'),
    meta: {
      pageTitle: 'Adicionar Classificação Fiscal',
      navActiveLink: 'tax-classification-list',
      resource: 'TaxClassification',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Classificação Fiscal',
        },
        {
          text: 'Nova Classificação Fiscal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/parametrizacao/tax-classification/atualizar',
    name: 'tax-classification-maintain',
    component: () => import('@/views/pages/invoice/parameters/TaxClassificationMaintain.vue'),
    meta: {
      pageTitle: 'Alterar Classificação Fiscal',
      navActiveLink: 'tax-classification-maintain',
      resource: 'TaxClassification',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Classificação Fiscal',
        },
        {
          text: 'Atualizar Classificação Fiscal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fiscal/parametrizacao/tax-classification-inconsistencies',
    name: 'tax-classification-list-inconsistencies',
    component: () =>
      import('@/views/pages/invoice/parameters/TaxClassificationListInconsistencies.vue'),
    meta: {
      pageTitle: 'Inconsistências Fiscais',
      navActiveLink: 'tax-classification-list-inconsistencies',
      resource: 'TaxClassification',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Classificação Fiscal',
        },
        {
          text: 'Inconsistências',
          active: true,
        },
      ],
    },
  },
  // invoice received list
  {
    path: '/fiscal/notas-fiscais-recebidas',
    name: 'invoice-received-list',
    component: () => import('@/views/pages/invoice/invoice-received/InvoiceReceivedList.vue'),
    meta: {
      pageTitle: 'Notas Fiscais Recebidas',
      resource: 'Invoice',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Fiscal',
        },
        {
          text: 'Notas Fiscais Recebidas',
          active: true,
        },
      ],
    },
  },
]
