export default {
  title: 'Vendas',
  icon: 'cart',
  iconPack: 'bootstrap',
  children: [
    {
      title: 'Cotações',
      route: 'quotation-list',
      resource: 'Quotation', // 'Quotation'
      action: 'Read', // 'Read'
    },
    {
      title: 'Pedidos',
      route: 'order-list',
      resource: 'Sale', // 'Order'
      action: 'Read', // 'Read'
    },
    {
      title: 'Vendas',
      route: 'sale-list',
      resource: 'Sale', // 'Order'
      action: 'Read', // 'Read'
    },
    {
      title: 'Clientes',
      route: 'client-list',
      resource: 'Customer', // 'Client'
      action: 'Read', // 'Read'
    },
    // {
    //   title: 'Devoluções',
    //   route: 'order-return-list',
    //   resource: 'Auth', // 'OrderReturn'
    //   action: 'read', // 'Read'
    // },
    {
      title: 'Retorno de Produtos',
      route: 'reclaim-list',
      resource: 'Sale', // 'OrderReturn'
      action: 'ReturnConsigned', // 'Read'
    },
    {
      title: 'Tabelas de Preço',
      route: 'price-table',
      resource: 'PriceTable',
      action: 'Read',
    },
    {
      title: 'Promoções',
      route: 'promotion-list',
      resource: 'Promotion',
      action: 'Read',
    },
    {
      target: '_blank',
      title: 'Delivery',
      route: 'delivery-dashboard',
      resource: 'Sale',
      action: 'Read',
    },
    {
      title: 'Config. Delivery',
      route: 'delivery-config-store-list',
      resource: 'Auth',
      action: 'read',
    },
    {
      title: 'Eventos',
      route: 'event-list',
      resource: 'Event',
      action: 'Read',
    },
  ],
}
