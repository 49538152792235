import i18n from '@/libs/i18n'

const reconciliationDomains = {
  data() {
    return {
      statusReconciliationEnum: {
        InProgress: 'InProgress',
        Completed: 'Completed',
        CompletedWithIssues: 'CompletedWithIssues',
        Finished: 'Finished',
      },
      statusReconciliationItemEnum: {
        Pending: 'Pending',
        Skipped: 'Skipped',
        Completed: 'Completed',
        Associated: 'Associated',
      },
    }
  },

  computed: {
    statusReconciliationOptions() {
      return [
        { value: 'InProgress', label: this.$t('Reconciliation_InProgress') },
        { value: 'Completed', label: this.$t('Reconciliation_Completed') },
        { value: 'CompletedWithIssues', label: this.$t('Reconciliation_CompletedWithIssues') },
        { value: 'Finished', label: this.$t('Reconciliation_Finished') },
      ]
    },
    statusReconciliationItemOptions() {
      return {
        Pending: i18n.t('Pending'),
        Skipped: i18n.t('Skipped'),
        Associated: i18n.t('Associated'),
        Completed: i18n.t('Completed'),
      }
    },
    inputMethodReconciliationOptions() {
      return {
        Auto: i18n.t('Auto'),
        Manual: i18n.t('Manual'),
      }
    },
  },

  methods: {},
}

export default reconciliationDomains
