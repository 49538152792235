var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-authorization",
        title: _vm.getActionName,
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        centered: "",
        size: "sm",
      },
      on: { hidden: _vm.onHideActions },
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "formDiscount",
          attrs: { name: "formDiscount" },
          on: { submit: _vm.onConfirm },
        },
        [
          _c(
            "b-container",
            [
              _vm.errorDescription
                ? _c(
                    "b-row",
                    { staticClass: "pb-1" },
                    [
                      _c("b-alert", {
                        staticStyle: { padding: "15px" },
                        attrs: { show: "", variant: "dark" },
                        domProps: { innerHTML: _vm._s(_vm.errorDescription) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "9" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "w-100 required",
                        attrs: {
                          id: "modalUserInput",
                          name: "user",
                          type: "text",
                          label: _vm.$t("Usuário"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.form.user,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "user", $$v)
                          },
                          expression: "form.user",
                        },
                      }),
                      _c("FormulateInput", {
                        staticClass: "w-100 required",
                        attrs: {
                          name: "password",
                          type: "password",
                          label: _vm.$t("Senha"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          text: _vm.$t("Cancelar"),
                          "text-shortcuts": ["ESC"],
                          variant: "danger",
                        },
                        on: { click: _vm.onCancel },
                      }),
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          type: "submit",
                          busy: _vm.busy,
                          text: _vm.$t("Confirmar"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }