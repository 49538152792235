const taxRegimes = {
  methods: {
    taxRegimes() {
      return [
        { value: 'NATIONAL_SIMPLE', label: 'Simples Nacional' },
        {
          value: 'NATIONAL_SIMPLE_ABOVE_INCOME_LIMIT',
          label: 'Simples Nacional - excesso sublimite de receita bruta',
        },
        { value: 'REAL_PROFIT', label: 'Lucro Real' },
        { value: 'PRESUMED_PROFIT', label: 'Lucro Presumido' },
      ]
    },
  },
}

export default taxRegimes
