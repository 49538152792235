import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const { jwt } = useJwt(axios, {
  loginEndpoint: '/api/auth/login',
  delegatePermissionEndpoint: '/api/auth/delegate-permission',
  registerEndpoint: '/backend/v1/auth/register',
  refreshEndpoint: null,
  logoutEndpoint: '/backend/v1/auth/logout',
  storageTokenKeyName: 'token',
})
export default jwt
