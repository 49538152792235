<template>
  <b-form-group>
    <validation-provider
      v-slot="{ errors }"
      :name="context.attributes.label"
      rules="required"
    >
      <b-input-group
        class="input-group-merge"
        :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <b-form-input
          id="password"
          v-model="context.model"
          :type="passwordFieldType"
          class="form-control-merge"
          :state="errors.length > 0 ? false : null"
          name="password"
          :placeholder="context.attributes.placeholder"
          @blur="context.blurHandler"
        />

        <b-input-group-append is-text>
          <feather-icon
            class="cursor-pointer"
            :icon="passwordToggleIcon"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { togglePasswordVisibility } from '@/@core/mixins/ui/forms'
import { ValidationProvider } from 'vee-validate'
import { BFormInput, BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'

export default {
  name: 'FormulateTextNumber',
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  // methods: {
  //   getMask() {
  //     console.log(`${'#'.repeat(this.context.attributes.maxlength || 999)}${this.decimalPlaces ? `.${'#'.repeat(this.decimalPlaces)}` : ''}`)
  //     return [`${'#'.repeat(this.context.attributes.maxlength || 999)}${this.decimalPlaces ? `.${'#'.repeat(this.decimalPlaces)}` : ''}`]
  //   },
  // },
}
</script>
