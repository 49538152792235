import i18n from "@/libs/i18n"
import address from "../address"

const otherAddress = () => ({
  id: -1,
  label: i18n.t('Outro'),
  value: { ...address.data().address, id: -1 },
})

const makeAddressOption = addressObject => {
  const addressLabel = address.methods.formatAddress(addressObject)
  return {
    id: addressLabel,
    label: addressLabel,
    value: { ...addressObject, zipcode: addressObject.zipcode.replace(/\D/g, '').replace(/^(\d{2})(\d{3})(\d{3})$/g, '$1.$2-$3') }
  }
}

const addressUtils = {
  otherAddress,
  makeAddressOption,
  addressOptionsWithOther(addressList) {
    const other = otherAddress()
    if (!addressList)
      return [other]

    const addresses = addressList.map(ad => makeAddressOption(ad))
    return [...addresses, other]
  }
}

export default addressUtils
