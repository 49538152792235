import FormulateTextNumber from './FormulateTextNumber.vue'

export default function VueFormulateTextNumberPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateTextNumber,
    },
    library: {
      'text-number': {
        classification: 'text',
        component: 'FormulateTextNumber',
        slotProps: {
          component: ['thousandSeparator'],
        },
      },
    },
  })
}
