var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show
      ? _c("div", { staticClass: "blockPage loading-overlay" }, [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "div",
                { staticClass: "logo-animation" },
                [
                  _c("b-img", {
                    staticClass: "img rounded",
                    attrs: { src: _vm.logoImg, alt: "Logo da echope" },
                  }),
                ],
                1
              ),
              _vm.title
                ? _c(
                    "p",
                    {
                      staticClass: "h4 mt-1 loading-overlay-label",
                      attrs: { id: "e-overlay-loading-label" },
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  )
                : _vm._e(),
              _vm.title ? _c("br") : _vm._e(),
              _c(
                "p",
                {
                  staticClass: "h3 mt-1 loading-overlay-label",
                  attrs: { id: "e-overlay-loading-label" },
                },
                [
                  _vm._v(" " + _vm._s(_vm.text || _vm.$t("Carregando")) + " "),
                  _c("span", { staticClass: "one" }, [_vm._v(".")]),
                  _c("span", { staticClass: "two" }, [_vm._v(".")]),
                  _c("span", { staticClass: "three" }, [_vm._v(".")]),
                ]
              ),
              _vm.image != null
                ? _c(
                    "b-container",
                    { staticClass: "overlay-img-container" },
                    [
                      _c("b-img", {
                        attrs: { src: _vm.image, "fluid-grow": "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCancelable
                ? _c(
                    "div",
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          variant: "outline-primary",
                          text: _vm.$t("Cancelar"),
                          "text-shortcuts": ["ESC"],
                        },
                        on: { click: _vm.onCancel },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }