import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

export const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'closedDate',
    sortDesc: true,
  },
  accountPayableCloses: [],
  filters: {
    competenceDate: '',
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_ACCOUNT_PAYABLE_CLOSES(state, accountPayableCloses) {
      state.accountPayableCloses = accountPayableCloses
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchAccountPayableCloses({ state, commit }) {
      const { competenceDate } = state.filters

      const { data } = await axios.get('/api/period-closes/accounts-payable', {
        params: {
          competence: competenceDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_ACCOUNT_PAYABLE_CLOSES', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async closePeriod(state, { forced = false }) {
      await axios.patch('/api/period-closes/accounts-payable', { forced })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },
  },
}
