/* eslint-disable no-param-reassign */
import Vue from 'vue'
import axios from 'axios'
import querystring from 'querystring'

const domain = window.env_domain || 'localhost'
const environment = window.env || 'local'

const apis = {
  api: {
    local: 'http://localhost:5027',
    development: 'http://localhost:5027',
    ci: 'http://echope-back:5027',
    staging: `https://api-erp.staging.${domain}`,
    production: `https://api-erp.${domain}`,
  },
}

const axiosInstance = axios.create({
  baseURL: '',
  paramsSerializer: params => querystring.stringify(params),
})

axiosInstance.interceptors.request.use(config => {
  if (config.url.startsWith('/api')) {
    config.url = `${apis.api[environment]}${config.url.replace('/api', '')}`
  }
  return config
})

Vue.prototype.$http = axiosInstance

export default axiosInstance
