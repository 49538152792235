var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isBadge && _vm.value != "-"
        ? _c(
            "div",
            { staticStyle: { "max-width": "120px" } },
            [
              _c("e-status-badge", {
                attrs: {
                  id: _vm.context.attributes.id,
                  status: _vm.value,
                  options: _vm.context.options,
                },
              }),
            ],
            1
          )
        : !_vm.routerName && !_vm.routerTo
        ? _c(
            "b",
            { attrs: { id: _vm.context.attributes.id, title: _vm.title } },
            [_vm._v(" " + _vm._s(_vm.value) + " ")]
          )
        : _c("e-router-link", {
            attrs: {
              to: _vm.routerTo || {
                name: _vm.routerName,
                params: _vm.routerParams,
              },
              title: _vm.routerTitle,
              value: _vm.value,
            },
          }),
      _vm._l(_vm.icons, function (iconItem) {
        return _c("b-icon", {
          key: iconItem.eventName,
          class: "cursor-pointer ml-1 " + iconItem.clazz,
          attrs: {
            icon: iconItem.icon,
            variant: iconItem.variant || "primary",
            "font-scale": "1",
            title: iconItem.title,
          },
          on: {
            click: function ($event) {
              return _vm.$emit(iconItem.eventName)
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }