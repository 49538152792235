import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  serviceAreas: [],
  serviceCategories: [],
  serviceRequest: {
    id: null,
    status: null,
    serviceAreaId: null,
    serviceCategoryId: null,
    storeId: null,
    userId: null,
    createdDate: null,
    description: null,
  },
  files: [],
  uploadType: "ServiceRequest"
})

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    serviceAreaOptions(state) {
      return state.serviceAreas?.map(serviceArea => ({
        label: serviceArea.name,
        value: serviceArea.id,
      }))
    },
    serviceCategoryOptions(state) {
      return state.serviceCategories
        // ?.filters(f => f.serviceAreaId === serviceAreaId)
        ?.map(serviceCategory => ({
          ...serviceCategory,
          label: serviceCategory.name,
          value: serviceCategory.id,
        }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_FILES(state, file) {
      state.files = file
    },
    SET_SERVICE_REQUEST(state, serviceRequest) {
      state.serviceRequest = serviceRequest
    },
    SET_SERVICE_AREA(state, serviceAreas) {
      state.serviceAreas = serviceAreas
    },
    SET_SERVICE_CATEGORY(state, serviceCategories) {
      state.serviceCategories = serviceCategories
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
    CLEAN_FILE(state) {
      const { files } = getInitialState()
      state.files = files
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchAllServiceArea({ commit }) {
      const response = await axios.get('/api/service/areas', {
        params: {
          ative: true,
          pageSize: 0,
        },
      })
      commit('SET_SERVICE_AREA', response.data.results)
    },
    async fetchServiceCategory({ commit }, serviceAreaId) {
      const response = await axios.get('/api/service/categories', {
        params: {
          ative: true,
          serviceAreaId,
          pageSize: 0,
        },
      })
      commit('SET_SERVICE_CATEGORY', response.data.results)
    },

    async uploadDocuments({ state }, entityId) {
      const filesToUpload = state.files.filter(f => !f.id)
      if (filesToUpload.length > 0) {
        const formData = new FormData()
        formData.append('entityId', entityId)
        formData.append('uploadType', state.uploadType)
        filesToUpload.forEach(localFile => {
          formData.append('files', localFile.file.file)
          formData.append('types', localFile.type ?? 'Others')
        })
        await axios.post(`/api/documents/upload-files`, formData)
      }
    },

    deleteLocalFile({ commit, state }, index) {
      if (state.files[index]?.id) {
        commit('SET_FILES_DELETED', state.files[index].id)
      }
    },

    setFiles({ commit }, file) {
      commit('SET_FILES', file)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
      commit('CLEAN_FILE')
    },
  },
}
