var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-sidebar",
        _vm._b(
          {
            attrs: {
              id: "sidebar-form-group_" + _vm.id,
              "aria-controls": "sidebar-form-group",
              "aria-expanded": _vm.showLocal,
              "header-class": "text-center",
              "no-close-on-backdrop": _vm.noCloseOnBackdrop,
              backdrop: "",
              shadow: "",
              right: "",
            },
            on: { hidden: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function (ref) {
                    var hide = ref.hide
                    return [
                      _c("e-sidebar-header", {
                        attrs: { title: _vm.title, hide: hide },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _vm.localFetching
                        ? _c("e-spinner")
                        : _c(
                            "div",
                            { staticClass: "px-2 py-2" },
                            [_vm._t("content")],
                            2
                          ),
                    ]
                  },
                  proxy: true,
                },
                _vm.showFooter
                  ? {
                      key: "footer",
                      fn: function (ref) {
                        var hide = ref.hide
                        return [
                          _c(
                            "b-row",
                            { staticClass: "px-2 py-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", align: "right" } },
                                [
                                  _vm.$slots["sidebar-footer"]
                                    ? _vm._t("sidebar-footer")
                                    : [
                                        _c("e-button", {
                                          staticClass: "mr-1",
                                          attrs: {
                                            variant: "outline-primary",
                                            icon: "x-circle",
                                            text: _vm.$t("Cancelar"),
                                          },
                                          on: { click: hide },
                                        }),
                                        !_vm.hideSaveButton
                                          ? _c("e-button", {
                                              attrs: {
                                                variant: "primary",
                                                icon: "save",
                                                text: _vm.$t("Salvar"),
                                                busy: _vm.localSaving,
                                              },
                                              on: { click: _vm.save },
                                            })
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.extraButtons,
                                          function (btn, index) {
                                            return _c("e-button", {
                                              key: index,
                                              attrs: {
                                                variant:
                                                  btn.variant || "primary",
                                                icon: btn.icon || "",
                                                text: btn.text || "",
                                                busy: _vm.localFetching,
                                              },
                                              on: {
                                                click: function () {
                                                  return _vm.$emit(btn.event)
                                                },
                                              },
                                            })
                                          }
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.showLocal,
              callback: function ($$v) {
                _vm.showLocal = $$v
              },
              expression: "showLocal",
            },
          },
          "b-sidebar",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }