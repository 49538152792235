const basePath = '/pdv/atm'

export default [
  // Catalog
  {
    path: `${basePath}/home`,
    name: 'pdv-atm',
    component: () => import('@/views/pages/pdv/atm/Atm.vue'),
    props: { isAtm: true },
    meta: {
      layout: 'atm',
      pageTitle: 'Catálogo',
      resource: 'Auth',
      action: 'read',
    },
  },
  // lock-screen
  {
    path: `${basePath}/lock-screen`,
    name: 'pdv-atm-lock-screen',
    component: () => import('@/views/pages/pdv/pay-box-bar/BarTabList.vue'),
    props: { isAtm: true },
    meta: {
      layout: 'full',
      pageTitle: 'Tela de descanso',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: `${basePath}/configuration`,
    name: 'pdv-atm-configuration',
    component: () => import('@/views/pages/pdv/pay-box-configuration/PayBoxConfiguration.vue'),
    meta: {
      pageTitle: 'Configuração do ATM',
      layout: 'atm',
      resource: 'PayBox',
      action: 'Setup',
    },
  },
]
