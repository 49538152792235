export default {
  title: 'Fiscal',
  icon: 'FileTextIcon',
  // iconPack: 'bootstrap',
  children: [
    {
      title: 'Notas Fiscais',
      resource: 'Invoice',
      action: 'Read',
      route: 'invoice-list',
    },
    {
      title: 'Notas Fiscais Recebidas',
      resource: 'Invoice',
      action: 'Read',
      route: 'invoice-received-list',
    },
    {
      title: 'Inutilizar Notas Fiscais',
      resource: 'Invoice',
      action: 'Invalidate',
      route: 'invoice-invalidate-list',
    },
    {
      title: 'Arquivos Fiscais',
      resource: 'Invoice',
      action: 'ReadCompetenceFile',
      route: 'invoice-fiscal-files',
    },
  ],
}
