import FormulateCheckboxButton from './FormulateCheckboxButton.vue'

export default function VueFormulateDatepickerPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateCheckboxButton,
    },
    library: {
      'checkbox-button': {
        classification: 'text',
        component: 'FormulateCheckboxButton',
      },
    },
  })
}
