// import axios from '@axios'
import productLines from './product-lines'
import brands from './brands'
import productDepartments from './product-departments'
import products from './products'
import productUnits from './product-units'
import services from './services'
import productKits from './product-kits'
import productVariations from './product-variations'
import productGroups from './product-groups'
import productSubgroups from './product-subgroups'
import productAverageCost from './product-average-cost'

export default {
  namespaced: true,
  modules: {
    products,
    productLines,
    brands,
    productDepartments,
    productUnits,
    productVariations,
    services,
    productKits,
    productGroups,
    productSubgroups,
    productAverageCost,
  },
}
