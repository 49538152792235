const basePath = '/financeiro'

export default [
  // contas a pagar list
  {
    path: '/financeiro/contas-a-pagar',
    name: 'accounts-payable-list',
    component: () => import('@/views/pages/financial/accounts-payable/AccountsPayableList.vue'),
    meta: {
      pageTitle: 'Contas a pagar',
      navActiveLink: 'accounts-payable-list',
      resource: 'AccountsPayable',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas a pagar',
          active: true,
        },
      ],
    },
  },
  // contas a pagar despesa add
  {
    path: '/financeiro/contas-a-pagar/adicionar-despesa',
    name: 'expense-add',
    component: () => import('@/views/pages/financial/accounts-payable/ExpenseMaintain.vue'),
    meta: {
      pageTitle: 'Nova Despesa',
      navActiveLink: 'accounts-payable-list',
      resource: 'AccountsPayable',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas a pagar',
          to: `${basePath}/contas-a-pagar`,
        },
        {
          text: 'Nova Despesa',
          active: true,
        },
      ],
    },
  },
  // contas a pagar despesa maintain
  {
    path: '/financeiro/contas-a-pagar/manter-despesa/:id',
    name: 'expense-maintain',
    component: () => import('@/views/pages/financial/accounts-payable/ExpenseMaintain.vue'),
    meta: {
      pageTitle: 'Editar Despesa',
      navActiveLink: 'accounts-payable-list',
      resource: 'AccountsPayable',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas a pagar',
          to: `${basePath}/contas-a-pagar`,
        },
        {
          text: 'Editar Despesa',
          active: true,
        },
      ],
    },
  },
  // contas a pagar despesa em lote maintain
  {
    path: `${basePath}/contas-a-receber/adicionar-receita-em-lote`,
    name: 'receivable-bulk-add',
    component: () => import('@/views/pages/financial/receivables/ReceivablesBulkMaintain.vue'),
    meta: {
      pageTitle: 'Importar Receitas',
      navActiveLink: 'receivables-list',
      resource: 'Receivable',
      action: 'BulkCreate',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas a receber',
          to: `${basePath}/contas-a-receber`,
        },
        {
          text: 'Importar Receitas',
          active: true,
        },
      ],
    },
  },

  {
    path: `${basePath}/contas-a-pagar/adicionar-despesa-em-lote`,
    name: 'expense-bulk-add',
    component: () =>
      import('@/views/pages/financial/accounts-payable/AccountsPayableBulkMaintain.vue'),
    meta: {
      pageTitle: 'Importar Despesas',
      navActiveLink: 'accounts-payable-list',
      resource: 'AccountsPayable',
      action: 'BulkCreate',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas a pagar',
          to: `${basePath}/contas-a-pagar`,
        },
        {
          text: 'Importar Despesas',
          active: true,
        },
      ],
    },
  },

  // contas a receber list
  {
    path: '/financeiro/contas-a-receber',
    name: 'receivables-list',
    component: () => import('@/views/pages/financial/receivables/ReceivablesList.vue'),
    meta: {
      pageTitle: 'Contas a receber',
      navActiveLink: 'receivables-list',
      resource: 'Receivable',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas a receber',
          active: true,
        },
      ],
    },
  },
  // contas a receber receita add
  {
    path: '/financeiro/contas-a-receber/adicionar-receita',
    name: 'bill-income-add',
    component: () => import('@/views/pages/financial/receivables/ReceivableMaintain.vue'),
    meta: {
      pageTitle: 'Nova Receita',
      navActiveLink: 'receivables-list',
      resource: 'Receivable',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas a receber',
          to: `${basePath}/contas-a-receber`,
        },
        {
          text: 'Nova Receita',
          active: true,
        },
      ],
    },
  },
  // contas a receber receita maintain
  {
    path: '/financeiro/contas-a-receber/manter-receita/:id',
    name: 'bill-income-maintain',
    component: () => import('@/views/pages/financial/receivables/ReceivableMaintain.vue'),
    meta: {
      pageTitle: 'Editar Receita',
      navActiveLink: 'receivables-list',
      resource: 'Receivable',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contas a receber',
          to: `${basePath}/contas-a-receber`,
        },
        {
          text: 'Editar receita',
          active: true,
        },
      ],
    },
  },
  {
    path: '/financeiro/conciliacoes/getnet',
    name: 'reconciliation-getnet-list',
    component: () => import('@/views/pages/financial/reconciliation/getnet/ReconciliationList.vue'),
    meta: {
      pageTitle: 'Conciliações Getnet',
      resource: 'GetnetReconciliation',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Conciliações Getnet',
          active: true,
        },
      ],
    },
  },
  {
    path: '/financeiro/conciliacoes/getnet/create',
    name: 'reconciliation-getnet-create',
    component: () =>
      import('@/views/pages/financial/reconciliation/getnet/ReconciliationMaintain.vue'),
    meta: {
      pageTitle: 'Nova Conciliação Getnet',
      navActiveLink: 'reconciliation-getnet-list',
      resource: 'GetnetReconciliation',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Conciliações Getnet',
          to: `${basePath}/conciliacoes/getnet`,
        },
        {
          text: 'Nova',
          active: true,
        },
      ],
    },
  },
  {
    path: '/financeiro/conciliacoes/getnet/maintain/:id',
    name: 'reconciliation-getnet-maintain',
    component: () =>
      import('@/views/pages/financial/reconciliation/getnet/ReconciliationMaintain.vue'),
    meta: {
      pageTitle: 'Manter Conciliação Getnet',
      navActiveLink: 'reconciliation-getnet-list',
      resource: 'GetnetReconciliation',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Conciliações Getnet',
          to: `${basePath}/conciliacoes/getnet`,
        },
        {
          text: 'Manter',
          active: true,
        },
      ],
    },
  },
  // PeriodCloseAccountPayable list
  {
    path: '/financeiro/fechamento-contabil/contas-a-pagar',
    name: 'period-close-account-payable-list',
    component: () =>
      import('@/views/pages/settings/period-close/PeriodCloseAccountPayableList.vue'),
    meta: {
      pageTitle: 'Contas a pagar',
      navActiveLink: 'period-close-account-payable-list',
      resource: 'AccountsPayablePeriodClose',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Fechamento contábil',
        },
        {
          text: 'Contas a pagar',
          active: true,
        },
      ],
    },
  },
]
