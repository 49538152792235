<template>
  <!-- class: 'form-control', -->
  <vue-autosuggest
    v-model="context.model"
    v-bind="context.attributes"
    :get-suggestion-value="getSuggestionValue"
    :input-props="{
      ...inputProps,
      class: `formulate-input-element--${context.type}`,
      focus: (e) => e.target.select(),
    }"
    @input="onInputChange"
    @click="onClick"
    @selected="onSelected"
  >
    <template #default="{ suggestion }">
      <div>{{ suggestion.item.textLabel }}</div>
    </template>

    <template
      v-if="context.attributes.suggestions.length === 0"
      #after-suggestions
    >
      <div class="d-flex justify-content-center py-1">
        <b-spinner
          v-if="context.attributes.loading"
          variant="primary"
        />
        <p v-else>
          Nenhum dado encontrado
        </p>
      </div>
    </template>
  </vue-autosuggest>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import _ from 'lodash'

/**
 * Para poder realizar pesquisas e ao selecionar o item, montar os dados sobre ele de seu jeito.
 *
 * atributos no vue-autosuggest:
 *  suggestions: [{ data: { textLabel: required } }]
 */

export default {
  name: 'AutoSuggest',
  components: { VueAutosuggest, BSpinner },

  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      inputProps: this.context.attributes['input-props'],
    }
  },

  methods: {
    // eslint-disable-next-line func-names
    onInputChange: _.debounce(function (text) {
      this.context.rootEmit('input-fetch', text)
    }, 500),

    onClick(item) {
      this.context.rootEmit('click', item)
    },

    onSelected(item) {
      this.context.rootEmit('selected', item)
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.textLabel
    },
  },
}
</script>

<style style="scss">
.autosuggest__results-container {
  position: inline;

  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 1000;
  width: 95%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow-y: scroll;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results-before {
  border-top: none;
}

.autosuggest__results .autosuggest__results-before {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results .autosuggest__results-item.autosuggest__results-item--highlighted {
  background-color: #f6f6f6;
}
</style>
