export default {
  title: 'Quiosque',
  icon: 'shop-window',
  iconPack: 'bootstrap',
  children: [
    {
      title: 'Torneiras',
      route: 'beer-tap-list',
      resource: 'KioskOperation',
      action: 'AssociateBarrel',
    },
    {
      title: 'Barris',
      route: 'barrel-list',
      resource: 'KioskOperation',
      action: 'AssociateBarrel',
    },
    {
      title: 'Relatório de consumo',
      route: 'beer-tap-consumption',
      resource: 'KioskOperation',
      action: 'ConsumeReport'
    }
  ],
}
