var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", [
      _vm._v(
        _vm._s(
          _vm.$t("Mostrando de") +
            " " +
            (_vm.from + 1) +
            " " +
            _vm.$t("até") +
            " " +
            _vm.to +
            " " +
            _vm.$t("de") +
            " " +
            _vm.of
        )
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }