var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-100 bg-white shadow rounded d-flex flex-column justify-content-between px-2 py-1",
    },
    [
      _c("div", [
        _c("p", { class: "h3 mb-1 " + _vm.titleClass }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c("p", { staticClass: "h4" }, [
          _vm._v(" " + _vm._s(_vm._f("currency")(_vm.value)) + " "),
        ]),
        _vm.hasPredictedValue
          ? _c("p", { staticClass: "body2" }, [
              _vm._v(
                " Previsto: " +
                  _vm._s(_vm._f("currency")(_vm.predictedValue)) +
                  " "
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }