import pagination from '@/mixins/pagination'
import axios from '@axios'
import { v4 as uuidV4 } from 'uuid'

export const getInitialPerson = () => ({
  id: null,
  personType: 'Individual',
  telephones: [{ id: null, type: 'Mobile', number: '' }],
  email: null,
  emailInvoice: null,
  siteUrl: null,
  observation: '',
  priceTableId: '1',
  document: '', // represent CNPJ or CPF
  name: '', // represent name or TradingName
  customerClassificationId: '1',
  hasISSWithHolding: false,
  isFinalCustumer: false,
  isRuralProducer: false,
  pushNotification: false,

  rg: {
    document: '',
    issuer: '',
    issuerState: '',
  },
  gender: '',
  birthdate: null,

  companyName: '',
  stateRegistrationType: '',
  stateRegistration: '',
  municipalInscription: '',
})

const getInitialState = () => ({
  person: getInitialPerson(),
  attributes: {
    addresses: [],
    contacts: [],
    telephonesApi: [],
  },

  historyFilters: {
    issueDate: {
      startDate: null,
      endDate: null,
    },
    saleId: null,
    storeId: null,
    origin: null,
    status: null,
    deliveryStatus: null,
    isDelivery: null,
    isConsigned: null,
  },
  clientHistory: [],
  paging: pagination.data().paging,
  summaryFields: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getAddresses(state) {
      return state.attributes.addresses.filter(ad => !ad.isDeleted)
    },
    getContacts(state) {
      return state.attributes.contacts.filter(ct => !ct.isDeleted)
    },
  },

  mutations: {
    SET_PERSON(state, payload) {
      state.person = { ...payload }
    },
    SET_CLIENT_HISTORY(state, data) {
      state.clientHistory = data || []
    },

    SET_ATTRIBUTE_ITEM(state, { itemName, payload }) {
      state.attributes[itemName] = payload
    },
    SET_HISTORY_FILTERS(state, filter) {
      state.historyFilters = filter
    },

    SET_PAGING(state, paging) {
      state.paging = paging
    },
    SET_CURRENT_PAGE(state, currentPage) {
      state.paging.currentPage = currentPage
    },
    SET_PAGE_SIZE(state, pageSize) {
      state.paging.pageSize = pageSize
    },

    CLEAN_SALE_HISTORY(state) {
      const { historyFilters, clientHistory } = getInitialState()
      state.historyFilters = historyFilters
      state.clientHistory = clientHistory
    },
    CLEAN_STATE(state) {
      const { person, attributes, paging, historyFilters, summaryFields } = getInitialState()
      state.person = person
      state.attributes = attributes
      state.paging = paging
      state.historyFilters = historyFilters
      state.summaryFields = summaryFields
    },
    CLEAN_PERSON_TYPE_DATA(state) {
      const {
        name,
        document,
        rg,
        gender,
        birthdate,
        companyName,
        stateRegistrationType,
        stateRegistration,
        municipalInscription,
      } = getInitialState().person
      state.person = {
        ...state.person,
        name,
        document,
        rg,
        gender,
        birthdate,
        companyName,
        stateRegistrationType,
        stateRegistration,
        municipalInscription,
      }
    },
    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },
  },

  actions: {
    async getCustomerById(states, { customerId, isReadOnly }) {
      const { data } = isReadOnly
        ? await axios.get(`/api/customers/${customerId}/read-only`, {
            params: {
              onlyActiveAddress: false,
            },
          })
        : await axios.get(`/api/customers/${customerId}`)
      return data
    },
    async fetchClientById({ commit, dispatch }, { customerId, isReadOnly }) {
      const data = await dispatch('getCustomerById', { customerId, isReadOnly })
      const { person, addresses, contacts, telephonesApi } = await dispatch('formatPerson', data)

      commit('SET_ATTRIBUTE_ITEM', { itemName: 'addresses', payload: addresses })
      commit('SET_ATTRIBUTE_ITEM', { itemName: 'contacts', payload: contacts })
      commit('SET_ATTRIBUTE_ITEM', { itemName: 'telephonesApi', payload: telephonesApi })

      commit('SET_PERSON', person)
    },

    formatPerson(states, personData) {
      const { addresses, contacts, telephones, customerClassificationId, priceTableId, rg } =
        personData

      const personDefault = getInitialState().person

      return {
        person: {
          ...personData,
          telephones: telephones?.length > 0 ? telephones : personDefault.telephones,
          customerClassificationId: String(customerClassificationId),
          priceTableId: priceTableId ? String(priceTableId) : null,
          rg: rg ?? personDefault.rg,
        },
        addresses,
        contacts,
        telephonesApi: telephones,
      }
    },

    async fetchCustomerSaleHistory({ state, commit }, customerId) {
      const {
        issueDate,
        deliveryStatus,
        saleId,
        storeId,
        origin,
        status,
        isDelivery,
        isConsigned,
      } = state.historyFilters

      const { data } = await axios.get(`/api/sales/customer/${customerId}`, {
        params: {
          issueDateFrom: issueDate.startDate,
          issueDateTo: issueDate.endDate,
          deliveryStatus,
          id: saleId,
          storeId,
          origin,
          status,
          isDelivery,
          isConsigned,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
      })

      commit('SET_CLIENT_HISTORY', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    addAttributeItem({ commit, state }, { itemName, formData }) {
      const item = state.attributes[itemName]
      item.push({ ...formData, localId: uuidV4() })
      commit('SET_ATTRIBUTE_ITEM', { itemName, payload: item })
    },
    updateAttributeItem({ commit, state }, { itemName, formData }) {
      const indexFound = state.attributes[itemName].findIndex(
        v =>
          (formData.id && v.id === formData.id) ||
          (formData.localId && v.localId === formData.localId)
      )

      const item = [...state.attributes[itemName]]
      item.splice(indexFound, 1, formData)
      commit('SET_ATTRIBUTE_ITEM', { itemName, payload: item })
    },
    removeAttributeItem({ commit, state }, { itemName, id }) {
      if (!id) throw new Error('Item sem id')
      const indexFound = state.attributes[itemName].findIndex(
        v => v.id === id || v.localId === id
      )

      if (indexFound < 0) throw new Error('Item não encontrado')

      const item = state.attributes[itemName]
      if (item[indexFound]?.id) {
        item[indexFound].isDeleted = true
      } else if (item[indexFound]?.localId) {
        item.splice(indexFound, 1)
      }
      commit('SET_ATTRIBUTE_ITEM', { itemName, payload: item })
    },

    setCurrentPage({ commit }, currentPage) {
      commit('SET_CURRENT_PAGE', currentPage)
    },

    setPageSize({ commit }, pageSize) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_PAGE_SIZE', pageSize)
    },

    setFilter({ commit }, filter) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_HISTORY_FILTERS', filter)
    },
    resetHistoryFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_HISTORY_FILTERS', getInitialState().historyFilters)
    },

    cleanSaleHistory({ commit }) {
      commit('CLEAN_SALE_HISTORY')
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanPersonTypeData({ commit }) {
      commit('CLEAN_PERSON_TYPE_DATA')
    },
  },
}
