<template>
  <div>
    <router-link
      v-if="!links"
      :to="to"
      :title="title"
      class="hyperlink"
    >
      <feather-icon icon="ExternalLinkIcon" />
      {{ text || value || '-' }}
    </router-link>
    <b-dropdown
      v-else
      boundary="window"
      split-variant="none"
      variant="none"
      class="hyperlink custom-dropdown-router-link"
    >
      <template #button-content>
        <p class="m-0 hyperlink">
          <feather-icon
            style="margin-bottom: 5px; margin-right: 5px"
            icon="ExternalLinkIcon"
          />
          <span style="">{{ text || value || '-' }}</span>
        </p>
      </template>
      <b-dropdown-header v-if="header">
        {{ header }}
      </b-dropdown-header>
      <b-dropdown-divider v-if="header" />
      <b-dropdown-item
        v-for="link in links"
        :key="link.to.name"
        :to="link.to"
        href="#"
      >{{
        link.title
      }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BDropdownHeader, BDropdownDivider } from 'bootstrap-vue'

export default {
  name: 'ERouterLink',
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownHeader,
    BDropdownDivider,
  },
  props: {
    to: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: undefined,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    header: {
      type: String,
      default: undefined,
    },
    /// Array with the links that will be rendered, eg:
    /// [
    ///   { to: { name: 'my-route-name-1', params: { param1: 'my-param1-value'}, title: 'Show route 1'},
    ///   { to: { name: 'my-route-name-2', params: { param1: 'my-param1-value'}, title: 'Show route 2'},
    /// ]
    links: {
      type: Array,
      default: null,
    },
  },

  computed: {},
}
</script>

<style lang="scss">
.custom-dropdown-router-link {
  &.hyperlink button {
    // text-align: left;
    padding: 0;
  }

  & button {
    &.dropdown-toggle {
      white-space: normal;

      &::after {
        display: inline;
      }
    }
  }
}
</style>
