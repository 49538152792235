const masks = {
  data() {
    return {
      masks: {
        cep: {
          numericOnly: true,
          delimiters: ['.', '-'],
          blocks: [2, 3, 3],
        },
        cnpj: '##.###.###/####-##',
        currency: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
        phone: {
          numericOnly: true,
          delimiters: ['(', ')', '-'],
          blocks: [0, 2, 4, 4],
        },
        cellphone: {
          numericOnly: true,
          delimiters: ['(', ')', '-'],
          blocks: [0, 2, 5, 4],
        },
        integer(max = Number.MAX_SAFE_INTEGER) {
          return {
            numeral: true,
            numeralIntegerScale: max,
            numeralDecimalScale: 0,
            numeralDecimalMark: '',
            delimiter: '',
          }
        },
      },
    }
  },
}

export default masks
