import store from '@/store'

function roundDecimal(value, precision = 2) {
  let decimals = 10
  for (let i = 1; i < precision; i += 1) decimals *= 10
  return Math.round(value * decimals) / decimals
}

function toNumber(value) {
  let n = Number(value)
  if (Number.isNaN(n)) n = 0
  return n
}

function currencySymbol() {
  return Intl.NumberFormat(store.state.app.locale, {
    style: 'currency',
    currency: store.state.app.currency || 'BRL',
  })
    .format(0)
    .replace(/[0-9,.]/gi, '')
    .trim()
}

export { roundDecimal, toNumber, currencySymbol }
