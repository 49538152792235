/* eslint-disable no-shadow */
import { storePagination, sorting, pagination } from '@/mixins'
import { elapsedTime } from '@/utils/dateUtils'
import axios from '@axios'
import moment from 'moment'

const getInitialState = () => ({
  ...storePagination.state(),
  certificates: [],
  paging: { ...pagination.data().paging, pageSize: 10 },
  sorting: { ...sorting.data().sorting, sortBy: 'digitalCertificateDueDate', sortDesc: false },
})

export default {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_CERTIFICATE(state, certificates) {
      state.certificates = certificates
    },
    SET_PAGE_SIZE(state, pageSize) {
      state.paging.pageSize = pageSize
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchCertificate({ state, commit }) {
      const { data } = await axios.get('/api/expired-certificate', {
        params: {
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      const resultFormated = data.results.map(store => {
        let elapsedTimeFormat = ''
        let color = 'secondary'
        if (new Date(store.digitalCertificateDueDate) < new Date()) {
          elapsedTimeFormat = 'Vencido'
          color = 'danger'
        } else {
          const dueDate = moment(store.digitalCertificateDueDate)
          const nowDate = moment()
          if (dueDate > nowDate && dueDate < nowDate.add(4, 'd')) {
            color = 'warning'
          } else if (dueDate > nowDate && dueDate < nowDate.add(15, 'd')) {
            color = 'info'
          } else if (dueDate > nowDate && dueDate < nowDate.add(1, 'M')) {
            color = 'dark'
          } else if (dueDate > nowDate && dueDate < nowDate.add(2, 'M')) {
            color = 'success'
          }
          elapsedTimeFormat = elapsedTime(store.digitalCertificateDueDate, '', false, true)
        }
        return {
          ...store,
          elapsedTime: elapsedTimeFormat,
          color,
        }
      })

      commit('SET_CERTIFICATE', resultFormated)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },
    setPageSize({ commit }, pageSize) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_PAGE_SIZE', pageSize)
    },
  },
}
