import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  paymentTypes: [],
  filters: { name: '', status: '' },
})

export default {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_PAYMENT_TYPES(state, paymentTypes) {
      state.paymentTypes = paymentTypes
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    CLEAN_STATE(state) {
      const { paymentTypes, paging, filters } = getInitialState()
      state.paymentTypes = paymentTypes
      state.paging = paging
      state.filters = filters
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchPaymentTypes({ commit, state }) {
      const { name, status } = state.filters
      const { data } = await axios.get('/api/settings/financial/payment-methods', {
        params: {
          name: name || '',
          active: status || '',
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_PAYMENT_TYPES', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
    },

    async fetchPaymentTypeById(states, id) {
      const { data } = await axios.get(`/api/settings/financial/payment-methods/${id}`)
      return data
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
