var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "b-badge",
        _vm._b(
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: _vm.title,
                  placement: "bottom",
                  classes: "e-badge-tooltip",
                  trigger: "click hover",
                },
                expression:
                  "{\n      content: title,\n      placement: 'bottom',\n      classes: 'e-badge-tooltip',\n      trigger: 'click hover',\n    }",
              },
            ],
            staticClass: "w-100 text-wrap",
            class: {
              "cursor-pointer": !!_vm.title,
            },
            attrs: { variant: _vm.getBadgeVariant },
          },
          "b-badge",
          _vm.getAttributesBind,
          false
        ),
        [
          _vm.title
            ? _c(
                "span",
                [
                  _c("feather-icon", {
                    attrs: { icon: "InfoIcon", size: "10" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.getText) + " "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }