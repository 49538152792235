import { address, uploader } from '@/mixins'
import { localListAddItem, localListDeleteItem } from '@/store/utils'
import axios from '@axios'
import {
  getInitialPurchaseOrder,
  getInitialPurchaseOrderItem,
  getInitialPurchaseOrderPayment,
} from './purchase-order-maintain'

const getInitialState = () => ({
  files: [], // [{ id: null, type: null, file: [] }]
  filesDeleted: [],
  store: null,
  purchaseOrder: getInitialPurchaseOrder(),
})

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {
    isEdit(state) {
      return state.purchaseOrder.status !== 'Completed'
    },
  },

  mutations: {
    SET_FILES(state, file) {
      state.files = file
    },
    SET_FILES_DELETED(state, id) {
      state.filesDeleted = [...state.filesDeleted, id]
    },
    SET_PURCHASE_ORDER(state, val) {
      state.purchaseOrder = val
      state.purchaseOrder.loaded = true
    },
    SET_PURCHASE_ORDER_SUPPLIER(state, supplier) {
      state.purchaseOrder.supplierId = supplier?.id ? supplier?.id.toString() : null
      state.purchaseOrder.supplier = supplier
    },
    SET_PURCHASE_ORDER_ITEMS(state, itemList) {
      state.purchaseOrder.items = itemList
    },
    SET_PURCHASE_ORDER_PAYMENTS(state, paymentList) {
      state.purchaseOrder.payments = paymentList
    },
    SET_STORE(state, store) {
      state.store = store
    },

    UPDATE_ASSOCIATED_SKU(state, { sku, itemIndex }) {
      state.purchaseOrder.items[itemIndex].erpSku.sku = sku
      state.purchaseOrder.items[itemIndex].erpSku.skuInputMethod = 'New'
      const itemsWithSameEan = state.purchaseOrder.items.filter(
        item => item.xmlProduct.eanTaxed === sku.ean && !item.erpSku.sku?.id
      )
      itemsWithSameEan.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.erpSku.sku = sku
      })
    },

    CLEAN_SUPPLIER(state) {
      const { purchaseOrder } = getInitialState()
      state.purchaseOrder.supplier = purchaseOrder.supplier
    },
    CLEAN_FILE(state) {
      const { files, purchaseOrder, filesDeleted, store } = getInitialState()
      state.store = store
      state.files = files
      state.filesDeleted = filesDeleted
      state.purchaseOrder = purchaseOrder
    },
  },

  actions: {
    async fetchSupplierById({ commit }, id) {
      const { data } = await axios.get(`/api/suppliers/${id}`)
      let localAddress = address.data().address
      let telephone = ''

      if (Array.isArray(data.addresses) && data.addresses.length > 0) {
        localAddress = { ...data.addresses[0] }
      }
      if (Array.isArray(data.telephones) && data.telephones.length > 0) {
        telephone = { ...data.telephones[0] }
      }

      commit('SET_PURCHASE_ORDER_SUPPLIER', { ...data, address: localAddress, telephone })
    },
    async fetchPurchaseOrder({ commit, dispatch, getters }, id) {
      const data = await dispatch(
        'pages/purchase/purchaseOrder/purchaseOrderMaintain/fetchPurchaseOrderById',
        id,
        { root: true }
      )

      const payments = data.payments
        ? data.payments.map(p => ({
            ...p,
            paymentMethodId: p.paymentMethodId?.toString(),
            managementAccountId: p.managementAccountId?.toString(),
          }))
        : []

      const files =
        data.documents.length > 0
          ? data.documents.map(d => ({
              id: d.id,
              type: d.type,
              file: uploader.methods.getFileInstance(d.url),
            }))
          : []

      const dataFormated = {
        ...data,
        supplierId: data.supplier?.id?.toString() || '',
        storeId: data.store?.id?.toString() || '',
        payments,
      }
      commit('SET_PURCHASE_ORDER', dataFormated)
      commit('SET_FILES', files)

      if (getters.isEdit) {
        await dispatch('loadItemsAveragePrice')
      }
    },

    async loadItemsAveragePrice({ state, commit, dispatch }, { toUpdateAll = false } = {}) {
      try {
        if (state.purchaseOrder.items.length === 0) return

        const itemsResult = await dispatch(
          'pages/purchase/purchaseOrder/purchaseOrderMaintain/prepareItemsAveragePrice',
          {
            items: state.purchaseOrder.items,
            storeId: state.purchaseOrder.storeId,
            toUpdateAll,
          },
          { root: true }
        )

        commit('SET_PURCHASE_ORDER_ITEMS', itemsResult)
      } catch (err) {
        console.error(err)
      }
    },

    async saveManualPurchaseOrder(
      { state, dispatch },
      { itemsUnitValueRevision = false, tokenDelegated } = {}
    ) {
      const axiosOptions = {}
      if (itemsUnitValueRevision) {
        axiosOptions.headers = {
          'delegate-token': tokenDelegated,
        }
      }

      const data = await dispatch('prepareData')

      return (
        await axios({
          url: '/api/purchases/purchase-orders/manual/completed',
          method: state.purchaseOrder?.id ? 'PUT' : 'POST',
          data: { ...data, itemsUnitValueRevision },
          ...axiosOptions,
        })
      ).data
    },
    async saveManualDraftPurchaseOrder({ state, dispatch }) {
      const data = await dispatch('prepareData')
      return (
        await axios({
          url: '/api/purchases/purchase-orders/manual/draft',
          method: state.purchaseOrder?.id ? 'PUT' : 'POST',
          data,
        })
      ).data
    },
    prepareData({ state }) {
      const { items, invoiceNumber } = state.purchaseOrder
      const itemsFormated = items.map(i => ({
        ...i,
        productValue: i.totalValue,
      }))

      return {
        ...state.purchaseOrder,
        invoiceNumber: (invoiceNumber?.toString() || '').replace(/[.]/gi, '') || '',
        items: itemsFormated,
      }
    },

    async uploadDocuments({ state }, purchaseOrderId) {
      const filesToUpload = state.files.filter(f => !f.id)
      if (filesToUpload.length > 0) {
        const formData = new FormData()
        formData.append('purchaseOrderId', purchaseOrderId)
        filesToUpload.forEach(localFile => {
          formData.append('files', localFile.file.file)
          formData.append('types', localFile.type)
        })
        await axios.post(`/api/purchases/purchase-orders/upload-files`, formData)
      }
    },
    async deleteDocuments({ state }, purchaseOrderId) {
      if (state.filesDeleted.length > 0) {
        await axios.delete(`/api/purchases/purchase-orders/delete-files`, {
          data: {
            purchaseOrderId,
            documentsId: state.filesDeleted,
          },
        })
      }
    },

    deleteLocalFile({ commit, state }, index) {
      if (state.files[index]?.id) {
        commit('SET_FILES_DELETED', state.files[index].id)
      }
    },

    setFiles({ commit }, file) {
      commit('SET_FILES', file)
    },
    setStore({ commit }, store) {
      commit('SET_STORE', store)
    },

    addItem({ commit, state }) {
      const item = getInitialPurchaseOrderItem()
      const itemNumber = state.purchaseOrder.items.filter(p => !p.isDeleted).length + 1
      const itemsList = localListAddItem(state.purchaseOrder.items, { ...item, itemNumber })
      commit('SET_PURCHASE_ORDER_ITEMS', itemsList)
    },
    removeItem({ commit, state }, item) {
      const itemId = item.localId || item.id
      const itemsList = localListDeleteItem(state.purchaseOrder.items, itemId)

      let numberAux = 1
      const itemListFormated = itemsList.map(p => {
        if (p.isDeleted) {
          return p
        }
        const updatedItem = { ...p, itemNumber: numberAux }
        numberAux += 1
        return updatedItem
      })

      commit('SET_PURCHASE_ORDER_ITEMS', itemListFormated)
    },

    updateAssociatedSku({ commit }, { sku, itemIndex }) {
      commit('UPDATE_ASSOCIATED_SKU', { sku, itemIndex })
    },

    addPayment({ commit, state }) {
      const payment = getInitialPurchaseOrderPayment()
      const list = state.purchaseOrder.payments
      const installment = list.filter(p => !p.isDeleted).length + 1
      const paymentList = localListAddItem(list, { ...payment, installment })
      commit('SET_PURCHASE_ORDER_PAYMENTS', paymentList)
    },
    removePayment({ commit, state }, payment) {
      const paymentId = payment.localId || payment.id
      const paymentList = localListDeleteItem(state.purchaseOrder.payments, paymentId)

      let installmentAux = 1
      const paymentListFormated = paymentList.map(p => {
        if (p.isDeleted) {
          return p
        }
        const updatedPayment = { ...p, installment: installmentAux }
        installmentAux += 1
        return updatedPayment
      })

      commit('SET_PURCHASE_ORDER_PAYMENTS', paymentListFormated)
    },

    cleanSupplier({ commit }) {
      commit('CLEAN_SUPPLIER')
    },
    cleanState({ commit }) {
      commit('CLEAN_FILE')
    },
  },
}
