import axios from '@axios'
import { uploader, storePagination, sorting } from '@/mixins'
import Decimal from 'decimal.js-light'

const getInitialState = () => ({
  ...storePagination.state(),
  filters: {
    nsuGetnet: null,
    nsuErp: null,
    value: null,
    status: null,
    inputMethod: null,
    acquirerProductId: null,
    paymentMethodIdErp: null,
  },
  reconciliation: {
    id: null,
    store: null,
    checkingAccount: {
      id: null,
      name: null,
    },
    reconciliationFileUrl: null,
    movementDate: null,
    fileAndVersion: null,
    companyCode: null,
    acquirerCnpj: null,
    acquirerName: null,
    sequence: null,
    acquirerCode: null,
    layoutVersion: null,
    percentCompleted: null,
    percentComissionTax: null,
    percentAntecipationComissionTax: null,
    status: null,
  },
  antecipationFilters: {
    nsuGetnet: null,
    value: null,
    acquirerProductId: null,
  },
  summaryFields: {
    erpTotalValue: {},
    getnetTotalValue: {},
    getnetTotalCommission: {},
  },
  antecipationSummaryFields: {
    getnetTotalGrossValue: {},
    getnetTotalNetValue: {},
    getnetTotalTaxDiscountValue: {},
  },
  reconciliationItems: [],
  antecipationItems: [],
  allReconciliationItems: [],
  reconciliationFile: [],
})

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    ...storePagination.mutations,
    SET_RECONCILIATION(state, reconciliation) {
      state.reconciliation = reconciliation
    },
    SET_RECONCILIATION_ITEMS(state, items) {
      state.reconciliationItems = items
    },
    SET_SUMMARY_FIELDS(state, summaryFields) {
      state.summaryFields = summaryFields
    },
    SET_ALL_RECONCILIATION_ITEMS(state, items) {
      state.allReconciliationItems = items
    },
    SET_RECONCILIATION_FILE(state, file) {
      state.reconciliationFile = file
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    SET_ANTECIPATION_ITEMS(state, items) {
      state.antecipationItems = items
    },
    SET_ANTECIPATION_SUMMARY_FIELDS(state, summaryFields) {
      state.antecipationSummaryFields = summaryFields
    },
    SET_ANTECIPATION_FILTERS(state, filters) {
      state.antecipationFilters = filters
    },

    CLEAN_STATE(state) {
      const {
        reconciliation,
        reconciliationItems,
        reconciliationFile,
        filters,
        antecipationFilters,
        antecipationItems,
      } = getInitialState()
      state.reconciliation = reconciliation
      state.reconciliationItems = reconciliationItems
      state.reconciliationFile = reconciliationFile
      state.filters = filters
      state.antecipationFilters = antecipationFilters
      state.antecipationItems = antecipationItems
    },
  },
  actions: {
    ...storePagination.actions,
    async sendReconciliationFile({ commit }, { file }) {
      const result = await uploader.methods.uploadCustom({
        file,
        apiUrl: '/api/financial/reconciliation/getnet',
        fileParamName: 'file',
      })
      commit('SET_RECONCILIATION', result)
      return result.id
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    async fetchReconciliation({ commit }, id) {
      const { data } = await axios.get(`/api/financial/reconciliation/getnet/${id}`)
      commit('SET_RECONCILIATION', data)
    },
    async fetchReconciliationItems({ commit, state }, id) {
      const { filters } = state

      const filterValue = new Decimal(filters.value || 0).times(100).toNumber()

      const params = {
        nsuGetnet: filters.nsuGetnet,
        nsuErp: filters.nsuErp,
        status: filters.status,
        inputMethod: filters.inputMethod,
        acquirerProductId: filters.acquirerProductId,
        paymentMethodIdErp: filters.paymentMethodIdErp,
        value: filterValue || undefined,
        sortBy: sorting.methods.getSorting(state),
        pageSize: state.paging.pageSize,
        pageIndex: state.paging.currentPage - 1,
      }
      const { data } = await axios.get(`/api/financial/reconciliation/getnet/${id}/items`, {
        params,
      })
      commit('SET_RECONCILIATION_ITEMS', data.results)
      commit('SET_SUMMARY_FIELDS', data.summaryFields)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
    },
    async fetchAllReconciliationItems({ commit }, id) {
      const params = {
        pageSize: 99999999,
        pageIndex: 0,
      }
      const { data } = await axios.get(`/api/financial/reconciliation/getnet/${id}/items`, {
        params,
      })
      commit('SET_ALL_RECONCILIATION_ITEMS', data.results)
    },
    async fetchAntecipationItems({ commit, state }, id) {
      const { antecipationFilters } = state
      const filterValue = new Decimal(antecipationFilters.value || 0).times(100).toNumber()

      const params = {
        value: filterValue || undefined,
        acquirerProductId: antecipationFilters.acquirerProductId,
        pageSize: 0,
        pageIndex: 0,
      }
      const { data } = await axios.get(
        `/api/financial/reconciliation/getnet/${id}/antecipation-items`,
        { params }
      )
      commit('SET_ANTECIPATION_ITEMS', data.results)
      commit('SET_ANTECIPATION_SUMMARY_FIELDS', data.summaryFields)
    },

    async finishReconciliation({ commit }, reconciliationId) {
      const { data } = await axios.put(
        `/api/financial/reconciliation/getnet/${reconciliationId}/finish`
      )
      commit('SET_RECONCILIATION', data)
    },
    async matchItem({ dispatch }, { reconciliationId, itemId, receivableInstallmentId }) {
      await axios.put(
        `/api/financial/reconciliation/getnet/${reconciliationId}/items/${itemId}/match`,
        {
          receivableInstallmentId,
        }
      )
      await dispatch('fetchReconciliation', reconciliationId)
      await dispatch('fetchReconciliationItems', reconciliationId)
    },
    async unmatchItem({ dispatch }, { reconciliationId, item }) {
      await axios.put(
        `/api/financial/reconciliation/getnet/${reconciliationId}/items/${item.id}/unmatch`
      )
      await dispatch('fetchReconciliation', reconciliationId)
      await dispatch('fetchReconciliationItems', reconciliationId)
    },
    async resetItem({ dispatch }, { reconciliationId, item }) {
      await axios.put(
        `/api/financial/reconciliation/getnet/${reconciliationId}/items/${item.id}/reset`
      )
      await dispatch('fetchReconciliation', reconciliationId)
      await dispatch('fetchReconciliationItems', reconciliationId)
    },
    async skipItem({ dispatch }, { reconciliationId, itemId, comments }) {
      await axios.put(
        `/api/financial/reconciliation/getnet/${reconciliationId}/items/${itemId}/skip`,
        {
          comments,
        }
      )
      await dispatch('fetchReconciliation', reconciliationId)
      await dispatch('fetchReconciliationItems', reconciliationId)
    },
    setReconciliationFile({ commit }, file) {
      commit('SET_RECONCILIATION_FILE', file)
    },
    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },
    resetAntecipationFilters({ commit }) {
      commit('SET_ANTECIPATION_FILTERS', getInitialState().antecipationFilters)
    },
  },
}
