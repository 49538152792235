import axios from '@axios'

const getInitialState = () => ({
  cests: [],
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {},
  getters: {
    getComboCests(state) {
      return state.cests.map(s => ({
        ...s,
        label: `${s.ncm} - ${s.cest} - ${s.description}`,
        value: s.id,
      }))
    },
  },

  mutations: {
    SET_CESTS(state, cests) {
      state.cests = cests
    },

    CLEAN_STATE(state) {
      const { cests } = getInitialState()
      state.cests = cests
    },
  },

  actions: {
    async fetchCests({ commit }, { itemSearch }) {
      if (itemSearch.length >= 3) {
        const { data: cestData } = await axios.get('/api/tax/cests', {
          params: { cest: itemSearch },
        })
        commit('SET_CESTS', cestData || [])
      }
    },
    async fetchCestsByNcm({ commit }, { ncm, cestSearch }) {
      const { data: cestData } = await axios.get('/api/tax/cests/ncm', {
        params: { ncm, cest: cestSearch },
      })
      commit('SET_CESTS', cestData || [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
