import { storePagination } from '@/mixins/store'
import axios from '@axios'

import { sorting, payBoxTypes } from '@/mixins'

const { saleOriginEnum } = payBoxTypes.data()

const getInitialState = () => ({
  ...storePagination.state(),
  statements: [],
  payBoxes: [],
  summaryFields: null,
  filters: {
    payBoxId: null,
    origins: [],
    paymentMethods: [],
    createdRangeDate: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    printAvailableOrigin() {
      return [
        saleOriginEnum.BLEEDING,
        saleOriginEnum.FINAL_BLEEDING,
        saleOriginEnum.OPENING,
        saleOriginEnum.CLOSING,
        saleOriginEnum.RETURN_SALE,
        saleOriginEnum.ACCOUNTS_PAYABLE,
      ]
    },

    payBoxesOptions(state) {
      return state.payBoxes.map(pb => ({
        ...pb,
        value: pb.id,
        label: `Caixa ${pb.number}${pb.description ? ` - ${pb.description}` : ''}`,
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_STATEMENTS(state, statements) {
      state.statements = statements
    },
    SET_PAY_BOXES(state, payBoxes) {
      state.payBoxes = payBoxes
    },
    SET_OPTIONS_OF(state, { option, value }) {
      state.options[option] = value
    },
    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, statements, paging } = getInitialState()
      state.filters = filters
      state.statements = statements
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchCashBookTransactioById(states, id) {
      const { data } = await axios.get(`/api/cash-book/transactions/${id}`)
      return data
    },

    async fetchStatements({ state, commit, rootGetters }) {
      const { origins, paymentMethods } = state.filters
      const { id: payBoxId } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const { data } = await axios.get(`/api/cash-book/active/transactions/pay-box/${payBoxId}`, {
        params: {
          origins,
          paymentMethods,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_STATEMENTS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchStatementsByFilter({ state, commit }) {
      const {
        payBoxId,
        createdRangeDate: { startDate, endDate },
        origins,
        paymentMethods,
      } = state.filters

      const { data } = await axios.get(`/api/cash-book/transactions/pay-box/${payBoxId}`, {
        params: {
          origins,
          paymentMethods,
          dateFrom: startDate,
          dateTo: endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_STATEMENTS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    async fetchPayBoxes({ commit }, storeId) {
      const { data } = await axios.get(`/api/sales/pay-box`, {
        params: {
          storeId,
          pageSize: 999,
        },
      })

      commit('SET_PAY_BOXES', data.results || [])
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
