<template>
  <FormulateInput
    v-model="content"
    v-bind="$attrs"
    :type="isReadOnly ? 'label' : 'vue-select'"
    :multiple="multiple"
    :label="showLabel ? label : ''"
    :placeholder="placeholder"
    :options="localOptions"
    :class="showLabel && required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    @input="handleInput"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  mixins: [],
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default() {
        return this.$t('Caixa')
      },
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('Selecione')
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      content: null,
    }
  },
  computed: {
    ...mapGetters('app', {
      options: 'payboxOptions',
    }),

    localOptions() {
      return this.options
    },
  },
  watch: {
    value(val) {
      this.content = val
    },
  },
  async mounted() {
    await this.fetchPayboxes()
  },
  created() {
    if (Array.isArray(this.value)) this.content = this.value
    else if (this.value) this.content = String(this.value)
  },

  methods: {
    ...mapActions('app', ['fetchPayboxes']),
    handleInput() {
      if (!this.localOptions) return

      this.$emit('input', this.content)
      const selected = this.localOptions.find(item => item.id === parseInt(this.content, 10))
      if (selected) {
        this.$emit('input-object', selected)
      }
    },
  },
}
</script>
