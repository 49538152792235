var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-actions",
    { attrs: { "action-collapse": "", title: _vm.title || _vm.defaultTitle } },
    [
      _c(
        "FormulateForm",
        {
          ref: "filterForm",
          attrs: { name: "filter" },
          on: { submit: _vm.search },
        },
        [
          _c(
            "div",
            [
              _vm._t("default"),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12", align: "right" } },
                    [
                      _vm._t("buttons", [
                        _c("e-button", {
                          class: _vm.showSearchButton
                            ? "btn-sm-block mr-1 my-1 my-md-0"
                            : "btn-sm-block my-1 my-md-0",
                          attrs: {
                            id: "filter_clean_search",
                            type: "button",
                            variant: "secondary",
                            icon: "x-circle",
                            text: _vm.$t("Limpar filtros"),
                          },
                          on: { click: _vm.reset },
                        }),
                        _vm.showSearchButton
                          ? _c("e-button", {
                              staticClass: "btn-sm-block",
                              attrs: {
                                id: "filter_search",
                                type: "submit",
                                variant: "primary",
                                icon: "search",
                                busy: _vm.searching,
                                text: _vm.searchText || _vm.defaultSearchText,
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._t("after_buttons"),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }