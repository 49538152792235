const pixTypes = {
  computed: {
    getPixTypes() {
      return {
        'Email': this.$t('E-mail'),
        'CpfCnpj': this.$t('CPF/CNPJ'),
        'Cellphone': this.$t('Telefone'),
        'RandomKey': this.$t('Chave aleatória'),
      }
    }
  },

  methods: {
    pixTypes() {
      return [
        { value: 'Email', label: this.$t('E-mail') },
        { value: 'CpfCnpj', label: this.$t('CPF/CNPJ') },
        { value: 'Cellphone', label: this.$t('Telefone') },
        { value: 'RandomKey', label: this.$t('Chave aleatória') },
      ]
    },
  },
}

export default pixTypes
