import { setCookie, getCookie } from 'tiny-cookie'
/* eslint-disable import/no-cycle */
import store from '@/store'
import jwtDefaultConfig from './jwtDefaultConfig'
import { isUserLoggedIn } from '@/auth/utils'
import { monitoringLogout } from '@/frontend-monitoring'
import isElectron from 'is-electron'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      async (config) => {
        // Get token from localStorage
        const accessToken = config.headers['delegate-token'] || this.getToken()
        delete config.headers['delegate-token']

        // add header for OpenTelemetry (only on PDV(electron) this should work)
        await this.enrichHeaders(config)

        // If token is present add it to request's Authorization Header
        if (accessToken && accessToken !== 'undefined') {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401)
        if (response && response.status === 401 && config.url === this.jwtConfig.refreshEndpoint) {
          store.dispatch('logout')
          return false
        }
        // if (status === 401) and delegating permission..
        // TODO: estamos dando um skip no refreshToken, antes da implementação, no model de autenticação para delegação ele ficava travado qnd inseria a senha errada por exemplo

        if (response && response.status === 401 && config.url.endsWith(this.jwtConfig.delegatePermissionEndpoint.replace("/api", ""))) {
          return Promise.reject(error)
        }

        if (response && response.status === 401 && !isUserLoggedIn()) {
          return Promise.reject(error)
        }

        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false
              if (r.data.consequence === 'SUCESSO') {
                // Update accessToken in localStorage
                this.setToken(r.data.dado.dadoUsuario.jwtToken)
                this.setRefreshToken(r.data.dado.dadoUsuario.refreshToken)
                this.onAccessTokenFetched(r.data.dado.dadoUsuario.jwtToken)
              } else {
                store.dispatch('logout')
              }
            }).catch(() => {
              this.isAlreadyFetchingAccessToken = false
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  async enrichHeaders(config) {
    const identify = isElectron() ? await window.electronAPI.system.identifyPayboxRead(): null

    const payBoxId = store.state.pages?.pdv?.payBoxConfiguration?.payBox?.id || identify?.payboxId
    const payBoxStoreId = store.state.pages?.pdv?.payBoxConfiguration?.payBox?.store?.id || identify?.storeId
    const currentSaleUuid = store.state.pages?.pdv?.payBox?.uuid

    if (payBoxId) {
      config.headers['echope-trace-paybox'] = payBoxId
    }
    if (payBoxStoreId) {
      config.headers['echope-trace-store'] = payBoxStoreId
    }
    if (currentSaleUuid) {
      config.headers['echope-trace-sale'] = currentSaleUuid
    }

    // force PDV filter (on /pdv/* routes we should always provide configured Store)
    const payBoxPdvFilterStoreId = store.state.pages?.pdv?.payBoxConfiguration?.pdvGlobalFilters?.payBox?.store?.id
    if (payBoxPdvFilterStoreId) {
      config.headers['echope-store-single'] = payBoxPdvFilterStoreId
    }
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    // A implementação original do template utiliza o localStorage para armazenar o refreshToken,
    // mas nós utilizamos o cookie, por isso foi necessário alterar a linha abaixo.
    return getCookie(this.jwtConfig.storageRefreshTokenKeyName)
  }

  logout() {
    this.setToken(undefined)
    this.setRefreshToken(undefined)
    localStorage.removeItem('userData')
    localStorage.removeItem('permissions')

    monitoringLogout()
  }

  setToken(value) {
    if (value)
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    else
      localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  setRefreshToken(value) {
    // Mesma consideração do método getRefreshToken()
    setCookie(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  async login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  async delegatePermission(...args) {
    return this.axiosIns.post(this.jwtConfig.delegatePermissionEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
