var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "osk-with-button-container" },
    [
      _c(
        "b-button",
        {
          class:
            "osk-with-button-btn osk-with-button-btn-left " +
            (_vm.context.attributes.disabled ? "cursor-not-allowed" : ""),
          attrs: {
            disabled: _vm.context.attributes.disabled,
            variant: "primary",
          },
          on: { click: _vm.onLeftClick },
        },
        [_c("b-icon", { attrs: { icon: _vm.iconLeft, "font-scale": "2" } })],
        1
      ),
      _c(
        "vue-numeric",
        _vm._b(
          {
            class:
              "formulate-input-element formulate-input-element--" +
              _vm.context.type +
              " osk-with-button-input",
            attrs: {
              "input-class": _vm.context.attributes.class,
              "data-type": _vm.context.type,
              "decimal-separator": _vm.decimalSeparator,
              "thousand-separator": _vm.thousandSeparator,
              separator: _vm.separator,
              disabled: _vm.inputDisabled,
            },
            on: { blur: _vm.onBlur, focus: _vm.onFocus },
            model: {
              value: _vm.context.model,
              callback: function ($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model",
            },
          },
          "vue-numeric",
          _vm.context.attributes,
          false
        )
      ),
      _c(
        "b-button",
        {
          class:
            "osk-with-button-btn osk-with-button-btn-right " +
            (_vm.context.attributes.disabled ? "cursor-not-allowed" : ""),
          attrs: {
            disabled: _vm.context.attributes.disabled,
            variant: "primary",
          },
          on: { click: _vm.onRightClick },
        },
        [_c("b-icon", { attrs: { icon: _vm.iconRight, "font-scale": "2" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }