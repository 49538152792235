import { storePagination, sorting } from '@/mixins'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  productsAverageCost: [],
  filters: {
    storeId: null,
    skuId: null,
    ean: '',
    name: '',
    department: null,
    line: null,
    unit: null,
    brand: null,
    group: null,
    subgroup: null,
  },
  defaultOptions: {
    skus: []
  }
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_PRODUCTS_AVERAGE_COSTS(state, productsAverageCost) {
      state.productsAverageCost = productsAverageCost
    },

    SET_FILTER(state, filters) {
      state.filters = filters
    },
    SET_DEFAULT_OPTIONS(state, { property, optionList }) {
      if (state.defaultOptions[property]) {
        state.defaultOptions[property] = optionList
      }
    },

    CLEAN_STATE(state) {
      const { filters, paging, productsAverageCost } = getInitialState()
      state.filters = filters
      state.productsAverageCost = productsAverageCost
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchProductAverageCosts({ commit, state }) {
      const { data } = await axios.get('/api/skus/average-costs', {
        params: {
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          brandId: state.filters.brand,
          groupId: state.filters.group,
          subgroupId: state.filters.subgroup,
          departmentId: state.filters.department,
          lineId: state.filters.line,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      const results = data.results.map(r => ({
        ...r,
        busy: false,
        originalValue: {
          ...r
        }
      }))

      commit('SET_PRODUCTS_AVERAGE_COSTS', results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async updateProductAverageCost(states, { skuId, storeId, averageCost }) {
      const { data } = await axios.patch('/api/skus/average-costs', { storeId, skuId, averageCost })
      return data
    },

    resetFilters({ commit }) {
      commit('SET_FILTER', getInitialState().filters)
    },

    setDefaultOptions({ commit }, { property, optionList }) {
      commit('SET_DEFAULT_OPTIONS', { property, optionList })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
