export const atmScreenEnum = {
  CATALOG: 'catalog',
  PAYMENT: 'payment',
}

const getInitialState = () => ({
  atmStatus: {
    atmScreen: atmScreenEnum.CATALOG,
    isPaymentError: false,
    isFinishError: false,

    isCartOpen: false, // flag para mostrar sidebar do carrinho
  },
})

/** Está separado pois existe uma subscribe monitorando alterações,
 * qnd altera algo desse state salva os dados no localStorage
 * */
export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getAtmScreen(state) {
      return state.atmStatus.atmScreen
    },
    getIsFinishError(state) {
      return state.atmStatus.isFinishError
    },
    getIsPaymentError(state) {
      return state.atmStatus.isPaymentError
    },

    getIsCartOpen(state) {
      return state.atmStatus.isCartOpen
    },
  },

  mutations: {
    SET_ATM_STATUS(state, status) {
      state.atmStatus = status
    },
    SET_ATM_SCREEN(state, atmScreen) {
      state.atmStatus.atmScreen = atmScreen
    },
    SET_IS_FINISH_ERROR(state, isFinishError) {
      state.atmStatus.isFinishError = isFinishError
    },
    SET_IS_PAYMENT_ERROR(state, isPaymentError) {
      state.atmStatus.isPaymentError = isPaymentError
    },

    SET_IS_CART_OPEN(state, isCartOpen) {
      state.atmStatus.isCartOpen = isCartOpen
    },

    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    setAtmScreen({ commit }, atmScreen) {
      commit('SET_ATM_SCREEN', atmScreen)
    },

    setIsFinishError({ commit }, isFinishError) {
      commit('SET_IS_FINISH_ERROR', isFinishError)
    },
    setIsPaymentError({ commit }, isPaymentError) {
      commit('SET_IS_PAYMENT_ERROR', isPaymentError)
    },

    setIsCartOpen({ commit }, isCartOpen) {
      commit('SET_IS_CART_OPEN', isCartOpen)
    },

    async loadAtmStatus({ commit, dispatch }) {
      const data = await dispatch('localStorageGetStatus')
      if (!data) return

      try {
        const dataParsed = JSON.parse(data)
        commit('SET_ATM_STATUS', { ...getInitialState().atmStatus, ...dataParsed })
      } catch (error) {
        console.error('[ATM_STATUS] load error', error)
      }
    },
    async updateAtmStatus({ dispatch }) {
      await dispatch('localStorageSaveStatus')
    },

    localStorageSaveStatus({ state }) {
      localStorage.setItem('atmStatus', JSON.stringify(state.atmStatus))
    },
    localStorageGetStatus() {
      return localStorage.getItem('atmStatus')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
