<template>
  <span>
    <!-- https://www.npmjs.com/package/v-tooltip#other-options -->
    <b-badge
      v-tooltip="{
        content: title,
        placement: 'bottom',
        classes: 'e-badge-tooltip',
        trigger: 'click hover',
      }"
      :variant="getBadgeVariant"
      v-bind="getAttributesBind"
      class="w-100 text-wrap"
      :class="{
        'cursor-pointer': !!title,
      }"
    >
      <span v-if="title">
        <feather-icon
          icon="InfoIcon"
          size="10"
        />
      </span>

      {{ getText }}
    </b-badge>
  </span>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'EStatusBadge',
  components: { BBadge },
  props: {
    status: {
      type: [Boolean, Number, String],
      default: false,
    },
    type: {
      type: String,
      default: 'activeInactive',
    },
    trueLabel: {
      type: String,
      default: undefined,
    },
    trueClassStyle: {
      type: String,
      default: null,
    },

    falseLabel: {
      type: String,
      default: undefined,
    },
    falseClassStyle: {
      type: String,
      default: null,
    },

    variant: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    /**
     * array de objetos
     * ex: [{
     *    value: string, // é o valor passado na prop "status"
     *    label: string, // texto mostrado no badge
     *    classStyle: 'success' | 'danger' | 'info' | 'warning' | 'primary' | 'secondary',
     *    style: object, // objeto CSS para estilos ex: { 'background-color': 'blue' }
     * }]
     */
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    getBadgeVariant() {
      if (this.options.length)
        return this.statusLabel[this.status]?.classStyle || this.variant || 'secondary'
      return this.statusLabel[!!this.status]?.classStyle || this.variant || 'secondary'
    },

    getAttributesBind() {
      const attributes = {}
      if (this.options.length) {
        if (this.statusLabel[this.status]?.style)
          attributes.style = this.statusLabel[this.status]?.style
      }

      return attributes
    },

    getText() {
      if (this.options.length) return this.statusLabel[this.status]?.text || this.status
      return this.statusLabel[!!this.status]?.text || this.status
    },

    statusLabel() {
      let result = {
        true: {
          text: this.trueLabel || this.$t('Ativo'),
          classStyle: this.trueClassStyle || 'success',
        },
        false: {
          text: this.falseLabel || this.$t('Inativo'),
          classStyle: this.falseClassStyle || 'danger',
        },
      }

      if (this.type === 'yesNo') {
        result = {
          true: { text: this.$t('Sim'), classStyle: this.trueClassStyle || 'success' },
          false: { text: this.$t('Não'), classStyle: this.falseClassStyle || 'danger' },
        }
      }

      if (this.type === 'noOptions') {
        result = {}
      }

      if (this.options.length > 0) {
        const optionDefault = {
          undefined: { text: '', classStyle: 'secondary' },
          null: { text: '', classStyle: 'secondary' },
        }
        result = this.options.reduce(
          (prev, opt) => ({
            ...prev,
            [opt.value]: {
              text: opt.label,
              classStyle: opt.classStyle || 'success',
              style: opt.style,
            },
          }),
          optionDefault
        )
      }

      return result
    },
  },
}
</script>

<style>
.e-badge-tooltip.tooltip {
  display: block !important;
  padding: 4px;
  z-index: 10000;
}

.e-badge-tooltip.tooltip .tooltip-inner {
  background: #333333;
  color: white;
  border-radius: 0px;
  padding: 4px 5px 4px;
}

.e-badge-tooltip.tooltip tooltip-arrow {
  display: none;
}

.e-badge-tooltip.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.e-badge-tooltip.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
