const environment = window.env || 'local'

const devUtils = {
  computed: {
    mxIsDevelopment() {
      return environment !== 'production'
    },
  },
  methods: {},
}

export default devUtils
