// import axios from '@axios'

const getInitialState = () => ({
  suppliers: [],
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {},
  getters: {
    getComboSuppliers(state) {
      return state.suppliers.map(s => ({
        ...s,
        label: s.name,
        value: s.id,
      }))
    },
  },

  mutations: {
    SET_SUPPLIERS(state, suppliers) {
      state.suppliers = suppliers
    },

    CLEAN_STATE(state) {
      const { suppliers } = getInitialState()
      state.suppliers = suppliers
    },
  },

  actions: {
    async searchSuppliers({ commit }, { supplierSearch }) {
      if (supplierSearch.length >= 3) {
        // TODO buscar por fornecedores
        // const { data: ncmsData } = await axios.get('/api/tax/ncms', {
        //   params: { ncm: itemSearch },
        // })
        const mockData = [
          { id: 1, name: 'Fornecedor 1' },
          { id: 2, name: 'Fornecedor 2' },
          { id: 3, name: 'Fornecedor 3' },
        ]

        const suppliersData = await new Promise(resolve => setTimeout(() => resolve(mockData), 1000))

        commit('SET_SUPPLIERS', suppliersData || [])
      }
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
