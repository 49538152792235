import { sorting, storePagination } from '@/mixins'
import axios from '@axios'
import clientMaintain from './client-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  clients: [],
  customerClassifications: [],
  searched: false,
  filter: {
    id: null,
    name: '',
    document: '',
    customerClassificationId: null,
    priceTable: null,
    email: '',
    active: null,
    personType: '',
    province: null,
    city: '',
  },
})

export default {
  namespaced: true,
  modules: { clientMaintain },

  state: getInitialState(),

  getters: {
    customerClassificationsOptions(state) {
      return state.customerClassifications.map(item => ({
        ...item,
        value: item.id,
        label: item.name,
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_CLIENTS(state, clients) {
      state.clients = clients
    },
    SET_SEARCHED(state, searched) {
      state.searched = searched
    },
    SET_CUSTOMER_CLASSIFICATIONS(state, items) {
      state.customerClassifications = items
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    CLEAN_STATE(state) {
      const { filter, paging, clients } = getInitialState()
      state.filter = filter
      state.clients = clients
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchClients({ state, commit }) {
      const {
        id,
        name,
        document,
        priceTable,
        email,
        active,
        personType,
        customerClassificationId,
        province,
        city,
      } = state.filter

      const { data } = await axios.get('/api/customers', {
        params: {
          id,
          name: name || '',
          customerClassificationId,
          document: document || '',
          priceTableId: priceTable || '',
          email: email || '',
          active,
          personType: personType || '',
          province,
          city,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_CLIENTS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchCustomerClassifications({ commit }) {
      const { data } = await axios.get('/api/customers-classifications')

      commit('SET_CUSTOMER_CLASSIFICATIONS', data)

    },

    setSearched({ commit }, searched) {
      commit('SET_SEARCHED', searched)
    },

    setFilter({ commit }, filter) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', filter)
    },

    resetFilter({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', getInitialState().filter)
      commit('CLEAN_STATE')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
