import i18n from '@/libs/i18n'
import { financialDomain, storePagination, stringUtils, uploader } from '@/mixins'
import axios from '@axios'

const getInitialXlsxData = () => ({
  dueDate: null,
  customerId: null,
  storeId: null,
  documentType: null,
  document: null,
  description: null,
  managementAccountName: null,
  paymentMethodName: null,
  issueDate: null,
  value: null,
})

const getInitialReceivableImport = () => ({
  dueDate: null,
  customerId: null,
  storeId: null,
  documentType: null,
  document: null,
  description: null,
  managementAccountName: null,
  paymentMethodName: null,
  issueDate: null,
  value: null,
  xlsxData: getInitialXlsxData(),

  // layout info
  hasErrors: false,
  xlsxErrors: {},
  defaultCustomerOptions: [],
  _showDetails: false,
})

const getInitialState = () => ({
  ...storePagination.state(),

  filters: {
    value: '',
    customerId: null,
    paymentMethodId: null,
    managementAccountId: null,
    storeId: null,
    periodType: financialDomain.data().receivablePeriodTypeEnum.CREATED_DATE,
    period: {
      startDate: null,
      endDate: null,
    },

    onlyErrors: false,
  },
  receivablesImported: [],
  receivablesImportedFiltered: [],
  xlsxImportedFile: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getReceivables(state) {
      return state.receivablesImportedFiltered
    },

    getItemsSummary(state) {
      return {
        totalItems: state.receivablesImported?.reduce((total, i) => total + i.value, 0) || 0,
        totalErrors: state.receivablesImported?.filter(i => i.hasErrors)?.length || 0,
      }
    },

    isValidToSave(state) {
      return !state.receivablesImported.some(accPay => accPay.hasErrors)
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_XLSX_IMPORTED_FILE(state, file) {
      state.xlsxImportedFile = file
    },

    SET_RECEIVABLE_IMPORTED(state, payload) {
      state.receivablesImported = payload
    },

    SET_RECEIVABLE_IMPORTED_FILTERED(state, payload) {
      state.receivablesImportedFiltered = payload
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    ...storePagination.actions,

    setXlsxImportedFile({ commit }, file) {
      commit('SET_XLSX_IMPORTED_FILE', file)
    },

    async sendXlsxReceivable({ commit, dispatch }, { file }) {
      const itemDefault = getInitialReceivableImport()
      const xlsxItemDefault = getInitialXlsxData()

      const result = await uploader.methods.uploadCustom({
        file,
        apiUrl: '/api/receivable/receivables/read-spreadsheet',
        fileParamName: 'file',
      })

      const { getStringOrNull } = stringUtils.methods

      const formatXlsxData = data => ({
        ...xlsxItemDefault,
        ...data,
      })

      const resultFormated = result.map(r => {
        const xlsxErrors = {}

        if (Array.isArray(r.errorMessages) && r.errorMessages?.length > 0) {
          r.errorMessages.forEach(errorItem => {
            xlsxErrors[errorItem?.field] = errorItem.messages?.map(msg => i18n.t(msg))
          })
        }

        return {
          ...itemDefault,
          ...r,
          hasErrors: r.errorMessages?.length > 0,
          storeId: getStringOrNull(r.storeId),
          managementAccountId: getStringOrNull(r.managementAccountId),
          paymentMethodId: getStringOrNull(r.paymentMethodId),
          customerId: getStringOrNull(r.customer?.id),
          defaultCustomerOptions: r.customer ? [r.customer] : [],
          xlsxData: formatXlsxData(r.xlsxData),
          xlsxErrors,
        }
      })

      commit('SET_RECEIVABLE_IMPORTED', resultFormated)

      dispatch('updatePaging')
    },

    async exampleXlsxDownload() {
      const { data } = await axios.get('/api/receivable/receivables/download/import-example', {
        responseType: 'blob',
      })
      return data
    },

    async saveBulkImport({ state }) {
      const receivables = state.receivablesImported.map(i => ({
        storeId: i.storeId,
        issueDate: i.issueDate,
        dueDate: i.dueDate,
        customerId: i.customerId,
        documentType: i.documentType,
        document: i.document,
        description: i.description,
        managementAccountId: i.managementAccountId,
        paymentMethodId: i.paymentMethodId,
        value: i.value,
      }))

      await axios.post('/api/accounting/receivables/bulk', { receivables })
    },

    filterReceivable({ state, commit, dispatch }) {
      const { customerId, storeId, onlyErrors, value, paymentMethodId, managementAccountId } =
        state.filters
      const filtered = state.receivablesImported.filter(a => {
        let result = true

        if (value !== '') {
          result &&= a.value === value
        }

        if (onlyErrors === true) {
          result &&= a.hasErrors === true
        }

        if (managementAccountId != null) {
          result &&= String(a.managementAccountId) === String(managementAccountId)
        }

        if (paymentMethodId != null) {
          result &&= String(a.paymentMethodId) === String(paymentMethodId)
        }

        if (customerId != null) {
          result &&= String(a.customerId) === String(customerId)
        }

        if (storeId != null) {
          result &&= String(a.storeId) === String(storeId)
        }

        return result
      })
      commit('SET_RECEIVABLE_IMPORTED_FILTERED', filtered)
      dispatch('updatePaging')
    },

    resetFilters({ state, commit, dispatch }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
      commit('SET_RECEIVABLE_IMPORTED_FILTERED', state.receivablesImported)
      dispatch('updatePaging')
    },
    updatePaging({ state, commit, getters }) {
      const rowCount = getters.getReceivables.length || 0
      const isLastPage = state.paging.currentPage === Math.ceil(rowCount / state.paging.pageSize)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount,
        rowsInCurrentPage: isLastPage ? rowCount % state.paging.pageSize : state.paging.pageSize,
      })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
