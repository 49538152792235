<template>
  <div class="h-100 bg-white shadow rounded d-flex flex-column justify-content-between px-2 py-1">
    <div>
      <p :class="`h3 mb-1 ${titleClass}`">
        {{ title }}
      </p>

      <p class="h4">
        {{ value | currency }}
      </p>
      <p
        v-if="hasPredictedValue"
        class="body2"
      >
        Previsto: {{ predictedValue | currency }}
      </p>
    </div>

    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillSummaryCard',

  props: {
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      required: true,
    },
    predictedValue: {
      type: Number,
      default: 0,
    },
    hasPredictedValue: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
