import axios from '@axios'
import moment from 'moment'
import { storePagination } from '@/mixins/store'
import { sorting } from '@/mixins'

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'sku.id',
    sortDesc: false,
  },
  stockItems: [],
  summaryFields: null,
  reportData: null,
  filters: {
    storeId: null,
    skuId: null,
    date: null,
    reportType: 'Sale',
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_STOCK_POSITIONS(state, stockItems) {
      state.stockItems = stockItems
    },
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },
    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, stockItems } = getInitialState()
      state.filters = filters
      state.stockItems = stockItems
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchStockItems({ commit, state }) {
      const { data } = await axios.get('/api/reports/retroactive-stock', {
        params: {
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          date: moment(state.filters.date).endOf('day').format(),
          reportType: state.filters.reportType,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_STOCK_POSITIONS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    async generateReport({ state, commit }, reportType) {
      const response = await axios.get('/api/reports/retroactive-stock/download', {
        params: {
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          date: moment(state.filters.date).endOf('day').format(),
          reportType,
        },
        responseType: 'blob',
      })
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      commit('SET_REPORT_DATA', url)
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('CLEAN_FILTERS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
