import imgAmericanExpress from '@/assets/images/brands/cards/american_express.svg'
import imgElo from '@/assets/images/brands/cards/elo.svg'
import imgHipercard from '@/assets/images/brands/cards/hipercard.svg'
import imgVisa from '@/assets/images/brands/cards/visa.svg'
import imgMastercard from '@/assets/images/brands/cards/mastercard.svg'

const cardTypeEnum = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
}

const roundInstallmentEnum = {
  FIRST: 'FIRST',
  LAST: 'LAST',
}

const acquirerEnum = {
  GETNET: 'GETNET',
  PIX: 'PIX',
  RAPPI: 'RAPPI',
  OTHERS: 'OTHERS',
}

const cardDomain = {
  data() {
    return {
      cardTypeEnum,
      roundInstallmentEnum,
      acquirerEnum,
    }
  },

  computed: {
    cardTypeLabel() {
      return {
        [cardTypeEnum.CREDIT]: this.$t('Crédito'),
        [cardTypeEnum.DEBIT]: this.$t('Débito'),
      }
    },
  },

  methods: {
    cardTypeOptions() {
      return [
        { value: cardTypeEnum.CREDIT, label: this.$t('Crédito') },
        { value: cardTypeEnum.DEBIT, label: this.$t('Débito') },
      ]
    },

    roundInstallmentOptions() {
      return [
        { value: roundInstallmentEnum.FIRST, label: this.$t('Primeira') },
        { value: roundInstallmentEnum.LAST, label: this.$t('Última') },
      ]
    },

    acquirerOptions() {
      return [
        { value: acquirerEnum.GETNET, label: this.$t('GetNet') },
        // { value: acquirerEnum.PIX, label: this.$t('Pix') },
        // { value: acquirerEnum.RAPPI, label: this.$t('Rappi') },
        // { value: acquirerEnum.OTHERS, label: this.$t('Outros') },
      ]
    },

    cardBrands() {
      return [
        { value: "MASTERCARD", label: "Mastercard", icon: imgMastercard },
        { value: "ELO", label: "ELO", icon: imgElo },
        { value: "VISA", label: "Visa", icon: imgVisa },
        { value: "AMEX", label: "American Express", icon: imgAmericanExpress },
        { value: "HIPERCARD", label: "Hipercard", icon: imgHipercard },
      ]
    }
  },
}

export default cardDomain
