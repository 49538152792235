import { validatorIpAddress } from "@/@core/utils/validations/validators"

const defaultOptionsPrinterEnum = {
  OTHER_LOCAL_PORT: 'otherLocalPort',
  NETWORK_PRINTER: 'networkPrinter'
}

const payBoxTypes = {
  data() {
    return {
      defaultOptionsPrinterEnum,
    }
  },

  computed: {
  },

  methods: {
    optionsPrinterModel() {
      return [
        'ppTexto',
        'ppEscPosEpson',
        'ppEscBematech',
        'ppEscDaruma',
        'ppEscVox',
        'ppEscDiebold',
        'ppEscEpsonP2',
        'ppCustomPos',
        'ppEscPosStar',
        'ppEscZJiang',
        'ppEscGPrinter',
        'ppEscDatecs',
      ]
    },
    optionsPrinterPageCode() {
      return ['None', 'pc437', 'pc850', 'pc852', 'pc860', 'pcUTF8', 'pc1252']
    },

    getOptionsPrinterFormated(printerList) {
      const localPrinterList = printerList.map(p => ({
        label: p,
        value: p,
      }))
      return [
        ...localPrinterList,
        {
          label: this.$t('Outra porta local'),
          value: defaultOptionsPrinterEnum.OTHER_LOCAL_PORT,
        },
        {
          label: this.$t('Impressora de rede'),
          value: defaultOptionsPrinterEnum.NETWORK_PRINTER,
        },
      ]
    },

    /**
     * Método apenas para retornar valor da opção que estiver no combo que lista impressoras de acordo com o que receber nos argumentos.
     * @param {String|Null} printerPort nome da impressora (dado local que representa o state)
     * @param {String|Null} printerPortFoundInList nome da impressora que foi encontrada na listagem de impressoras retornadas pelo agente.
     * @param {String|Null} comboSelected `value` do combo atualmente
     * @returns {String|Null} `value` da opção que estiver no combo de impressora
     */
    getOptionSelected(printerPort, printerPortFoundInList, comboSelected) {
      const { NETWORK_PRINTER, OTHER_LOCAL_PORT } = defaultOptionsPrinterEnum
      let port = ''
      const isIpAddress = validatorIpAddress((printerPort ?? '').replace('TCP:', '').trim())
      if (printerPortFoundInList) {
        port = printerPortFoundInList
      } else if (printerPort || comboSelected) {
        if (
          comboSelected === NETWORK_PRINTER ||
          isIpAddress
        ) {
          port = NETWORK_PRINTER
        } else if (
          comboSelected === OTHER_LOCAL_PORT ||
          printerPort !== null
        ) {
          port = OTHER_LOCAL_PORT
        }
      }

      return port
    },

    formatToLocalPrinterPort(value) {
      const locVal = (value || '').replace('TCP:', '').trim()
      if (validatorIpAddress(locVal)) return locVal
      return value
    },
    formatToStatePrinterPort(value) {
      if (validatorIpAddress(value)) return `TCP:${value}`
      return value
    },
  },
}

export default payBoxTypes
