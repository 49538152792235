const productDomains = {
  computed: {
    productClassificationsEnum() {
      return {
        NONE: 'None',
        SALE: 'Sale',
        RENT: 'Rent',
        LENDING: 'Lending',
        CREDIT: 'Credit',
      }
    },
  },

  methods: {
    productClassifications() {
      return [
        { value: 'Sale', label: this.$t('Venda') },
        { value: 'Rent', label: this.$t('Aluguel') },
        { value: 'Lending', label: this.$t('Comodato') },
        { value: 'Credit', label: this.$t('Crédito') },
      ]
    },
    productClassificationsForInventory() {
      return [
        { value: 'Sale', label: this.$t('Venda'), classStyle: 'secondary' },
        { value: 'Rent', label: this.$t('Aluguel'), classStyle: 'secondary' },
        { value: 'Lending', label: this.$t('Comodato'), classStyle: 'secondary' },
      ]
    },
    productClassificationsReclaims() {
      return [
        { value: 'Rent', label: this.$t('Aluguel') },
        { value: 'Lending', label: this.$t('Comodato') },
      ]
    },
    productTypes() {
      return [
        { value: 'Product', label: this.$t('Produto') },
        { value: 'Input', label: this.$t('Consumo') },
        { value: 'Gift', label: this.$t('Brinde') },
      ]
    },
    productCreditTypes() {
      return [
        { value: 'Accession', label: this.$t('Adesão') },
        { value: 'Recharge', label: this.$t('Recarga') },
      ]
    },
    productTaxTypes() {
      return [
        { label: this.$t('Ativo Imobilizado'), value: '9' },
        { label: this.$t('Embalagem'), value: '3' },
        { label: this.$t('Material de Uso e Consumo'), value: '8' },
        { label: this.$t('Matéria-Prima'), value: '2' },
        { label: this.$t('Mercadoria para revenda'), value: '1' },
        { label: this.$t('Outras'), value: '12' },
        { label: this.$t('Outros insumos'), value: '11' },
        { label: this.$t('Produto Acabado'), value: '5' },
        { label: this.$t('Produto em Processo'), value: '4' },
        { label: this.$t('Produto Intermediário'), value: '7' },
        { label: this.$t('Serviços'), value: '10' },
        { label: this.$t('Subproduto'), value: '6' },
      ]
    },
    productOrigins() {
      return [
        { label: '0 - Nacional, exceto as indicadas nos códigos 3 a 5', value: '0' },
        { label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6', value: '1' },
        {
          label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
          value: '2',
        },
        {
          label: '3 - Nacional, mercadoria ou bem com conteúdo de importação superior a 40%',
          value: '3',
        },
        {
          label:
            '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos',
          value: '4',
        },
        {
          label:
            '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
          value: '5',
        },
        {
          label:
            '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução CAMEX',
          value: '6',
        },
        {
          label:
            '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX',
          value: '7',
        },
        { label: '8 - Nacional, Conteúdo de Importação superior a 70%', value: '8' },
      ]
    },
  },
}

export default productDomains
