import FormulateERadioIcon from './FormulateERadioIcon.vue'

export default function VueFormulateERadioIconPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateERadioIcon,
    },
    library: {
      'e-radio-icon': {
        classification: 'radio',
        component: 'FormulateERadioIcon',
        slotProps: {
          component: ['comboOptions']
        }
      },
    },
  })
}
