<template>
  <div class="select-with-button-container">
    <v-select
      v-model="context.model"
      v-bind="context.attributes"
      :data-type="context.type"
      :reduce="(x) => x.value"
      :class="`select-with-button formulate-input-element formulate-input-element--${context.type}`"
      :options="context.options"
      label="label"
      @close="onClose"
    />

    <b-button
      :disabled="!context.attributes['button-permission'] || context.attributes.disabled"
      variant="primary"
      :class="`select-with-button-btn ${context.attributes.disabled ? 'cursor-not-allowed' : ''}`"
      :title="
        !context.attributes['button-permission']
          ? $t('Você não possui acesso a esta funcionalidade')
          : ''
      "
      @click="click"
    >
      <b-icon
        :icon="icon"
        font-scale="2"
      />
    </b-button>
  </div>
</template>

<script>
import { BIcon, BButton } from 'bootstrap-vue'

export default {
  name: 'SelectWithButton',
  components: { BIcon, BButton },

  props: {
    context: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      default: 'plus',
    },
  },

  methods: {
    click() {
      this.context.rootEmit('click')
    },
    onClose() {
      this.context.blurHandler()
    },
  },
}
</script>

<style lang="scss">
.select-with-button-container {
  display: flex;

  .select-with-button-btn {
    height: 37px;
    width: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }
}

.select-with-button {
  width: 100%;

  .vs__dropdown-toggle {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
</style>
