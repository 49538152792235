import moment from 'moment'
import i18n from '@/libs/i18n'

const formatTime = (value, defaultValue = '', withSeconds = false) => {
  if (value) {
    const treatedValue = moment(value)

    if (treatedValue.isValid()) {
      return treatedValue.format(withSeconds ? 'LTS' : 'LT')
    }
    return defaultValue
  }
  return defaultValue
}

const formatDate = (value, defaultValue = '', applyMask = false) => {
  if (value) {
    const treatedValue = moment(value.replace("****", 1900))

    if (treatedValue.isValid()) {
      let formattedDate = treatedValue.format('L')

      // if the date was masked, we keep the mask (year)
      //  or if we want to format the date masking it
      if (value.includes('****') || applyMask) {
        formattedDate = formattedDate.replace(treatedValue.format('YYYY'), '****')
      }

      return formattedDate
    }

    return defaultValue
  }
  return defaultValue
}

const formatDatetime = (value, defaultValue = '', withSeconds = false) => {
  if (value) {
    const treatedValue = moment(value)

    if (treatedValue.isValid()) {
      return `${treatedValue.format('L')} ${treatedValue.format(withSeconds ? 'LTS' : 'LT')}`
    }

    return defaultValue
  }
  return defaultValue
}

const month = (value, showYear = true) => {
  if (!value) return ''
  const val = String(value)
  const regx = /\w+\s(\w+)\s\d+\s(\d+)./
  if (!showYear) {
    return regx.exec(val)[1]
  }
  return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`
}

const elapsedTime = (time, emptyText = '', includeAgo = true, isFuture = false) => {
  if (!time) {
    return emptyText
  }

  const x = new Date(time)
  const now = new Date()
  let timeDiff = isFuture ? x - now : now - x
  timeDiff /= 1000

  const seconds = Math.round(timeDiff)
  timeDiff = Math.floor(timeDiff / 60)

  const minutes = Math.round(timeDiff % 60)
  timeDiff = Math.floor(timeDiff / 60)

  const hours = Math.round(timeDiff % 24)
  timeDiff = Math.floor(timeDiff / 24)

  const days = Math.round(timeDiff % 365)
  timeDiff = Math.floor(timeDiff / 365)

  const years = timeDiff

  const ago = includeAgo ? i18n.t('atrás') : ''

  if (years > 0) {
    return `${years} ${years > 1 ? i18n.t('anos') : i18n.t('anos')} ${ago}`
  }
  if (days > 0) {
    return `${days} ${days > 1 ? i18n.t('dias') : i18n.t('dias')} ${ago}`
  }
  if (hours > 0) {
    return `${hours} ${hours > 1 ? i18n.t('horas') : i18n.t('hora')} ${ago}`
  }
  if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? i18n.t('mins') : i18n.t('min')} ${ago}`
  }
  if (seconds > 0) {
    return seconds < 30 ? i18n.t('Agora mesmo') : `${seconds} ${i18n.t('segundos')} ${ago}`
  }

  return i18n.t('Agora mesmo')
}

const durationTime = (time, timeEnd, emptyText = '') => {
  if (!time || !timeEnd) {
    return emptyText
  }

  const x = new Date(time)
  const now = new Date(timeEnd)
  let timeDiff = now - x
  timeDiff /= 1000

  const seconds = Math.round(timeDiff)
  timeDiff = Math.floor(timeDiff / 60)

  const minutes = Math.round(timeDiff % 60)
  timeDiff = Math.floor(timeDiff / 60)

  const hours = Math.round(timeDiff % 24)
  timeDiff = Math.floor(timeDiff / 24)

  const days = Math.round(timeDiff % 365)
  timeDiff = Math.floor(timeDiff / 365)

  const years = timeDiff

  if (years > 0) {
    return `${years} ${years > 1 ? i18n.t('anos') : i18n.t('anos')}`
  }
  if (days > 0) {
    return `${days} ${days > 1 ? i18n.t('dias') : i18n.t('dias')}`
  }
  if (hours > 0) {
    return `${hours} ${hours > 1 ? i18n.t('horas') : i18n.t('hora')}`
  }
  if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? i18n.t('mins') : i18n.t('min')}`
  }
  if (seconds > 0) {
    return `${seconds} ${i18n.t('segundos')}`
  }

  return i18n.t('Agora mesmo')
}

const nowUTC = () => {
  const now = new Date()
  return new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  )
}

const formatJSDate = dateObj =>
  `${dateObj.getFullYear()}-${dateObj.getMonth()}-${dateObj.getDate()}-${dateObj.getHours()}-${dateObj.getMinutes()}-${dateObj.getSeconds()}`

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export { formatTime, formatDate, formatDatetime, elapsedTime, month, nowUTC, formatJSDate, days, durationTime }
