var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAsLabel,
              expression: "showAsLabel",
            },
          ],
          staticClass: "mt-25",
        },
        [
          _c("e-button", {
            staticClass: "p-5 btn-sm-block",
            attrs: {
              icon: "trash",
              variant: "danger",
              title: _vm.$t("Excluir"),
            },
            on: {
              click: function ($event) {
                return _vm.onBeforeDelete(_vm.firstFile)
              },
            },
          }),
          _c("b", { staticClass: "ml-1" }, [_vm._v(_vm._s(_vm.fileName))]),
        ],
        1
      ),
      _c(
        "VueFileAgent",
        _vm._b(
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showAsLabel,
                expression: "!showAsLabel",
              },
            ],
            ref: "documentos",
            attrs: {
              "input-class": _vm.context.attributes.class,
              "data-type": _vm.context.type,
              "help-text":
                _vm.context.attributes["help-text"] ||
                _vm.$t("Arraste e solte os arquivos aqui..."),
              "error-text": _vm.helpText,
            },
            on: {
              beforedelete: function ($event) {
                return _vm.onBeforeDelete($event)
              },
              delete: function ($event) {
                return _vm.fileDeleted($event)
              },
              blur: _vm.context.blurHandler,
            },
            model: {
              value: _vm.context.model,
              callback: function ($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model",
            },
          },
          "VueFileAgent",
          _vm.context.attributes,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }