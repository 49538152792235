import saleByPaymentMethod from './sale-by-payment-method'
import skuSaleDetailed from './sku-sale-detailed'

export default {
  namespaced: true,
  modules: {
    saleByPaymentMethod,
    skuSaleDetailed,
  },
  state: {},
  mutations: {},
  actions: {},
}
