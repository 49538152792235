import axios from "@axios"
import { localListAddItem, localListDeleteItem, localListUpdateItem } from '@/store/utils'

export const getInitialPromotionItemForm = () => ({
  id: null,
  skuId: null,
  quantity: null,
  priceFrom: null,
  price: null,
  isDeleted: false,
})

const getInitialState = () => ({
  promotion: {
    id: null,
    name: null,
    description: null,
    active: false,
    items: [],
    allowAllStores: false,
    stores: [],
    storesSelected: [],
    period: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getDiscountType(state) {
      return state.promotion.discountType
    },

    getPromotionItems(state) {
      return state.promotion.items.filter(k => !k.isDeleted)
    }
  },

  mutations: {
    SET_PRODUCT_OPTIONS(state, options) {
      state.productOptions = options
    },

    SET_PROMOTION(state, promotion) {
      state.promotion = promotion
    },

    ADD_PROMOTION_ITEM(state, promotionItem) {
      state.promotion.items = localListAddItem(state.promotion.items, {
        ...promotionItem,
        quantity: promotionItem.quantity || null,
      })
    },

    UPDATE_PROMOTION_ITEM(state, { promotionItem }) {
      state.promotion.items = localListUpdateItem(state.promotion.items, promotionItem)
    },

    REMOVE_PROMOTION_ITEM(state, id) {
      state.promotion.items = localListDeleteItem(state.promotion.items, id)
    },

    CLEAN_STATE(state) {
      const { promotion } = getInitialState()
      state.promotion = promotion
    },
  },

  actions: {
    async loadPromotionById({ commit }, id) {
      const { data } = await axios.get(`/api/promotions/${id}`)

      // const items = data.items.map(p => ({
      //   ...p,
      //   // skuProduct: { ...p.sku, label: p.sku.name }
      // }))

      commit('SET_PROMOTION', {
        ...data,
        storesSelected: data.stores.map(store => String(store.store.id)),
        period: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      })
    },

    addPromotionItem({ commit }, promotionItem) {
      commit('ADD_PROMOTION_ITEM', promotionItem)
    },

    updatePromotionItem({ commit }, { promotionItem, index }) {
      commit('UPDATE_PROMOTION_ITEM', { promotionItem, index })
    },

    removePromotionItem({ commit }, index) {
      commit('REMOVE_PROMOTION_ITEM', index)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
