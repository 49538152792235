import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  productSubgroups: [],
  productGroups: [],
  filters: { name: '', groupId: null },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    groupOptions(state) {
      return state.productGroups?.map(group => ({ value: group.id, label: group.name })) || []
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_PRODUCT_SUBGROUPS(state, productSubgroups) {
      state.productSubgroups = productSubgroups
    },
    SET_PRODUCT_GROUPS(state, productGroups) {
      state.productGroups = productGroups
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, productSubgroups } = getInitialState()
      state.filters = filters
      state.productSubgroups = productSubgroups
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchProductSubgroups({ commit, state }) {
      const { data } = await axios.get('/api/product-subgroups', {
        params: {
          name: state.filters.name,
          groupId: state.filters.groupId,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_PRODUCT_SUBGROUPS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchProductGroups({ commit }) {
      const { data } = await axios.get('/api/product-groups')
      commit('SET_PRODUCT_GROUPS', data.results)
    },

    setFilters({ commit }, filters) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
