const pagination = {
  data() {
    return {
      paging: {
        currentPage: 1,
        pageSize: 10,
        rowCount: 0,
        rowsInCurrentPage: 0,
        allRowsLoaded: false, // utilizar no scroll infinito para saber que já buscou todos os dados
      },
    }
  },
}

export default pagination
