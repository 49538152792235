<template>
  <div
    class="d-inline"
    style="margin-top: 5px"
  >
    <b-badge
      v-bind="badgeAttr"
      class="text-wrap"
    >
      <span
        v-for="(text, index) in shortcuts"
        :key="index"
      >
        <span v-if="index !== 0">+</span>
        {{ text }}
      </span>
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: { BBadge },

  props: {
    shortcuts: {
      type: Array,
      required: true,
    },
    badgeAttr: {
      type: Object,
      default: () => ({ variant: 'dark' }),
    },
  },
}
</script>

<style lang="scss" scoped>
.badge-light {
  color: var(--dark);
}
.badge-dark {
  color: var(--light);
}
</style>
