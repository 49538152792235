import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import promotionMaintain from './promotion-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  promotions: [],
  filters: {
    storeId: null,
    skuId: null,
    name: null,
    region: null,
    province: null,
    city: null,
    active: null,
    period: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: {
    promotionMaintain,
  },

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_PROMOTIONS(state, promotions) {
      state.promotions = promotions
    },
    SET_FILTER(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, promotions } = getInitialState()
      state.filters = filters
      state.promotions = promotions
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchPromotions({ state, commit }) {
      const { data } = await axios.get('/api/promotions', {
        params: {
          ...state.filters,
          startDate: state.filters?.period?.startDate,
          endDate: state.filters?.period?.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      commit('SET_PROMOTIONS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    setFilter({ commit }, filters) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', filters)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
