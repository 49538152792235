/* eslint-disable */

import { getSearchableMenus } from '@/navigation/vertical'

const searchAndBookmarkData = {
  mixins: [],
  computed: {
    searchAndBookmarkData() {
      // const sorted = _.sortBy(this.sellers, seller => seller.dadoSeller.nomeFantasia)
      // const sellers = sorted.map(seller => ({
      //   nomeFantasia: `${this.$options.filters.vueBrazil(seller.dadoSeller.identificacao, 'cnpj')} - ${seller.dadoSeller.nomeFantasia}`,
      //   tenant: seller.id,
      // }))
      return {
        pages: {
          key: 'title',
          title: 'Páginas',
          data: [
            ...getSearchableMenus(),
            // { title: 'Blog Detail', route: { name: 'pages-blog-detail', params: { id: 1 } }, icon: 'FileTextIcon', isBookmarked: false },
          ],
        },
        sellers: {
          key: 'nomeFantasia',
          data: [],
        },
      }
    },
  },
}

export default searchAndBookmarkData
