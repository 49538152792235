<template class="col-md-1">
  <div
    :class="`mr-1 ${labelContainerClass}`"
    style="display: inline"
  >
    <feather-icon
      v-if="localInstruction || localInstructionList.length"
      :id="target"
      icon="InfoIcon"
      size="15"
      class="cursor-pointer"
      style="margin-rigth: 8px; margin-bottom: 3px"
      :tabindex="tabIndex"
    />
    <label
      v-show="localLabel"
      :id="`${localFor}_label`"
      :for="localFor"
      class="formulate-input-label"
      style="display: inline"
    >
      {{ localLabel }}
    </label>
    <b-popover
      v-if="localInstruction || localInstructionList.length > 0"
      :target="target"
      triggers="focus"
      placement="top"
    >
      <template #title>
        <span>{{ localTitle }}</span>
      </template>
      <span>
        {{ localInstruction }}
        <ul>
          <li
            v-for="item in localInstructionList"
            :key="item.text"
          >
            <strong v-if="item.strong">
              {{ item.text }}
            </strong>
            <span v-else>
              {{ item.text }}
            </span>
          </li>
        </ul>
      </span>
    </b-popover>
  </div>
</template>

<script>
import { BPopover, VBPopover } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    /**
     * attributes: {
     *    label-container-class, // string de classes para o container do label
     * }
     */
    context: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: 'Instruções',
    },
    instruction: {
      type: String,
      default: null,
    },
    instructions: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    for: {
      type: String,
      default: null,
    },
    tabIndex: {
      type: String,
      default: '-1',
    },
  },
  computed: {
    localTitle() {
      return this.$t(this.title)
    },
    localFor() {
      return (
        this.for ||
        this.defaultId ||
        this.context?.id ||
        this.instruction ||
        this.instructions.join('_')
      )
    },
    target() {
      return `${this.localFor}_label`
    },
    localLabel() {
      return this.label || this.context?.label
    },
    localInstruction() {
      return this.instruction || this.context?.attributes.instruction
    },
    localInstructionList() {
      const list = this.instructions || this.context?.attributes.instructions || []
      return list.filter(i => !i.hide)
    },
    labelContainerClass() {
      return this.context?.attributes?.['label-container-class'] || ''
    },
  },
}
</script>
