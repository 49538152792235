import FormulateOskTextNumber from './FormulateOskTextNumber.vue'

export default function VueFormulateOskTextNumberPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateOskTextNumber,
    },
    library: {
      'osk-text-number': {
        classification: 'text',
        component: 'FormulateOskTextNumber',
      },
    },
  })
}
