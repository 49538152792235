export default {
  title: 'Bar',
  icon: 'CoffeeIcon', // featherIcons tem padrão 'NomeIcon'
  iconPack: 'feather',
  children: [
    {
      title: 'Gerenciar comandas',
      route: 'bar-tab-list',
      resource: 'BarTabConsumption',
      action: 'Read',
    },
    {
      title: 'Histórico de consumos',
      route: 'bar-tab-consumption-list',
      resource: 'BarTabConsumption',
      action: 'ReadHistory',
    },
  ],
}
