/* eslint-disable import/prefer-default-export */

import isElectron from 'is-electron'

export const getAppVersions = () => {
  let appVersion = { web: window.app_version }
  if (isElectron()) {
    const { echope, echopeAgent } = window.electronAPP.versions()
    appVersion = { app: echope, agent: echopeAgent }
  }

  return appVersion
}
