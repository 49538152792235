import FormulateDateRangePicker from './FormulateDateRangePicker.vue'

export default function VueFormulateDatepickerPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateDateRangePicker,
    },
    library: {
      'date-range-picker': {
        classification: 'text',
        component: 'FormulateDateRangePicker',
        slotProps: {
          component: ['clearable']
        }
      },
    },
  })
}
