var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "e-sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1",
    },
    [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c("feather-icon", {
        staticClass: "ml-1 cursor-pointer",
        attrs: { icon: "XIcon", size: "16" },
        on: { click: _vm.hide },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }