import states from './states'
import ncms from './ncms'
import cests from './cests'
import suppliers from './suppliers'
import clients from './clients'
import products from './products'
import reasons from './reasons'
import priceTables from './price-tables'
import managementAccounts from './management-accounts'

export default {
  namespaced: true,
  modules: {
    states,
    ncms,
    cests,
    suppliers,
    clients,
    products,
    reasons,
    priceTables,
    managementAccounts,
  },
  state: {},
  mutations: {},
  actions: {},
}
