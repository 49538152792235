import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'code',
    sortDesc: false,
  },
  cfops: [],
  filter: {
    code: '',
    description: '',
    movementType: null,
    cfopType: null,
    active: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {},
  mutations: {
    ...storePagination.mutations,

    SET_CFOPS(state, cfops) {
      state.cfops = cfops
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    }
  },
  actions: {
    ...storePagination.actions,

    async fetchCfops({ commit, state }) {
      const { code, description, movementType, cfopType, active } = state.filter

      const response = await axios.get('/api/tax/cfops', {
        params: {
          code,
          description,
          movementType,
          cfopType,
          active,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      commit('SET_CFOPS', response.data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: response.data.rowCount,
        rowsInCurrentPage: response.data.rowsInCurrentPage,
      })
    },

    resetFilter({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', getInitialState().filter)
    },
  },
}
