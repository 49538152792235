export default {
  title: 'Financeiro',
  icon: 'DollarSignIcon',
  // iconPack: 'bootstrap',
  children: [
    // {
    //   title: 'Fluxo de Caixa',
    //   route: 'purchase-estimate-list',
    //   resource: 'Auth', // 'PurchaseEstimate'
    //   action: 'read', // 'Read'
    // },
    // {
    //   title: 'Movimentação de Caixa',
    //   route: 'box-movimentation-list',
    //   resource: 'Auth', // 'Purchase'
    //   action: 'read', // 'Read'
    // },
    // {
    //   title: 'Conciliação Bancária',
    //   route: 'bank-reconciliation-list',
    //   resource: 'Auth', // 'Supplier'
    //   action: 'read', // 'Read'
    // },
    // {
    //   title: 'Pagamentos',
    //   route: 'purchase-returned-list',
    //   resource: 'Auth', // 'PurchaseReturn'
    //   action: 'read', // 'Read'
    // },
    // {
    //   title: 'Recebimentos',
    //   route: 'purchase-opened-list',
    //   resource: 'Auth', // 'Purchase'
    //   action: 'read', // 'Read'
    // },
    // {
    //   title: 'Cobranças',
    //   route: 'purchase-opened-list',
    //   resource: 'Auth', // 'Purchase'
    //   action: 'read', // 'Read'
    // },
    // {
    //   title: 'Faturamento',
    //   route: 'purchase-opened-list',
    //   resource: 'Auth', // 'Purchase'
    //   action: 'read', // 'Read'
    // },
    {
      title: 'Contas a Pagar',
      route: 'accounts-payable-list',
      resource: 'AccountsPayable', // 'BillsToPay'
      action: 'Read', // 'Read'
    },
    {
      title: 'Contas a Receber',
      route: 'receivables-list',
      resource: 'Receivable', // 'BillsToReceive'
      action: 'Read', // 'Read'
    },
    {
      title: 'Conta Corrente',
      icon: 'Safe',
      iconPack: 'bootstrap',
      action: 'Read',
      children: [
        {
          title: 'Cadastro',
          route: 'checking-account-list',
          resource: 'CheckingAccount',
          action: 'Read', // 'Read'
        },
        {
          title: 'Extrato',
          route: 'checking-account-entry-list',
          resource: 'CheckingAccountEntry',
          action: 'Read',
        },
        {
          title: 'Transferência',
          route: 'checking-account-transference-list',
          resource: 'CheckingAccountTransfer',
          action: 'Read',
        },
      ],
    },
    {
      title: 'Conciliação Getnet',
      route: 'reconciliation-getnet-list',
      resource: 'GetnetReconciliation',
      action: 'Read',
    },
    {
      title: 'Fechamento contábil',
      icon: 'calendarCheckFill',
      iconPack: 'bootstrap',
      children: [
        {
          title: 'Contas a pagar',
          route: 'period-close-account-payable-list',
          resource: 'AccountsPayablePeriodClose',
          action: 'Read',
        },
      ],
    },
  ],
}
