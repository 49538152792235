<template>
  <div class="global-keyboard">
    <div class="simple-keyboard" />
  </div>
</template>

<script>
import Keyboard from "simple-keyboard"
import "simple-keyboard/build/css/index.css"

export default {
  name: "SimpleKeyboard",

  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    type: {
      type: String,
      default: 'mobile',
      validator(value) {
        return ['numeric', 'numericDecimal', 'default', 'mobile'].includes(value)
      },
    }
  },

  data: () => ({
    keyboard: null
  }),

  mounted() {
    const keyboardConfig = {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      preventMouseDownDefault: true, // evita de tirar o foco do input qnd digitar no simple-keyboard
      ...this.getLayout()
    }

    this.keyboard = new Keyboard(keyboardConfig)
  },

  methods: {
    /** inputValue is a value in Keyboard instance, if you want update the value in instance need use this.keyboard.setInput() */
    onChange(inputValue) {
      this.$emit("input", inputValue)
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button)
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift()
      if (button === "{numbers}" || button === "{abc}") this.handleNumbers()
    },

    setInput(input) {
      this.keyboard.setInput(input)
    },

    handleShift() {
      const currentLayout = this.keyboard.options.layoutName
      const shiftToggle = currentLayout === "default" ? "shift" : "default"

      this.keyboard.setOptions({
        layoutName: shiftToggle
      })
    },
    handleNumbers() {
      const currentLayout = this.keyboard.options.layoutName
      const numbersToggle = currentLayout !== "numbers" ? "numbers" : "default"

      this.keyboard.setOptions({
        layoutName: numbersToggle
      })
    },

    getLayout() {
      if (this.type === 'numeric' || this.type === 'numericDecimal') {
        const decimalChar = this.type === 'numericDecimal' ? ' , ' : ' '
        return {
          theme: "hg-theme-default mobile-theme keyboardDefContainer",
          layout: {
            default: [
              "1 2 3",
              "4 5 6",
              "7 8 9",
              `{bksp} 0${decimalChar}`
            ]
          },
          display: {
            "{bksp}": "⌫",
            "{enter}": "Ok"
          }
        }
      }

      if (this.type === 'mobile') {
        return {
          mergeDisplay: true,
          theme: "hg-theme-default mobile-theme keyboardDefContainer",
          layout: {
            default: [
              "q w e r t y u i o p",
              "a s d f g h j k l",
              "{shift} . @ z x c v b n m {bksp}",
              "{numbers} {space}"
            ],
            shift: [
              "Q W E R T Y U I O P",
              "A S D F G H J K L",
              "{shift} Z X C V B N M {bksp}",
              "{numbers} {space}"
            ],
            numbers: [
              "1 2 3",
              "4 5 6",
              "7 8 9",
              "{abc} 0 {bksp}"
            ]
          },
          display: {
            "{numbers}": "123",
            "{enter}": "Ok",
            "{escape}": "esc ⎋",
            "{tab}": "tab ⇥",
            "{bksp}": "⌫",
            "{capslock}": "caps lock ⇪",
            "{shift}": "⇧",
            "{controlleft}": "ctrl ⌃",
            "{controlright}": "ctrl ⌃",
            "{altleft}": "alt ⌥",
            "{altright}": "alt ⌥",
            "{metaleft}": "cmd ⌘",
            "{metaright}": "cmd ⌘",
            "{abc}": "ABC"
          }
        }
      }

      return {}
    }
  }
}
</script>

<style scoped>
.global-keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 90000;
}
</style>
