const payBoxTypes = {
  data() {
    return {
      saleOriginEnum: {
        SALE: 'Sale',
        BLEEDING: 'Bleeding',
        ACCOUNTS_PAYABLE: 'AccountsPayable',
        RETURN_SALE: 'ReturnSale',
        FINAL_BLEEDING: 'FinalBleeding',
        OPENING: 'Opening',
        CLOSING: 'Closing',
      },
      operationTypeEnum: {
        CREDIT: 'Credit',
        DEBIT: 'Debit',
      },
      productInputMethodEnum: {
        SCANNED: 'Scanned',
        TYPED: 'Typed',
        SEARCHED: 'Searched',
      },

      uiPayBoxActionsEnum: {
        PRODUCT_ADD: 'ProductAdd',
        PRODUCT_UPDATE: 'ProductUpdate',
        PRODUCT_REMOVE: 'ProductRemove',
        PAYMENT_ADD: 'PaymentAdd',
        PAYMENT_REMOVE: 'PaymentRemove',
        SALE_ADD_DISCOUNT: 'SaleAddDiscount',
        SALE_UPDATE_DISCOUNT: 'SaleUpdateDiscount',
        CUSTOMER_CHANGE: 'CustomerChange',
        CUSTOMER_ADD: 'CustomerAdd',
      },

      uiPayBoxEventsEnum: {
        SALE_FINISH: 'SaleFinish',
      }
    }
  },

  computed: {
    saleOriginEnumLabel() {
      return {
        [this.saleOriginEnum.SALE]: this.$t('Venda'),
        [this.saleOriginEnum.BLEEDING]: this.$t('Sangrias realizadas'),
        [this.saleOriginEnum.ACCOUNTS_PAYABLE]: this.$t('Despesas'),
        [this.saleOriginEnum.RETURN_SALE]: this.$t('Devoluções'),
        [this.saleOriginEnum.FINAL_BLEEDING]: this.$t('Sangria final'),
        [this.saleOriginEnum.OPENING]: this.$t('Abertura'),
        [this.saleOriginEnum.CLOSING]: this.$t('Fechamento'),
      }
    },
    saleOriginOptions() {
      return [
        {
          label: this.$t('Venda'),
          value: this.saleOriginEnum.SALE,
        },
        {
          label: this.$t('Sangria'),
          value: this.saleOriginEnum.BLEEDING,
        },
        {
          label: this.$t('Despesas'),
          value: this.saleOriginEnum.ACCOUNTS_PAYABLE,
        },
        {
          label: this.$t('Devoluções'),
          value: this.saleOriginEnum.RETURN_SALE,
        },
        {
          label: this.$t('Abertura'),
          value: this.saleOriginEnum.OPENING,
        },
        {
          label: this.$t('Fechamento'),
          value: this.saleOriginEnum.CLOSING,
        },
      ]
    }
  },

  methods: {

  },
}

export default payBoxTypes
