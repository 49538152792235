var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-checkbox",
      style: _vm.cssProps,
      on: { click: _vm.handle },
    },
    [
      _c("div", { staticClass: "wrapper-checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model,
              expression: "model",
            },
          ],
          ref: "input",
          attrs: { type: "checkbox" },
          domProps: {
            value: _vm.inputValue,
            checked: Array.isArray(_vm.model)
              ? _vm._i(_vm.model, _vm.inputValue) > -1
              : _vm.model,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.model,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.inputValue,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.model = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.model = $$c
              }
            },
          },
        }),
        _c("label", [_vm._v(_vm._s(_vm.label))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }