var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-router-link", {
    attrs: {
      value: _vm.productName,
      header: "Cód.: " + _vm.skuId + " | Ean: " + _vm.ean,
      links: _vm.links,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }