import { invoiceDomains, sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import taxClassificationMaintain from './tax-classification-maintain'
import ProductWithoutTaxClassification from './product-without-tax-classification'

const getInitialState = () => ({
  ...storePagination.state(),
  taxClassifications: [],
  taxClassificationsInconsistencies: [],
  sorting: {
    sortBy: 'ncm',
    sortDesc: false,
  },
  sortInconcistencies: {
    sortBy: 'totalProducts',
    sortDesc: true,
  },
  filters: {
    ncm: '',
    originState: '',
    operationType: '',
    destinationState: '',
  },
  reportData: null,
  taxClassificationCombos: {
    cstIcms: [],
    operationTypes: [],
    csosnIcms: [],
    cfops: [],
    cfopsInput: [],
    cfopsOutput: [],
    cstIpiInput: [],
    cstIpiOutput: [],
    cstPisCofinsInput: [],
    cstPisCofinsOutput: [],
  },
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: { taxClassificationMaintain, ProductWithoutTaxClassification },
  getters: {
    getCfopNatureOperation(state) {
      return state.taxClassificationCombos.cfopsOutput.concat(
        state.taxClassificationCombos.cfopsInput
      )
    },
    getCstIcms(state) {
      return state.taxClassificationCombos.cstIcms
    },
    getCsosnIcms(state) {
      return state.taxClassificationCombos.csosnIcms
    },
    getComboOperationTypes(state) {
      return state.taxClassificationCombos.operationTypes
    },

    getComboAllCfop(state) {
      return state.taxClassificationCombos.cfops.map(c => ({
        ...c,
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
    getComboCfopInput(state) {
      return state.taxClassificationCombos.cfopsInput.map(c => ({
        ...c,
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
    getComboCfopOutput(state) {
      return state.taxClassificationCombos.cfopsOutput.map(c => ({
        ...c,
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
    getComboCfopNatureOperation(state) {
      return state.taxClassificationCombos.cfopsOutput
        .concat(state.taxClassificationCombos.cfopsInput)
        .map(c => ({
          ...c,
          label: `${c.code} - ${c.description}`,
          value: `${c.code} - ${c.description}`,
        }))
    },
    getComboCstIpiInput(state) {
      return state.taxClassificationCombos.cstIpiInput.map(c => ({
        ...c,
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
    getComboCstIpiOutput(state) {
      return state.taxClassificationCombos.cstIpiOutput.map(c => ({
        ...c,
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
    getComboCstPisCofinsInput(state) {
      return state.taxClassificationCombos.cstPisCofinsInput.map(c => ({
        ...c,
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
    getComboCstPisCofinsOutput(state) {
      return state.taxClassificationCombos.cstPisCofinsOutput.map(c => ({
        ...c,
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_TAX_CLASSIFICATIONS(state, taxClassifications) {
      state.taxClassifications = taxClassifications
    },

    SET_TAX_CLASSIFICATIONS_INCONSISTENCIES(state, taxClassificationsInconsistencies) {
      state.taxClassificationsInconsistencies = taxClassificationsInconsistencies.map(i => ({
        ...i,
        validations: JSON.parse(i.validations).map(v => v.Description),
      }))
    },

    SET_FILTER(state, filters) {
      state.filters = filters
    },

    SET_TAX_CLASSIFICATION_COMBOS(state, { name, data }) {
      state.taxClassificationCombos = {
        ...state.taxClassificationCombos,
        [name]: data,
      }
    },

    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },

    CLEAN_TAX_CLASSIFICATION_COMBO(state, comboName) {
      state.taxClassificationCombos[comboName] = []
    },

    CLEAN_STATE(state) {
      const {
        filter,
        paging,
        taxClassifications,
        taxClassificationCombos,
        taxClassificationsInconsistencies,
      } = getInitialState()
      state.filter = filter
      state.taxClassifications = taxClassifications
      state.paging = paging
      state.taxClassificationCombos = taxClassificationCombos
      state.taxClassificationsInconsistencies = taxClassificationsInconsistencies
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchTaxClassifications({ commit, state }) {
      const response = await axios.get('/api/tax/tax-classifications', {
        params: {
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          ...state.filters,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      commit('SET_TAX_CLASSIFICATIONS', response.data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: response.data.rowCount,
        rowsInCurrentPage: response.data.rowsInCurrentPage,
      })
    },

    async fetchTaxClassificationsInconsistencies({ commit, state }) {
      const response = await axios.get('/api/tax/tax-classifications-inconsistencies', {
        params: {
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          ...state.filters,
          sortBy: sorting.methods.getSorting({
            sorting: state.sortInconcistencies,
          }),
        },
      })
      commit('SET_TAX_CLASSIFICATIONS_INCONSISTENCIES', response.data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: response.data.rowCount,
        rowsInCurrentPage: response.data.rowsInCurrentPage,
      })
    },

    async fetchTaxClassificationCombos({ commit, state }) {
      const { movementTypeEnum } = invoiceDomains.data()
      const { cfopsInput, cfopsOutput, cfops } = state.taxClassificationCombos

      if (state.taxClassificationCombos.cstIcms.length === 0) {
        const { data: cstIcmsData } = await axios.get('/api/tax/tax-codes?taxType=ICMS', {
          params: { pageSize: 0, pageIndex: 0 },
        })
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'cstIcms',
          data: cstIcmsData.results || [],
        })
      }

      if (state.taxClassificationCombos.csosnIcms.length === 0) {
        const { data: csosnIcmsData } = await axios.get('/api/tax/tax-codes?taxType=ICMS_SN', {
          params: { pageSize: 0, pageIndex: 0 },
        })
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'csosnIcms',
          data: csosnIcmsData.results || [],
        })
      }

      if (state.taxClassificationCombos.operationTypes.length === 0) {
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'operationTypes',
          data:
            [
              { label: 'Interna', value: 'INTERNAL' },
              { label: 'Interestadual', value: 'INTERSTATE' },
              { label: 'Exterior', value: 'EXTERIOR' },
            ] || [],
        })
      }

      if (cfopsInput?.length === 0 || cfopsOutput?.length === 0 || cfops?.length === 0) {
        const { data: cfopsData } = await axios.get('/api/tax/cfops', {
          params: { pageSize: 0, pageIndex: 0 },
        })

        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'cfopsInput',
          data:
            cfopsData?.results.filter(cfop => cfop.movementType === movementTypeEnum.INPUT && cfop.active === true) || [],
        })
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'cfopsOutput',
          data:
            cfopsData?.results.filter(cfop => cfop.movementType === movementTypeEnum.OUTPUT && cfop.active === true) ||
            [],
        })
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'cfops',
          data: cfopsData?.results || [],
        })
      }

      if (state.taxClassificationCombos.cstIpiInput.length === 0) {
        const { data: cstIpiInputData } = await axios.get(
          '/api/tax/tax-codes?taxType=IPI&movementType=INPUT',
          {
            params: { pageSize: 0, pageIndex: 0 },
          }
        )
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'cstIpiInput',
          data: cstIpiInputData.results || [],
        })
      }

      if (state.taxClassificationCombos.cstIpiOutput.length === 0) {
        const { data: cstIpiOutputData } = await axios.get(
          '/api/tax/tax-codes?taxType=IPI&movementType=OUTPUT',
          {
            params: { pageSize: 0, pageIndex: 0 },
          }
        )
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'cstIpiOutput',
          data: cstIpiOutputData.results || [],
        })
      }

      if (state.taxClassificationCombos.cstPisCofinsInput.length === 0) {
        const { data: cstPisCofinsInputData } = await axios.get(
          '/api/tax/tax-codes?taxType=PIS_COFINS&movementType=INPUT',
          {
            params: { pageSize: 0, pageIndex: 0 },
          }
        )
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'cstPisCofinsInput',
          data: cstPisCofinsInputData.results || [],
        })
      }

      if (state.taxClassificationCombos.cstPisCofinsOutput.length === 0) {
        const { data: cstPisCofinsOutputData } = await axios.get(
          '/api/tax/tax-codes?taxType=PIS_COFINS&movementType=OUTPUT',
          {
            params: { pageSize: 0, pageIndex: 0 },
          }
        )
        commit('SET_TAX_CLASSIFICATION_COMBOS', {
          name: 'cstPisCofinsOutput',
          data: cstPisCofinsOutputData.results || [],
        })
      }
    },

    async generateReport({ state, commit }) {
      const { ncm, originState, operationType, destinationState } = state.filters

      const params = {
        ncm,
        originState,
        operationType,
        destinationState,
        sortBy: 'CreatedDate ASC',
        pageSize: 0,
        pageIndex: 0,
      }

      const { data } = await axios.get('/api/tax/tax-classifications/download', {
        params,
        responseType: 'blob',
      })
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      commit('SET_REPORT_DATA', url)
    },

    setFilter({ commit }, filter) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', filter)
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTER', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    // comboName propertie of productCombos in this getInitialState
    cleanCombo({ commit }, comboName) {
      commit('CLEAN_TAX_CLASSIFICATION_COMBO', comboName)
    },
  },
}
