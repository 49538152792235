import { telephoneTypes } from "../dominio"

const contactUtils = {
  getPricipalMobileAndLandline(telephoneList) {
    const { LANDLINE, MOBILE } = telephoneTypes.computed.telephoneTypeEnum()

    if (telephoneList && telephoneList?.length > 0) {
      return {
        cellphone: telephoneList.find(t => t.type === MOBILE)?.number || null,
        phone: telephoneList.find(t => t.type === LANDLINE)?.number || null,
      }
    }
    return {
      cellphone: null,
      phone: null,
    }
  }
}

export default contactUtils
