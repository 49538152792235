var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "input",
    _vm._b(
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.context.model,
            expression: "context.model",
          },
        ],
        class: _vm.context.attributes.class,
        attrs: {
          type: "time",
          "no-close-button": "",
          locale: "pt-br",
          locate: _vm.locate,
        },
        domProps: { value: _vm.context.model },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.context, "model", $event.target.value)
          },
        },
      },
      "input",
      Object.assign({}, _vm.context.attributes, _vm.labels[_vm.locate] || {}),
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }