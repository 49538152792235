var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-numeric",
    _vm._b(
      {
        attrs: {
          "input-class": _vm.context.attributes.class,
          "data-type": _vm.context.type,
          "decimal-separator": _vm.decimalSeparator,
          separator: _vm.separator,
        },
        on: { blur: _vm.onChanceValue, focus: _vm.onFocus },
        model: {
          value: _vm.valueTemp,
          callback: function ($$v) {
            _vm.valueTemp = $$v
          },
          expression: "valueTemp",
        },
      },
      "vue-numeric",
      _vm.context.attributes,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }