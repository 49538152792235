import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  brands: [],
  filters: { name: null, manufacturerId: null },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_BRANDS(state, brands) {
      state.brands = brands
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, brands } = getInitialState()
      state.filters = filters
      state.brands = brands
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchBrands({ commit, state }) {
      const { data } = await axios.get('/api/brands', {
        params: {
          name: state.filters.name,
          manufacturerId: state.filters.manufacturerId,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_BRANDS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
