import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import { elapsedTime, durationTime } from '@/utils/dateUtils'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'createdDate',
    sortDesc: true,
  },
  approvalFlows: [],
  serviceRequest: null,
  filters: {
    status: true,
    entityId: null,
    type: 'ServiceRequest',
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {

  },

  mutations: {
    ...storePagination.mutations,

    SET_SERVICE_REQUEST(state, serviceRequest) {
      state.serviceRequest = serviceRequest
    },
    SET_APPROVAL_FLOWS(state, approvalFlows) {
      state.approvalFlows = approvalFlows
    },
    SET_FILTER(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { serviceRequest, approvalFlow, filters } = getInitialState()
      state.serviceRequest = serviceRequest
      state.approvalFlow = approvalFlow
      state.filters = filters
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchApprovalFlows({ commit, state }, serviceRequestId) {
      const { data } = await axios.get('/api/approval-flows/transactions', {
        params: {
          ...state.filters,
          entityId: serviceRequestId,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      if (data.results) {
        data.results.forEach(approvalFlow => {
          approvalFlow.steps.forEach(step => {
            const stepCopy = { ...step }
            if (step.approvalDate) {
              stepCopy.elapsedTimeFormat = elapsedTime(stepCopy.approvalDate, '', true, false)
              stepCopy.durationTimeFormat = durationTime(stepCopy.startDate, stepCopy.approvalDate, '')
            } else {
              // stepCopy.elapsedTimeFormat = `Pendente à ${elapsedTime(stepCopy.startDate, '', false, false)}`
            }
            Object.assign(step, stepCopy)
          })
        })
      }
      commit('SET_APPROVAL_FLOWS', data.results || [])
    },

    async fetchServiceRequest({ commit }, id) {
      const { data } = await axios.get(`/api/service/requests/${id}`)
      commit('SET_SERVICE_REQUEST', data)
    },

    setServiceRequest({ commit }, serviceRequest) {
      commit('SET_SERVICE_REQUEST', serviceRequest)
    },

    resetFilters({ commit }) {
      commit('SET_FILTER', getInitialState().filters)
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
