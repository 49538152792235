/* eslint-disable eqeqeq */
import i18n from '@/libs/i18n'
import { financialDomain, storePagination, stringUtils, uploader } from '@/mixins'
import axios from '@axios'

const getInitialXlsxData = () => ({
  storeId: null,
  statementDate: null,
  dueDate: null,
  supplierDocument: null,
  documentType: null,
  document: null,
  description: null,
  managementAccountName: null,
  paymentMethodName: null,
  value: null,
  comments: null,
  installmentComment: null,
})

const getInitialAccPayImport = () => ({
  storeId: null,
  statementDate: null,
  dueDate: null,
  supplierId: null,
  documentType: null,
  document: null,
  description: null,
  managementAccountId: null,
  paymentMethodId: null,
  value: null,
  comments: null,
  installmentComment: null,
  recordType: null,
  errorMessages: [],
  xlsxData: getInitialXlsxData(),

  // layout info
  hasErrors: false,
  xlsxErrors: {},
  defaultSupplierOptions: [],
  _showDetails: false,
})

const getInitialState = () => ({
  ...storePagination.state(),
  filters: {
    value: '',
    supplierId: null,
    paymentMethodId: null,
    managementAccountId: null,
    storeId: null,
    periodType: financialDomain.data().payablePeriodTypeEnum.CREATED_DATE,
    period: {
      startDate: null,
      endDate: null,
    },

    onlyErrors: false,
  },
  accountsPayableImported: [],
  accountsPayableImportedFiltered: [],
  xlsxImportedFile: [],
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getAccountsPayable(state) {
      return state.accountsPayableImportedFiltered
    },

    getItemsSummary(state) {
      return {
        totalItems: state.accountsPayableImported?.reduce((total, i) => total + i.value, 0) || 0,
        totalErrors: state.accountsPayableImported?.filter(i => i.hasErrors)?.length || 0,
      }
    },

    isValidToSave(state) {
      return !state.accountsPayableImported.some(accPay => accPay.hasErrors)
    }
  },

  mutations: {
    ...storePagination.mutations,

    SET_XLSX_IMPORTED_FILE(state, file) {
      state.xlsxImportedFile = file
    },
    SET_ACCOUNTS_PAYABLE_IMPORTED(state, payload) {
      state.accountsPayableImported = payload
    },
    SET_ACCOUNTS_PAYABLE_IMPORTED_FILTERED(state, payload) {
      state.accountsPayableImportedFiltered = payload
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    ...storePagination.actions,

    setXlsxImportedFile({ commit }, file) {
      commit('SET_XLSX_IMPORTED_FILE', file)
    },

    async sendXlsxAccountPayable({ commit, dispatch }, { file }) {
      const itemDefault = getInitialAccPayImport()
      const xlsxItemDefault = getInitialXlsxData()

      const result = await uploader.methods.uploadCustom({
        file,
        apiUrl: '/api/accounting/accounts-payable-installments/read-spreadsheet',
        fileParamName: 'file'
      })
      const { getStringOrNull } = stringUtils.methods

      const formatXlsxData = data => ({
        ...xlsxItemDefault,
        ...data,
      })

      const resultFormated = result.map(r => {
        const xlsxErrors = {}

        if (Array.isArray(r.errorMessages) && r.errorMessages?.length > 0) {
          r.errorMessages.forEach(errorItem => {
            xlsxErrors[errorItem?.field] = errorItem.messages?.map(msg => i18n.t(msg))
          })
        }

        return {
          ...itemDefault,
          ...r,
          hasErrors: r.errorMessages?.length > 0,
          storeId: getStringOrNull(r.storeId),
          managementAccountId: getStringOrNull(r.managementAccountId),
          paymentMethodId: getStringOrNull(r.paymentMethodId),
          supplierId: getStringOrNull(r.supplierId),
          defaultSupplierOptions: r.supplier ? [r.supplier] : [],
          xlsxData: formatXlsxData(r.xlsxData),
          xlsxErrors,
        }
      })

      commit('SET_ACCOUNTS_PAYABLE_IMPORTED', resultFormated)
      dispatch('updatePaging')
    },
    async exampleXlsxDownload() {
      const { data } = await axios.get(
        '/api/accounting/accounts-payable-installments/download/import-example',
        {
          responseType: 'blob',
        }
      )
      return data
    },

    async saveBulkImport({ state }) {
      const accountsPayable = state.accountsPayableImported.map(i => ({
        storeId: i.storeId,
        statementDate: i.statementDate,
        dueDate: i.dueDate,
        supplierId: i.supplierId,
        documentType: i.documentType,
        document: i.document,
        description: i.description,
        managementAccountId: i.managementAccountId,
        paymentMethodId: i.paymentMethodId,
        value: i.value,
        comments: i.comments,
        installmentComment: i.installmentComment,
        recordType: i.recordType,
      }))

      await axios.post(
        '/api/accounting/accounts-payable-installments/bulk',
        { accountsPayable }
      )
    },

    filterAccountsPayable({ state, commit, dispatch }) {
      const {
        supplierId,
        storeId,
        onlyErrors,
        value,
        paymentMethodId,
        managementAccountId,
      } = state.filters
      const filtered = state.accountsPayableImported.filter(a => {
        let result = true
        if (value != '') result &&= a.value == value
        if (onlyErrors === true) result &&= a.hasErrors === true

        if (managementAccountId != null) result &&= a.managementAccountId == managementAccountId
        if (paymentMethodId != null) result &&= a.paymentMethodId == paymentMethodId
        if (supplierId != null) result &&= a.supplierId == supplierId
        if (storeId != null) result &&= a.storeId == storeId

        return result
      })
      commit('SET_ACCOUNTS_PAYABLE_IMPORTED_FILTERED', filtered)
      dispatch('updatePaging')
    },
    resetFilters({ state, commit, dispatch }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
      commit('SET_ACCOUNTS_PAYABLE_IMPORTED_FILTERED', state.accountsPayableImported)
      dispatch('updatePaging')
    },
    updatePaging({ state, commit, getters }) {
      const rowCount = getters.getAccountsPayable.length || 0
      const isLastPage = state.paging.currentPage === Math.ceil(rowCount / state.paging.pageSize)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount,
        rowsInCurrentPage: isLastPage ? rowCount % state.paging.pageSize : state.paging.pageSize,
      })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
