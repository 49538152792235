import { stringUtils } from '@/mixins'

const sorting = {
  data() {
    return {
      sorting: {
        sortBy: 'id',
        sortDesc: true,
      },
    }
  },
  methods: {
    getSorting(state) {
      const { sortBy, sortDesc } = state?.sorting
      let sort = stringUtils.methods.getStringWithFirstLetterUpper(state?.sorting?.sortBy, ['.'], '.')
      if (sortBy) {
        sort = `${sort} ${sortDesc ? 'DESC' : 'ASC'}`
      }
      return sort
    },
  },
}

export default sorting
