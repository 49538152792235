import axios from '@axios'
import { sorting, storePagination } from "@/mixins"

const getInitialState = () => ({
  ...storePagination.state(),
  fiscalFiles: [],
  selectedFiles: [],
  filters: {
    storeId: null,
    competence: '',
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  mutations: {
    ...storePagination.mutations,

    SET_FISCAL_FILES(state, fiscalFiles) {
      state.fiscalFiles = fiscalFiles
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_SELECTED_FILES(state, selectedFiles) {
      state.selectedFiles = selectedFiles
    },

    CLEAN_STATE(state) {
      const initial = getInitialState()
      Object.keys(initial).forEach(key => {
        state[key] = initial[key]
      })
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchFiscalFiles({ commit, state }) {
      const {
        storeId,
        competence,
      } = state.filters

      const { data } = await axios.get('/api/invoices/competence-files', {
        params: {
          storeId,
          competenceDate: competence,
          sortBy: sorting.methods.getSorting(state),
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
      })

      const dataFormated = data.results.map(fiscalFile => ({
        ...fiscalFile,
        rowChecked: state.selectedFiles.some(selected => selected.id === fiscalFile.id),
      }))

      commit('SET_FISCAL_FILES', dataFormated || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    addSelectedFiles({ commit, state }, item) {
      const newSelectedFiles = [...state.selectedFiles, { ...item }]
      commit('SET_SELECTED_FILES', newSelectedFiles)
    },
    removeSelectedFiles({ commit, state }, item) {
      const newList = [...state.selectedFiles]
      const indexFound = newList.findIndex(selected => selected.id === item.id)
      newList.splice(indexFound, 1)
      commit('SET_SELECTED_FILES', newList)
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanSelectedFiles({ commit }) {
      commit('SET_SELECTED_FILES', [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
