var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReadOnly
    ? _c(
        "FormulateInput",
        _vm._b(
          {
            class: _vm.showLabel && _vm.required ? "required" : "",
            attrs: {
              type: "label",
              label: _vm.showLabel ? _vm.label : "",
              validation: _vm.required ? "required" : "",
              options: _vm.localOptions,
            },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          },
          "FormulateInput",
          _vm.$attrs,
          false
        )
      )
    : _c(
        "FormulateInput",
        _vm._b(
          {
            ref: "select",
            class: _vm.showLabel && _vm.required ? "required" : "",
            attrs: {
              type: "select-searchable",
              label: _vm.showLabel ? _vm.label : "",
              placeholder: _vm.$t("Digite para pesquisar"),
              options: _vm.localOptions,
              validation: _vm.required ? "required" : "",
              instruction: _vm.showLabel
                ? _vm.$t("Pesquise pelo login, nome ou documento do usuário")
                : "",
            },
            on: { fetchSearch: _vm.onSearch, input: _vm.handleInput },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          },
          "FormulateInput",
          _vm.$attrs,
          false
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }