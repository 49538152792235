var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-checkbox",
        _vm._b(
          {
            attrs: {
              "input-class": _vm.context.attributes.class,
              "data-type": _vm.context.type,
            },
            on: {
              blur: _vm.context.blurHandler,
              input: _vm.onInput,
              change: _vm.change,
            },
            model: {
              value: _vm.context.model,
              callback: function ($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model",
            },
          },
          "b-form-checkbox",
          _vm.context.attributes,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }