const indicatorIETypes = {
  data() {
    return {
      indicatorIETypesEnum: {
        ICMS_TAXPAYER: 'IcmsTaxpayer',
        EXEMPT_REGISTRATION: 'ExemptRegistration',
        NO_TAXPAYER: 'NoTaxpayer',
      }
    }
  },

  methods: {
    indicatorIETypes() {
      return [
        { value: 'IcmsTaxpayer', label: 'Contribuinte do ICMS', },
        { value: 'ExemptRegistration', label: 'Isento de Inscrição', },
        { value: 'NoTaxpayer', label: 'Não Contribuinte', },
      ]
    },
  },
}

export default indicatorIETypes
