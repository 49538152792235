import FormulateSlider from './FormulateSlider.vue'

export default function VueFormulateSliderPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateSlider,
    },
    library: {
      slider: {
        classification: 'text',
        component: 'FormulateSlider',
      },
    },
  })
}
