import reconciliationPedingItens from './reconciliation-peding-itens'

export default {
  namespaced: true,
  modules: {
    reconciliationPedingItens,
  },
  state: {},
  mutations: {},
  actions: {},
}
