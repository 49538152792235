import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import { roundDecimal } from '@/utils/number-utils'

const getInitialState = () => ({
  ...storePagination.state(),
  storeId: null,
  beerTaps: [],
  barrels: [],
  filters: {
    storeId: null,
  },
})

export default {
  namespaced: true,
  state: {
    ...getInitialState(),
  },
  getters: {
    // storeId(state, getters, rootState) {
    //   const store = rootState.app.stores.find(storeItem => storeItem.type === 'Kiosk')
    //   return store?.id
    // },
  },

  mutations: {
    ...storePagination.mutations,

    SET_BEER_TAPS(state, beerTaps) {
      state.beerTaps = beerTaps
    },
    SET_BARRELS(state, barrels) {
      state.barrels = barrels
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, sorting: sortingData } = getInitialState()
      state.filters = filters
      state.sorting = sortingData
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchBeerTaps({ commit, state }, storeId) {
      // const store = rootState.app.stores.find(storeItem => storeItem.type === 'Kiosk')
      if (!storeId && !state.filters.storeId) {
        commit('SET_BEER_TAPS', [])
        return
      }
      const response = await axios.get(
        `/api/palmbeer/stores/${storeId || state.filters.storeId}/beer-taps`
      )
      response.data.forEach(beerTap => {
        // eslint-disable-next-line no-param-reassign
        beerTap.series = [
          beerTap.barrelInstalled?.capacity
            ? roundDecimal((beerTap.remainingLiquid / beerTap.barrelInstalled?.capacity) * 100, 0)
            : 0,
        ]
      })
      commit('SET_BEER_TAPS', response.data)
      if (storeId) {
        commit('SET_FILTERS', { storeId })
      }
    },
    async fetchBarrels({ commit, state }) {
      // const store = rootState.app.stores.find(storeItem => storeItem.type === 'Kiosk')
      if (!state.filters.storeId) {
        commit('SET_BARRELS', [])
        return
      }
      const { data } = await axios.get(`/api/palmbeer/stores/${state.filters.storeId}/barrels`, {
        params: {
          name: state.filters.name,
          remainingLiquid: 2000,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      commit('SET_BARRELS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    setStoreFilter({ commit, state }, storeId) {
      commit('SET_FILTERS', { ...state.filters, storeId })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
