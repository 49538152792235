<template>
  <div style="display: flex">
    <slot name="before" />
    <v-select
      ref="select"
      v-model="context.model"
      v-bind="context.attributes"
      :data-type="context.type"
      :reduce="(x) => x.value"
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :options="context.options"
      label="label"
      style="width: 100%"
      :filterable="false"
      @close="onClose"
      @search="onSearch"
    >
      <template #no-options>
        {{ noOptionsMessage }}
      </template>
    </v-select>
    <slot name="after" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      searchPerformed: false,
    }
  },

  computed: {
    // model() {
    //   return this.context.model
    // },
    noOptionsMessage() {
      return this.searchPerformed
        ? this.$t('Nenhuma opção disponível')
        : this.$t(
            `Digite ${
              this.minSearchLength ? `ao menos ${this.minSearchLength + 1} caracteres` : ''
            } para pesquisar`
          )
    },
    defaultValue() {
      if (!this.context || !this.context.attributes) return null
      return this.context.attributes.defaultValue || this.context.attributes['default-value']
    },
    startSearch() {
      if (!this.context || !this.context.attributes) return null
      return this.context.attributes.startSearch || this.context.attributes['start-search']
    },
    minSearchLength() {
      if (!this.context || !this.context.attributes) return null
      const minSearchLength =
        this.context.attributes.minSearchLength || this.context.attributes['min-search-length']
      return minSearchLength !== undefined ? minSearchLength : 2
    },
  },

  watch: {
    // model() {
    //   if (this.context.model && !this.context.options?.length) {
    //     this.searchToCombo(() => {}, this.context.model, this)
    //   }
    // },
    defaultValue(val) {
      if (!this.context.options.length && val) {
        this.context.options.push(val)
        // this.searchToCombo(() => {}, val.label, this)
      }
    },
    startSearch(val) {
      if (!this.context.options.length && val) {
        this.searchToCombo(() => {}, val, this)
      }
    },
  },

  methods: {
    onClose() {
      this.context.blurHandler()
    },

    onSearch(search, loading) {
      if (search.length > this.minSearchLength) {
        setTimeout(() => {
          this.searchPerformed = true
        }, 1000)
        this.searchToCombo(loading, search, this)
      }
    },

    searchToCombo: _.debounce((loading, search, vm) => {
      vm.context.rootEmit('fetchSearch', loading, search, vm)
    }, 500),
  },
}
</script>
