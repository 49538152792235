import axios from '@axios'
import { storePagination } from '@/mixins/store'
import moment from 'moment'
import { sorting } from '@/mixins'
import stockInventoryMaintain from './stock-inventory-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  stockInventories: [],
  filters: {
    inventoryId: null,
    storeId: null,
    brandId: null,
    groupId: null,
    subgroupId: null,
    departmentId: null,
    status: null,
    period: {
      startDate: moment().subtract(7, 'day').startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
    productClassifications: [],
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { stockInventoryMaintain },

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_STOCK_INVENTORIES(state, stockInventories) {
      state.stockInventories = stockInventories
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, stockInventories } = getInitialState()
      state.filters = filters
      state.stockInventories = stockInventories
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchStockInventories({ commit, state }) {
      const { data } = await axios.get('/api/stock-inventories', {
        params: {
          inventoryId: state.filters.inventoryId,
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          status: state.filters.status,
          from: state.filters.period.startDate,
          to: state.filters.period.endDate,
          productClassifications: state.filters.productClassifications,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_STOCK_INVENTORIES', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async generateReport({ state }) {
      const { data } = await axios.get(`/api/stock-inventories/download`, {
        params: {
          skuId: state.filters.skuId,
          ean: state.filters.ean,
          name: state.filters.name,
          brandId: state.filters.brand,
          groupId: state.filters.group,
          subgroupId: state.filters.subgroup,
          departmentId: state.filters.department,
          pageSize: 0,
          pageIndex: 0,
          sortBy: sorting.methods.getSorting(state),
          inventoryId: state.filters.inventoryId,
          storeId: state.filters.storeId,
          status: state.filters.status,
          from: state.filters.period.startDate,
          to: state.filters.period.endDate,
          productClassifications: state.filters.productClassifications,
        },
        responseType: 'blob',
      })
      return data
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('CLEAN_FILTERS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
