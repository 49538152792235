var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-status-badge", {
    attrs: {
      status: _vm.context.model === true,
      type: _vm.badgeType,
      "true-label": _vm.trueLabel,
      "false-label": _vm.falseLabel,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }