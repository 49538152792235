<template>
  <div>
    <span>{{ `${$t('Mostrando de')} ${from + 1} ${$t('até')} ${to} ${$t('de')} ${of}` }}</span>
  </div>
</template>

<script>
export default {
  props: {
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalOnPage: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    from() {
      return (this.currentPage - 1) * this.perPage
    },
    to() {
      return this.from + this.totalOnPage > this.total ? this.total : this.from + this.totalOnPage
    },
    of() {
      return this.total
    },
  },
}
</script>
