var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.containerClass || "mr-1",
      staticStyle: { display: "inline" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("feather-icon", {
        staticClass: "cursor-pointer",
        attrs: { id: _vm.localTarget, icon: "InfoIcon", size: "15" },
      }),
      _c(
        "b-popover",
        {
          attrs: {
            target: _vm.localTarget,
            triggers: "focus",
            placement: "top",
            boundary: "window",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("span", [_vm._v(_vm._s(_vm.localTitle))])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("span", [
            _vm._v(" " + _vm._s(_vm.instruction) + " "),
            _c(
              "ul",
              _vm._l(_vm.instructions, function (item) {
                return _c("li", { key: item.text }, [
                  item.strong
                    ? _c("strong", [_vm._v(" " + _vm._s(item.text) + " ")])
                    : _c("span", [_vm._v(" " + _vm._s(item.text) + " ")]),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }