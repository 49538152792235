var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-numeric",
        _vm._b(
          {
            attrs: {
              "input-class": _vm.context.attributes.class,
              "data-type": _vm.context.type,
              "decimal-separator": _vm.decimalSeparator,
              "thousand-separator": _vm.thousandSeparator,
              separator: _vm.separator,
            },
            on: { blur: _vm.onBlur, focus: _vm.onFocus },
            model: {
              value: _vm.context.model,
              callback: function ($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model",
            },
          },
          "vue-numeric",
          _vm.context.attributes,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }