import getnet from './getnet'

export default {
  namespaced: true,
  modules: {
    getnet,
  },
  getters: {},
  state: {},
  mutations: {},
  actions: {},
}
