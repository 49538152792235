import axios from '@axios'

const getInitialState = () => ({
  states: [],
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {},
  getters: {
    getComboStates(state) {
      return state.states.map(s => ({
        ...s,
        label: s.abreviation,
        value: s.abreviation,
      }))
    },
    getComboStatesIdValue(state) {
      return state.states.map(s => ({
        ...s,
        label: `${s.name} (${s.abreviation})`,
        value: s.idIbge,
      }))
    },
    getComboStatesWithAll(state) {
      return [
        {
          label: 'Todos',
          value: 'Todos',
        },
        ...state.states.map(s => ({
          ...s,
          label: s.abreviation,
          value: s.abreviation,
        })),
      ]
    },
  },

  mutations: {
    SET_STATES(state, states) {
      state.states = states
    },

    CLEAN_STATE(state) {
      const { states } = getInitialState()
      state.states = states
    },
  },

  actions: {
    async fetchStates({ commit, state }) {
      if (state.states.length === 0) {
        const { data: statesData } = await axios.get('/api/states', {
          params: { pageSize: 0, pageIndex: 0 }
        })
        commit('SET_STATES', statesData.results || [])
      }
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
