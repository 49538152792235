const barTabStatusEnum = {
  OPENED: 'Opened',
  CLOSED: 'Closed',
}
const barTabConsumptionStatusEnum = {
  IN_PROGRESS: 'InProgress',
  START_CLOSE: 'StartClose',
  CLOSED: 'Closed',
}
const barTabConsumptionEventTypeEnum = {
  OPENING: 'Opening',
  REQUEST_CLOSING: 'RequestClosing',
  PAYMENT_CONFIRMED: 'PaymentConfirmed',
  START_CLOSING: 'StartClosing',
  CLOSING: 'Closing',
  CANCELED: 'Canceled',
}

const barDomain = {
  data() {
    return {
      barTabStatusEnum,
      barTabConsumptionStatusEnum,
      barTabConsumptionEventTypeEnum,
    }
  },

  computed: {
    barTabStatusLabel() {
      return {
        [barTabStatusEnum.OPENED]: this.$t('Aberto'),
        [barTabStatusEnum.CLOSED]: this.$t('Fechado'),
      }
    },
    barTabConsumptionStatusLabel() {
      return {
        [barTabConsumptionStatusEnum.IN_PROGRESS]: this.$t('Em Progresso'),
        [barTabConsumptionStatusEnum.START_CLOSE]: this.$t('Iniciado fechamento'),
        [barTabConsumptionStatusEnum.CLOSED]: this.$t('Fechado'),
      }
    },
    barTabConsumptionEventTypeLabel() {
      return {
        [barTabConsumptionEventTypeEnum.OPENING]: this.$t('Abertura'),
        [barTabConsumptionEventTypeEnum.REQUEST_CLOSING]: this.$t('Fechamento solicitado'),
        [barTabConsumptionEventTypeEnum.PAYMENT_CONFIRMED]: this.$t('Pagamento confirmado'),
        [barTabConsumptionEventTypeEnum.START_CLOSING]: this.$t('Fechamento iniciado'),
        [barTabConsumptionEventTypeEnum.CLOSING]: this.$t('Fechamento finalizado'),
        [barTabConsumptionEventTypeEnum.CANCELED]: this.$t('Cancelada'),
      }
    },
  },

  methods: {
    barTabStatusOptions() {
      return [
        { value: barTabStatusEnum.OPENED, label: this.$t('Aberto'), classStyle: 'success' },
        { value: barTabStatusEnum.CLOSED, label: this.$t('Fechado'), classStyle: 'danger' },
      ]
    },
    barTabConsumptionStatusOptions() {
      return [
        {
          value: barTabConsumptionStatusEnum.IN_PROGRESS,
          label: this.$t('Em Progresso'),
          classStyle: 'info',
        },
        {
          value: barTabConsumptionStatusEnum.START_CLOSE,
          label: this.$t('Fechamento iniciado'),
          classStyle: 'warning',
        },
        {
          value: barTabConsumptionStatusEnum.CLOSED,
          label: this.$t('Fechado'),
          classStyle: 'danger',
        },
      ]
    },
    barTabConsumptionStatusWithoutCloseOptions() {
      return [
        {
          value: barTabConsumptionStatusEnum.IN_PROGRESS,
          label: this.$t('Em Progresso'),
          classStyle: 'info',
        },
        {
          value: barTabConsumptionStatusEnum.START_CLOSE,
          label: this.$t('Fechamento iniciado'),
          classStyle: 'warning',
        },
      ]
    },
  },
}

export default barDomain
