export default {
  namespaced: true,
  state: {
    isVisible: false,
    currentInputValue: '',
    activeElement: null,
    layoutType: 'mobile',
  },
  mutations: {
    setVisibility(state, isVisible) {
      state.isVisible = isVisible
    },
    setCurrentInputValue(state, value) {
      state.currentInputValue = value
    },
    setActiveElement(state, element) {
      state.activeElement = element
    },
    setLayoutType(state, layoutType) {
      state.layoutType = layoutType || 'mobile'
    },
  },
  actions: {
    showKeyboard({ commit }, { el, layoutType }) {
      commit('setLayoutType', layoutType)
      commit('setVisibility', true)
      commit('setActiveElement', el)
      commit('setCurrentInputValue', el.value)
    },
    hideKeyboard({ commit }) {
      commit('setVisibility', false)
      commit('setActiveElement', null)
      commit('setCurrentInputValue', '')
      commit('setLayoutType', 'mobile')
    },
    updateInputValue({ commit, state }, value) {
      commit('setCurrentInputValue', value)
      if (state.activeElement) {
        state.activeElement.value = value
      }
    },
  },
}
