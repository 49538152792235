import FormulateStatusBadge from './FormulateStatusBadge.vue'

export default function VueFormulateStatusBadgePlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateStatusBadge,
    },
    library: {
      'status-badge': {
        classification: 'text',
        component: 'FormulateStatusBadge',
      },
    },
  })
}
