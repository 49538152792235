import { address, invoiceDomains, paymentTypes, storePagination } from '@/mixins'
import { localListAddItem, localListDeleteItem, localListUpdateItem } from '@/store/utils'
import { roundDecimal } from '@/utils/number-utils'
import axios from '@axios'
import { v4 as uuidV4 } from 'uuid'
import moment from 'moment'

export const getInitialInvoiceItem = () => ({
  id: 0,
  sequentialNumber: 0,
  stockLossesReason: null,
  aditionalInformation: '',
  eanCode: '',

  productTax: null, // dados de taxas do produto

  skuId: null,
  sku: null,
  ncm: null,
  cest: null,

  quantity: 1,
  unit: '',
  unityPrice: 0,

  iva: 0,
  csosnIcms: '',
  cfop: 0,
  stIcms: '',
  stIpi: '',
  ipiLegalFrameworkCode: '',
  stPis: '',
  stCofins: '',

  icmsPercent: 0,
  icmsStPercent: 0,
  icmsBaseReductionPercent: 0,
  icmsStBaseReductionPercent: 0,
  fcpPercent: 0,
  fcpStPercent: 0,
  ipiPercent: 0,
  pisPercent: 0,
  cofinsPercent: 0,

  icmsBase: 0,
  icmsStBase: 0,
  basePis: 0,
  baseCofins: 0,

  icmsValue: 0,
  icmsStValue: 0,
  fcpValue: 0,
  fcpStValue: 0,
  ipiValue: 0,
  pisValue: 0,
  cofinsValue: 0,
  ibptValue: 0,

  unityDiscountValue: 0,
  unityOtherCostValue: 0,
  discountProratedValue: 0,
  freightProratedValue: 0,
  insuranceProratedValue: 0,
  otherCostsProratedValue: 0,
  productValue: 0,
  totalValue: 0,

  ibpt: null,
})

const getInitialInvoicePayment = () => ({
  id: 0,
  paymentMethodId: null,
  paymentValue: 0,
  changeValue: 0,
})

export const getInitialInvoice = isFromSales => {
  const {
    movementTypeEnum,
    operationTypeEnum,
    invoicePurposeTypeEnum,
    presenceIndicatorEnum,
    paymentIndicatorEnum,
    invoiceModelEnum,
  } = invoiceDomains.data()
  return {
    id: null,
    invoiceNumber: 0,
    serialNumber: null,
    model: invoiceModelEnum.NFE,
    isFinalConsumer: false,
    natureOperation: '',
    cfopCode: '',
    issuedContingency: false,
    storeId: null,
    store: null,
    customerId: null,
    customer: null,
    supplierId: null,
    supplier: null,
    issueDate: moment().format(),
    movementDate: moment().format(),
    movementType: movementTypeEnum.OUTPUT,
    operationType: operationTypeEnum.INTERNAL,
    purposeType: invoicePurposeTypeEnum.NORMAL,
    presenceIndicator: presenceIndicatorEnum.PRESENTIAL_OPERATION,
    paymentIndicator: paymentIndicatorEnum.IN_CASH,
    invoiceCategory: null,
    status: null,
    freightValue: 0,
    insuranceValue: 0,
    otherCostsValue: 0,
    totalUnityDiscountValue: 0,
    discountValue: 0,
    totalTaxValue: 0,
    totalIcms: 0,
    totalIcmsSt: 0,
    totalIpi: 0,
    productsValue: 0,
    totalValue: 0,
    items: [],
    payments: [],
    invoiceReferences: [],
    nfeDetail: {
      accessKey: '',
      authorizationProtocol: null,
      returnCode: null,
      returnMessage: '',
      shippingProtocol: null,
      xmlFinishedSuccessPath: '',
      xmlGeneratedPath: '',
      danfeNFePath: '',
    },
    invoiceEvents: [],
    isFromSales,
  }
}

const getInitialState = () => ({
  ...storePagination.state(),
  invoice: getInitialInvoice(),
  isReturn: false,
  saleInvoicesFilter: {
    saleId: null,
    storeId: null,
    customerId: null,
    createdRangeDate: {
      startDate: moment().add(-30, 'days').startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
    value: null,
  },
  saleInvoices: [],
  saleInvoicesAdded: [],
  natureOperationCfop: null,
})

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {
    isDevolution(state) {
      const { invoicePurposeTypeEnum } = invoiceDomains.data()
      return state.isReturn && state.invoice.purposeType === invoicePurposeTypeEnum.DEVOLUTION
    },
    availableSaleInvoices(state) {
      return state.saleInvoices.filter(
        invoice => !state.saleInvoicesAdded.find(invoiceAdded => invoice.id === invoiceAdded.id)
      )
    },
    isGeneratingInReadOnlyMode(state) {
      const { statusInvoiceEnum, invoiceModelEnum } = invoiceDomains.data()
      // 5929 - Lançamento efetuado em decorrência de emissão de documento fiscal --> Não vamos permitir alteração dos dados neste caso
      return (
        state.invoice.natureOperation?.includes('5929') ||
        state.invoice.status === statusInvoiceEnum.ISSUED_IN_CONTINGENCY ||
        state.invoice.model !== invoiceModelEnum.NFE
      )
    },
    isAveragePrice(state) {
      const { Transference, StockLosses } = invoiceDomains.data().invoiceCategoryEnum
      return [Transference, StockLosses].includes(state.invoice.invoiceCategory)
    },
    isAdjustStock(state) {
      return !!state.invoice.stockInventory
    },

    isPaymentValuesReadOnly(state, getters) {
      return (
        getters.isStockLossesCategory ||
        getters.isProductBonusCategory ||
        getters.isStoreLendingCategory
      )
    },

    isSaleCategory(state) {
      return state.invoice.invoiceCategory === invoiceDomains.data().invoiceCategoryEnum.Sale
    },
    isSaleFromPdvCategory(state) {
      return state.invoice.invoiceCategory === invoiceDomains.data().invoiceCategoryEnum.SaleFromPdv
    },
    isInvoiceFromCouponCategory(state) {
      return (
        state.invoice.invoiceCategory ===
        invoiceDomains.data().invoiceCategoryEnum.InvoiceFromCoupon
      )
    },
    isInvoiceReturnFromCouponCategory(state) {
      return (
        state.invoice.invoiceCategory ===
        invoiceDomains.data().invoiceCategoryEnum.InvoiceReturnFromCoupon
      )
    },
    isInvoiceComplementaryCategory(state) {
      return (
        state.invoice.invoiceCategory ===
        invoiceDomains.data().invoiceCategoryEnum.InvoiceComplementary
      )
    },
    isReturnCategory(state) {
      return (
        state.invoice.invoiceCategory ===
        invoiceDomains.data().invoiceCategoryEnum.PuchaseOrderReturn
      )
    },
    isStoreCategory(state) {
      return (
        state.invoice.invoiceCategory === invoiceDomains.data().invoiceCategoryEnum.Transference
      )
    },
    // baixa de estoque
    isStockLossesCategory(state) {
      return state.invoice.invoiceCategory === invoiceDomains.data().invoiceCategoryEnum.StockLosses
    },
    // bonificação
    isProductBonusCategory(state) {
      return (
        state.invoice.invoiceCategory === invoiceDomains.data().invoiceCategoryEnum.ProductBonus
      )
    },
    // comodato
    isStoreLendingCategory(state) {
      return (
        state.invoice.invoiceCategory === invoiceDomains.data().invoiceCategoryEnum.StoreLending
      )
    },

    isFinishedOrCancelled(state) {
      const { SUCCESS_FINISHED, CANCELED } = invoiceDomains.data().statusInvoiceEnum
      return state.invoice.status === SUCCESS_FINISHED || state.invoice.status === CANCELED
    },

    canChangeTotalsAndTaxes(state, getters) {
      return !getters.isInvoiceFromCouponCategory && !getters.isInvoiceReturnFromCouponCategory
    },
  },

  mutations: {
    ...storePagination.mutations,
    SET_INVOICE(state, val) {
      if (val.items) {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        val.items.filter(i => !i.id).forEach(i => (i.localId = uuidV4()))
      }

      state.invoice = val
    },
    SET_INVOICE_SUPPLIER(state, supplier) {
      state.invoice.supplier = supplier
    },
    SET_INVOICE_CUSTOMER(state, customer) {
      state.invoice.customer = customer
    },
    SET_INVOICE_CUSTOMER_ID(state, id) {
      state.invoice.customerId = id
    },
    SET_INVOICE_STORE(state, store) {
      state.invoice.store = store
    },
    SET_INVOICE_DATA_FROM_STORE(state, { serialNumber }) {
      state.invoice.serialNumber = serialNumber
    },
    SET_INVOICE_ITEMS(state, itemList) {
      state.invoice.items = itemList
    },
    SET_INVOICE_PAYMENTS(state, paymentList) {
      state.invoice.payments = paymentList
    },
    SET_INVOICE_TOTALS(
      state,
      {
        productsValue,
        totalValue,
        totalTaxValue,
        totalIcms,
        totalIcmsSt,
        totalIpi,
        unityDiscountValue,
      }
    ) {
      state.invoice.productsValue = productsValue
      state.invoice.totalValue = totalValue
      state.invoice.totalTaxValue = totalTaxValue
      state.invoice.totalIcms = totalIcms
      state.invoice.totalIcmsSt = totalIcmsSt
      state.invoice.totalIpi = totalIpi
      state.invoice.totalUnityDiscountValue = unityDiscountValue
    },
    SET_INVOICE_IS_FINAL_CONSUMER(state, val) {
      state.invoice.isFinalConsumer = val
    },
    SET_SALE_INVOICES(state, val) {
      state.saleInvoices = val
    },
    SET_IS_RETURN(state, isReturn) {
      state.isReturn = isReturn
    },

    SET_FILTERS(state, filters) {
      state.saleInvoicesFilter = filters
    },

    CLEAN_INVOICE_TOTALS(state) {
      state.invoice.productsValue = 0
      state.invoice.totalValue = 0
      state.invoice.totalTaxValue = 0
      state.invoice.totalIcms = 0
      state.invoice.totalIcmsSt = 0
      state.invoice.totalIpi = 0
    },
    CLEAN_INVOICE_PROP(state, propName) {
      const { invoice } = getInitialState()
      state.invoice[propName] = invoice[propName]
    },
    CLEAN_INVOICE_PAYMENTS(state) {
      const { invoice } = getInitialState()
      state.invoice.payments = invoice.payments
    },
    CLEAN_STATE(state) {
      const { invoice, isReturn, natureOperationCfop, saleInvoicesFilter } = getInitialState()
      state.invoice = invoice
      state.isReturn = isReturn
      state.natureOperationCfop = natureOperationCfop
      state.saleInvoicesFilter = saleInvoicesFilter
    },
    CLEAN_NATURE_OPERATION_PROP(state) {
      const { natureOperationCfop, invoice } = getInitialState()
      state.natureOperationCfop = natureOperationCfop
      state.invoice.cfopCode = invoice.cfopCode
    },
    SET_NATURE_OPERATION_CFOP(state, natureOperationCfop) {
      state.natureOperationCfop = natureOperationCfop
      state.invoice.cfopCode = natureOperationCfop?.code
    },
    ADD_SALE_INVOICE(state, invoice) {
      state.saleInvoicesAdded.push(invoice)
    },
    REMOVE_SALE_INVOICE(state, invoiceIndex) {
      state.saleInvoicesAdded.splice(invoiceIndex, 1)
    },
    CLEAN_SALE_INVOICE(state) {
      state.saleInvoicesAdded = []
    },
  },

  actions: {
    ...storePagination.actions,
    async fetchPersonById({ commit, dispatch }, { id, type }) {
      const { PuchaseOrderReturn } = invoiceDomains.data().invoiceCategoryEnum

      if (type === PuchaseOrderReturn) {
        const { data } = await axios.get(`/api/suppliers/${id}`)
        commit('SET_INVOICE_SUPPLIER', await dispatch('preparePerson', data))
      } else {
        const { data } = await axios.get(`/api/customers/${id}`)
        commit('SET_INVOICE_CUSTOMER', await dispatch('preparePerson', data))
      }
    },
    async fetchPersonByDocument({ commit, dispatch }, { document }) {
      const { data } = await axios.get(`/api/customers/by-document/${document}`)
      commit('SET_INVOICE_CUSTOMER', await dispatch('preparePerson', data))
      commit('SET_INVOICE_CUSTOMER_ID', data.id)
    },
    async fetchStoreById({ commit, dispatch }, id) {
      const { data } = await axios.get(`/api/stores/${id}`)
      commit('SET_INVOICE_STORE', data)
      commit('SET_INVOICE_DATA_FROM_STORE', { serialNumber: String(data.invoiceSerialNumber) })

      // necessário para evitar que o destinatário seja igual à loja selecionada.
      dispatch('cleanInvoiceSupplier')
      dispatch('cleanInvoiceCustomer')
    },
    async fetchInvoice({ commit, dispatch }, id) {
      const { invoicePurposeTypeEnum } = invoiceDomains.data()
      const { data } = await axios.get(`/api/invoices/${id}`)
      const dataFormated = await dispatch('prepareInvoiceData', data)

      const isReturn =
        dataFormated.invoiceReferences.length > 0 &&
        dataFormated.purposeType === invoicePurposeTypeEnum.DEVOLUTION
      commit('SET_IS_RETURN', isReturn)
      commit('SET_INVOICE', dataFormated)
    },

    async purchaseOrderToReturn({ commit, dispatch }, payload) {
      commit('CLEAN_STATE')

      const { invoicePurposeTypeEnum } = invoiceDomains.data()
      const body = {
        idPurchaseOrder: payload.purchaseOrderId,
        cfopDevolution: payload.cfop,
        isFullReturn: payload.isFullReturn,
        items: [],
        includeTaxesOnOtherValues: payload.includeTaxesOtherCosts,
      }
      if (!body.isFullReturn) {
        body.items = payload.items
          .filter(i => i.willReturn)
          .map(i => ({
            idPurchaseOrderItem: i.id,
            quantity: i.returnQuantity,
          }))
      }
      const { data } = await axios.post(`/api/invoices/invoice-import-purchase`, body)

      const convertedData = {
        ...data,
        purposeType: invoicePurposeTypeEnum.DEVOLUTION,
      }
      const invoiceData = await dispatch('prepareInvoiceData', convertedData)

      commit('SET_IS_RETURN', true)
      commit('SET_INVOICE', invoiceData)
    },

    async fetchInvoiceFromSales({ state, commit }) {
      // commit('CLEAN_STATE')
      const { data } = await axios.get(`/api/invoices/invoice-by-sales`, {
        params: {
          ...state.saleInvoicesFilter,
          dateFrom: state.saleInvoicesFilter?.createdRangeDate?.startDate,
          dateTo: state.saleInvoicesFilter?.createdRangeDate?.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
      })
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
      commit('SET_SALE_INVOICES', data.results)
    },

    async addSaleInvoice({ commit }, invoice) {
      commit('ADD_SALE_INVOICE', invoice)
    },

    async removeSaleInvoice({ commit }, invoiceIndex) {
      commit('REMOVE_SALE_INVOICE', invoiceIndex)
    },

    async cleanSaleInvoices({ commit }) {
      commit('CLEAN_SALE_INVOICE')
    },

    async createInvoiceFromSales({ commit, dispatch, state }) {
      commit('CLEAN_STATE')
      const { invoicePurposeTypeEnum } = invoiceDomains.data()
      const { data } = await axios.post(`/api/invoices/invoice-import-sales`, {
        saleIds: state.saleInvoicesAdded.map(saleInvoice => saleInvoice.saleId),
      })
      const convertedData = {
        ...data,
        purposeType: invoicePurposeTypeEnum.NORMAL,
        isFromSales: true,
      }
      const invoiceData = await dispatch('prepareInvoiceData', convertedData)

      commit('SET_IS_RETURN', true)
      commit('SET_INVOICE', invoiceData)
    },

    async prepareInvoiceData({ dispatch }, data) {
      const initialInvoice = getInitialInvoice(data.isFromSales)
      let customer = null
      let supplier = null
      if (data.supplier) {
        supplier = await dispatch('preparePerson', data.supplier)
      } else if (data.customer) {
        customer = await dispatch('preparePerson', data.customer)
      }

      const paymentsConverted = (data?.payments || []).map(p => ({
        ...p,
        paymentType: null,
        paymentMethod: null,
        paymentMethodId: p.paymentMethod?.id ? String(p.paymentMethod.id) : null,
      }))

      const itemsFormatted =
        data.purposeType === 'COMPLEMENTARY'
          ? data.items.map(item => ({
              ...item,
              _showDetails: true,
            }))
          : data.items

      return {
        ...initialInvoice,
        ...data,
        payments: paymentsConverted,
        items: itemsFormatted,
        nfeDetail: data.nfeDetail || initialInvoice.nfeDetail,
        movementDate: data.movimentDate,
        supplierId: data.supplierId?.toString() || null,
        supplier,
        customerId: data.customerId?.toString() || null,
        customer,
        storeId: data.storeId?.toString() || null,
      }
    },

    async saveInvoiceDraft({ dispatch }) {
      const data = await dispatch('prepareToSave')
      return (
        await axios({
          url: '/api/invoices',
          method: 'POST',
          data,
        })
      ).data
    },

    async invalidateInvoice({ dispatch, state }) {
      await axios({
        url: '/api/invoices/invoice-invalidate',
        method: 'POST',
        data: {
          storeId: state.invoice.storeId,
          model: 'NFE',
          serialNumber: state.invoice.serialNumber,
          initialNumber: state.invoice.invoiceNumber,
          finalNumber: state.invoice.invoiceNumber,
          justification: 'NOTA EM RASCUNHO NÃO EMITIDA',
        },
      })
      await dispatch('fetchInvoice', state.invoice.id)
    },

    async invoiceDownload(states, { pathUrl }) {
      const payload = {
        url: pathUrl,
      }
      const { data } = await axios({
        url: '/api/invoices/invoice-download',
        method: 'POST',
        data: payload,
      })

      return data
    },

    async invoiceManage({ state }, { requestType, invoiceId, delegation }) {
      const payload = {
        invoiceRequestType: requestType,
        id: invoiceId || state.invoice.id,
      }
      const { data } = await axios({
        url: '/api/invoices/invoice-manage',
        method: 'POST',
        data: payload,
        headers: {
          'delegate-token': delegation?.token,
        },
      })

      return data
    },

    async generateComplementary({ state }, { invoiceId }) {
      const payload = {
        id: invoiceId || state.invoice.id,
      }
      const { data } = await axios({
        url: '/api/invoices/invoice-complementary',
        method: 'POST',
        data: payload,
      })

      return data
    },

    async invoiceEvent(states, { eventType, invoiceId, justification }) {
      const payload = {
        id: invoiceId,
        eventType,
        justification,
      }
      const { data } = await axios({
        url: '/api/invoices/invoice-event',
        method: 'POST',
        data: payload,
      })

      return data
    },

    preparePerson(states, data) {
      if (!data) return null

      let localAddress = address.data().address
      let telephone = ''

      const addressesActive = data.addresses.find(addressItem => addressItem.active)

      if (addressesActive) {
        localAddress = addressesActive
      }
      if (Array.isArray(data.telephones) && data.telephones.length > 0) {
        telephone = { ...data.telephones[0] }
      }
      return { ...data, address: localAddress, telephone }
    },
    prepareToSave({ state }) {
      const { invoiceModelEnum, invoiceCategoryEnum } = invoiceDomains.data()
      const localInvoice = { ...state.invoice }
      const { items } = localInvoice

      const { natureOperationCfop } = state
      localInvoice.cfopCode = natureOperationCfop?.code

      delete localInvoice.store
      delete localInvoice.customer
      delete localInvoice.supplier
      delete localInvoice.totalTaxValue
      delete localInvoice.totalIcms
      delete localInvoice.totalIcmsSt
      delete localInvoice.totalIpi
      delete localInvoice.nfeDetail

      if (state.invoice.invoiceCategory === invoiceCategoryEnum.PuchaseOrderReturn) {
        delete localInvoice.customerId
      } else if (
        state.invoice.invoiceCategory === invoiceCategoryEnum.Sale ||
        state.invoice.invoiceCategory === invoiceCategoryEnum.Transference
      ) {
        delete localInvoice.supplierId
      }

      const itemsFormated = items.map(i => {
        const newItem = { ...i }
        delete newItem.productTax
        delete newItem.sku
        return newItem
      })

      return {
        ...localInvoice,
        invoiceNumber: localInvoice.invoiceNumber || 0,
        movimentDate: localInvoice.movementDate,
        model: invoiceModelEnum.NFE,
        items: itemsFormated,
      }
    },

    setStore({ commit }, store) {
      commit('SET_INVOICE_STORE', store)
    },
    setSupplier({ commit }, supplier) {
      commit('SET_INVOICE_SUPPLIER', supplier)
    },
    setCustomer({ commit }, customer) {
      commit('SET_INVOICE_CUSTOMER', customer)
    },
    setInvoiceCategory({ commit }, invoiceCategory) {
      if (
        invoiceCategory === invoiceDomains.data().invoiceCategoryEnum.Sale ||
        invoiceCategory === invoiceDomains.data().invoiceCategoryEnum.SaleFromPdv
      ) {
        commit('SET_INVOICE_IS_FINAL_CONSUMER', true)
      }
    },

    calculateInvoiceTotals({ commit, state, dispatch }) {
      const items = state.invoice.items.filter(i => !i.isDeleted)
      const productsValue = items.reduce((total, item) => total + item.productValue, 0)
      const unityDiscountValue = items.reduce((total, item) => total + item.unityDiscountValue, 0)
      const totalValue = items.reduce((total, item) => total + item.totalValue, 0)
      const totalTaxValue = items.reduce(
        (total, item) => total + (item.icmsStValue + item.fcpStValue + item.ipiValue),
        0
      )
      const totalIcmsValue = items.reduce((total, item) => total + item.icmsValue, 0)
      const totalIcmsStValue = items.reduce((total, item) => total + item.icmsStValue, 0)
      const totalIpiValue = items.reduce((total, item) => total + item.ipiValue, 0)

      commit('SET_INVOICE_TOTALS', {
        productsValue: roundDecimal(productsValue, 2),
        totalValue: roundDecimal(totalValue, 2),
        totalTaxValue: roundDecimal(totalTaxValue, 2),
        totalIcms: roundDecimal(totalIcmsValue, 2),
        totalIcmsSt: roundDecimal(totalIcmsStValue, 2),
        totalIpi: roundDecimal(totalIpiValue, 2),
        unityDiscountValue: roundDecimal(unityDiscountValue, 2),
      })

      dispatch('updateInvoicePaymentValues')
    },
    async calculateInvoiceItems({ state, commit, dispatch, getters, rootGetters }) {
      if (!getters.canChangeTotalsAndTaxes) {
        await dispatch('calculateInvoiceTotals')
        return
      }

      const { movementTypeEnum, invoiceStockLossesReasonEnum } = invoiceDomains.data()

      const selectedNatureCfop = state.natureOperationCfop

      // get productTax if needed
      await Promise.all(
        state.invoice.items
          .filter(i => !i.isDeleted && i.skuId && selectedNatureCfop)
          .map(async i => {
            if (!i.productTax) await dispatch('enrichItemWithTax', { item: i, taxConfigOnly: true })
          })
      )

      const {
        store,
        items,
        freightValue,
        insuranceValue,
        otherCostsValue,
        discountValue,
        movementType,
        purposeType,
      } = state.invoice

      const comboCfopOutput = rootGetters['pages/invoice/taxClassifications/getComboCfopOutput']
      const comboCfopInput = rootGetters['pages/invoice/taxClassifications/getComboCfopInput']
      const comboCfops = movementType === movementTypeEnum.OUTPUT ? comboCfopOutput : comboCfopInput

      const validItems = items
        .filter(i => !i.isDeleted && i.skuId && selectedNatureCfop)
        .map(i => {
          if (getters.isInvoiceComplementaryCategory) return i

          const taxClassification = i.productTax?.taxClassification || {}

          const getCfopItem = (taxClassificationCfop, cfopItemCode) => {
            if (!selectedNatureCfop?.code) return cfopItemCode

            const hasInNatureCfopRelated = cfop =>
              selectedNatureCfop.cfopRelatedCfops.some(rel => rel.cfopRelatedCode === cfop.code)
            const optionsAuxFiltered = comboCfops.filter(hasInNatureCfopRelated)

            return optionsAuxFiltered.length > 0 &&
              optionsAuxFiltered.some(cfop => cfop.code === taxClassificationCfop)
              ? taxClassificationCfop
              : selectedNatureCfop.code
          }

          let propsByMovementType = {}

          const lossReason = getters.isStockLossesCategory
            ? i.stockLossesReason || invoiceStockLossesReasonEnum.Deviation
            : null

          if (!getters.isDevolution) {
            const propsByMovementTypeCommon = {
              icmsBase: i.icmsBase,
              icmsStBase: i.icmsStBase,
              icmsValue: i.icmsValue,
              icmsStValue: i.icmsStValue,
              stIcms: taxClassification.stIcms || i.stIcms,
              icmsPercent: taxClassification.icmsPercent || i.icmsPercent,
              icmsStPercent: taxClassification.icmsStPercent || i.icmsStPercent,
              icmsBaseReductionPercent:
                taxClassification.icmsBaseReductionPercent || i.icmsBaseReductionPercent,
              icmsStBaseReductionPercent:
                taxClassification.icmsStBaseReductionPercent || i.icmsStBaseReductionPercent,
              fcpPercent: taxClassification.fcpPercent || i.fcpPercent,
              fcpStPercent: taxClassification.fcpStPercent || i.fcpStPercent,
              ipiPercent: taxClassification.ipiPercent || i.ipiPercent,
              pisPercent: taxClassification.pisPercent || i.pisPercent,
              cofinsPercent: taxClassification.cofinsPercent || i.cofinsPercent,
              benefitCode: taxClassification?.benefitCode,
              icmsTaxReliefReason: taxClassification?.icmsTaxReliefReason,
            }

            const customTaxState = selectedNatureCfop.cfopCustomTaxes?.find(
              c => c.stockLossesReason === lossReason
            )
            const customTax = customTaxState ? { ...customTaxState } : null // clone obj, so the delete operation do not reflect to obj in state

            if (selectedNatureCfop && customTax) {
              const benefitCode = customTax.benefitCodes
                ? customTax.benefitCodes[store.address.province]
                : null

              delete customTax.id
              delete customTax.cfopCode
              delete customTax.benefitCodes

              propsByMovementType = {
                cfop: selectedNatureCfop.code || i.cfop,
                benefitCode,
                ...customTax,
              }
            } else if (movementType === movementTypeEnum.INPUT) {
              propsByMovementType = {
                ...propsByMovementTypeCommon,
                cfop: getCfopItem(taxClassification?.cfopInput, i.cfop),
                stIpi: taxClassification?.stIpiInput || i.stIpi,
                stPis: taxClassification?.stPisInput || i.stPis,
                stCofins: taxClassification?.stCofinsInput || i.stCofins,
              }
            } else if (movementType === movementTypeEnum.OUTPUT) {
              propsByMovementType = {
                ...propsByMovementTypeCommon,
                cfop: getCfopItem(taxClassification?.cfopOutput, i.cfop),
                stIpi: taxClassification?.stIpiOutput || i.stIpi,
                stPis: taxClassification?.stPisOutput || i.stPis,
                stCofins: taxClassification?.stCofinsOutput || i.stCofins,
              }
            }
          }
          return {
            ...i,
            ...propsByMovementType,
            stockLossesReason: lossReason,
            discountProratedValue: 0,
            freightProratedValue: 0,
            insuranceProratedValue: 0,
            otherCostsProratedValue: 0,
          }
        })

      if (validItems.length === 0) {
        commit('CLEAN_INVOICE_TOTALS')
        return
      }

      const body = {
        items: validItems,
        discount: discountValue,
        freigth: freightValue,
        otherCosts: otherCostsValue,
        insurance: insuranceValue,
        purposeType,
      }

      const { data } = await axios.post(`/api/invoices/calculate-item-tax`, body)
      await Promise.all(
        validItems.map(async validItem => {
          const returnedItem = data.find(i => validItem.sequentialNumber === i.sequentialNumber)
          await dispatch('updateItem', {
            ...validItem,
            ...returnedItem,
            id: validItem.id,
          })
        })
      )

      await dispatch('calculateInvoiceTotals')
    },

    addItem({ commit, state, getters }) {
      if (getters.isDevolution) return
      const item = getInitialInvoiceItem()
      const sequentialNumber = state.invoice.items.filter(p => !p.isDeleted).length + 1
      const itemsList = localListAddItem(state.invoice.items, { ...item, sequentialNumber })
      commit('SET_INVOICE_ITEMS', itemsList)
    },
    updateItem({ state, commit }, itemData) {
      // console.log('itemData', itemData)
      const itemList = localListUpdateItem(state.invoice.items, itemData)
      commit('SET_INVOICE_ITEMS', itemList)
    },
    removeItem({ commit, state, getters }, item) {
      if (getters.isDevolution) return
      const itemId = item.localId || item.id
      const itemsList = localListDeleteItem(state.invoice.items, itemId)

      let numberAux = 1
      const itemListFormated = itemsList.map(p => {
        if (p.isDeleted) {
          return p
        }
        const updatedItem = { ...p, sequentialNumber: numberAux }
        numberAux += 1
        return updatedItem
      })

      commit('SET_INVOICE_ITEMS', itemListFormated)
    },
    async enrichItemWithTax({ state, dispatch, getters }, { item, taxConfigOnly }) {
      if (!getters.canChangeTotalsAndTaxes) return

      const ean = item.sku?.ean || item.eanCode
      const { unitOfMeasurement } = item.sku || {}
      const { storeId } = state.invoice
      const { data } = await axios.get(
        `/api/sales/pay-box-sku/store/${storeId}/ean/${ean}?averagePrice=${getters.isAveragePrice}`
      )
      const { taxClassification, cest, taxIbpt } = data.tax

      const dataFormated =
        taxConfigOnly === true
          ? {
              ...item,
              productTax: data.tax,
              ncm: taxClassification?.ncm,
              cest: cest ? cest.code : null,
              ibpt: taxIbpt,
            }
          : {
              ...item,
              productTax: data.tax,
              unityPrice: data.price,
              eanCode: ean,
              unit: unitOfMeasurement || item.unit,
              ncm: taxClassification?.ncm,
              cest: cest ? cest.code : null,
              iva: taxClassification?.iva,
              csosnIcms: taxClassification.csosnIcms,
              stIcms: taxClassification.stIcms,
              ipiLegalFrameworkCode: taxClassification.ipiLegalFrameworkCode,
              icmsPercent: taxClassification.icmsPercent || 0,
              icmsStPercent: taxClassification.icmsStPercent || 0,
              icmsBaseReductionPercent: taxClassification.icmsBaseReductionPercent || 0,
              icmsStBaseReductionPercent: taxClassification.icmsStBaseReductionPercent || 0,
              fcpPercent: taxClassification.fcpPercent || 0,
              fcpStPercent: taxClassification.fcpStPercent || 0,
              ipiPercent: taxClassification.ipiPercent || 0,
              pisPercent: taxClassification.pisPercent || 0,
              cofinsPercent: taxClassification.cofinsPercent || 0,
              ibpt: taxIbpt,
            }

      await dispatch('updateItem', dataFormated)
    },
    async updateItemsOfStore({ state, dispatch }) {
      const { items } = state.invoice
      await Promise.all(
        items.map(async i => {
          await dispatch('enrichItemWithTax', { item: i })
        })
      )

      await dispatch('calculateInvoiceItems')
    },

    addPayment({ commit, state }, paymentInfo) {
      const payment = {
        ...getInitialInvoicePayment(),
        ...(paymentInfo || {}),
      }
      const totalPayments = state.invoice.payments
        .filter(p => !p.isDeleted)
        .reduce((total, p) => total + p.paymentValue, 0)
      const paymentValue = state.invoice.totalValue - totalPayments
      const paymentList = localListAddItem(state.invoice.payments, {
        ...payment,
        paymentValue: roundDecimal(paymentValue),
      })
      commit('SET_INVOICE_PAYMENTS', paymentList)
    },
    updatePayment({ commit, state }, paymentUpdate) {
      const paymentList = localListUpdateItem(state.invoice.payments, {
        ...paymentUpdate,
      })
      commit('SET_INVOICE_PAYMENTS', paymentList)
    },
    removePayment({ commit, state }, payment) {
      const paymentId = payment.localId || payment.id
      const paymentList = localListDeleteItem(state.invoice.payments, paymentId)
      commit('SET_INVOICE_PAYMENTS', paymentList)
    },
    updateInvoicePaymentValues({ state, getters, rootState, dispatch }) {
      if (getters.isFinishedOrCancelled || !getters.isPaymentValuesReadOnly) return

      const { WITHOUT_PAYMENT } = paymentTypes.data().paymentTypeEnum
      const withoutPayment = (rootState.app.paymentMethods || []).find(
        pm => pm.method === WITHOUT_PAYMENT
      )

      const wpInPayments = state.invoice.payments.filter(
        p => p.paymentMethodId && p.paymentMethodId.toString() === withoutPayment?.id.toString()
      )

      if (wpInPayments.length > 1) return

      const isToUpdate =
        wpInPayments.length > 0 ||
        (state.invoice.payments.length === 1 && !state.invoice.payments[0].paymentMethodId)

      const invoiceTotalValue = state.invoice.totalValue
      if (isToUpdate) {
        dispatch('updatePayment', {
          ...wpInPayments[0],
          paymentValue: invoiceTotalValue,
        })
      } else {
        dispatch('addPayment', {
          paymentMethodId: withoutPayment?.id,
          paymentValue: invoiceTotalValue,
        })
      }
    },

    cleanInvoiceSupplier({ commit }) {
      commit('CLEAN_INVOICE_PROP', 'supplierId')
      commit('CLEAN_INVOICE_PROP', 'supplier')
    },
    cleanInvoiceCustomer({ commit }) {
      commit('CLEAN_INVOICE_PROP', 'customerId')
      commit('CLEAN_INVOICE_PROP', 'customer')
    },
    cleanInvoiceStore({ commit }) {
      commit('CLEAN_INVOICE_PROP', 'storeId')
      commit('CLEAN_INVOICE_PROP', 'store')
    },
    cleanInvoicePayments({ commit }) {
      commit('CLEAN_INVOICE_PAYMENTS')
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().saleInvoicesFilter)
    },
    // campo natureOperation da invoice
    cleanNatureOperation({ commit }) {
      commit('CLEAN_INVOICE_PROP', 'natureOperation')
    },
    // campo natureOperationCfop para auxiliar no layout
    cleanNatureOperationCfop({ commit }) {
      commit('CLEAN_NATURE_OPERATION_PROP')
    },
    // campo natureOperationCfop para auxiliar no layout
    setNatureOperationCfop({ commit }, natureOperationCfop) {
      commit('SET_NATURE_OPERATION_CFOP', natureOperationCfop)
    },
  },
}
