<template>
  <div class="cc-selector-2">
    <template v-for="option in comboOptions">
      <div
        :key="`${elementId}-${option.value}`"
        class="radio-input-container"
      >
        <input
          :id="`${elementId}-${option.value}`"
          v-model="context.model"
          type="radio"
          :name="elementId"
          :value="option.value"
          @change="(event) => onInput(event, option.value)"
        >
        <label
          class="drinkcard-cc"
          :for="`${elementId}-${option.value}`"
        >
          <img
            class="w-75"
            :src="option.icon"
            :alt="option.label"
          >
        </label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FormulateERadioIcon',
  components: {},

  props: {
    context: {
      type: Object,
      required: true,
    },

    /**
     * lista com opções para selecionar
     * ex: [{
     *    icon: string, // caminho da imagem
     *    value: string | number,
     *    label: string,
     * }]
     */
    comboOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {}
  },

  computed: {
    elementId() {
      return this.context?.attributes?.id || 'radio_icon'
    },
  },

  methods: {
    onInput(event, val) {
      this.context.model = val
      this.context.rootEmit('input', val)
    },
  },
}
</script>

<style lang="scss" scoped>
// styles based on http://jsfiddle.net/La8wQ/313/
.cc-selector-2 {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.radio-input-container {
  height: 100px;
  position: relative;
}

.cc-selector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cc-selector-2 input {
  position: absolute;
  z-index: 999;
}

.cc-selector-2 input:active + .drinkcard-cc,
.cc-selector input:active + .drinkcard-cc {
  opacity: 0.9;
}
.cc-selector-2 input:checked + .drinkcard-cc,
.cc-selector input:checked + .drinkcard-cc {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}
.drinkcard-cc {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;

  // controla o tamanho das imagens
  width: 100px;
  height: 80px;

  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
  filter: brightness(1.8) grayscale(1) opacity(0.7);
}
.drinkcard-cc:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}

/* Extras */
a:visited {
  color: #888;
}
a {
  color: #444;
  text-decoration: none;
}
p {
  margin-bottom: 0.3em;
}
* {
  font-family: monospace;
}
span.cc {
  color: #6d84b4;
}
</style>
