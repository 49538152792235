const approvalFlowDomains = {
  data() {
    return {
      approvalFlowStatusOptions: [
        {
          value: 'Approved',
          label: this.$t(`ApprovalFlow.Status.Approved`),
          classStyle: 'success',
        },
        {
          value: 'Disapproved',
          label: this.$t(`ApprovalFlow.Status.Disapproved`),
          classStyle: 'danger',
        },
        {
          value: 'InProgress',
          label: this.$t(`ApprovalFlow.Status.InProgress`),
          classStyle: 'primary',
        },
        {
          value: 'Holding',
          label: this.$t(`ApprovalFlow.Status.InProgress`),
          classStyle: 'none',
        },
        {
          value: 'ChangesRequested',
          label: this.$t(`ApprovalFlow.Status.ChangesRequested`),
          classStyle: 'warning',
        },
        {
          value: 'Canceled',
          label: this.$t(`ApprovalFlow.Status.Canceled`),
          classStyle: 'danger',
          // style: object, // objeto CSS para estilos ex: { 'background-color': 'blue' }
        },
      ],
    }
  },
  computed: {
    approvalFlowLabel() {
      return {
        Holding: this.$t('ApprovalFlow.Status.Holding'),
        InProgress: this.$t('ApprovalFlow.Status.InProgress'),
        Approved: this.$t('ApprovalFlow.Status.Approved'),
        Disapproved: this.$t('ApprovalFlow.Status.Disapproved'),
        ChangesRequested: this.$t('ApprovalFlow.Status.ChangesRequested'),
        Canceled: this.$t('ApprovalFlow.Status.Canceled'),
      }
    },
  },
  methods: {
  },
}

export default approvalFlowDomains
