const stockDomain = {
  data() {
    return {
      stockInventoryStatusEnum: {
        IN_PROGRESS: 'InProgress',
        INVOICE_PENDING: 'InvoicePending',
        COMPLETED_PENDING: 'CompletedPending',
        COMPLETED: 'Completed',
        CANCELED: 'Canceled',
      },
    }
  },

  computed: {
    stockReservationStatusEnum() {
      return {
        ACTIVE: 'Active',
        FINISHED: 'Finished',
        CANCELED: 'Canceled',
      }
    },

    stockReservationStatusLabel() {
      return {
        Active: this.$t('Ativo'),
        Finished: this.$t('Finalizado'),
        Canceled: this.$t('Cancelado'),
      }
    },

    stockInventoryStatusLabel() {
      return {
        [this.stockInventoryStatusEnum.IN_PROGRESS]: this.$t('InProgress'),
        [this.stockInventoryStatusEnum.INVOICE_PENDING]: this.$t(
          'STOCK_INVENTORY.STATUS.InvoicePending'
        ),
        [this.stockInventoryStatusEnum.COMPLETED_PENDING]: this.$t(
          'STOCK_INVENTORY.STATUS.CompletedPending'
        ),
        [this.stockInventoryStatusEnum.COMPLETED]: this.$t('Completed'),
        [this.stockInventoryStatusEnum.Canceled]: this.$t('Canceled'),
      }
    },

    stockMovementOriginLabel() {
      return {
        Manual: this.$t('Manual'),
        Inversion: this.$t('Inversão de estoque'),
        BarrelConsumption: this.$t('Consumo de chope'),
        PurchaseOrder: this.$t('Pedido de compra'),
        Sale: this.$t('Venda'),
        SaleReclaim: this.$t('Retorno venda'),
        SaleItemReturn: this.$t('Devolução consignado'),
        Inventory: this.$t('Inventário'),
        Invoice: this.$t('Nota fiscal'),
      }
    },
    stockMovementTypeLabel() {
      return {
        Input: this.$t('Entrada'),
        Output: this.$t('Saída'),
      }
    },
  },

  methods: {
    stockReservationStatusOptions() {
      return [
        { value: 'Active', label: this.$t('Ativo'), classStyle: 'success' },
        { value: 'Finished', label: this.$t('Finalizado'), classStyle: 'warning' },
        { value: 'Canceled', label: this.$t('Cancelado'), classStyle: 'danger' },
      ]
    },

    stockInventoryStatusOptions() {
      return [
        { value: 'InProgress', label: this.$t('InProgress'), classStyle: 'secondary' },
        {
          value: 'InvoicePending',
          label: this.$t('STOCK_INVENTORY.STATUS.InvoicePending'),
          classStyle: 'warning',
        },
        {
          value: 'CompletedPending',
          label: this.$t('STOCK_INVENTORY.STATUS.CompletedPending'),
          classStyle: 'warning',
        },
        { value: 'Completed', label: this.$t('Completed'), classStyle: 'success' },
        { value: 'Canceled', label: this.$t('Canceled'), classStyle: 'danger' },
      ]
    },
  },
}

export default stockDomain
