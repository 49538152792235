const personCategoryEnum = {
  CUSTOMER: 'Customer',
  SUPPLIER: 'Supplier',
}

const personTypes = {
  data() {
    return {
      personCategoryEnum,
    }
  },

  computed: {
    personTypeEnum() {
      return {
        'CONSUMER': 'Consumer',
        'JURIDICAL': 'Juridical',
        'INDIVIDUAL': 'Individual',
      }
    },
    getPersonType() {
      return {
        'Consumer': this.$t('Consumidor'),
        'Juridical': this.$t('Jurídica'),
        'Individual': this.$t('Física'),
      }
    },

    personCategoryLabel() {
      return {
        [personCategoryEnum.CUSTOMER]: this.$t('Cliente'),
        [personCategoryEnum.SUPPLIER]: this.$t('Fornecedor'),
      }
    },
  },

  methods: {
    personTypes() {
      return [
        { value: 'Individual', label: this.$t('Física') },
        { value: 'Juridical', label: this.$t('Jurídica') },
      ]
    },
    personTypesWithAll() {
      return [
        { value: '', label: this.$t('Todas') },
        { value: 'Individual', label: this.$t('Física') },
        { value: 'Juridical', label: this.$t('Jurídica') },
      ]
    },
    personTypesOptions() {
      return [
        { value: 'Consumer', label: this.$t('Consumidor') },
        { value: 'Individual', label: this.$t('Pessoa Física') },
        { value: 'Juridical', label: this.$t('Pessoa Jurídica') },
      ]
    },

    personCategoryOptions() {
      return [
        { value: personCategoryEnum.CUSTOMER, label: this.$t('Cliente') },
        { value: personCategoryEnum.SUPPLIER, label: this.$t('Fornecedor') },
      ]
    },
    personCategoryRadioOptions() {
      return {
        [personCategoryEnum.CUSTOMER]: this.$t('Cliente'),
        [personCategoryEnum.SUPPLIER]: this.$t('Fornecedor'),
      }
    }
  },
}

export default personTypes
