import { stringUtils } from '@/mixins'
import axios from '@axios'

const uploader = {
  methods: {
    /**
     * Realiza o upload de documentos para o servidor, retornando as urls dos arquivos enviados.
     * @param {*} param0
     * @returns
     */
    async upload({ files, destinationPath }) {
      const uploadRequests = []
      const result = []
      const alreadUploadedFiles = {}
      if (files?.length) {
        files.forEach((fileItem, index) => {
          // Só faz o upload se o arquivo ainda não foi enviado ao server
          if (typeof fileItem.url === 'function' && !fileItem.url()) {
            // formData.append(treatedInputName, fileItem.file)
            uploadRequests.push({
              fileName: fileItem.name(),
              file: fileItem.file,
            })
          } else {
            // Se já foi enviado ao server, apenas retorna as propriedades do arquivo
            alreadUploadedFiles[index] = {
              name: typeof fileItem.name === 'function' ? fileItem.name() : fileItem.name,
              url: typeof fileItem.url === 'function' ? fileItem.url() : fileItem.url,
              size: fileItem.size,
            }
          }
        })
      } else {
        return result
      }

      if (uploadRequests.length) {
        const formData = new FormData()
        formData.append('filePath', destinationPath)
        uploadRequests.forEach(fileRequest => {
          formData.append('files', fileRequest.file)
        })
        const uploadResult = (await this.$http.post('/api/files/upload', formData)).data
        uploadResult.forEach(fileResult => {
          result.push({
            name: fileResult.fileName,
            url: fileResult.fileUrl,
            size: 0,
          })
        })
      }
      Object.keys(alreadUploadedFiles).forEach(fileIndex => {
        result.splice(fileIndex, 0, alreadUploadedFiles[fileIndex])
      })
      return result
    },

    /**
     * Realiza o upload de documentos para o servidor usando a api informada na chamada, retornando o resultado da chamada da api.
     * @param {*} param0
     * @returns
     */
    async uploadCustom({ file, apiUrl, params, fileParamName = 'files' }) {
      const formData = new FormData()
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const param in params) {
        formData.append(param, params[param])
      }
      formData.append(fileParamName, file.file)
      const uploadResult = (await axios.post(apiUrl, formData)).data
      return uploadResult
    },

    /**
     * A partir de uma url de arquivo, gera um objeto para ser utilizado no componente Formulate[uploader]
     * @param {*} fileUrl
     * @returns
     */
    getFileInstance(fileUrl) {
      if (!fileUrl) return null
      return {
        url: fileUrl,
        name: stringUtils.methods.getFileName(fileUrl),
        type: stringUtils.methods.getFileType(fileUrl),
        size: 0,
        ext: stringUtils.methods.getFileExtension(fileUrl),
        urlValue: fileUrl,
      }
    },

    //   /**
    //    * Realiza o upload de documentos para o servidor, retornando as urls dos arquivos enviados.
    //    * @param {*} param0
    //    * @returns
    //    */
    //   /* eslint-disable no-shadow */
    //   async uploadWithPreSignedUrl({
    //     files,
    //     // inputName,
    //     // fileType,
    //     // destinationPath,
    //     // isPublic = false,
    //     // imageType,
    //   }) {
    //     // const treatedInputName = inputName || 'file'
    //     // const formData = new FormData()
    //     debugger
    //     const preSignedUrlRequests = []
    //     const result = []
    //     if (files?.length) {
    //       files.forEach(fileItem => {
    //         // Só faz o upload se o arquivo ainda não foi enviado ao server
    //         if (typeof fileItem.url === 'function' && !fileItem.url()) {
    //           // formData.append(treatedInputName, fileItem.file)
    //           preSignedUrlRequests.push({
    //             s3Key: fileItem.name(),
    //             httpVerb: 'PUT',
    //           })
    //         } else {
    //           // Se já foi enviado ao server, apenas retorna as propriedades do arquivo
    //           result.push({
    //             name: typeof fileItem.name === 'function' ? fileItem.name() : fileItem.name,
    //             url: typeof fileItem.url === 'function' ? fileItem.url() : fileItem.url,
    //             size: fileItem.size,
    //           })
    //         }
    //       })
    //     } else {
    //       return result
    //     }

    //     if (preSignedUrlRequests.length) {
    //       const signedUrls = await this.$http.post('/api/files/getPreSignedUrl', preSignedUrlRequests)
    //       // const promises = []
    //       signedUrls.data.forEach(signedUrlResult => {
    //         result.push({
    //           name: signedUrlResult.fileName,
    //           url: signedUrlResult.fileUrl,
    //           size: 0,
    //         })
    //         const formData = new FormData()
    //         formData.append(
    //           'file',
    //           files.find(file => file.name() === signedUrlResult.fileName).file
    //         )
    //         // promises.push(axios.put(signedUrlResult.signedUrl, formData))
    //         axios.put(signedUrlResult.signedUrl, formData)
    //       })
    //     }
    //     return result
    //   },
  },
}

export default uploader
