import axios from '@axios'

const getInitialState = () => ({
  clients: [],
})

export default {
  namespaced: true,
  state: getInitialState(),
  modules: {},
  getters: {
    getComboClients(state) {
      return state.clients.map(c => ({
        ...c,
        label: c.name,
        value: c.id,
      }))
    },
  },

  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients
    },

    CLEAN_STATE(state) {
      const { clients } = getInitialState()
      state.clients = clients
    },
  },

  actions: {
    async searchClients({ commit }, { clientSearch }) {
      if (clientSearch.length >= 3) {
        // TODO buscar por clientes
        const { data } = await axios.get(`/api/sales/pay-box-customer/search`, {
          params: { searchString: clientSearch || '' },
        })

        commit('SET_CLIENTS', data.results || [])
        return data.results || []
      }

      return []
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
