import FormulateTags from './FormulateTags.vue'

export default function VueFormulateTagsPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateTags,
    },
    library: {
      tags: {
        classification: 'text',
        component: 'FormulateTags',
      },
    },
  })
}
