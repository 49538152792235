import axios from '@axios'
import { storePagination, sorting } from '@/mixins'
// import moment from 'moment'

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'createdDate',
    sortDesc: true,
  },
  stockReservations: [],
  filters: {
    storeId: null,
    skuId: null,
    saleId: null,
    movementType: null,
    movementOrigin: null,
    invoiceNumber: '',
    status: null,
    period: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_STOCK_RESERVATIONS(state, stockReservations) {
      state.stockReservations = stockReservations
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, stockReservations } = getInitialState()
      state.filters = filters
      state.stockReservations = stockReservations
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchStockReservations({ commit, state }) {
      const { startDate, endDate } = state.filters.period || {}
      const { data } = await axios.get('/api/stock-reservations', {
        params: {
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          saleId: state.filters.saleId,
          status: state.filters.status,
          from: startDate,
          to: endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_STOCK_RESERVATIONS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    resetFilters({ commit }) {
      commit('CLEAN_FILTERS')
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
