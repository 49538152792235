const bankDepositImportModelEnum = {
  PROSEGUR: 'Prosegur',
  ECHOPE: 'Echope',
}

const checkingAccountDomains = {
  data() {
    return {
      bankDepositImportModelEnum,
    }
  },

  computed: {
    bankDepositImportTypeLabel() {
      return {
        [bankDepositImportModelEnum.ECHOPE]: this.$t('Echope'),
        [bankDepositImportModelEnum.PROSEGUR]: this.$t('Prosegur'),
      }
    }
  },
  methods: {
    bankDepositImportTypeOptions() {
      const { ECHOPE, PROSEGUR } = bankDepositImportModelEnum
      return [
        { value: ECHOPE, label: this.bankDepositImportTypeLabel[ECHOPE] },
        { value: PROSEGUR, label: this.bankDepositImportTypeLabel[PROSEGUR] },
      ]
    },
  },
}

export default checkingAccountDomains
